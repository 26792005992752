(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('PermissionManagementController', PermissionManagementController);

    PermissionManagementController.$inject = ['$scope', '$stateParams', '$state', "$log", 'Principal', 'targetEntity', 'targetEntityPermissions', 'permissions', 'companyService'];

    function PermissionManagementController($scope, $stateParams, $state, $log, Principal, targetEntity, targetEntityPermissions, permissions, companyService) {
        $scope.targetEntityPermissions = targetEntityPermissions;
        $scope.permissions = permissions;
        $scope.companyIdNameMap = [];
        $scope.permissionNames = new Array();
        $scope.permissionRows = [];
        //koosta lista joka naytetaan hallintasivulla (permissionin nimi, yrityksen nimi tai organisaatiotason oikeus
        if (targetEntityPermissions !== null && targetEntityPermissions.length > 0) {
            Principal.identity()
                .then(function (account) {
                    var accountSourceId = account.sourceId;
                    companyService.getCompanyIdAndNameForOrg(accountSourceId).then(function (companies) {
                        angular.forEach(targetEntityPermissions, function (permissionMapping) {
                            var permissionMappingId = {id: permissionMapping.id};
                            var companyName = null;
                            for (var company in companies) {
                                if (companies[company].companyId === permissionMapping.companyId) {
                                    companyName = companies[company].name;
                                    break;
                                }
                            }
                            var targetCompanyName = {companyName: companyName};
                            var permissionName = null;
                            for (var permission in permissions) {
                                if (permissions[permission].id === permissionMapping.permissionId) {
                                    permissionName = permissions[permission].code;
                                    break;
                                }
                            }
                            var targetPermissionName = {permissionName: permissionName};

                            var singleRow = {};
                            angular.extend(singleRow, permissionMappingId, targetPermissionName, targetCompanyName);
                            $scope.permissionRows.push(singleRow);
                        });
                    });
                });

        }
        //
        $scope.targetEntity = targetEntity;
        $scope.sourceType = $stateParams.sourceType;
        $scope.sourceId = $stateParams.sourceId;
        $scope.targetName = undefined;

        if ($stateParams.sourceType === "user") {
            $scope.targetName = targetEntity.login;
            $scope.sourceStateSref = "org-user-management";
        } else {
            $scope.targetName = targetEntity.name;
            $scope.sourceStateSref = "user-group";
        }
    }
})();
