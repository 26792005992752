import {CompanyAccountChartEditDialogController} from "./company-account-chart-edit-dialog.controller";
import template from './company-account-chart.html';

export const CompanyAccountChartComponent = {
    template: template,
    controller: class CompanyAccountChartComponent {
        constructor(CompanyChartService, CompanyAccountChartService, $log, $translate, $uibModal, SelectedCompanyService, BasicChartOfAccountsService, ChartService, Principal) {
            "ngInject";
            this.companyAccountChartService = CompanyAccountChartService;
            this.basicChartOfAccountsService = BasicChartOfAccountsService;
            this.companyChartService = CompanyChartService;
            this.chartService = ChartService;
            this.$log = $log;
            this.$translate = $translate;
            this.$uibModal = $uibModal;
            this.chartModified = false;
            this.chart = null;
            this.basicCharts = [];
            this.measures = [];
            this.incomeStatementAccountGroups = [];
            this.balanceSheetAccountGroups = [];
            this.ptkButtonsVisible=false;
            this.selectedCompanyId = SelectedCompanyService.getSelectedCompanyId();
            this.selectedBasicChartId = SelectedCompanyService.getBasicChartOfAccountsId();
            this.Principal = Principal;
            $('[data-toggle="tooltip"]').tooltip();
            this.measureOptionsFn = () => {
                return this.companyAccountChartService.getCompanyMeasures(this.selectedCompanyId);
            };
        }

        edit(row, chart, basicCharts, basicChartId, ptkButtonsVisible) {
            this.$uibModal.open({
                backdrop: 'static',
                template: require('./company-account-chart-edit-dialog.html'),
                controller: CompanyAccountChartEditDialogController,
                controllerAs: '$ctrl',
                size: 'lg',
                resolve: {
                    row: function () {
                        return row;
                    },
                    tase: function () {
                        if (chart.roots.length>3){
                            if (chart.roots[1].name.replace(/\s+/g, '') === 'TASE') {
                                return chart.roots[1];
                            } else if (chart.roots[2].name.replace(/\s+/g, '') === 'TASE') {
                                return chart.roots[2];
                            }
                        }else{
                            if (chart.roots[0].name.replace(/\s+/g, '') === 'TASE') {
                                return chart.roots[0];
                            } else if (chart.roots[1].name.replace(/\s+/g, '') === 'TASE') {
                                return chart.roots[1];
                            }
                        }


                    },
                    tuloslaskelma: function () {
                        if (chart.roots.length>3){
                            if (chart.roots[1].name.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                                return chart.roots[1];
                            } else if (chart.roots[2].name.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                                return chart.roots[2];
                            }
                        }else{
                            if (chart.roots[0].name.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                                return chart.roots[0];
                            } else if (chart.roots[1].name.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                                return chart.roots[1];
                            }
                        }

                    },
                    PTKtase: function(){
                        var basicChart = basicCharts.find((g)=>{return g.id === basicChartId});
                        if (basicChart.roots[0].name.replace(/\s+/g, '') === 'TASE') {
                            return basicChart.roots[0];
                        } else if (basicChart.roots[1].name.replace(/\s+/g, '') === 'TASE') {
                            return basicChart.roots[1];
                        }
                    },
                    PTKtuloslaskelma: function(){

                        var basicChart = basicCharts.find((g)=>{return g.id === basicChartId});
                        if (basicChart.roots[0].name.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                            return basicChart.roots[0];
                        } else if (basicChart.roots[1].name.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                            return basicChart.roots[1];
                        }
                    },
                    PTKButtons: function(){
                        return ptkButtonsVisible;
                    }
                }
            }).result.then((object) => {
                this.$log.debug('modal return: object', object);
                if (object.account) {
                    this.ptkButtonsVisible = object.ptkButtonsVisible;
                    if (object.selectedYTKRow){
                        this.companyAccountChartService.editCompanyAccountChartAccount(
                            this.selectedCompanyId,
                            object.account.id,
                            {
                                companyChartGroupId: object.selectedYTKRow.id,
                                accountNameFi: object.account.nameFi,
                                accountNameEn: object.account.nameEn,
                                accountNameSv: object.account.nameSv
                            }).then((response) => {
                            this.loadChart();
                            this.chartModified = true;
                        });
                    }else if(object.selectedPTKRow){
                        this.companyAccountChartService.editCompanyAccountChartAccount(
                            this.selectedCompanyId,
                            object.account.id,
                            {
                                basicChartGroupId: object.selectedPTKRow.id,
                                accountNameFi: object.account.nameFi,
                                accountNameEn: object.account.nameEn,
                                accountNameSv: object.account.nameSv
                            }).then((response) => {
                            this.loadChart();
                            this.chartModified = true;
                        });
                    } else {
                        this.companyAccountChartService.editCompanyAccountChartAccount(
                            this.selectedCompanyId, object.account.id,
                            {
                                companyChartGroupId: null,
                                basicChartGroupId: null,
                                accountNameFi: object.account.nameFi,
                                accountNameEn: object.account.nameEn,
                                accountNameSv: object.account.nameSv
                            }).then((response) => {
                                this.loadChart();
                                this.chartModified = true;
                            });
                    }

                } else if (object.group) {
                    if (object.selected) {
                        this.companyAccountChartService.editCompanyAccountChartGroup(
                            this.selectedCompanyId,
                            object.group.id, {
                                "parentId": object.selected.id,
                                "nameFi": object.group.nameFi,
                                "nameEn": object.group.nameEn,
                                "nameSv": object.group.nameSv
                            }).then((response) => {
                            this.loadChart();
                            this.chartModified = true;
                        });
                    } else {
                        this.companyAccountChartService.editCompanyAccountChartGroup(
                            this.selectedCompanyId,
                            object.group.id, {
                                "parentId": undefined,
                                "nameFi": object.group.nameFi,
                                "nameEn": object.group.nameEn,
                                "nameSv": object.group.nameSv
                            }).then((response) => {

                            this.loadChart();
                            this.chartModified = true;
                        });
                    }
                }
            });
        };

        $onInit() {
            let self = this;
            this.Principal.identity().then(function (account) {
                self.hasPermission = self.Principal.hasPermissionOrAuthority( "ACCOUNT_MAPPING", "ROLE_ADMIN_ORGANISATION");
                self.loadChart();
            })
        };

        loadChart() {
            this.companyAccountChartService.getCompanyAccountChart(this.selectedCompanyId).then((response) => {
                this.chart = this.companyChartService.fromJsonToCharts(response);
                this.chart.roots.push({name:"Measures", title:"TUNNUSLUVUT"});

                this.activeTabIndex = 0;
                this.langKey = this.$translate.use();

                this.incomeStatementAccountGroups = this.chart.incomeStatement.rows;
                this.balanceSheetAccountGroups = this.chart.balanceSheet.rows;
                this.loadMeasures();
            });

            this.basicChartOfAccountsService.getBasicChartOfAccounts().then((response) => {
                this.basicCharts = this.chartService.fromJsonToCharts(response);
            });
        }

        loadMeasures() {
            this.companyAccountChartService.queryMeasures(this.selectedCompanyId).then((response) => {
                this.measures = response;
            });
        }

        addMeasure(measure) {
            this.companyAccountChartService.saveMeasure(this.selectedCompanyId, measure).then((result)=>{
                this.loadMeasures();
            });
        }

        updateMeasure(measure) {
            this.companyAccountChartService.updateMeasure(this.selectedCompanyId, measure).then((result)=>{
                this.loadMeasures();
            });
        }

        removeMeasure(measure) {
            this.companyAccountChartService.removeMeasure(this.selectedCompanyId, measure).then((result)=>{
                this.loadMeasures();
            });
        }
        setActiveTabIndex(index){
            this.activeTabIndex = index;
        }
    }
};
