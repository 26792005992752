'use strict';

angular.module('ecoeasyApp')
    .controller('DevaluationDataController', function ($scope, $state, DevaluationData) {

        $scope.devaluationDatas = [];
        $scope.loadAll = function () {
            DevaluationData.query(function (result) {
                $scope.devaluationDatas = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.devaluationData = {
                position: null,
                value: null,
                id: null
            };
        };
    });
