'use strict';

angular.module('ecoeasyApp')
    .controller('FundingBudgetDeleteController', function ($scope, $uibModalInstance, entity, FundingBudgetData) {

        $scope.fundingBudgetData = entity;
        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            FundingBudgetData.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
