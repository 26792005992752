'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('cashflow', {
                parent: 'site',
                url: '/cashflow',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['CASH_FLOW'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./cashflow.html'),
                        controller: 'CashFlowController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cashflow');
                        $translatePartialLoader.addPart('variablecodes');
                        return $translate.refresh();
                    }]
                }
            });
    });
