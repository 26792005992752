'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyVariableController', function ($scope, $state, CompanyVariable) {

        $scope.companyVariables = [];
        $scope.loadAll = function () {
            CompanyVariable.query(function (result) {
                $scope.companyVariables = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.companyVariable = {
                accountnmbr: null,
                formula: null,
                code: null,
                estimateFormula: null,
                id: null
            };
        };
    });
