'use strict';

import {PollingService} from "./polling.service";

angular.module('ecoeasyApp')
    .controller('CustomerController', function ($scope, $http, $state, $timeout, $q, $log, Company, ParseLinks, Principal, PurchaseManagement, FEATURE_COMPANY_ACCOUNT_CHART, FEATURE_YHDISTYSKIRJURI, FEATURE_KIINTEISTOKIRJURI) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.orc = 0;
            $scope.basicCharts = [
                {name:'Liikekirjuri', number :1},
                {name:'Yhdistyskirjuri', number :2},
                {name:'Kiinteistökirjuri', number :3}
            ];

            if (Principal.hasAnyAuthority(["ROLE_USER_ORGANISATION", "ROLE_ADMIN_ORGANISATION"])) {
                $scope.orc = account.sourceId;
            }

            if (FEATURE_COMPANY_ACCOUNT_CHART) {
                if (!FEATURE_KIINTEISTOKIRJURI) {
                    $scope.basicCharts = $scope.basicCharts.filter(it => it.name !== 'Kiinteistökirjuri');
                }
                if (!FEATURE_YHDISTYSKIRJURI) {
                    $scope.basicCharts = $scope.basicCharts.filter(it => it.name !== 'Yhdistyskirjuri');
                }
            }


            $scope.orgUsers = [];
            $http.get("/api/accountans/" + $scope.account.sourceId).then(function (response) {
                response.data.forEach(function (user) {
                    $scope.orgUsers.push({id: user.id, name: user.lastName + ", " + user.firstName});
                });
            });
            $scope.demo = account.demo;
            $scope.convertingInProgress = false;
            $scope.basicChartNumber =null;
            $scope.customers = [];
            $scope.predicate = 'id';
            $scope.reverse = true;
            $scope.page = 1;

            $scope.setSelectedBasicChart = function (basicChart){
                $scope.basicChartNumber = basicChart.number;
            }


            $scope.loadAll = function () {
                $http.get("/api/customers/" + $scope.orc).then(function (response) {
                    $scope.customers = [];
                    let companies = response.data;
                    var acc = {accountant: null};
                    for (var i = 0; i < companies.length; i++) {
                        let myCompany = companies[i];
                        for (var j = 0; j < $scope.orgUsers.length; j++) {
                            if (myCompany.accountantId === $scope.orgUsers[j].id)
                                acc = {accountant: $scope.orgUsers[j].name};
                        }
                        var obj = angular.merge(myCompany, acc);
                        acc = {accountant: null};
                        obj.canBeConverted = FEATURE_COMPANY_ACCOUNT_CHART && myCompany.mayCreateCompanyChart;
                        obj.canBeRollbacked = FEATURE_COMPANY_ACCOUNT_CHART && myCompany.hasCompanyChart;
                        $scope.customers.push(obj);
                    }
                    $scope.convertingInProgress = false;
                });
            };

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };

            $scope.loadAll();


            $scope.refresh = function () {
                $scope.loadAll();
                $scope.clear();
            };

            $scope.companySpots = PurchaseManagement.companySpots();

            const poller = new PollingService($http, $timeout, $q, $log);

            $scope.buildCompanyChart = function(companyId) {
                $scope.convertingInProgress = true;

                $http.put('/api/companies/'+companyId, { companyChart: true , basicChartNumber: $scope.basicChartNumber}).then(function(success){
                    console.log('buildCompanyChart put: ', success);
                    const meterId = success.data;
                    poller.poll(
                        "/api/agent/recalculatepoll/" + meterId,
                        5,
                        (response)=>{
                            $log.debug('poll untilFn response', response);
                            return response.data == "FINISHED" || response.data == "ERROR_FINISHED";
                        })
                        .then((response)=>{
                            $log.debug('poll resolve response', response);
                            $scope.loadAll();
                        }, (error)=>{
                            $scope.convertingInProgress = false;
                            $log.debug('poll reject error', error);
                        });
                }, function(error){
                    $scope.convertingInProgress = false;
                    console.log(error);
                });
            }

            $scope.rollbackCompanyChart = function(companyId) {
                $scope.convertingInProgress = true;
                $http.put('/api/companies/'+companyId, { companyChart: false }).then(function(success){
                    $scope.loadAll();
                }, function(error){
                    $scope.convertingInProgress = false;
                    console.log(error);
                });
            }

            $scope.$on('$destroy', function() {
                poller.stopPolling();
            });
        });
    });
