angular.module('ecoeasyApp')
    .service('NavbarService', function (Principal, $rootScope, $http, $q) {

        this.getOrganisationDisplayName = function () {
            return Principal.identity().then(function (account) {
                if (account) {
                    return account.firstName + " "+ account.lastName;
                }
            });
        };


        this.refresh = function () {
            var showProNavigation = angular.element(document.getElementsByClassName('showProNavigation'));
            var showNormalHomeNav = angular.element(document.getElementById('showNormalHomeNav'));
            var showHomeDropDown = angular.element(document.getElementById('showHomeDropDown'));
            var showNavbar = angular.element(document.getElementsByClassName('showNavbar'));
            var isAuth = Principal.isAuthenticated();
            if (isAuth == true) {
                if (showNavbar.hasClass('ng-hide')) {
                    showNavbar.removeClass('ng-hide');
                }
            } else{
                if (!showNavbar.hasClass('ng-hide')) {
                    showNavbar.addClass('ng-hide');
                }
            }
             Principal.identity().then(function (account) {
                    var isOrgUser = false;
                    if (account) {
                        account.authorities.forEach(function (auth) {
                            if (auth === "ROLE_ADMIN_ORGANISATION" || auth === "ROLE_USER_ORGANISATION") {
                                isOrgUser = true;
                            }
                        });

                        if (!isOrgUser && account.licenseStatus) {
                            if (account.licenseStatus === "PRO") {
                                if (showProNavigation.hasClass('ng-hide')) {
                                    showProNavigation.removeClass('ng-hide');
                                }
                            } else {
                                if (showProNavigation.hasClass('ng-hide')) {
                                    showProNavigation.addClass('ng-hide');
                                }
                            }
                            if (showNormalHomeNav.hasClass('ng-hide')) {
                                showNormalHomeNav.removeClass('ng-hide');
                            }
                            if (!showHomeDropDown.hasClass('ng-hide')) {
                                showHomeDropDown.addClass('ng-hide');
                            }

                        } else {
                            if (showProNavigation.hasClass('ng-hide')) {
                                showProNavigation.removeClass('ng-hide');
                            }
                            if (showHomeDropDown.hasClass('ng-hide')) {
                                showHomeDropDown.removeClass('ng-hide');
                            }
                            if (!showNormalHomeNav.hasClass('ng-hide')) {
                                showNormalHomeNav.addClass('ng-hide');
                            }
                        }

                    }
                });
        };
    });
