import {jsonToMeasure} from "../basic-chart-of-accounts/MeasureConverter";
import {variableTypes} from "./variable-type";

function companyVariableToVariableListItem(variable, $translate) {
    let item = {};
    item.type = variableTypes.COMPANY_VARIABLE;
    item.isAccount = variable && variable.indicator.accountnmbr &&
        variable.indicator.accountnmbr > 0 &&
        (variable.indicator.formula &&
            variable.indicator.formula.length === 0
            || !variable.indicator.formula);
    item.accountNumber = variable.indicator.accountnmbr;
    let code = variable.indicator.code;
    item.value = variable.value;
    item.id = variable.indicator.id
    item.drillable = !item.isAccount && !code.endsWith('_calc');
    item.name = $translate.instant('variable.' + code);
    return item;
}


export class DrillService {

    constructor($q, $translate, $http, indicatorService, SurvivorService, DrillExpression, CompanyVariableService) {
        "ngInject";
        this.$q = $q;
        this.$translate = $translate;
        this.$http = $http;
        this.indicatorService = indicatorService;
        this.survivorService = SurvivorService;
        this.DrillExpression = DrillExpression;
        this.companyVariableService = CompanyVariableService;
    }

    getDataForCompanyVariable(variableid, company_id, monthly, fiscalYear) {
        return this.$q((resolve, reject) => {
            let result = {};
            let varsByAccount = {};
            let varsByCode = {};
            let indicator = {};
            this.$q.all([
                this.companyVariableService.getVariables(variableid, company_id, monthly).then((variables) => {
                    varsByAccount = variables.varsByAccount;
                    varsByCode = variables.varsByCode;
                    result.variables = variables.variables.map((variable) => {
                        let item = {};
                        item.type = variableTypes.COMPANY_VARIABLE;
                        item.isAccount = variable && variable.indicator.accountnmbr &&
                            variable.indicator.accountnmbr > 0 &&
                            (variable.indicator.formula &&
                                variable.indicator.formula.length === 0
                                || !variable.indicator.formula);
                        item.accountNumber = variable.indicator.accountnmbr;
                        let code = variable.indicator.code;
                        item.value = variable.value;
                        item.id = variable.indicator.id
                        item.drillable = !item.isAccount && !code.endsWith('_calc');
                        item.name = this.$translate.instant('variable.' + code);
                        return item;
                    });
                }),
                this.companyVariableService.getDrilled(variableid, company_id, monthly).then((drilled) => {
                    indicator = drilled.indicator;
                    result.varValue = drilled.value;
                })
            ]).then(() => {
                result.expression = this.DrillExpression.formulaExpression(indicator.formula, varsByAccount, varsByAccount);
                result.varName = this.$translate.instant("variable." + indicator.code);
                var monthlyVar;
                if (monthly === 'null' || monthly === null || monthly === 'undefined') {
                    monthlyVar = 'false';
                } else {
                    monthlyVar = monthly;
                }
                this.survivorService.getCompanyVariableChartData(indicator, monthlyVar, fiscalYear, company_id).then((data) => {
                    result.chartLabels = data.chartLabels;
                    result.chartData = data.chartData;
                    result.chartSeries = data.chartSeries;
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        });
    }


    getDataForCompanyAccountGroup(variableId, companyId, monthly, year, month) {
        return this.$q((resolve, reject) => {
            let cumulative = 'true' != monthly;
            var getUtl = "/api/companies/" + companyId + "/account-groups/" + variableId + "?cumulative=" + cumulative;
            if (year && month) {
                getUtl = getUtl + "&year=" + year + "&month=" + month;
            }
            this.$http.get(getUtl).then((response) => {
                console.log('getDataForCompanyAccountGroup: response=', response);
                let json = response.data;
                let data = {};
                data.id = json.id;
                data.varName = json.nameFi;
                data.varValue = json.value;
                data.chartData = [json.fiscalYearValues.previous, json.fiscalYearValues.current];
                data.chartSeries = [
                    data.varName + this.$translate.instant('survivor.previous'),
                    data.varName + this.$translate.instant('survivor.current')];
                data.chartLabels = json.fiscalYearValues.months.map((m) => {
                    return this.$translate.instant('survivor.month.' + m);
                });
                data.variables = json.subGroups
                    .map((group) => {
                        let variable = {};
                        variable.id = group.id;
                        variable.isAccount = false;
                        variable.value = group.value;
                        variable.name = group.nameFi;
                        variable.type = variableTypes.COMPANY_ACCOUNT_GROUP;
                        variable.drillable = true;
                        return variable;
                    });
                data.variables = data.variables.concat(json.companyAccounts.map((account) => {
                    let variable = {};
                    variable.isAccount = true;
                    variable.accountNumber = account.code;
                    variable.value = account.value;
                    variable.name = account.nameFi;
                    variable.type = null;
                    variable.drillable = false;
                    return variable;
                }));
                resolve(data);
            }, (error) => {
                reject(error);
            });
        });
    }

    getBudget(companyId, year, month) {
        return this.$q((resolve, reject) => {
            var getUtl = "/api/companies/" + companyId + "/budgets/fiscal-years/year-month/";
            if (year && month) {
                getUtl = getUtl + "?year=" + year + "&month=" + month;
            }
            this.$http.get(getUtl).then((response) => {
                let json = response.data;
                let groupList = [];
                this.addGroupsToList(groupList, json.groups);
                resolve(groupList);
            }, (error) => {
                reject(error);
            });
        });
    }

    addGroupsToList(groupList, groups) {
        for (let i in groups) {
            groupList.push(groups[i]);
            this.addGroupsToList(groupList, groups[i].groups);
            this.addGroupsToList(groupList, groups[i].accounts);
        }
    }

    getDataForCompanyAccount(variableId, companyId, monthly, year, month) {
        return this.$q((resolve, reject) => {
            let cumulative = 'true' != monthly;
            var getUtl = "/api/companies/" + companyId + "/accounts/" + variableId + "?cumulative=" + cumulative;
            if (year && month) {
                getUtl = getUtl + "&year=" + year + "&month=" + month;
            }
            this.$http.get(getUtl).then((response) => {
                console.log('getDataForCompanyAccount: response=', response);
                let json = response.data;
                let data = {};
                data.id = json.id;
                data.varName = json.code + ' ' + json.nameFi;
                data.varValue = json.value;
                data.chartData = [json.fiscalYearValues.previous, json.fiscalYearValues.current];
                data.chartSeries = [
                    data.varName + this.$translate.instant('survivor.previous'),
                    data.varName + this.$translate.instant('survivor.current')];
                data.chartLabels = json.fiscalYearValues.months.map((m) => {
                    return this.$translate.instant('survivor.month.' + m);
                });
                data.variables = [];
                resolve(data);
            }, (error) => {
                reject(error);
            });
        });
    }

    getDataForMeasure(variableId, companyId, monthly) {
        return this.$q((resolve, reject) => {
            let cumulative = 'true' != monthly;
            this.$http.get("/api/companies/" + companyId + "/measures/" + variableId + "?cumulative=" + cumulative + "&filterByActualDate=true&getEconomicActivitiesValue=true").then((response) => {
                let json = response.data;
                let data = {};
                data.companyAccountId = json.companyAccountId;
                data.basicChartCategoryId = json.basicAccountId;
                data.varName = json.name;
                data.varValue = json.value;
                data.varEconomicActivitiesValue = json.economicActivitiesValue;
                data.descriptionFi = json.descriptionFi;
                data.descriptionEn = json.descriptionEn;
                data.descriptionSv = json.descriptionSv;
                data.thresholdName1 = json.thresholdName1;
                data.thresholdName2 = json.thresholdName2;
                data.thresholdName3 = json.thresholdName3;
                data.thresholdName4 = json.thresholdName4;
                data.thresholdName5 = json.thresholdName5;
                data.thresholdName1En = json.thresholdName1En;
                data.thresholdName2En = json.thresholdName2En;
                data.thresholdName3En = json.thresholdName3En;
                data.thresholdName4En = json.thresholdName4En;
                data.thresholdName5En = json.thresholdName5En;
                data.thresholdName1Sv = json.thresholdName1Sv;
                data.thresholdName2Sv = json.thresholdName2Sv;
                data.thresholdName3Sv = json.thresholdName3Sv;
                data.thresholdName4Sv = json.thresholdName4Sv;
                data.thresholdName5Sv = json.thresholdName5Sv;
                data.thresholdValue1Low = json.thresholdValue1Low;
                data.thresholdValue2Low = json.thresholdValue2Low;
                data.thresholdValue3Low = json.thresholdValue3Low;
                data.thresholdValue4Low = json.thresholdValue4Low;
                data.thresholdValue5Low = json.thresholdValue5Low;
                data.thresholdValue1High = json.thresholdValue1High;
                data.thresholdValue2High = json.thresholdValue2High;
                data.thresholdValue3High = json.thresholdValue3High;
                data.thresholdValue4High = json.thresholdValue4High;
                data.thresholdValue5High = json.thresholdValue5High;
                data.chartData = [json.fiscalYearValues.previous, json.fiscalYearValues.current];
                data.chartSeries = [
                    json.name + this.$translate.instant('survivor.previous'),
                    json.name + this.$translate.instant('survivor.current')];
                data.chartLabels = json.fiscalYearValues.months.map((m) => {
                    return this.$translate.instant('survivor.month.' + m);
                });
                data.formula = jsonToMeasure(json).formula;
                data.variables = json.formula
                    .filter((v) => {
                        return v.id;
                    })
                    .filter((v) => {
                        return v.value;
                    })
                    .map((v) => {
                        let variable = {};
                        variable.id = v.id;
                        variable.isAccount = false;
                        variable.value = v.value;
                        if (v.nameFi) {
                            variable.name = v.nameFi;
                            variable.type = variableTypes.COMPANY_ACCOUNT_GROUP;
                            variable.drillable = true;
                        }
                        if (v.name) {
                            variable.name = v.name;
                            variable.type = variableTypes.MEASURE;
                            variable.drillable = true;
                        }
                        return variable;
                    });

                resolve(data);
            }, (error) => {
                reject(error);
            });
        });
    }

    getDataForIndicator(variableId, companyId, monthly, year, month) {
        return this.$q((resolve, reject) => {
            this.indicatorService.getIndicator(variableId).then((indicatorSuccess)=>{
                //console.log('getDataForIndicator-getIndicator', indicatorSuccess);
                const indicator = indicatorSuccess.data;
                if (variableTypes.COMPANY_ACCOUNT == indicator.measure.type) {
                    this.getDataForCompanyAccount(indicator.measure.id, companyId, monthly, year, month).then((success)=>{
                        //console.log('getDataForIndicator: getDataForCompanyAccount', success);
                        let data = {};
                        data.varName = indicator.name;
                        data.varValue = success.varValue;
                        data.chartData = success.chartData;
                        data.chartSeries = [
                            data.varName + this.$translate.instant('survivor.previous'),
                            data.varName + this.$translate.instant('survivor.current')];
                        data.chartLabels = success.chartLabels;
                        let variable = {};
                        variable.id = indicator.measure.id;
                        variable.isAccount = false;
                        variable.value = success.varValue;
                        variable.name = success.varName;
                        variable.type = variableTypes.COMPANY_ACCOUNT;
                        variable.drillable = true;
                        data.variables = [variable];
                        resolve(data);
                    }, (error)=>{
                        reject(error);
                    });
                }
                if (variableTypes.COMPANY_ACCOUNT_GROUP == indicator.measure.type ) {
                    this.getDataForCompanyAccountGroup(indicator.measure.id, companyId, monthly, year, month).then((success)=>{
                        //console.log('getDataForIndicator: getDataForCompanyAccount', success);
                        let data = {};
                        data.varName = indicator.name;
                        data.varValue = success.varValue;
                        data.chartData = success.chartData;
                        data.chartSeries = [
                            data.varName + this.$translate.instant('survivor.previous'),
                            data.varName + this.$translate.instant('survivor.current')];
                        data.chartLabels = success.chartLabels;
                        let variable = {};
                        variable.id = indicator.measure.id;
                        variable.isAccount = false;
                        variable.value = success.varValue;
                        variable.name = success.varName;
                        variable.type = variableTypes.COMPANY_ACCOUNT;
                        variable.drillable = true;
                        data.variables = [variable];
                        resolve(data);
                    }, (error)=>{
                        reject(error);
                    });
                }else {
                    this.getDataForMeasure(indicator.measure.id, companyId, monthly).then((measureSuccess)=>{
                        //console.log('getDataForIndicator-getDataForMeasure', measureSuccess);
                        let data = {};
                        data.companyAccountId = measureSuccess.companyAccountId;
                        data.basicChartCategoryId = measureSuccess.basicChartCategoryId;
                        data.varName = indicator.name;
                        data.varValue = measureSuccess.varValue;
                        data.varEconomicActivitiesValue = measureSuccess.varEconomicActivitiesValue;
                        data.chartData = measureSuccess.chartData;
                        data.chartSeries = [
                            data.varName + this.$translate.instant('survivor.previous'),
                            data.varName + this.$translate.instant('survivor.current')];
                        data.chartLabels = measureSuccess.chartLabels;
                        data.descriptionFi = measureSuccess.descriptionFi;
                        data.descriptionEn = measureSuccess.descriptionEn;
                        data.descriptionSv = measureSuccess.descriptionSv;
                        data.thresholdName1 = measureSuccess.thresholdName1;
                        data.thresholdName2 = measureSuccess.thresholdName2;
                        data.thresholdName3 = measureSuccess.thresholdName3;
                        data.thresholdName4 = measureSuccess.thresholdName4;
                        data.thresholdName5 = measureSuccess.thresholdName5;
                        data.thresholdName1En = measureSuccess.thresholdName1En;
                        data.thresholdName2En = measureSuccess.thresholdName2En;
                        data.thresholdName3En = measureSuccess.thresholdName3En;
                        data.thresholdName4En = measureSuccess.thresholdName4En;
                        data.thresholdName5En = measureSuccess.thresholdName5En;
                        data.thresholdName1Sv = measureSuccess.thresholdName1Sv;
                        data.thresholdName2Sv = measureSuccess.thresholdName2Sv;
                        data.thresholdName3Sv = measureSuccess.thresholdName3Sv;
                        data.thresholdName4Sv = measureSuccess.thresholdName4Sv;
                        data.thresholdName5Sv = measureSuccess.thresholdName5Sv;
                        data.thresholdValue1Low = measureSuccess.thresholdValue1Low;
                        data.thresholdValue2Low = measureSuccess.thresholdValue2Low;
                        data.thresholdValue3Low = measureSuccess.thresholdValue3Low;
                        data.thresholdValue4Low = measureSuccess.thresholdValue4Low;
                        data.thresholdValue5Low = measureSuccess.thresholdValue5Low;
                        data.thresholdValue1High = measureSuccess.thresholdValue1High;
                        data.thresholdValue2High = measureSuccess.thresholdValue2High;
                        data.thresholdValue3High = measureSuccess.thresholdValue3High;
                        data.thresholdValue4High = measureSuccess.thresholdValue4High;
                        data.thresholdValue5High = measureSuccess.thresholdValue5High;
                        let variable = {};
                        variable.id = indicator.measure.id;
                        variable.isAccount = false;
                        variable.value = measureSuccess.varValue;
                        variable.name = measureSuccess.varName;
                        variable.type = variableTypes.MEASURE;
                        variable.drillable = true;
                        data.variables = [variable];
                        resolve(data);
                    }, (measureError)=>{
                        reject(measureError);
                    });
                }


            }, (indicatorError)=>{
                reject(indicatorError);
            });
        });
    }

}
