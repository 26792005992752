'use strict';

angular.module('ecoeasyApp')
    .controller('IndustryCollectionDefinitionController', function ($scope, $state, IndustryCollectionDefinition) {

        $scope.industryCollectionDefinitions = [];
        $scope.loadAll = function () {
            IndustryCollectionDefinition.query(function (result) {
                $scope.industryCollectionDefinitions = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.industryCollectionDefinition = {
                name: null,
                id: null
            };
        };
    });
