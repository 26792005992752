import React, {useState} from 'react';
import {array, bool, func, number, object} from 'prop-types';
import {useTranslation} from "react-i18next";

import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import Alert from "react-bootstrap/lib/Alert";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

import SingleLineInput from "../../../shared/inputs/single-line-input";
import SingleMonthPicker from "../../../shared/inputs/single-month-picker";
import MultipleMonthCheckboxInput from "../../../shared/inputs/multiple-month-checkbox-input";

import {determineIfLongTerm, getFirstAmorizationDate} from '../../budget-utils';
import {getInvestmentFundingData, updateInvestment} from "../../../../service/react-service";
import {getFreePeriodInMonths, parseDateToMonth, parseDateToYear, emptyIfAbsent} from "../../../../utils/util";

import '../../investment/styles.css';

const EditInvestmentFunding = ({
                                   budgetId,
                                   companyId,
                                   fundingOptions,
                                   showModal,
                                   setShowModal,
                                   selectedData,
                                   investmentData,
                                   setSelectedData,
                                   setInvestmentFundingData
                               }) => {

    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState(null);

    const {
        id,
        name,
        year,
        month,
        amount,
        initialCashEffect,
        bcoaCategoryId,
        termInMonths,
        freePeriodInMonths,
        annualPaymentMonths,
        annualInterestRate,
        repaymentMethod
    } = selectedData;

    const onEditAcquisitionDate = new Date(year, (month - 1));

    const showParsedDate = date => {
        if (date !== null) {
            const monthInDate = parseDateToMonth(date);
            const yearInDate = parseDateToYear(date);
            return `${monthInDate}/${yearInDate}`;
        }
    }
    const parseFloatIfValuePresent = (formValue, defaultValue) => {
        if (Array.isArray(formValue)) {
            let temp = [];
            formValue.forEach(arrItem => {
                temp.push(parseFloat(arrItem));
            });
            temp.sort((a, b) => a - b);
            return temp;
        }
        return formValue === '' ? defaultValue : parseFloat(formValue);
    }

    const firstAmortizationDate = getFirstAmorizationDate(year, month - 1, freePeriodInMonths);
    const annualPaymentMonthsToString = annualPaymentMonths.map(value => value.toString());

    const initialValues = {
        name: emptyIfAbsent(name, ''),
        acquisitionDate: emptyIfAbsent(onEditAcquisitionDate, ''),
        initialCashEffect: emptyIfAbsent(initialCashEffect, ''),
        bcoaCategoryId: emptyIfAbsent(bcoaCategoryId, ''),
        amount: emptyIfAbsent(amount, ''),
        loanTime: emptyIfAbsent(termInMonths, ''),
        firstAmortizationDate: emptyIfAbsent(firstAmortizationDate, ''),
        repaymentMonths: parseFloatIfValuePresent(annualPaymentMonthsToString, []),
        annualInterestRate: emptyIfAbsent(annualInterestRate, ''),
        repaymentMethod: repaymentMethod,
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string(),
        amount: Yup.number()
            .required(t('investments:modal.inputValidation.required.investmentAmount'))
            .positive(t('investments:modal.inputValidation.positiveCheck.positive')),
        initialCashEffect: Yup.number(),
        bcoaCategoryId: Yup.number()
            .required(t('investments:modal.inputValidation.required.balanceAccount')),
        acquisitionDate: Yup.date(),
        loanTime: Yup.number()
            .required(t('investments:modal.inputValidation.required.loanTime'))
            .positive(t('investments:modal.inputValidation.positiveCheck.loanTime'))
            .integer(t('investments:modal.inputValidation.integerCheck.loanTime')),
        firstAmortizationDate: Yup.date()
            .required(t('investments:modal.inputValidation.required.firstAmortizationDate'))
            .typeError(t('investments:modal.inputValidation.required.firstAmortizationDate'))
            .min(Yup.ref('acquisitionDate'), t('investments:modal.inputValidation.earlierThanCheck.firstAmortizationDate')),
        repaymentMonths: Yup.array()
            .min(1, t('investments:modal.inputValidation.required.amortizationsPerYear'))
            .test({
                name: 'max',
                exclusive: false,
                params: {},
                message: t('investments:modal.inputValidation.maxLength.amortizationsPerYear'),
                test: function (value) {
                    const testValue = [...new Set(value)]
                    return testValue.length <= parseFloat(this.parent.loanTime)
                }
            }),
        annualInterestRate: Yup.number()
            .required(t('investments:modal.inputValidation.required.annualInterest'))
            .positive(t('investments:modal.inputValidation.positiveCheck.annualInterest'))
    });

    const handleOnHide = () => {
        setSelectedData(null);
        setShowModal(!showModal);
    }

    /** Handle submit data and create objects for PUT */
    const handleOnSubmit = (values) => {
        const updatedInvestmentFunding = {
            id: id,
            name: investmentData.name,
            month: investmentData.month,
            year: investmentData.year,
            initialCashEffect: investmentData.value,
            amount: parseFloatIfValuePresent(values.amount, 0),
            bcoaCategoryId: parseFloat(values.bcoaCategoryId),
            termInMonths: parseFloatIfValuePresent(values.loanTime, 0),
            freePeriodInMonths: getFreePeriodInMonths(onEditAcquisitionDate, values.firstAmortizationDate),
            annualPaymentMonths: parseFloatIfValuePresent([...new Set(values.repaymentMonths.sort((a, b) => {
                return a - b
            }))], []),
            annualInterestRate: parseFloatIfValuePresent(values.annualInterestRate, 0),
            repaymentMethod: values.repaymentMethod
        };

        /** Investment data object */
        const updatedInvestment = {
            id: investmentData.id,
            name: investmentData.name,
            value: investmentData.value,
            bcoaCategoryId: investmentData.bcoaCategoryId,
            remnantValue: investmentData.remnantValue,
            month: investmentData.month,
            year: investmentData.year,
            devaluationTime: investmentData.devaluationTime,
            devaluationTimeDelay: investmentData.devaluationTimeDelay,
            vatValue: investmentData.vatValue,
            devaluationInterest: investmentData.devaluationInterest,
            fundingObject: updatedInvestmentFunding
        }

        updateInvestment(companyId, budgetId, updatedInvestment).then((res, rej) => {
            if (rej !== undefined) {
                setErrorMessage(rej);
            } else {
                getInvestmentFundingData(companyId, budgetId).then((res, rej) => {
                    if (rej !== undefined) {
                        console.log(rej.message);
                    } else {
                        setInvestmentFundingData(res.data)
                        setSelectedData(null);
                        setShowModal(!showModal);
                    }
                });
            }
        });
    };
    if (investmentData !== undefined && investmentData !== null && fundingOptions.length !== 0) {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                resetForm={initialValues}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    handleOnSubmit(values);
                    setSubmitting(false);
                    if (errorMessage === null) {
                        resetForm(initialValues);
                    }
                }}
            >
                {({values, isSubmitting}) => (
                    <Modal show={showModal} onHide={handleOnHide}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('fundingModal:title.editFunding')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {errorMessage !== null ?
                                    <Alert bsStyle='danger'>{errorMessage}</Alert>
                                    : null
                                }
                                <fieldset className="investment-border">
                                    <legend className="investment-border">
                                        {t('investments:modal.fundingBudget')}
                                    </legend>
                                    <SingleLineInput
                                        id='name'
                                        name='name'
                                        type='text'
                                        label={t('investments:values.name')}
                                        placeholder={investmentData.name}
                                        readOnly
                                    />
                                    <SingleLineInput
                                        id='acquisitionDate'
                                        name={showParsedDate(onEditAcquisitionDate)}
                                        type='text'
                                        label={t('investments:values.fundingPosition')}
                                        placeholder={showParsedDate(onEditAcquisitionDate)}
                                        readOnly
                                    />
                                    <SingleLineInput
                                        id='initialCashEffect'
                                        name='initialCashEffect'
                                        type='text'
                                        label={t('investments:values.initialCashEffect')}
                                        placeholder={initialCashEffect.toString()}
                                        readOnly
                                    />
                                    <div>
                                        <SingleLineInput
                                            id='amount'
                                            name='amount'
                                            type='number'
                                            label={t('investments:values.amount')}
                                            placeholder='0'
                                        />
                                        <SingleLineInput
                                            id='loanTime'
                                            name='loanTime'
                                            type='number'
                                            label={t('investments:values.loanTime')}
                                            placeholder='0'
                                        />
                                        <SingleMonthPicker
                                            id='firstAmortizationDate'
                                            name='firstAmortizationDate'
                                            label={t('investments:values.firstAmortizationDate')}
                                        />
                                        <MultipleMonthCheckboxInput
                                            id='repaymentMonths'
                                            name='repaymentMonths'
                                            label={t('investments:values.amortizationPerYear')}
                                        />
                                        <div style={{marginBottom: '15px'}}>
                                            {values.firstAmortizationDate === '' ?
                                                <label style={{color: "darkgray"}}>
                                                    {t('investments:values.balanceAccount')}
                                                </label>
                                                :
                                                <label>{t('investments:values.balanceAccount')}</label>
                                            }
                                            <Field
                                                className='BalanceAccount'
                                                component='select'
                                                name='bcoaCategoryId'
                                            >
                                                {(investmentData.acquisitionDate && values.firstAmortizationDate) &&
                                                determineIfLongTerm(investmentData.acquisitionDate, values.firstAmortizationDate) ?
                                                    fundingOptions[0].subCategories.map(option => {
                                                        return (
                                                            <option value={option.id}
                                                                    key={option.id}
                                                                    disabled={values.firstAmortizationDate === ''}
                                                            >
                                                                {option.name}
                                                            </option>
                                                        );
                                                    })
                                                    :
                                                    fundingOptions[1].subCategories.map(option => {
                                                        return (
                                                            <option value={option.id}
                                                                    key={option.id}
                                                                    disabled={values.firstAmortizationDate === ''}
                                                            >
                                                                {option.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage name="fundingAccount">
                                                {msg => <div className='ErrorMessage'>
                                                    <Glyphicon glyph="remove"/>
                                                    {msg}
                                                </div>}
                                            </ErrorMessage>
                                        </div>
                                        <SingleLineInput
                                            id='annualInterestRate'
                                            name='annualInterestRate'
                                            type='number'
                                            label={t('investments:values.interestRate')}
                                            placeholder='0,00'
                                        />
                                    </div>
                                </fieldset>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={handleOnHide}>
                                    <Glyphicon glyph='ban-circle'/>
                                    {t('investments:modal.buttons.cancel')}
                                </Button>
                                <Button bsStyle="primary" type='submit' disabled={isSubmitting}>
                                    <Glyphicon glyph='save'/>
                                    {t('investments:modal.buttons.save')}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        );
    } else {
        return (
            <div/>
        );
    }
}

export default EditInvestmentFunding;
