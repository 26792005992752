import React, { useEffect, useState } from 'react';
import { bool, number } from 'prop-types';

import { moneyFilter } from "../../../app/shared/money-format.filter";
import { integerFilter } from "../../../app/shared/integer-format.filter"

const Money = props => {
    const { value, decimals } = props;

   const [moneyStr, setMoneyStr] = useState(value || '-');
   const [showDecimals, setShowDecimals] = useState(decimals || true);

   useEffect(() => {
       if (isDefined(decimals) && decimals === false) {
           setShowDecimals(false)
       } else {
           setShowDecimals(true)
       }

       if (isDefined(value)) {
           if (showDecimals) {
               setMoneyStr(formatMoney(value));
           } else {
               setMoneyStr(formatWithoutDecimals(value));
           }
       }
   });

    const isDefined = some => {
        return typeof some !== "undefined" && some != null;
    }

    const formatMoney = input => {
        return moneyFilter(input).replace(/\s/g, ' ');
    }

    const formatWithoutDecimals = input => {
        return integerFilter(input).replace(/\s/g, ' ');
    }

    return (
        <span>
            {moneyStr}
        </span>
    );
};

Money.propTypes = {
    value: number,
    decimals: bool,
 };

export default Money;