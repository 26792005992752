import {variableTypes} from "../drill/variable-type";

export class SurvivorService {

    constructor($http, $translate, $log, $q, SelectedCompanyService, indicatorService) {
        "ngInject";
        this.$http = $http;
        this.$translate = $translate;
        this.$log = $log;
        this.$q = $q;
        this.SelectedCompanyService = SelectedCompanyService;
        this.indicatorService = indicatorService;
    }

    getMeasureIndicatorChartData(indicator) {
        return this.$q((resolve, reject) => {
            let selectedCompanyId = this.SelectedCompanyService.getSelectedCompanyId();
            this.$http.get(this._getUrl(selectedCompanyId, indicator)).then((response) => {
                let json = response.data;
                let result = {};
                result.chartData = [json.fiscalYearValues.previous, json.fiscalYearValues.current];
                result.chartSeries = [
                    indicator.name + this.$translate.instant('survivor.previous'),
                    indicator.name + this.$translate.instant('survivor.current')];
                result.chartLabels = json.fiscalYearValues.months.map((m) => {
                    return this.$translate.instant('survivor.month.' + m);
                });
                resolve(result);
            }, (error) => {
                reject(error);
            });
        });
    }

    _getUrl(selectedCompanyId, indicator) {
        var cumulative ='';
        if (indicator.monthly ===false){
            cumulative ='true';
        }

        if (indicator.measure.type == variableTypes.MEASURE) {
            return "/api/companies/" + selectedCompanyId + "/measures/" + indicator.measure.id + "?cumulative="+cumulative+"&filterByActualDate=true";
        } else if (indicator.measure.type == variableTypes.COMPANY_ACCOUNT_GROUP) {
            return "/api/companies/"+selectedCompanyId+"/account-groups/"+indicator.measure.id+ "?cumulative="+cumulative;
        } else if (indicator.measure.type == variableTypes.COMPANY_ACCOUNT) {
            return "/api/companies/"+selectedCompanyId+"/accounts/"+indicator.measure.id+ "?cumulative="+cumulative;
        }
        return null;
    }

    getCompanyVariableIndicatorChartData(indicator, fiscalYear) {
        return this.$q((resolve, reject) => {
            let selectedCompanyId = this.SelectedCompanyService.getSelectedCompanyId();
            this.indicatorService.getPeriodValues(indicator.companyVariable.id, selectedCompanyId, indicator.monthly, fiscalYear.startPosition).then((response) => {
                const chartData = response.data;
                let result = {};
                result.chartLabels = this._getLabels(chartData, fiscalYear);
                result.chartData = this._getChartData(chartData, result);
                result.chartSeries = this._getChartSeries(chartData, indicator.companyVariable.code);
                resolve(result);
            }, (error) => {
                reject(error);
            });
        })
    }

    _getChartData(chartData) {
        if (chartData[0].length !== 12) {
            return [chartData[1]];
        } else {
            return chartData;
        }
    }

    _getChartSeries(chartData, companyVariableCode) {
        const varName = this.$translate.instant("variable." + companyVariableCode);
        if (chartData[0].length !== 12) {
            return [varName + this.$translate.instant('survivor.current')];
        } else {
            return [varName + this.$translate.instant('survivor.previous'), varName + this.$translate.instant('survivor.current')];
        }
    }

    _getLabels(chartData, fiscalYear) {
        var labels = [];
        for (var i = 0; i < chartData[0].length; i++) {
            var month = i + fiscalYear.startDate.getMonth() + 1;
            if ((month) <= 12) {
                labels.push(this.$translate.instant('survivor.month.' + month));
            } else {
                var nm = month - 12;
                labels.push(this.$translate.instant('survivor.month.' + nm));
            }
        }
        return labels;
    }

    getCompanyVariableChartData(companyVariable, monthly, fiscalYear, companyId) {
        return this.$q((resolve, reject) => {
            this.indicatorService.getPeriodValues(companyVariable.id, companyId, monthly, fiscalYear.startPosition).then((response) => {
                var chartData = response.data;
                let result = {};
                result.chartLabels = this._getLabels(chartData, fiscalYear);
                result.chartData = this._getChartData(chartData);
                result.chartSeries = this._getChartSeries(chartData, companyVariable.code);
                resolve(result);
            }, (error)=>{
                reject(error);
            });
        });
    }

}
