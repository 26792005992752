import {CompanyChart} from "./company-chart";
import {CompanyChartRow} from "./company-chart-row";
import {CompanyChartRoot} from "./company-chart-root";
import {CompanyChartAccount} from "./company-chart-account";

const byOrdinalFn = (a, b)=>{ return a.ordinal - b.ordinal; };
const byCodeFn = (a, b)=>{ return a.code - b.code};

function createRow(jsonRowObject, level, rows) {
    if (jsonRowObject != undefined && jsonRowObject != null) {
        rows.push(new CompanyChartRow(jsonRowObject.id, jsonRowObject.nameFi, jsonRowObject.nameEn, jsonRowObject.nameSv, level, jsonRowObject.ordinal, jsonRowObject.companyAccounts, jsonRowObject.movable));
        jsonRowObject.subGroups.sort(byOrdinalFn);
        if (jsonRowObject.companyAccounts[0]){
            jsonRowObject.companyAccounts.sort(byCodeFn);
            jsonRowObject.companyAccounts.forEach(function (account) {
                rows.push(new CompanyChartAccount(account.id, account.code, account.nameFi, account.nameEn, account.nameSv, false, level, jsonRowObject));
            })
        }

        jsonRowObject.subGroups.forEach(function (value) {
            createRow(value, level + 1, rows);
        });
    }
}

function createRowReverse(jsonRowObject, level, rows) {
    jsonRowObject.subGroups.sort(byOrdinalFn);
    if (jsonRowObject.companyAccounts[0]){
        jsonRowObject.companyAccounts.sort(byCodeFn);
        jsonRowObject.companyAccounts.forEach(function (account) {
            rows.push(new CompanyChartAccount(account.id, account.code, account.nameFi, account.nameEn, account.nameSv, false, level, jsonRowObject));
        })
    }

    jsonRowObject.subGroups.forEach(function (value) {
        createRow(value, level, rows);
    });

    rows.push(new CompanyChartRow(jsonRowObject.id, jsonRowObject.nameFi, jsonRowObject.nameEn, jsonRowObject.nameSv, level, jsonRowObject.ordinal, jsonRowObject.companyAccounts, jsonRowObject.movable));
}

function createRowReverseAccountsLast(jsonRowObject, level, rows) {
    jsonRowObject.subGroups.sort(byOrdinalFn);

    jsonRowObject.subGroups.forEach(function (value) {
        createRow(value, level, rows);
    });

    rows.push(new CompanyChartRow(jsonRowObject.id, jsonRowObject.nameFi, jsonRowObject.nameEn, jsonRowObject.nameSv, level, jsonRowObject.ordinal, jsonRowObject.companyAccounts, jsonRowObject.movable));

    if (jsonRowObject.companyAccounts[0]){
        jsonRowObject.companyAccounts.sort(byCodeFn);
        jsonRowObject.companyAccounts.forEach(function (account) {
            rows.push(new CompanyChartAccount(account.id, account.code, account.nameFi, account.nameEn, account.nameSv, false, level, jsonRowObject));
        })
    }

}

function createRoot(jsonObject) {
    let rows = [];
    createRow(jsonObject, 0, rows);

    return new CompanyChartRoot(jsonObject.nameFi, rows, false)
}

function createTaseRoot(jsonTase) {
    let vastattavaa;
    let vastaavaa;
    if (jsonTase.subGroups[0].nameFi.replace(/\s+/g, '') == "Vastaavaa") {
        vastaavaa = jsonTase.subGroups[0];
        vastattavaa = jsonTase.subGroups[1];
    } else {
        vastaavaa = jsonTase.subGroups[1];
        vastattavaa = jsonTase.subGroups[0];
    }
    let rows = [];
    createRow(vastaavaa, 0, rows);
    createRow(vastattavaa, 0, rows);
    return new CompanyChartRoot(jsonTase.nameFi, rows, false, jsonTase.nameFi)
}

function createTuloslaskelmaRootLiikekirjuri(jsonGroup) {
    jsonGroup.subGroups.sort(byOrdinalFn)
    let profitOfTheFinancialYear = jsonGroup.subGroups[0];
    jsonGroup.subGroups.splice(0, 1);

    profitOfTheFinancialYear.subGroups.sort(byOrdinalFn)
    let profitBeforeTaxes = profitOfTheFinancialYear.subGroups[0];
    profitOfTheFinancialYear.subGroups.splice(0, 1);

    profitBeforeTaxes.subGroups.sort(byOrdinalFn)
    let operatingProfit = profitBeforeTaxes.subGroups[0];
    profitBeforeTaxes.subGroups.splice(0, 1);

    operatingProfit.subGroups.sort(byOrdinalFn)
    let netTurnover = operatingProfit.subGroups[0];
    operatingProfit.subGroups.splice(0, 1);

    netTurnover.subGroups.sort(byOrdinalFn)

    let rows = [];
    createRow(netTurnover, 0, rows);
    createRowReverse(operatingProfit, 0, rows);
    createRowReverse(profitBeforeTaxes, 0, rows);
    createRowReverseAccountsLast(profitOfTheFinancialYear, 0, rows);

    return new CompanyChartRoot(jsonGroup.nameFi, rows, false, jsonGroup.nameFi)
}

function createTuloslaskelmaRootYhdistyskirjuri(jsonGroup) {
    jsonGroup.subGroups.sort(byOrdinalFn)
    /*
    let profitOfTheFinancialYear = jsonGroup.subGroups[0];
    jsonGroup.subGroups.splice(0, 1);

    profitOfTheFinancialYear.subGroups.sort(byOrdinalFn)
    let profitBeforeTaxes = profitOfTheFinancialYear.subGroups[0];
    profitOfTheFinancialYear.subGroups.splice(0, 1);

    profitBeforeTaxes.subGroups.sort(byOrdinalFn)
    let operatingProfit = profitBeforeTaxes.subGroups[0];
    profitBeforeTaxes.subGroups.splice(0, 1);

    operatingProfit.subGroups.sort(byOrdinalFn)
    let netTurnover = operatingProfit.subGroups[0];
    operatingProfit.subGroups.splice(0, 1);

    netTurnover.subGroups.sort(byOrdinalFn)

    let rows = [];
    createRow(netTurnover, 0, rows);
    createRowReverse(operatingProfit, 0, rows);
    createRowReverse(profitBeforeTaxes, 0, rows);
    createRowReverseAccountsLast(profitOfTheFinancialYear, 0, rows);
    */
    let rows = [];
    jsonGroup.subGroups.forEach(group => createRow(group, 0, rows));
    return new CompanyChartRoot(jsonGroup.nameFi, rows, false, jsonGroup.nameFi)
}

function createTuloslaskelmaRootKiinteistokirjuri(jsonGroup) {
    jsonGroup.subGroups.sort(byOrdinalFn)
    let rows = [];
    jsonGroup.subGroups.forEach(group => createRow(group, 0, rows));
    return new CompanyChartRoot(jsonGroup.nameFi, rows, false, jsonGroup.nameFi)
}


function createOrphanAccountsRoot(jsonObject){
    let rows = [];
    jsonObject.orphanAccounts.forEach(function (account) {
        rows.push(new CompanyChartAccount(account.id, account.code, account.nameFi, account.nameEn, account.nameSv, true, null, null));
    });
    return new CompanyChartRoot("OrphanAccounts", rows, false, "KOHDISTAMATTOMAT TILIT");
}

export class CompanyChartService {

    constructor() {
        "ngInject";
    }

    fromJsonToCharts(jsonChart) {
        if (!jsonChart) {
            console.error('undefined jsonArray', jsonChart);
            return null;
        }

        let roots = [];

        if(jsonChart.orphanAccounts[0]){
            jsonChart.orphanAccounts.sort((a, b)=>{ return a.code - b.code});
            roots.push(createOrphanAccountsRoot(jsonChart));
        }

        let tuloslaskelma = null;
        let tase = null;
        let otherRoots = [];

        jsonChart.groups.forEach(function (jsonGroup) {

            if (jsonGroup.nameFi.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                if ('Yhdistyskirjuri' === jsonChart.name) {
                    tuloslaskelma = createTuloslaskelmaRootYhdistyskirjuri(jsonGroup);
                } else if ('Kiinteistökirjuri' === jsonChart.name) {
                    tuloslaskelma = createTuloslaskelmaRootKiinteistokirjuri(jsonGroup);
                } else {
                    tuloslaskelma = createTuloslaskelmaRootLiikekirjuri(jsonGroup);
                }
            } else if (jsonGroup.nameFi.replace(/\s+/g, '') === 'TASE') {
                tase = createTaseRoot(jsonGroup);
            } else {
                otherRoots.push(createRoot(jsonGroup));
            }
        });

        if (tuloslaskelma) roots.push(tuloslaskelma);
        if (tase) roots.push(tase);
        roots = roots.concat(otherRoots);

        return new CompanyChart(jsonChart.id, jsonChart.name, roots, true)
    }

}
