import React from 'react';
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import {bool, func} from "prop-types";
import {useTranslation} from "react-i18next";

/** Used when investment is handled.<br/><b>Use case: </b> inform user that they should update depreciations table */
const InvestmentPopup = ({showModal, setShowModal}) => {
    const {t} = useTranslation('investments');
    return (
        <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('popup.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('popup.content')}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="primary" onClick={() => setShowModal(!showModal)}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

InvestmentPopup.protoTypes = {
    showModal: bool.isRequired,
    setShowModal: func.isRequired
}

export default InvestmentPopup;