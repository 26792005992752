'use strict';

angular.module('ecoeasyApp')
    .controller('OrganisationCompanyIndicatorDetailController', function ($scope, $rootScope, $stateParams, entity, OrganisationCompanyIndicator, Company, Organisation, Indicator) {
        $scope.organisationCompanyIndicator = entity;
        $scope.load = function (id) {
            OrganisationCompanyIndicator.get({id: id}, function (result) {
                $scope.organisationCompanyIndicator = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:organisationCompanyIndicatorUpdate', function (event, result) {
            $scope.organisationCompanyIndicator = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
