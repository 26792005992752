import template from './chart-table.html';
import {cashFlowTypes} from "../cash-flow-types";

export const ChartTableComponent = {
    bindings: {
        rows: '<',
        chart: '<'
    },
    template: template,
    controller: class ChartTableComponent {
        constructor(BasicChartOfAccountsService, $log) {
            "ngInject";
            this.basicChartOfAccountsService = BasicChartOfAccountsService;
            this.editingRow = null;
            this.$log = $log;
            this.cashFlowTypes = cashFlowTypes;
        }

        edit(row) {
            this.editingRow = angular.copy(row);
        }

        save(row) {
            let backup = angular.copy(row);
            row.nameFi = this.editingRow.nameFi;
            row.nameEn = this.editingRow.nameEn;
            row.nameSv = this.editingRow.nameSv;
            row.setAccountRanges(this.editingRow.accountRanges);
            row.movable = this.editingRow.movable;
            row.topLevel = this.editingRow.topLevel;
            row.cashFlowType = this.editingRow.cashFlowType;
            let payload = {
                nameFi: this.editingRow.nameFi,
                nameEn: this.editingRow.nameEn,
                nameSv: this.editingRow.nameSv,
                movable: this.editingRow.movable,
                topLevel: this.editingRow.topLevel,
                cashFlowType: this.editingRow.cashFlowType,
                accountRanges: this.editingRow.accountRanges.map((r) => {
                    return {min: r.min, max: r.max}
                })

            }
            this.basicChartOfAccountsService.updateBasicChartOfAccountsCategory({
                chartId: this.chart.id,
                categoryId: row.id,
                payload: payload
            }).then((response) => {
                this.$log.debug('update success', response);
            }, (response) => {
                this.$log.debug('update failed', response);
                row.nameFi = backup.nameFi;
                row.nameEn = backup.nameEn;
                row.nameSv = backup.nameSv;
                row.movable = backup.movable;
                row.topLevel= backup.topLevel;
                row.setAccountRanges(backup.accountRanges);
            });
            this.editingRow = null;
        }

        cancel() {
            this.editingRow = null;
        }

        removeRange(range) {
            this.editingRow.accountRanges = this.editingRow.accountRanges.filter((ele) => {
                return ele != range;
            });
        }

        addRange() {
            this.editingRow.accountRanges.push({min: null, max: null});
        }

    }
};
