'use strict';

angular.module('ecoeasyApp')
    .controller('DevaluationDataDetailController', function ($scope, $rootScope, $stateParams, entity, DevaluationData, CompanyVariable, Budget) {
        $scope.devaluationData = entity;
        $scope.load = function (id) {
            DevaluationData.get({id: id}, function (result) {
                $scope.devaluationData = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:devaluationDataUpdate', function (event, result) {
            $scope.devaluationData = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
