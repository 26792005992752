'use strict';

angular.module('ecoeasyApp')
    .controller('OrganisationCompanyIndicatorController', function ($scope, $state, OrganisationCompanyIndicator) {

        $scope.organisationCompanyIndicators = [];
        $scope.loadAll = function () {
            OrganisationCompanyIndicator.query(function (result) {
                $scope.organisationCompanyIndicators = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.organisationCompanyIndicator = {
                id: null
            };
        };
    });
