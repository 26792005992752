import React, {useState} from 'react';
import {Alert, Button, Glyphicon, Modal} from 'react-bootstrap';
import {Formik, Form,} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {getFundingData, postFunding} from '../../../service/react-service';
import MultipleMonthCheckboxInput from '../../shared/inputs/multiple-month-checkbox-input';
import RadioButtonInput from '../../shared/inputs/radio-button-input';
import SingleLineInput from '../../shared/inputs/single-line-input';
import SingleMonthPicker from '../../shared/inputs/single-month-picker';
import MultipleLineInput from '../../shared/inputs/multiple-line-input';

const getFreePeriodInMonths = (withDrawalDate, firstAmorizationDate) => {
    if (withDrawalDate.getYear() < firstAmorizationDate.getYear()) {
        const yearsToMonths = (firstAmorizationDate.getFullYear() - withDrawalDate.getFullYear()) * 12;
        return (firstAmorizationDate.getMonth() - withDrawalDate.getMonth() + yearsToMonths);
    } else {
        return firstAmorizationDate.getMonth() - withDrawalDate.getMonth();
    }
};

const AddFundingModal = ({
                             showModal,
                             setShowModal,
                             setFundingData,
                             companyId,
                             budgetId,
                             actualDate
                         }) => {
    const {t} = useTranslation(['fundingModal', 'fundingForm', 'fundingOptionalValues']);
    const [errorMessage, setErrorMessage] = useState();

    const capitalCategoryOptions = [
        {
            value: 'loan',
            translation: 'fundingOptionalValues:capitalCategory.loan',
            key: 'loan-lable-key'
        },
        {
            value: 'capital',
            translation: 'fundingOptionalValues:capitalCategory.capital',
            key: 'capital-label-key',
        },
    ];

    // amorization type is 'fixed_annuity', customers later decision of dropping 'amorization type' would require backend & db refactoring
    const initialValues = {
        funding_name: '',
        funding_description: '',
        capital_category: '',
        withdrawal_date: '',
        funding_amount: '', //add language specific
        loan_time: '',
        first_amorization_date: '',
        amorizations_per_year: [],
        yearly_interest: '', //add language specific
        amorization_type: 'fixed_annuity',
    }

    const actualizedDate = new Date(actualDate.year, actualDate.month);

    const validationSchema = Yup.object().shape({
        funding_name: Yup.string()
            .required(t('fundingForm:inputValidation.required.fundingName'))
            .max(32, t('fundingForm:inputValidation.maxLength.fundingName')),
        funding_description: Yup.string()
            .max(128, t('fundingForm:inputValidation.maxLength.description')),
        capital_category: Yup.string()
            .required(t('fundingForm:inputValidation.required.capitalCategory')),
        withdrawal_date: Yup.date()
            .min(actualizedDate, t('fundingForm:inputValidation.earlierThanCheck.withdrawalDate'))
            .required(t('fundingForm:inputValidation.required.withdrawalDate'))//'Withdrawal date is required')
            .typeError(t('fundingForm:inputValidation.required.withdrawalDate')),//'Withdrawal date is required'),
        funding_amount: Yup.number()
            .required(t('fundingForm:inputValidation.required.fundingAmount'))//'Amount is required')
            .positive(t('fundingForm:inputValidation.positiveAmountCheck.fundingAmount')), //'Amount must be a positive number'),
        loan_time: Yup.number()
            .required(t('fundingForm:inputValidation.required.loanTime'))//'Loan time is required')
            .positive(t('fundingForm:inputValidation.positiveAmountCheck.loanTime'))//'Loan time must be a positive number')
            .integer(t('fundingForm:inputValidation.integerCheck.loanTime')),//'Loan time must be an integer'),
        first_amorization_date: Yup.date()
            .required(t('fundingForm:inputValidation.required.firstAmorizationDate'))//'First amorization date is required')
            .typeError(t('fundingForm:inputValidation.required.firstAmorizationDate'))//'First amorization date is required')
            .min(Yup.ref('withdrawal_date'), t('fundingForm:inputValidation.earlierThanCheck.firstAmorizationDate')),//'First amorization date cannot be before Withdrawal date'),
        amorizations_per_year: Yup.array()
            .min(1, t('fundingForm:inputValidation.required.amorizationsPerYear'))//'At least one amorization month needs to be selected'),
            .test({
                name: 'max',
                exclusive: false,
                params: {},
                message: t('fundingForm:inputValidation.maxLength.amortizationsPerYear'),
                test: function (value) {
                    const testValue = [...new Set(value)]
                    return testValue.length <= parseFloat(this.parent.loan_time)
                }
            }),
        yearly_interest: Yup.number()
            .required(t('fundingForm:inputValidation.required.yearlyInterest'))//'Yearly interest rate is required')
            .positive(t('fundingForm:inputValidation.positiveAmountCheck.yearlyInterest')),//'Yearly interest rate must be a positive number'),
    });

    const handleOnHide = (formikReset) => {
        formikReset(initialValues);
        setShowModal(!showModal);
    }

    const handlePostFunding = (values) => {
        const funding = {
            name: values.funding_name,
            year: values.withdrawal_date.getFullYear(),
            month: (values.withdrawal_date.getMonth() + 1),
            amount: parseFloat(values.funding_amount),
            capitalCategory: values.capital_category,
            termInMonths: parseInt(values.loan_time),
            freePeriodInMonths: getFreePeriodInMonths(values.withdrawal_date, values.first_amorization_date),
            annualPaymentMonths: [...new Set(values.amorizations_per_year.sort((a, b) => {
                return a - b
            }))],
            annualInterestRate: parseFloat(values.yearly_interest),
            repaymentMethod: values.amorization_type,
            description: values.funding_description !== undefined ? values.funding_description : '',
        };

        postFunding(companyId, budgetId, funding).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
                setErrorMessage(t('fundingModal:errorMessage.general'));
            } else {
                getFundingData(companyId, budgetId).then((res, rej) => {
                    if (rej !== undefined) {
                        console.log(rej);
                    } else {
                        setFundingData(res.data);
                        setShowModal(false);
                    }
                });
            }
        });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            resetForm={initialValues}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                handlePostFunding(values);
                setSubmitting(false);
                if (errorMessage === undefined) {
                    resetForm({values: ''});
                }
            }}
        >
            {({values, resetForm, isSubmitting}) => (
                <Modal show={showModal} onHide={() => handleOnHide(resetForm)}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('fundingModal:title.addFunding')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {errorMessage !== undefined
                                ? <Alert bsStyle='danger'>{errorMessage}</Alert>
                                : <div/>}
                            <SingleLineInput
                                label={t('fundingForm:label.fundingName')}
                                id='fundingName'
                                name='funding_name'
                                type='text'
                                placeholder={t('fundingForm:placeholder.fundingName')}
                            />
                            <MultipleLineInput
                                label={t('fundingForm:label.description')}
                                id='description'
                                name='funding_description'
                            />
                            <RadioButtonInput
                                label={'fundingForm:label.capitalCategory'}
                                options={capitalCategoryOptions}
                                id='capitalCategory'
                                name='capital_category'
                            />
                            <SingleMonthPicker
                                label={t('fundingForm:label.withdrawalDate')}
                                id='withdrawalDate'
                                name='withdrawal_date'
                            />
                            <SingleLineInput
                                label={t('fundingForm:label.fundingAmount')}
                                id='fundingAmount'
                                name='funding_amount'
                                type='number'
                                placeholder='0,00'
                            />
                            <SingleLineInput
                                label={t('fundingForm:label.loanTime')}
                                id='loanTime'
                                name='loan_time'
                                type='number'
                                placeholder='0'
                            />
                            <SingleMonthPicker
                                label={t('fundingForm:label.firstAmorizationDate')}
                                id='firstAmorizationDate'
                                name='first_amorization_date'
                            />
                            <MultipleMonthCheckboxInput
                                label={t('fundingForm:label.amorizationMonths')}
                                id='amorizationPerYear'
                                name='amorizations_per_year'
                            />
                            <SingleLineInput
                                label={t('fundingForm:label.yearlyInterest')}
                                id='yearlyInterest'
                                name='yearly_interest'
                                type='number'
                                placeholder='0,00'
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => handleOnHide(resetForm)}>
                                <Glyphicon glyph='ban-circle'/>
                                {t('fundingModal:button.cancel')}
                            </Button>
                            <Button bsStyle="primary" type='submit'>{t('fundingModal:button.addFunding')}</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
}

export default AddFundingModal;