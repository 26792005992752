'use strict';

angular.module('ecoeasyApp').controller('DevaluationDataDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DevaluationData', 'CompanyVariable', 'Budget',
        function ($scope, $stateParams, $uibModalInstance, entity, DevaluationData, CompanyVariable, Budget) {

            $scope.devaluationData = entity;
            $scope.companyvariables = CompanyVariable.query();
            $scope.budgets = Budget.query();
            $scope.load = function (id) {
                DevaluationData.get({id: id}, function (result) {
                    $scope.devaluationData = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:devaluationDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.devaluationData.id != null) {
                    DevaluationData.update($scope.devaluationData, onSaveSuccess, onSaveError);
                } else {
                    DevaluationData.save($scope.devaluationData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
