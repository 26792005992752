import template from "./performance-measure-formula-item.html";
import "./performance-measure-formula-item.css";
import {formulaItemTypes} from "./PerformanceMeasureFormulaItem";

class PerformanceMeasureFormulaItemComponent {

    constructor($log) {
        "ngInject";
        this.$log = $log;
        this.itemType = null;
    }

    $onInit() {
        this.$log.debug('$onInit()', this.item);
        if (this.item.name && this.item.formula) {
            this.itemType = formulaItemTypes.MEASURE;
        } else if (this.item.id && this.item.code) {
            this.itemType = formulaItemTypes.COMPANY_ACCOUNT;
        } else if (this.item.id) {
            this.itemType = formulaItemTypes.COMPANY_ACCOUNT_GROUP;
        } else if (this.item.key) {
            this.itemType = 'CODE_KEY';
        } else {
            this.itemType = 'OPERATOR';
        }
    }
}

export default {
    bindings: {
        item: '<'
    },
    template: template,
    controller: PerformanceMeasureFormulaItemComponent
}