'use strict';

angular.module('ecoeasyApp') 
    .factory('User', function ($resource) {
        return $resource('api/users/:login/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': { method:'POST' },
                'update': { method:'PUT' },
                'delete':{ method:'DELETE'}
            });
        });

angular.module('ecoeasyApp')
	.service('UserService', function ($http, $q, $rootScope, $log){
		return ({
			getByOrganisation: getByOrganisation, 
                        getAllUserGroupUsers: getAllUserGroupUsers,
                        getById: getById,
                        deleteById: deleteById
		});
                
	function getByOrganisation(id){
				
		return $http({
			method: "get",
			url: "/api/users/organisation/" + id
		}).then(function(response){
			return response.data;
		});
	}
        
        function getAllUserGroupUsers(userGroupId){
            return $http({
                method: "get",
                url: "/api/users/userGroup/" + userGroupId
            }).then(function(response){
                return response.data;
            });
        }
        
        function getById(id){
            return $http({
                method: "get",
                url: "/api/users/byId/" + id
            }).then(function(response){
                return response.data;
            });
        }
        
        function deleteById(id){
            return $http({
                method: "delete",
                url: "/api/users/byId/" + id
            }).then(function(response){
                return response.data;
            });
        }
});
