import React from 'react';
import { 
  ControlLabel,
  FormControl, 
  FormGroup,
  Glyphicon
} from 'react-bootstrap';
import { useField } from 'formik';

const MultipleLineInput = ({label, ...props}) => {
  const [field, meta] = useField(props);

  const errorStyle= {
    fontSize: '12px',
    color: 'red',
    paddingTop: '0.5rem',
  };

  return (
    <FormGroup 
      controlId={props.id} 
      bsSize="small"
    >
      <ControlLabel htmlfo={props.id || props.name}>{label}</ControlLabel>
      <FormControl 
        {...field}
        {...props}
        componentClass="textarea" 
        placeholder={label} />
        {meta.touched && meta.error ? (
          <div className="errormessage" style={errorStyle}><Glyphicon glyph="remove" />{meta.error}</div>
        ) : null}
    </FormGroup>
  );
}

export default MultipleLineInput;