export const BALANCESHEET = {
    TASE: 1,
    PYSYVAT_VASTAAVAT: 3,
    RAHOITUSARVOPAPERIT: 58,
    AINEELLISET_HYODYKKEET: 11,
    VASTAAVAA: 2,
    AINEETTOMAT_HYODYKKEET: 4,
    SIJOITUKSET: 21,
    VAIHTUVAT_VASTAAVAT: 28,
    VASTATTAVAA: 66,
    VIERAS_PAAOMA: 120,
    PITKA_AIKAINEN_LAINA: 121,
    LYHYT_AIKAINEN_LAINA: 135
};

export const capitalCategoryOptions = [
    {
        value: 'loan',
        translation: 'fundingOptionalValues:capitalCategory.loan'
    },
    {
        value: 'capital',
        translation: 'fundingOptionalValues:capitalCategory.capital'
    },
];

export const amortizationTypeOptions = [
    {
        value: 'equal_payments',
        translation: 'fundingOptionalValues:amortizationType.equalPayments'
    },
    {
        value: 'fixed_annuity',
        translation: 'fundingOptionalValues:amortizationType.fixedAnnuity'
    }
];

export const devaluationMethodOptions = [
    {
        value: 'SUUNNITELMAN_MUKAINEN',
        translation: 'investments:values.devaluationMethodOptions.planned'
    },
    {
        value: 'MENOJÄÄNNÖS',
        translation: 'investments:values.devaluationMethodOptions.interest'
    }
];

export const determineIfLongTerm = (acquisitionDate, firstAmortizationDate) => {
    let acquisitionYear = acquisitionDate.getFullYear();
    let firstRepayment = firstAmortizationDate.getFullYear();
    if (acquisitionYear < firstRepayment) return true;
}

/** Find relevant data for investment account options */
export const findSubCategories = ([...accounts], nameKey) => {
    let result = [];
    const recursiveSearch = (object, target) => {
        object.forEach(item => {
            let temp = [];
            item.subCategories.length === 0 ?
                target.push({id: item.id, name: item[nameKey]})
                :
                target.push({
                    id: item.id,
                    name: item[nameKey],
                    subCategories: recursiveSearch(item.subCategories, temp)
                });
        });
        return target;
    };

    accounts.forEach(account => {
        account.forEach(item => {
            let temp = [];
            result.push({
                id: item.id,
                name: item[nameKey],
                subCategories: recursiveSearch(item.subCategories, temp),
            });
        });
    });

    return result;
};

export const getFirstAmorizationDate = (dateYear, dateMonth, freeMonths) => {
    const firstDate = new Date(dateYear, dateMonth);

    if (dateMonth + freeMonths > 12) {
        const monthsAll = dateMonth + freeMonths;
        const additionalYears = (monthsAll - (monthsAll % 12)) / 12;
        firstDate.setMonth((firstDate.getMonth() + freeMonths) % 12);
        firstDate.setFullYear(firstDate.getFullYear() + additionalYears);
    } else {
        firstDate.setMonth(firstDate.getMonth() + freeMonths);
    }

    return firstDate;
};

/** function to parse data into account options from VASTAAVAA */
export const getInvestmentAccountOptions = (data, setOptions, nameKey) => {
    // Parse data into assets
    const assets = data.subCategories.filter(e => e.id === BALANCESHEET.VASTAAVAA);
    const nonCurrent = assets[0].subCategories.filter(e => e.id === BALANCESHEET.PYSYVAT_VASTAAVAT);

    // Parse data into securities
    const currentAssets = assets[0].subCategories.filter(e => e.id === BALANCESHEET.VAIHTUVAT_VASTAAVAT);
    const fundingSecurities = currentAssets[0].subCategories.filter(e => e.id === BALANCESHEET.RAHOITUSARVOPAPERIT);

    // Set investment account options
    const investmentOptions = findSubCategories([nonCurrent[0].subCategories, fundingSecurities], nameKey);
    setOptions(investmentOptions);
};

/** function to parse data into account options from VASTATTAVAA */
export const getFundingAccountOptions = (data, setOptions, nameKey) => {
    // Parse data into liabilities to set funding categories
    const liabilities = data.subCategories.filter(e => e.id === BALANCESHEET.VASTATTAVAA);
    const loanCategories = liabilities[0].subCategories.filter(e => e.id === BALANCESHEET.VIERAS_PAAOMA);

    // Parse long term and short term accounts
    const longTerm = loanCategories[0].subCategories.filter(e => e.id === BALANCESHEET.PITKA_AIKAINEN_LAINA);
    const shortTerm = loanCategories[0].subCategories.filter(e => e.id === BALANCESHEET.LYHYT_AIKAINEN_LAINA);

    let fundingAccounts = findSubCategories([longTerm, shortTerm], nameKey);
    setOptions(fundingAccounts);
};

export const firstCharToUpper = s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

// utility function to return name value for selected account
export const findAccountKey = (target, key, value) => {
    let result = '';

    const recursiveSearch = obj => {
        if (!obj || typeof obj !== 'object') {
            return;
        }

        if (obj[key] === value) {
            result = obj.name;
        }

        Object.keys(obj).forEach(k => {
            recursiveSearch(obj[k]);
        });
    };

    recursiveSearch(target);

    return result;
}

export const getIncomeGroupByBasicChartId = (groups, basicChartId) => {
    if (!groups) return;
    for (const group of groups) {
        if (group.basicChartGroupId === basicChartId) return group;
        if (group.subGroups) {
            const child = getIncomeGroupByBasicChartId(group.subGroups, basicChartId);
            if (child) return child;
        }
    }
};

/** Validate working capital data object changes */
export const valuesChanged = (account, prefix, values) => {
    const updated = {
        calculationType: values[`${prefix}_${account.categoryId}_calculationType`],
        percent: values[`${prefix}_${account.categoryId}_percent`] === undefined ? null : values[`${prefix}_${account.categoryId}_percent`],
        turnover: values[`${prefix}_${account.categoryId}_turnover`] === undefined ? null : values[`${prefix}_${account.categoryId}_turnover`],
        value: values[`${prefix}_${account.categoryId}_value`]
    }
    const original = {
        calculationType: account.calculationType,
        percent: account.percent === undefined ? null : account.percent,
        turnover: account.turnover === undefined ? null : account.turnover,
        value: account.value
    }
    return JSON.stringify(updated) !== JSON.stringify(original);
}

const findGroupFromIncomeStatementGroups = (groups, groupId) => {
    for (const group of groups) {
        if (group.globalGroupId === groupId) {
            return group;
        } else {
            const foundGroup = findGroupFromIncomeStatementGroups(group.groups, groupId);
            if (foundGroup != null) {
                return foundGroup
            }
        }
    }
    return null
}

export const findGroupFromData = (incomeStatementsData, groupId) => {
    let result = [];
    for (const incomeDataObject of incomeStatementsData) {
        const foundGroup = findGroupFromIncomeStatementGroups(incomeDataObject.groups, groupId);
        if (foundGroup != null) {
            result.push(foundGroup);
        }
    }
    return result;
}