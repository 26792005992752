'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('reporting', {
                    parent: 'site',
                    url: '/reporting',
                    data: {
                        authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                        permissions: ['REPORTING'],
                        license: 'PRO',
                        pageTitle: 'global.title'
                    },
                    views: {
                        'content@': {
                            template: require('./reporting.html'),
                            controller: 'ReportingController'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('reporting');
                            return $translate.refresh();
                        }]
                    }
                }
            )
    });

/*
    TODO : Correctly manage the scope for childs.
.state('reporting.new', {
    parent: 'reporting',
    url: '/new',
    onEnter: ['$uibModal', '$state', '$scope', function($uibModal, $state, $scope) {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: 'scripts/app/reporting/reporting-dialog.html',
            controller: 'ReportingDialogController',
            size: 'md',
            scope: $scope,
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    // $translatePartialLoader.addPart('reporting');
                    $translatePartialLoader.addPart('variablecodes');
                    return $translate.refresh();
                }],
            }
        });
    }]
})
// Not enabled ?? Display set to none for the button.
.state('reporting.edit', {
    parent: 'reporting',
    url: '/edit/{page}',
    onEnter: ['$uibModal', function ($uibModal) {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: 'scripts/app/reporting/reporting-dialog.html',
            controller: 'ReportingDialogController',
            size: 'lg',
            scope: $scope
            });
        }]
    })
    .state('reporting.delete', {
        parent: 'reporting',
        url: '/delete/{page}',
        // onEnter: []
    }); */
