export class Measure {
    constructor(id, name, formula) {
        this.id = id;
        this.name = name;
        this.formula = [];
        formula.forEach((f)=>{
            if (f.symbol) {
                this.formula.push(f.symbol);
            } else if (f.nameFi) {
                this.formula.push({id: f.id, name: f.nameFi, code: f.code});
            } else if (f.value) {
                this.formula.push(f.value);
            } else {
                this.formula.push(f);
            }
        })
    }
}
