'use strict';

angular.module('ecoeasyApp')
    .controller('BalanceBudgetDataController', function ($scope, $state, BalanceBudgetData) {

        $scope.balanceBudgetDatas = [];
        $scope.loadAll = function () {
            BalanceBudgetData.query(function (result) {
                $scope.balanceBudgetDatas = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.balanceBudgetData = {
                accountCategoryValueId: null,
                position: null,
                value: null,
                id: null
            };
        };
    });
