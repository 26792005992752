'use strict';

import {DrillService} from "./drill.service";
import {variableTypes} from "./variable-type";
import {CompanyVariableService} from "./company-variable.service";

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('drill', {
                parent: 'site',
                url: '/drill/:variableid/:variableType/:monthly',
                params: {variableid: '0', monthly: 'false', variableType: variableTypes.COMPANY_VARIABLE, year: null, month: null},
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION', 'ROLE_USER_ORGANISATION'],
                    pageTitle: 'drill.title',
                    variableid: ':variableid',
                    variableType: ':variableType',
                    monthly: ':monthly',
                    year: ':year',
                    month: ':month'
                },

                views: {
                    'content@': {
                        template: require('./drill.html'),
                        controller: 'DrillController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('drill');
                        $translatePartialLoader.addPart('variablecodes');
                        $translatePartialLoader.addPart('variableaccounts');
                        return $translate.refresh();
                    }],
                    variableid: ['$stateParams', function ($stateParams) {
                        return $stateParams.variableid;
                    }],
                    monthly: ['$stateParams', function ($stateParams) {
                        return $stateParams.monthly;
                    }],
                    variableType: ['$stateParams', function ($stateParams) {
                        return $stateParams.variableType;
                    }],
                    year: ['$stateParams', function ($stateParams) {
                        return $stateParams.year;
                    }],
                    month: ['$stateParams', function ($stateParams) {
                        return $stateParams.month;
                    }]

                }
            })
    })
    .service('CompanyVariableService', CompanyVariableService)
    .service('DrillService', DrillService);


