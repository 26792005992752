export default class BudgetDataManager {

    constructor($log, BudgetService, BudgetData) {
        this.$log = $log;
        this.BudgetService = BudgetService;
        this.BudgetData = BudgetData;
        this.data = [];
    }

    getBudgetData(selectedBudgetId, callback) {
        this.data = this.BudgetData.get({id: selectedBudgetId}, () => {
            this.$log.debug("BudgetDataManager.getBudgetData: got data", this.data);
            //this.$log.debug("BudgetDataManager.getBudgetData: liikevaihto (REST)", this.data.filter(dataItem => dataItem.companyVariable.code == 'liikevaihto' && dataItem.position == 20189)[0].value);
            //this.$log.debug("BudgetDataManager.getBudgetData: myyntituotot (REST)", this.data.filter(dataItem => dataItem.companyVariable.code == 'myyntituotot' && dataItem.position == 20189)[0].value);
            //this.$log.debug("BudgetDataManager.getBudgetData: myynnin_oikaisuerat (REST)", this.data.filter(dataItem => dataItem.companyVariable.code == 'myynnin_oikaisuerat' && dataItem.position == 20189)[0].value);
            let subBudgetData = this.data.filter(item => item.accountCategoryValueId != 0);
            if (subBudgetData.length > 0) {
                subBudgetData
                    .filter(dataItem => dataItem.companyVariable.code == 'liikevaihto')
                    .forEach(lkv => {
                        let myyntituotot = this.data.filter(dataItem => dataItem.companyVariable.code == 'myyntituotot' && dataItem.position == lkv.position && dataItem.accountCategoryValueId == lkv.accountCategoryValueId);
                        let myynnin_oikaisuerat = this.data.filter(dataItem => dataItem.companyVariable.code == 'myynnin_oikaisuerat' && dataItem.position == lkv.position && dataItem.accountCategoryValueId == lkv.accountCategoryValueId);
                        lkv.value = myyntituotot[0].value + myynnin_oikaisuerat[0].value;
                    });
                let mainBudgetData = this.data.filter(item => item.accountCategoryValueId == 0);
                mainBudgetData.forEach(mainItem => {
                    let sum = 0;
                    subBudgetData.filter(subItem => mainItem.companyVariable.code == subItem.companyVariable.code && mainItem.position == subItem.position)
                        .forEach(subItem => sum += subItem.value);
                    mainItem.value = sum;
                });

            } else {
                this.data
                    .filter(dataItem => dataItem.companyVariable.code == 'liikevaihto')
                    .forEach(lkv => {
                        let myyntituotot = this.data.filter(dataItem => dataItem.companyVariable.code == 'myyntituotot' && dataItem.position == lkv.position);
                        let myynnin_oikaisuerat = this.data.filter(dataItem => dataItem.companyVariable.code == 'myynnin_oikaisuerat' && dataItem.position == lkv.position);
                        lkv.value = myyntituotot[0].value + myynnin_oikaisuerat[0].value;
                    });
            }
            this.$log.debug("BudgetDataManager.getBudgetData: liikevaihto (after adjust)", this.data.filter(dataItem => dataItem.companyVariable.code == 'liikevaihto' && dataItem.position == 20194));
            this.$log.debug("BudgetDataManager.getBudgetData: myyntituotot (after adjust)", this.data.filter(dataItem => dataItem.companyVariable.code == 'myyntituotot' && dataItem.position == 20194));
            this.$log.debug("BudgetDataManager.getBudgetData: myynnin_oikaisuerat (after adjust)", this.data.filter(dataItem => dataItem.companyVariable.code == 'myynnin_oikaisuerat' && dataItem.position == 20194));
            callback(angular.copy(this.data));
        });
    }

    saveBudgetData(selectedBudgetId) {
        const dataForServer = angular.copy(this.data);
        dataForServer
            .filter(dataItem => dataItem.companyVariable.code == 'myynnin_oikaisuerat')
            .forEach(myynnin_oikaisuera => {
                dataForServer
                    .filter(dataItem => dataItem.companyVariable.code == 'myyntituotot' && dataItem.position == myynnin_oikaisuera.position && dataItem.accountCategoryValueId == myynnin_oikaisuera.accountCategoryValueId && dataItem.value != 0)
                    .forEach(dataItem => dataItem.value += myynnin_oikaisuera.value);
            });
        //this.$log.debug("BudgetDataManager.saveBudgetData: liikevaihto", dataForServer.filter(dataItem => dataItem.companyVariable.code == 'liikevaihto' && dataItem.position == 20189)[0].value);
        //this.$log.debug("BudgetDataManager.saveBudgetData: myyntituotot", dataForServer.filter(dataItem => dataItem.companyVariable.code == 'myyntituotot' && dataItem.position == 20189)[0].value);
        //this.$log.debug("BudgetDataManager.saveBudgetData: myynnin_oikaisuerat", dataForServer.filter(dataItem => dataItem.companyVariable.code == 'myynnin_oikaisuerat' && dataItem.position == 20189)[0].value);
        this.BudgetService.saveBudgetData(selectedBudgetId, dataForServer).then(() => {
            this.$log.debug("BudgetDataManager.saveBudgetData:  Saved.");
        });
    }

    changeValue(position, value, accountCategoryValueId, name) {
        //this.$log.debug("BudgetDataManager.changeValue:", position, value, accountCategoryValueId, name);
        const changeMyValue = this.data.filter((item) => {
            return item.position == position && item.accountCategoryValueId == accountCategoryValueId && item.companyVariable.code == name
        });
        //this.$log.debug('BudgetDataManager.changeValue: changeMyValue', changeMyValue.length);
        if (changeMyValue.length == 1) {
            changeMyValue[0].value = value;
        }
    }

}