import {CompanyIncomeStatement} from "./company-income-statement";
import {CompanyIncomeStatementRowFactory} from "./company-income-statement-row-factory";

export class CompanyIncomeStatementService {
    constructor($http, $log, $q, $translate, IncomeStatementRestClientService, BasicChartOfAccountsService, ChartService ) {
        "ngInject";
        this.$http = $http;
        this.$log = $log;
        this.$q = $q;
        this.$translate = $translate;
        this.incomeStatementRestClientService = IncomeStatementRestClientService;
        this.basicChartOfAccountsService = BasicChartOfAccountsService;
        this.chartService = ChartService
    }


    getCompanyIncomeStatement(companyId, year, month) {
        return this.$q.all([this.basicChartOfAccountsService.getBasicChartOfAccounts(), this.incomeStatementRestClientService.getIncomeStatement(companyId,year,month)]).then((results) =>{
            this.charts =  this.chartService.fromJsonToCharts(results[0]);
            return new CompanyIncomeStatement(
                results[1].current,
                results[1].previous,
                new CompanyIncomeStatementRowFactory(this.$log, this.$translate).createRows(results[1].current, results[1].previous, this.charts));
        });

    }

    getMergedIncomeStatement(companyIds){
        return this.$http.get("/api/companies/merged-financials/"+ companyIds +'').then((results) =>{
            this.charts =  this.chartService.fromJsonToCharts(results.data.current);
            return new CompanyIncomeStatement(
                results.data.current,
                results.data.previous,
                new CompanyIncomeStatementRowFactory(this.$log, this.$translate).createRows(results.data.current, results.data.previous, this.charts));
        });
    }

}
