'use strict';

angular.module('ecoeasyApp').controller('CompanyDataVersionDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'CompanyDataVersion', 'Company',
        function ($scope, $stateParams, $uibModalInstance, entity, CompanyDataVersion, Company) {

            $scope.companyDataVersion = entity;
            $scope.companys = Company.query();
            $scope.load = function (id) {
                CompanyDataVersion.get({id: id}, function (result) {
                    $scope.companyDataVersion = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:companyDataVersionUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.companyDataVersion.id != null) {
                    CompanyDataVersion.update($scope.companyDataVersion, onSaveSuccess, onSaveError);
                } else {
                    CompanyDataVersion.save($scope.companyDataVersion, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.datePickerForAccountingDate = {};

            $scope.datePickerForAccountingDate.status = {
                opened: false
            };

            $scope.datePickerForAccountingDateOpen = function ($event) {
                $scope.datePickerForAccountingDate.status.opened = true;
            };
            $scope.datePickerForInsertionDate = {};

            $scope.datePickerForInsertionDate.status = {
                opened: false
            };

            $scope.datePickerForInsertionDateOpen = function ($event) {
                $scope.datePickerForInsertionDate.status.opened = true;
            };
        }]);
