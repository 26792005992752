import template from './row-calculation-dialog.html';

const rowValueCalculation = (operator, value1, value2) => {
    switch (operator) {
        case 'ADD':
            return value1 + value2
        case 'SUBTRACT':
            return value1 - value2
        case 'MULTIPLY':
            return value1 * value2
        case 'DIVISION':
            return value1 / value2
        case 'GROW_PERCENTAGE_BY':
            return value1 * ((100 + value2) / 100)
        case 'REDUCE_PERCENTAGE_BY':
            return value1 * ((100 - value2) / 100)
    }
}

export const RowCalculationDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class RowCalculationComponent {

        constructor() {
            "ngInject";
            this.itemsToCalculate = 'SELECTED_ROW';
            this.rowOperator = 'ADD';
            this.valueToCalculation = 0;
            this.selectedCells = [];
            this.selectedRow = this.resolve.data.row;
            this.index = this.resolve.data.index;
            this.months = this.resolve.data.months;
        }

        $onInit() { }

        showRowOperator() {
            switch (this.rowOperator) {
                case 'ADD':
                    return '+';
                case 'SUBTRACT':
                    return '-';
                case 'MULTIPLY':
                    return '*';
                case 'DIVISION':
                    return '/';
                case 'GROW_PERCENTAGE_BY':
                    return 'Korota %';
                case 'REDUCE_PERCENTAGE_BY':
                    return 'Vähennä %';
            }
        }

        selectCells(cell, index) {
            if (this.selectedCells.find(item => item.index === index)) {
                this.selectedCells = this.selectedCells.filter(item => item.index !== index);
            } else {
                let item = {index: index, cell: cell};
                this.selectedCells.push(item);
                return this.selectedCells.sort((a, b) => (a.index > b.index) ? 1 : -1);
            }
        }

        save() {
            let results = {};
            let newValues = [];

            if (this.itemsToCalculate === 'ALL_ROWS') {
                let rowCopy = JSON.parse(JSON.stringify(this.resolve.data.rows));

                rowCopy.forEach(rowItem => {
                    let tempValues = [];

                    rowItem.values.forEach(value => {
                        tempValues.push(rowValueCalculation(this.rowOperator, value, this.valueToCalculation));
                    });

                    rowItem.values = tempValues;
                    newValues.push(rowItem);
                });

                results = { calculate: this.itemsToCalculate, values: newValues };

            } if (this.itemsToCalculate === 'SELECT_CELLS') {
                let updatedValues = [];
                this.selectedCells.forEach(value => {
                    updatedValues.push({
                        index: value.index,
                        value: rowValueCalculation(this.rowOperator, value.cell, this.valueToCalculation)
                    });
                });

                newValues = this.selectedRow.values.map((val, i) => {
                    let indexMatch = updatedValues.find(el => el.index === i);
                    if (indexMatch) {
                        return indexMatch.value;
                    } else {
                        return val;
                    }
                });

                results = { calculate: 'SINGLE_ROW', values: newValues };

            } else if (this.itemsToCalculate === 'SELECTED_ROW') {
                let rowValues = this.resolve.data.row.values;

                rowValues.forEach(value => {
                    newValues.push(rowValueCalculation(this.rowOperator, value, this.valueToCalculation));
                });

                results = { calculate: 'SINGLE_ROW', values: newValues };
            }

            this.close({$value: results});
        }

        clear() {
            this.dismiss({$value: 'dismissed'})
        }

    }
}