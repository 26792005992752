import template from './memo-dialog.html';

export const MemoDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class MemoDialogComponent {
        constructor() {
            "ngInject";
            this.memo = "";
            this.onEdit = false;
        }

        $onInit() {
            this.memo = this.resolve.memoData.memo || "";
            if (this.memo !== "") {
                this.onEdit = true;
            }
        }

        save() {
            let result = {};
            result.row = this.resolve.memoData;
            if (this.memo !== ("" || undefined || null)) {
                result.memo = this.memo;
                this.close({$value: result});
            } if (this.memo !== result.row) {
                result.memo = this.memo;
                this.close({$value: result});
            } else {
                this.clear();
            }
        }

        delete() {
            let result = {};
            result.row = this.resolve.memoData;
            result.memo = "";
            this.close({$value: result});
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }
    }
}