(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('BudgetMonthSelectController', BudgetMonthSelectController);

    BudgetMonthSelectController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity'];

    function BudgetMonthSelectController($timeout, $scope, $stateParams, $uibModalInstance, entity) {
        var vm = this;
        vm.possibleRealizePositions = entity;
        vm.realizePosition = vm.possibleRealizePositions[vm.possibleRealizePositions.length-1];


        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $uibModalInstance.close(vm.realizePosition);
        }
    }
})();
