'use strict';

angular.module('ecoeasyApp')
    .controller('CashFlowController', function ($scope, $q, $http, Principal) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });

        $scope.codeToVar;
        $scope.actPos = "";
        $scope.estPos = "";

        $q.when($scope.$root.getSelectedCompany()).then(function (company) {
            if (company != null) {
                init(company.id);
            }
            else {
                $q.when($scope.$root.getDefaultCompany()).then(function (company) {
                    if (company != null) {
                        init(company.id);
                    }
                });
            }
        });

        function init(companyId) {
            $http.get("/api/cashflow/" + companyId).then(function (response) {
                var temp = {};

                $scope.actPos = getPositionString(response.data[0].pos);
                var ep1 = response.data[0].estPos;
                $scope.estPos1 = getPositionString(ep1);
                var ep2 = addToPosition(ep1);
                $scope.estPos2 = getPositionString(ep2);
                var ep3 = addToPosition(ep2);
                $scope.estPos3 = getPositionString(ep3);
                response.data.forEach(function (varData) {
                    temp[varData.code] = varData;
                });
                $scope.codeToVar = temp;
            });
        }

        $scope.show = [false, false, false];

        $scope.toggle = function (index) {
            for (var i = 0; i < $scope.show.length; i++) {
                if (i != index) {
                    $scope.show[i] = false;
                }
            }

            $scope.show[index] = !$scope.show[index];
        };

        function addToPosition(pos) {
            var month;
            var year;
            if (pos < 100000) {
                month = pos % 10;
                year = Math.floor((pos - month) / 10);
            }
            else {
                month = pos % 100;
                year = Math.floor(pos / 100);
            }
            if (month === 12) {
                year = year + 1;
                month = 1;
            } else {
                month = month + 1;
            }
            var value = 0;
            if (month < 9) {
                value = year * 100 + month;
            } else {
                value = year * 10 + month;
            }
            return value;
        }

        function getPositionString(pos) {
            var month;
            var year;
            if (pos < 100000) {
                month = pos % 10;
                year = Math.floor((pos - month) / 10);
            }
            else {
                month = pos % 100;
                year = Math.floor(pos / 100);
            }
            return month + "/" + (year % 100);
        }
    });
