'use strict';

import {DynamicReportVariablesService} from './dynamic-report-variables.service';

angular.module('ecoeasyApp')
    .service('DynamicReportVariablesService', DynamicReportVariablesService)
    .controller('ReportingDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', '$http', '$q', 'DynamicReportVariablesService',
        function ($scope, $stateParams, $uibModalInstance, $http, $q, DynamicReportVariablesService) {

            $scope.selectedPage = {};
            $scope.defaultPages = [];
            $scope.selectedVariables = [];
            $scope.variables = [];
            $scope.availablePeriods = ["Y+0", "Y-1", "Y-2", "Y-3", "Y-4", "Y-5"];
            $scope.selectedPeriods = [];
            $scope.flipped = [];
            $scope.title = "";
            var codeToVarMap = {};

            init();

            // TODO Pass the data from reporting controller.
            function init() {
                $q.when($scope.$root.getSelectedCompany()).then(function (comp) {
                    $http.get("/api/reports/allpages/" + comp.id).then(function (response) {
                        $scope.defaultPages = response.data;
                    });
                    //
                    DynamicReportVariablesService.getVariables().then(function (variables) {
                        $scope.variables = variables;
                        createVarMap();
                    });
                });

            }

            function createVarMap() {
                $scope.variables.forEach(function (v) {
                    codeToVarMap[v.code] = v;
                })
            }

            if ($scope.selectedId > 0) {
                $scope.page = angular.copy($scope.filtered[$scope.selectedId - 1]);
            }
            else {
                $scope.page = {
                    pageType: -1,
                    name: "",
                    data: {}
                };
            }

            $scope.clear = function () {
                $uibModalInstance.close();
                $scope.selectedId = -1;
                $scope.page = {};
            };

            function savePage() {
                if ($scope.selectedId > 0) {
                    $scope.pages[$scope.selectedId - 1] = angular.copy($scope.page);
                }
                else {
                    $scope.page.id = $scope.pages.length;
                    $scope.pages.push($scope.page);
                }
                $scope.page = {};
                $scope.selectedId = -1;
                $uibModalInstance.close();
            }

            $scope.save = function () {
                if ($scope.selectedPage) {
                    var vars = [];

                    for (var i = 0; i < $scope.selectedVariables.length; i++) {
                        var variables = {
                            companyVariableId: $scope.selectedVariables[i].id,
                            relativePosition: $scope.selectedPeriods[i],
                            flipped: $scope.flipped[i]
                        }
                        vars.push(variables);
                    }

                    $scope.selectedPage.pageVariables = vars;

                    if ($scope.title != "") {
                        $scope.selectedPage.pageTitle = $scope.title;
                    }
                    // else {
                    //     $scope.selectedPage.pageTitle = $scope.
                    // }
                    $scope.addPage($scope.selectedPage);
                    $scope.selectedPage = null;
                }
                $uibModalInstance.close();
            };

            function deletePage() {
                $scope.pages.splice($scope.selectedId - 1, 1);
                $scope.selectedId = -1;
                for (var i = 0; i < $scope.pages.length; i++) {
                    $scope.pages[i].pageNumber = i + 1;
                }
                $uibModalInstance.close();
            }

            $scope.del = function () {
                if ($scope.selectedId > 0) {
                    deletePage();
                }
            };

            $scope.iterations = function () {
                var arr = [];
                if ($scope.selectedPage && $scope.selectedPage.variableCount)
                    for (var i = 0; i < $scope.selectedPage.variableCount; i++) {
                        arr.push(i);
                    }
                return arr;
            }

            $scope.getName = function (code) {
                var v = codeToVarMap[code];
                if (v.companyChart) {
                    if (v.accountnmbr != null) {
                        return v.accountnmbr + " - " + v.code;
                    } else {
                        return v.code;
                    }
                } else {
                    if (v.accountnmbr != null) {
                        return v.accountnmbr + " - " + $scope.$translate.instant("variable." + code);
                    } else {
                        return $scope.$translate.instant("variable." + code);
                    }
                }
            }
        }]);
