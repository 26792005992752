'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('user-management', {
                parent: 'admin',
                url: '/user-management',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'user-management.home.title'
                },
                views: {
                    'content@': {
                        template: require('./user-management.html'),
                        controller: 'UserManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        $translatePartialLoader.addPart('company');
                        return $translate.refresh();
                    }]
                }
            })
            .state('user-management-detail', {
                parent: 'admin',
                url: '/user/:login',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    pageTitle: 'user-management.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./user-management-detail.html'),
                        controller: 'UserManagementDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    sourceState: function () {
                        return "ADMIN";
                    }
                }
            })
            .state('user-management.new', {
                parent: 'user-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./user-management-dialog.html'),
                        controller: 'UserManagementDialogController',

                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null, login: null, firstName: null, lastName: null, email: null,
                                    activated: true, langKey: null, createdBy: null, createdDate: null,
                                    lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                    resetKey: null, authorities: []
                                };
                            },
                            company_id: [function () {
                                return -1;
                            }],
                            sourceState: function () {
                                return "ADMIN";
                            },
                            executionMethod: function () {
                                return "ADD";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('user-management', null, {reload: true});
                    }, function () {
                        $state.go('user-management');
                    })
                }]
            })
            .state('user-management.edit', {
                parent: 'user-management',
                url: '/{login}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./user-management-dialog.html'),
                        controller: 'UserManagementDialogController',

                        size: 'lg',
                        resolve: {
                            entity: [function () {
                                return $stateParams.login;
                            }],
                            company_id: [function () {
                                return -1;
                            }],
                            sourceState: function () {
                                return "ADMIN";
                            },
                            executionMethod: function () {
                                return "EDIT";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('user-management.delete', {
                parent: 'user-management',
                url: '/{login}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./user-management-delete-dialog.html'),
                        controller: 'user-managementDeleteController',

                        size: 'md',
                        resolve: {
                            entity: ['User', function (User) {
                                return User.get({login: $stateParams.login});
                            }],
                            sourceState: function () {
                                return "ADMIN";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
