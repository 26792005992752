'use strict';

angular.module('ecoeasyApp')
    .controller('IndicatorController', function ($scope, $state, Indicator) {

        $scope.indicators = [];
        $scope.loadAll = function () {
            Indicator.query(function (result) {
                $scope.indicators = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.indicator = {
                name: null,
                shortName: null,
                warnLimit: null,
                alertLimit: null,
                id: null
            };
        };
    });
