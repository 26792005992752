import {BudgetTableRowType} from "../budget-table-data-row";

export class RowCalculator {

    constructor(rows, actualDate, monthNumbers) {
        this.rows = rows;
        this.actualDate = actualDate;
        this.monthNumbers = monthNumbers;
        //this.calculateByColumns();

        //this.calculateRowTotals();
        this.calculateAll();
        this.calculateAll();
    }

    calculateRowTotals() {
        this.rows.forEach((r) => {
            r.total = r.values.reduce((a, b) => a + b, 0);
        });
    }

    calculateByColumn(row, columnIndex, action) {
        if (Array.isArray(row.augend)) {
            row.augend.forEach((augend) => {
                let augendRow = this.rows.find((r) => r.basicChartCategoryId === augend);
                let addendRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId || (Array.isArray(r.augend) && r.augend.includes(augendRow.basicChartCategoryId)));

                var hasInfoRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId && r.companyAccountId == null);
                if (!(hasInfoRows.length !== 0 && hasInfoRows.length === addendRows.length && this.actualDate && this.monthNumbers.indexOf(this.actualDate.month) >= parseInt(columnIndex))) {
                    augendRow.values[columnIndex] = addendRows.map((r) => r.values[columnIndex]).reduce((a, b) => a + b, 0);
                }
                action({row: row, index: columnIndex});
                this.calculateByColumn(augendRow, columnIndex, action);
            });
        } else if (row.augend && !Array.isArray(row.augend)) {
            let augendRow = this.rows.find((r) => r.basicChartCategoryId === row.augend);
            let addendRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId || (Array.isArray(r.augend) && r.augend.includes(augendRow.basicChartCategoryId)));

            var hasInfoRows =this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId && r.companyAccountId == null);
            if (!(hasInfoRows.length !==0 && hasInfoRows.length === addendRows.length && this.actualDate && this.monthNumbers.indexOf(this.actualDate.month)>= parseInt(columnIndex))){
                augendRow.values[columnIndex] = addendRows.map((r) => r.values[columnIndex]).reduce((a, b) => a + b, 0);
            }
            action({row: row, index: columnIndex});
            this.calculateByColumn(augendRow, columnIndex, action);
        }
    }

    calculateRefRows(changedRow, columnIndex, action) {
        const changedValue = changedRow.values[columnIndex];
        let filter = this.rows.filter((r) => r.refId === changedRow.id);
        filter.forEach(refRow => {
            if ((this.actualDate && this.monthNumbers.indexOf(this.actualDate.month)< parseInt(columnIndex)) || !this.actualDate){
                refRow.values[columnIndex] = refRow.refRatio / 100 * changedValue;
                action({row: refRow, index: columnIndex});
            }
        });
    }

    calculateGrowthColumns(changedRow, columnIndex, action) {
        if (columnIndex === 0 && BudgetTableRowType.GROWTH === changedRow.type) {
            changedRow.values.forEach((columnValue, index) => {
                if (index > 0) {
                    changedRow.values[index] = (100 + changedRow.refRatio) / 100 * changedRow.values[index - 1];
                    action({row: changedRow, index: index});
                    this.calculateRefRows(changedRow, index, action);
                }
            });
           // this.calculateByColumns();
        }
    }

    calculateByColumnWithoutAction(row, columnIndex) {
        if (Array.isArray(row.augend)) {
            row.augend.forEach(augend => {
                let augendRow = this.rows.find((r) => r.basicChartCategoryId === augend);
                let addendRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId ||(Array.isArray(r.augend) && r.augend.includes(augendRow.basicChartCategoryId)));
                let hasInfoRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId && r.companyAccountId == null);
                if (!(hasInfoRows.length !==0 && hasInfoRows.length === addendRows.length && this.actualDate && this.monthNumbers.indexOf(this.actualDate.month) >= parseInt(columnIndex))) {
                    augendRow.values[columnIndex] = addendRows.map((r) => r.values[columnIndex]).reduce((a, b) => a + b, 0);
                }
                this.calculateByColumnWithoutAction(augendRow, columnIndex);
            })
        } else if (row.augend && !Array.isArray(row.augend)) {
            let augendRow = this.rows.find((r) => r.basicChartCategoryId === row.augend);
            let addendRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId ||(Array.isArray(r.augend) && r.augend.includes(augendRow.basicChartCategoryId)));
            let hasInfoRows = this.rows.filter((r) => r.augend === augendRow.basicChartCategoryId && r.companyAccountId == null);
            if (!(hasInfoRows.length !== 0 && hasInfoRows.length === addendRows.length && this.actualDate && this.monthNumbers.indexOf(this.actualDate.month) >= parseInt(columnIndex))){
                augendRow.values[columnIndex] = addendRows.map((r) => r.values[columnIndex]).reduce((a, b) => a + b, 0);
            }
            this.calculateByColumnWithoutAction(augendRow, columnIndex);
        }
    }

    calculateRefRowsWithoutAction(changedRow, columnIndex) {
        const changedValue = changedRow.values[columnIndex];
        let filter = this.rows.filter((r) => r.refId === changedRow.id);
        filter.forEach(refRow => {
            if ((this.actualDate && this.monthNumbers.indexOf(this.actualDate.month)< parseInt(columnIndex)) || !this.actualDate){
                refRow.values[columnIndex] = refRow.refRatio / 100 * changedValue;
            }
        });
    }

    calculateGrowthColumnsWithoutAction(changedRow, columnIndex) {
        if (columnIndex === 0 && BudgetTableRowType.GROWTH === changedRow.type) {
            changedRow.values.forEach((columnValue, index) => {
                if (index > 0) {
                    changedRow.values[index] = (100 + changedRow.refRatio) / 100 * changedRow.values[index - 1];
                    this.calculateRefRowsWithoutAction(changedRow, index);
                }
            });
            //this.calculateByColumns();
        }
    }

    calculateAll(){
        for (var i in this.rows) {
            for (var j in this.rows[i].values) {
                this.calculateByColumnWithoutAction(this.rows[i], j);
                this.calculateGrowthColumnsWithoutAction(this.rows[i], j);
                this.calculateRefRowsWithoutAction(this.rows[i], j);
            }
        }
        this.calculateRowTotals();
    }

}
