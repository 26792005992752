'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('permission-management', {
                parent: 'site',
                url: '/permission-management/{sourceType}/{sourceId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_GROUP', 'USER_ORGANISATION'],
                    pageTitle: 'user-management.home.title'
                },
                views: {
                    'content@': {
                        template: require('./permission-management.html'),
                        controller: 'PermissionManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('permissionManagement');
                        $translatePartialLoader.addPart('permissionCodes');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('permission');
                        return $translate.refresh();
                    }],
                    targetEntity: function ($stateParams, UserService, UserGroup) {
                        if ($stateParams.sourceType === "user") {
                            return UserService.getById($stateParams.sourceId)
                        } else {
                            return UserGroup.get({id: $stateParams.sourceId}).$promise;
                        }
                    },
                    targetEntityPermissions: function ($stateParams, permissionMappingService) {
                        if ($stateParams.sourceType === "user") {
                            return permissionMappingService.getAllPermissionsForUser($stateParams.sourceId);
                        } else {
                            return permissionMappingService.getAllPermissionsForUserGroup($stateParams.sourceId);
                        }
                    },
                    permissions: function (Principal, Permission) {
                        return Permission.query().$promise;
                    },
                }
            })

            .state('org-user-management-permission-dialog', {
                parent: 'permission-management',
                url: '/{login}/permission',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_GROUP', 'USER_ORGANISATION'],
                    pageTitle: 'user-management.detail.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./permission-management-dialog.html'),
                        controller: 'PermissionManagementDialogController',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('permissionManagement');
                                $translatePartialLoader.addPart('permissionCodes');
                                $translatePartialLoader.addPart('permission');
                                return $translate.refresh();
                            }],
                            targetEntity: function ($stateParams, UserService, UserGroup) {
                                if ($stateParams.sourceType === "user") {
                                    return UserService.getById($stateParams.sourceId);
                                } else {
                                    return UserGroup.get({id: $stateParams.sourceId}).$promise;
                                }
                            },
                            permissions: function (Principal, Permission) {
                                return Permission.query().$promise;
                            },
                            sourceState: function () {
                                return "ORG";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('permission-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('permission-management.delete', {
                parent: 'permission-management',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_GROUP', 'USER_ORGANISATION']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./permission-management-delete-dialog.html'),
                        controller: 'PermissionManagementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            permissionMapping: function (permissionMappingService) {//TODO vie koko rivi deleteen
                                return permissionMappingService.getPermissionMappingById($stateParams.id);
                            }
                        }
                    }).result.then(function () {
                        $state.go('permission-management', null, {reload: 'permission-management'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
        ;
    });
