'use strict';

angular.module('ecoeasyApp')
    .controller('PurchaseManagementController', function ($scope, $state, PriceCatalog, Organisation, LicenseProductPurchase, PurchaseManagement, Company, companyService) {
        window.vvm = $scope;
        $scope.organisation = null;
        $scope.organisations = Organisation.query();


        initVariables();

        function initVariables() {
            $scope.company = null;
            $scope.priceCatalog = [];
            $scope.productStructure = [];
            $scope.productCategories = [{category: 'COMPANIES'}, {category: 'ORGANISATION_USERS'}, {category: 'COMPANY_BASIC_USERS'}, {category: 'COMPANY_PRO_USERS'}];
            $scope.purchaseCompanies = [];
            $scope.companies = [];
            $scope.purchases = [];
            $scope.proUserCategory = null;
            $scope.basicUserCategory = null;
        }

        $scope.pickOrganisation = function () {
            if ($scope.organisation == null) {
                $scope.priceCatalog = [];
            } else {
                PriceCatalog.OrganisationPriceCatalog.query({'organisationId': $scope.organisation.id}, onSuccessCatalog, onError);
            }
        };

        function onSuccessCatalog(data, headers) {
            $scope.priceCatalog = data;
            $scope.productCategories.forEach(function (productCategory) {
                productCategory.selectLevel = null;
                productCategory.levelPackages = data.catalogRows.filter(function (product) {
                    return product.productCategory === productCategory.category && product.productType === 'LEVEL_PACKAGE';
                });
                productCategory.levelPackages.forEach(function (levelPackage) {
                    levelPackage.addOns = data.catalogRows.filter(function (product) {
                        return levelPackage.productId === product.productParentId;
                    });
                    levelPackage.addOns.forEach(function (addOn) {
                        addOn.selectedAmount = 0;
                    });
                });
                if (productCategory.category == 'COMPANY_PRO_USERS') {
                    $scope.proUserCategory = angular.copy(productCategory);
                }
                if (productCategory.category == 'COMPANY_BASIC_USERS') {
                    $scope.basicUserCategory = angular.copy(productCategory);
                }
            });
            PurchaseManagement.active({'organisationId': $scope.organisation.id}, onSuccessPurchases, onError);
        }

        function onError(error) {
        }

        function onSuccessPurchases(data, headers) {
            $scope.purchases = data;
            $scope.productCategories.forEach(function (productCategory) {
                if (productCategory.category == 'COMPANIES' || productCategory.category == 'ORGANISATION_USERS') {
                    $scope.purchases.forEach(function (purchase) {
                        if (purchase.company == null && purchase.product.category === productCategory.category) {
                            productCategory.levelPackages.forEach(function (levelPackage) {
                                if (purchase.product.id === levelPackage.productId) {
                                    productCategory.selectLevel = levelPackage;
                                }
                            });
                        }
                    });
                    if (productCategory.selectLevel != null) {
                        productCategory.selectLevel.addOns.forEach(function (addOn) {
                            $scope.purchases.forEach(function (purchase) {
                                if (purchase.company == null && purchase.product.id === addOn.productId) {
                                    addOn.selectedAmount = purchase.purchaseAmount;
                                }
                            });
                        });
                    }
                }
            });
            companyService.getCompaniesForOrganisation($scope.organisation.id).then(function (companies) {
                $scope.companies = companies;
                $scope.purchaseCompanies = [];

                $scope.purchases.forEach(function (purchase) {
                    if (purchase.company != null && !contains($scope.purchaseCompanies, purchase.company)) {
                        var company = null;
                        $scope.companies.forEach(function (loopCompany) {
                            if (loopCompany.id === purchase.company.id) {
                                company = angular.copy(loopCompany);
                            }
                        });
                        $scope.purchaseCompanies.push(company);
                    }
                });

                $scope.purchaseCompanies.forEach(function (company) {
                    $scope.purchases.forEach(function (purchase) {
                        if (purchase.company != null && company.id === purchase.company.id) {
                            if (purchase.product.category === 'COMPANY_PRO_USERS') {
                                $scope.proUserCategory.levelPackages.forEach(function (levelPackage) {
                                    if (purchase.product.id === levelPackage.productId) {
                                        company.selectLevel = angular.copy(levelPackage);
                                    }
                                });
                            } else if (purchase.product.category === 'COMPANY_BASIC_USERS') {
                                $scope.basicUserCategory.levelPackages.forEach(function (levelPackage) {
                                    if (purchase.product.id === levelPackage.productId) {
                                        company.selectLevel = angular.copy(levelPackage);
                                    }
                                });
                            }
                        }
                    });
                    if (company.selectLevel != null) {
                        company.selectLevel.addOns.forEach(function (addOn) {
                            $scope.purchases.forEach(function (purchase) {
                                if (purchase.company != null && company.id === purchase.company.id && purchase.product.id === addOn.productId) {
                                    addOn.selectedAmount = purchase.purchaseAmount;
                                }
                            });
                        });
                    }
                });
            });
        }

        function contains(a, obj) {
            for (var i = 0; i < a.length; i++) {
                if (a[i].id === obj.id) {
                    return true;
                }
            }
            return false;
        }

        var onSaveSuccess = function (result) {
            initVariables();
            $scope.pickOrganisation();
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.pickCompany = function (company) {
            $scope.company = company;
            if ($scope.company != null && !contains($scope.purchaseCompanies, $scope.company)) {
                $scope.purchaseCompanies.push(angular.copy($scope.company));
            }
        };

        $scope.save = function () {
            $scope.isSaving = true;
            var purchasesFromModel = [];
            $scope.productCategories.forEach(function (productCategory) {
                if (productCategory.selectLevel != null) {
                    purchasesFromModel.push({
                        organisationId: $scope.organisation.id,
                        companyId: null,
                        productId: productCategory.selectLevel.productId,
                        amount: 1
                    });
                    productCategory.selectLevel.addOns.forEach(function (addOn) {
                        if (addOn.selectedAmount > 0) {
                            purchasesFromModel.push({
                                organisationId: $scope.organisation.id,
                                companyId: null,
                                productId: addOn.productId,
                                amount: addOn.selectedAmount
                            });
                        }
                    });
                }
            });
            $scope.purchaseCompanies.forEach(function (company) {
                if (company.selectLevel != null) {
                    purchasesFromModel.push({
                        organisationId: $scope.organisation.id,
                        companyId: company.id,
                        productId: company.selectLevel.productId,
                        amount: 1
                    });
                    company.selectLevel.addOns.forEach(function (addOn) {
                        if (addOn.selectedAmount > 0) {
                            purchasesFromModel.push({
                                organisationId: $scope.organisation.id,
                                companyId: company.id,
                                productId: addOn.productId,
                                amount: addOn.selectedAmount
                            });
                        }
                    });
                }
            });
            var purchaseDto = {
                organisationId: $scope.organisation.id,
                purchases: purchasesFromModel
            };
            PurchaseManagement.save(purchaseDto, onSaveSuccess, onSaveError);
        };
    });
angular.module('ecoeasyApp')
    .filter('unitPrice', function () {
        return function (catalog) {
            if (catalog == undefined) {
                return 0;
            }
            if (catalog.organisationPriceId != null) {
                return catalog.organisationPriceUnitPrice;
            } else {
                return catalog.catalogPriceUnitPrice;
            }
        };
    });
angular.module('ecoeasyApp')
    .filter('unitAmount', function () {
        return function (catalog) {
            if (catalog == undefined) {
                return 0;
            }
            if (catalog.organisationPriceId != null) {
                return catalog.organisationPriceUnitAmount;
            } else {
                return catalog.catalogPriceUnitAmount;
            }
        };
    });
angular.module('ecoeasyApp')
    .filter('levelPrice', function () {
        return function (catalog) {
            if (catalog == undefined) {
                return 0;
            }
            var sum = 0;
            sum += calculate(catalog);
            catalog.addOns.forEach(function (addOn) {
                sum += calculate(addOn);
            });
            return sum;
        };

        function calculate(catalog) {
            var selectedAmount = 1;
            if (catalog.selectedAmount != undefined) {
                selectedAmount = catalog.selectedAmount;
            }

            if (catalog.organisationPriceId != null) {
                return selectedAmount * catalog.organisationPriceUnitAmount * catalog.organisationPriceUnitPrice;
            } else {
                return selectedAmount * catalog.catalogPriceUnitAmount * catalog.catalogPriceUnitPrice;
            }
        }
    });
angular.module('ecoeasyApp')
    .filter('levelAmount', function () {
        return function (catalog) {
            if (catalog == undefined) {
                return 0;
            }
            var amount = 0;
            amount += calculate(catalog);
            catalog.addOns.forEach(function (addOn) {
                amount += calculate(addOn);
            });
            return amount;
        };

        function calculate(catalog) {
            var selectedAmount = 1;
            if (catalog.selectedAmount != undefined) {
                selectedAmount = catalog.selectedAmount;
            }

            if (catalog.organisationPriceId != null) {
                return selectedAmount * catalog.organisationPriceUnitAmount;
            } else {
                return selectedAmount * catalog.catalogPriceUnitAmount;
            }
        }
    });
angular.module('ecoeasyApp')
    .filter('sumPrice', function () {
        return function (catalogArray, companyArray) {
            var sum = 0;
            if (catalogArray != undefined) {
                catalogArray.forEach(function (category) {
                    if (category.selectLevel != undefined) {
                        sum += calculate(category.selectLevel);
                        category.selectLevel.addOns.forEach(function (addOn) {
                            sum += calculate(addOn);
                        });
                    }
                });
            }
            if (companyArray != undefined) {
                companyArray.forEach(function (company) {
                    if (company.selectLevel != undefined) {
                        sum += calculate(company.selectLevel);
                        company.selectLevel.addOns.forEach(function (addOn) {
                            sum += calculate(addOn);
                        });
                    }
                });
            }
            return sum;
        };

        function calculate(catalog) {
            var selectedAmount = 1;
            if (catalog.selectedAmount != undefined) {
                selectedAmount = catalog.selectedAmount;
            }

            if (catalog.organisationPriceId != null) {
                return selectedAmount * catalog.organisationPriceUnitAmount * catalog.organisationPriceUnitPrice;
            } else {
                return selectedAmount * catalog.catalogPriceUnitAmount * catalog.catalogPriceUnitPrice;
            }
        }
    });


angular.module('ecoeasyApp')
    .filter('levelSelect', function () {
        return function (catalog) {
            if (catalog == undefined) {
                return "";
            }

            var out = catalog.productName;
            if (catalog.organisationPriceId != null) {
            } else {
                out += ", " + catalog.catalogPriceUnitAmount + "kpl x " + catalog.catalogPriceUnitPrice.toFixed(2) + "€ = " + (catalog.catalogPriceUnitAmount * catalog.catalogPriceUnitPrice).toFixed(2) + "€";
            }
            return out;
        };
    });
