import template from './company-income-statement.html';
import './company-income-statement.css';
import {variableTypes} from "../drill/variable-type";

export const companyIncomeStatementComponent = {
    bindings: {
        companyId: '<',
        year: '<',
        month: '<',
        merged:'<',
        companyids: '<'
    },
    template: template,
    controller: class CompanyIncomeStatementComponent {
        constructor($log, $state, CompanyIncomeStatementService) {
            "ngInject";
            this.$log = $log;
            this.$state = $state;
            this.CompanyIncomeStatementService = CompanyIncomeStatementService;
            this.companyIncomeStatement = null;
        }

        $onInit() {
            this.$log.debug("CompanyIncomeStatementComponent.$onInit()");
            if (this.merged){
                this.loadStatement();
            }

        }

        $onChanges(changesObj) {

            this.$log.debug("CompanyIncomeStatementComponent.$onChanges()", changesObj);
            if (this.month && this.year && this.companyId && this.merged == null) {
                this.CompanyIncomeStatementService.getCompanyIncomeStatement(this.companyId, this.year, this.month).then((companyIncomeStatement)=>{
                    this.companyIncomeStatement = companyIncomeStatement;
                });
            }
        }
        loadStatement(){
            this.CompanyIncomeStatementService.getMergedIncomeStatement(this.companyids).then((companyIncomeStatement)=>{
                this.companyIncomeStatement = companyIncomeStatement;
            });
        }



        drillMonthly(row, year, month) {
            this.$log.debug("CompanyIncomeStatementComponent.drill(row)", row);
            this.$state.go('drill', {
                variableid: row.id,
                monthly: true,
                variableType: (row.header) ? variableTypes.COMPANY_ACCOUNT_GROUP : variableTypes.COMPANY_ACCOUNT,
                year: year,
                month: month
            });
        }

        drillCumulative(row, year, month) {
            this.$log.debug("CompanyIncomeStatementComponent.drillCumulative(row)", row);
            this.$state.go('drill', {
                variableid: row.id,
                monthly: false,
                variableType: (row.header) ? variableTypes.COMPANY_ACCOUNT_GROUP : variableTypes.COMPANY_ACCOUNT,
                year: year,
                month: month
            });
        }
    }
}
