import React, {useEffect, useState} from 'react';
import {array, number, object} from 'prop-types';
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import Button from "react-bootstrap/lib/Button";
import {useTranslation} from "react-i18next";
import InvestmentTable from "./investment-table";
import AddInvestmentModal from "./add-investment-modal";
import {getBasicChartOfAccountsData, getInvestmentData} from "../../../service/react-service";
import {firstCharToUpper, getFundingAccountOptions, getInvestmentAccountOptions} from "../budget-utils";
import InvestmentPopup from "./investment-popup";
import './styles.css';

const InvestmentWrapper = ({companyId, budgetId, accountChartId, yearMonths, actualDate}) => {
    const [showModal, setShowModal] = useState(false);
    const [accountOptions, setAccountOptions] = useState([]);
    const [fundingAccountOptions, setFundingAccountOptions] = useState([]);
    const [investments, setInvestments] = useState([]);
    const [showInvestmentPopup, setShowInvestmentPopup] = useState(false);

    const {t, i18n} = useTranslation('investments');

    const localNameKey = `name${firstCharToUpper(i18n.language)}`;

    useEffect(() => {
        getBasicChartOfAccountsData().then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                const liikekirjuri = res.data.find(item => item.id === accountChartId);
                liikekirjuri && getInvestmentAccountOptions(liikekirjuri.balanceSheet, setAccountOptions, localNameKey);
                liikekirjuri && getFundingAccountOptions(liikekirjuri.balanceSheet, setFundingAccountOptions, localNameKey);
            }
        });
    }, [budgetId]);

    useEffect(() => {
        getInvestmentData(companyId, budgetId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej.message);
            } else {
                setInvestments(res.data)
            }
        });
    }, [budgetId, showInvestmentPopup]);

    return (
        <>
            <Button className='AdditionButton' bsStyle="primary" onClick={() => setShowModal(!showModal)} disabled={(accountOptions.length < 1 && fundingAccountOptions.length < 1)}>
                <Glyphicon glyph='plus'/>
                {t('wrapper.createNewInvestment')}
            </Button>
            {showModal &&
                <AddInvestmentModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    options={accountOptions}
                    fundingOptions={fundingAccountOptions}
                    companyId={companyId}
                    budgetId={budgetId}
                    showInvestmentPopup={showInvestmentPopup}
                    setShowInvestmentPopup={setShowInvestmentPopup}
                    actualDate={actualDate}
                />

            }
            {showInvestmentPopup &&
                <InvestmentPopup
                    showModal={showInvestmentPopup}
                    setShowModal={setShowInvestmentPopup}
                />
            }
            <>
                {investments.length === 0 ?
                    null
                    :
                    <InvestmentTable
                        companyId={companyId}
                        budgetId={budgetId}
                        tableData={investments}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        options={accountOptions}
                        fundingOptions={fundingAccountOptions}
                        setInvestments={setInvestments}
                        yearMonths={yearMonths}
                        actualDate={actualDate}
                        showInvestmentPopup={showInvestmentPopup}
                        setShowInvestmentPopup={setShowInvestmentPopup}
                    />
                }
            </>
        </>
    );
};

InvestmentWrapper.propTypes = {
    companyId: number.isRequired,
    budgetId: number.isRequired,
    accountChartId: number.isRequired,
    yearMonths: array.isRequired,
    actualDate: object
};

export default InvestmentWrapper;