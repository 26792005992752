'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyDataVersionController', function ($scope, $state, CompanyDataVersion) {

        $scope.companyDataVersions = [];
        $scope.loadAll = function () {
            CompanyDataVersion.query(function (result) {
                $scope.companyDataVersions = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.companyDataVersion = {
                accountingDate: null,
                insertionDate: null,
                id: null
            };
        };
    });
