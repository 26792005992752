'use strict';

angular.module('ecoeasyApp')
    .factory('PurchaseManagement', function ($resource) {

        var resourceUrl = 'api/licensePurchase';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'active': {method: 'GET', url: 'api/licensePurchase/organisation/:organisationId/active', isArray: true},
            'companySpots': {method: 'GET', url: 'api/licensePurchase/spots/companies', isArray: false},
            'userSpots': {method: 'GET', url: 'api/licensePurchase/spots/users', isArray: false},
            'companyUserSpots': {method: 'GET', url: 'api/licensePurchase/spots/users/:companyId', isArray: false}
        });
    });
