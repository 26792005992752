import {companyMeasureToJson, jsonToMeasure} from "../basic-chart-of-accounts/MeasureConverter";

export class CompanyAccountChartService {

    constructor($log, $http) {
        "ngInject";
        this.$log = $log;
        this.$http = $http;
    }

    getCompanyAccountChart(companyId) {
        return this.$http.get('/api/companies/'+companyId+'/account-chart').then((response)=>{
            return response.data;
        });
    }

    editCompanyAccountChartAccount(companyId, accountId, accountEditParams) {
        return this.$http.put("/api/companies/" + companyId + "/account-chart/accounts/" + accountId, accountEditParams);
    }

    editCompanyAccountChartGroup(companyId, groupId, groupEditParams) {
        return this.$http.put("/api/companies/" + companyId + "/account-chart/groups/" + groupId, groupEditParams);
    }

    queryMeasures(companyId) {
        return this.$http.get('/api/companies/'+companyId+'/account-chart/measures').then((response)=>{
            return response.data.map((json) => {
                return jsonToMeasure(json);
            });
        });
    }

    saveMeasure(companyId, measure) {
        return this.$http.post('/api/companies/'+companyId+'/account-chart/measures', companyMeasureToJson(measure)).then((response)=>{
            return jsonToMeasure(response.data);
        });
    }

    updateMeasure(companyId, measure) {
        return this.$http.put('/api/companies/'+companyId+'/account-chart/measures/'+measure.id, companyMeasureToJson(measure)).then((response)=>{
            return jsonToMeasure(response.data);
        });
    }

    removeMeasure(companyId, measure) {
        return this.$http.delete('/api/companies/'+companyId+'/account-chart/measures/'+measure.id);
    }

    getCompanyMeasures(companyId) {
        return this.$http.get('/api/companies/'+companyId+'/measures').then((response)=>{
            return response.data.map((json) => {
                return jsonToMeasure(json);
            });
        });
    }

}
