export const workingCapitalInputDetails = {
    //currentAssets
    account_30: {
        idName: 'current-assets-30',
        options: [],
    },
    account_31: {
        idName: 'current-assets-31',
        options: [],
    },
    account_32: {
        idName: 'current-assets-32',
        options: [],
    },
    account_33: {
        idName: 'current-assets-33',
        options: [],
    },
    account_34: {
        idName: 'current-assets-34',
        options: [],
    },
    account_35: {
        idName: 'current-assets-35',
        options: [],
    },
    //short term debts
    account_23: {
        idName: 'short-term-debts-23',
        options: [],
    },
    account_25: {
        idName: 'short-term-debts-25',
        options: [],
    },
    account_27: {
        idName: 'short-term-debts-27',
        options: [],
    },
    account_47: {
        idName: 'short-term-debts-47',
        options: [],
    },
    account_48: {
        idName: 'short-term-debts-48',
        options: [],
    },
    account_49: {
        idName: 'short-term-debts-49',
        options: [],
    },
    account_50: {
        idName: 'short-term-debts-50',
        options: [],
    },
    account_51: {
        idName: 'short-term-debts-51',
        options: [],
    },
    account_52: {
        idName: 'short-term-debts-52',
        options: [],
    },
    account_53: {
        idName: 'short-term-debts-53',
        options: [],
    },
    account_57: {
        idName: 'short-term-debts-57',
        options: [],
    },
    //accounts payables
    account_116: {
        idName: 'accounts-payables-116',
        options: [],
    },
    account_117: {
        idName: 'accounts-payables-117',
        options: [],
    },
    account_118: {
        idName: 'accounts-payables-118',
        options: [],
    },
    account_140: {
        idName: 'accounts-payables-140',
        options: [],
    },
    account_141: {
        idName: 'accounts-payables-141',
        options: [],
    },
    account_142: {
        idName: 'accounts-payables-142',
        options: [],
    },
    account_144: {
        idName: 'accounts-payables-144',
        options: [],
    },
    account_145: {
        idName: 'accounts-payables-145',
        options: [],
    },
    account_146: {
        idName: 'accounts-payables-146',
        options: [],
    },
    account_147: {
        idName: 'accounts-payables-147',
        options: [],
    },
    account_151: {
        idName: 'accounts-payables-151',
        options: [],
    },
}
