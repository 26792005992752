export const BudgetTableRowType = {
    SUM: 'SUM',
    ABSOLUTE: 'ABSOLUTE',
    REFERENCE: 'REFERENCE',
    GROWTH: 'GROWTH'
}

export class BudgetTableDataRow {

    constructor(ordinal, id, nameFi, nameEn, nameSv, level, augend, basicChartCategoryId, rowType, main, values, childCount, refId, refRatio, memo, removable, companyAccountId) {
        this.ordinal = ordinal;
        this.id = id;
        this.nameFi = nameFi;
        this.nameEn = nameEn;
        this.nameSv = nameSv;
        this.level = level;
        this.total = 0;
        this.augend = augend;
        this.basicChartCategoryId = basicChartCategoryId;
        this.sum = BudgetTableRowType.SUM === rowType || BudgetTableRowType.REFERENCE === rowType;
        this.main = main;
        this.values = values;
        this.childCount = childCount;
        this.type = rowType;
        this.refId = refId;
        this.refRatio = refRatio;
        this.memo = memo;
        this.removable = removable;
        this.companyAccountId = companyAccountId
    }

}
