import {jsonToMeasure, measureToJson} from "./MeasureConverter";

export class BasicChartOfAccountsMeasuresResource {
    constructor($resource, $q) {
        "ngInject";
        this.resource = $resource('/api/basic-chart-of-accounts/:chartId/measures/:measureId', {}, {'update': {method: 'PUT'}}, {});
        this.$q = $q;
    }

    query(chartId) {
        return this.$q((resolve, reject) => {
            this.resource
                .query({chartId: chartId},
                    (jsonResponse) => {
                        let measures = jsonResponse.map((json) => {
                            return jsonToMeasure(json)
                        });
                        resolve(measures);
                    }, (httpResponse) => {
                        reject();
                    });
        });
    }

    save(chartId, measure) {
        return this.$q((resolve, reject) => {
            let jsonPayload = measureToJson(measure);
            this.resource.save({chartId: chartId}, jsonPayload, (jsonResponse) => {
                let measure = jsonToMeasure(jsonResponse);
                resolve(measure);
            }, (httpResponse) => {
                reject();
            })
        });
    }

    update(chartId, measure) {
        return this.$q((resolve, reject) => {
            let jsonPayload = measureToJson(measure);
            this.resource.update({chartId: chartId, measureId: measure.id}, jsonPayload, (jsonResponse) => {
                let measure = jsonToMeasure(jsonResponse);
                resolve(measure);
            }, (httpResponse) => {
                reject();
            })
        });
    }

    remove(chartId, measure) {
        return this.$q((resolve, reject) => {
            this.resource.delete({chartId: chartId, measureId: measure.id}, (jsonResponse) => {
                resolve();
            }, (httpResponse) => {
                reject();
            })
        });
    }


}