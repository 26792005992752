'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyController', function ($scope, $state, Company, ParseLinks) {

        $scope.companys = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function () {
            Company.query({
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.companys = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.company = {
                name: null,
                businessId: null,
                streetAddr: null,
                postCode: null,
                city: null,
                tel: null,
                accountingStartMonth: null,
                accountingPeriodLength: null,
                lastModelUpdate: null,
                lastAccountingDataUpdate: null,

                id: null
            };
        };
    });
