'use strict';

angular.module('ecoeasyApp')
    .controller('EconomicActivitiesImportController', function ($scope, $http, $q, $state, Principal, EconomicActivitiesImportService) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
        window.ms = $scope;
        $scope.isSaving = false;
        $scope.fileName = "";
        $scope.importOk = true;

        document.getElementById('sourceFile').onchange = function () {
            $scope.fileName = this.value.replace(/.*[\/\\]/, '');
            ;
            $scope.$apply();
        }

        $scope.submitData = function () {
            $scope.isSaving = true;
            $('#modal-spinner').show();
            var f = document.getElementById('sourceFile').files[0];
            EconomicActivitiesImportService.sendFile(f, onSuccess, onError);
        };

        function onSuccess(response) {
            $('#modal-spinner').hide();
            $state.go("economicactivitiesimport.done", {importOk: true});
        }

        function onError(response) {
            $('#modal-spinner').hide();
            $state.go("economicactivitiesimport.done", {importOk: false});
        }

    });
