export const cashFlowTypes = {
    LIIKETOIMINTA: { code: "liiketoiminta", isSubCategory: false },
    LIIKEVOITTO: { code: "liikevoitto", isSubCategory: true },
    RAHOITUSTUOTOT: { code: "rahoitustuotot", isSubCategory: true },
    RAHOITUSKULUT: { code: "rahoituskulut", isSubCategory: true },
    SATUNNAISET_ERAT: { code: "satunnaiset_erat", isSubCategory: true },
    TULOVEROT: { code: "tuloverot", isSubCategory: true },

    KAYTTOPAAOMAN_MUUTOS: { code: "kayttopaaoman_muutos", isSubCategory: false },
    VAIHTO_OMAISUUS: { code: "vaihto_omaisuus", isSubCategory: true },
    LYHYTAIKAISET_SAAMISET: { code: "lyhytaikaiset_saamiset", isSubCategory: true },
    KOROTTOMAT_LYHYTAIKAISET_VELAT: { code: "korottomat_lyhytaikaiset_velat", isSubCategory: true },
    /*KAYTTOPAAOMA: { code: "kayttopaaoma", isSubCategory: true }, */

    INVESTOINNIT: { code: "investoinnit", isSubCategory: false },
    POISTOT_JA_ARVONALENTUMISET: { code: "poistot_ja_arvonalentumiset", isSubCategory: false },

    RAHOITUS: { code: "rahoitus", isSubCategory: false },
    PITKAAIKAISET_SAAMISET: { code: "pitkaaikaiset_saamiset", isSubCategory: true },
    PITKAAIKAISET_LAINAT: { code: "pitkaaikaiset_lainat", isSubCategory: true },
    LYHYTAIKAISET_LAINAT: { code: "lyhytaikaiset_lainat", isSubCategory: true },
    LYHYTAIKAISET_SIJOITUKSET: { code: "lyhytaikaiset_sijoitukset", isSubCategory: true },
    MAKSETUT_OSINGOT: { code: "maksetut_osingot", isSubCategory: true },
    OMA_PAAOMA: { code: "oma_paaoma", isSubCategory: true },
    VAHEMMISTO_OSUUS: { code: "vahemmisto_osuus", isSubCategory: true },
    YKSITYISTILIT_TILIKAUDELLA: { code: "yksityistilit_tilikaudella", isSubCategory: true },

    RAHAT_JA_PANKKISAAMISET: { code: "rahat_ja_pankkisaamiset", isSubCategory: false },

    /*
    LIIKETOIMINNAN_KASSAVIRTA: { code: "liiketoiminnan_kassavirta", isSubCategory: false },
    KAYTTOKATE: "kayttokate",
    TULOSRAHOITUS: "tulosrahoitus",
    KASSAVIRTA_ENNEN_RAHOITUSTA: "kassavirta_ennen_rahoitusta",
    LASKELMAN_MUKAINEN_MUUTOS: "laskelman_mukainen_muutos",
    RAHAVARAT: "rahavarat",
     */
};