/*
 * Directive for popping up a delete confirmation dialog
 * 
 * usage:
 *  Add attribute confirmed-delete="f(something)" to get confirmation dialog when the element is clicked and
 *  f(someting) gets executed on confirmation.
 *  
 *  Set attribute confirmed-delete-message="message translation key" to customize confirmation question.
 * 
 * Example:
 *  <button
 *     confirmed-delete="vm.deletesometing($index)" 
       confirmed-delete-message="abcQualityMatchApp.training.examQuestions_deleteConfirm"
    >
 * 
 */
(function() {
    'use strict';

    angular.module('ecoeasyApp')
        .directive('confirmedDelete', ConfirmedDelete);

    ConfirmedDelete.$inject = ['$uibModal','$translate'];
    function ConfirmedDelete($uibModal, $translate) {
        return {
            restrict: 'A',
            
            link: function(scope, element, attrs){
                
                var message = attrs.confirmedDeleteMessage;
                if (!message){
                    message = null;
                }
                if (message){
                    message = $translate.instant(message);
                }
                
               $(element).on('click', function(e) {
                   
                    $uibModal.open({
                        template: require('./confirmed-delete-dialog.html'),
                        controller: 'DeleteConfirmController',
                        controllerAs: 'vm',
                        size: 'sm',
                        resolve:{
                            message: function (){return message;}
                        }
                    }).result.then(function () {
                        scope.$eval(attrs.confirmedDelete);
                    }); 
               });
            }
      };
    }



    angular
            .module('ecoeasyApp')
            .controller('DeleteConfirmController', DeleteConfirmController);

    DeleteConfirmController.$inject = ['$uibModalInstance', 'message'];

    function DeleteConfirmController($uibModalInstance, message) {
        var vm = this;
        vm.closeDialog = closeDialog;
        vm.confirmDelete = confirmDelete;
        vm.customMessage = message;

        function closeDialog() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete() {
            $uibModalInstance.close(true); 
        }
        
    }
})();
