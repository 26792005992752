'use strict';

angular.module('ecoeasyApp') 
    .factory('CompanyVariable', function ($resource, DateUtils) {
        return $resource('api/companyVariables/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    });
angular.module('ecoeasyApp') 
        .service('companyVariableService', function ($http) {
              var urlBase = '/api/companyVariables';
              
                this.getCompanyVariable = function(id){
                  return $http.get(urlBase+'/'+id);  
                };
                
                this.getByParent = function(parent_id){
                    return $http.get(urlBase + '/parent/' + parent_id);
                };
                                    
                this.getValue = function( cv_id, company_id, monthly){
                    return $http.get(urlBase + '/value/'+ cv_id + '/company/' + company_id + '/monthly/'+monthly);
                };
                
                this.getPrevValue = function (cv_id, company_id, monthly){
                    return $http.get(urlBase + '/prevalue/'+ cv_id + '/company/' + company_id + '/monthly/'+monthly);
                };
                
                this.getByCompany = function(company_id) {
                	return $http.get(urlBase + '/byCompany/'+company_id);
                };
                
                this.getForNewInvestment = function(companyId) {
                	return $http.get(urlBase + "/forInvestment/byCompany/" + companyId);
                };
                
                this.getForNewFunding = function(companyId) {
                	return $http.get(urlBase + "/forFunding/byCompany/" + companyId);
                };
                this.getForSelect = function(companyId) {
                    return $http.get(urlBase+"/forSelect/"+companyId)
                }
                
});
