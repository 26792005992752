import template from "./allocate-values-dialog.html";

export const AllocateValuesDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class AllocateValuesDialogComponent {

        constructor() {
            "ngInject";
            this.totalSum = null;
            this.allocationFactor = 'EQUAL_ALLOCATION';
        }

        $onInit() {

        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }

        save() {
            let result = {
                total: this.totalSum,
                evenly: this.allocationFactor == 'EQUAL_ALLOCATION'
            }
            this.close({$value: result});
        }
    }
}