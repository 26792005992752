import template from "./survivor.chart-dialog.measure.html";

export const SurvivorChartDialogMeasureComponent = {
    template: template,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: class SurvivorChartDialogMeasureComponent {

        constructor($log, $http, SurvivorService) {
            "ngInject";
            this.$log = $log;
            this.chartData = [];
            this.chartLabels = [];
            this.chartSeries = [];
            this.varName = null;
            this.SurvivorService = SurvivorService;
        }

        $onInit() {
            this.$log.debug('SurvivorChartDialogMeasureComponent.$onInit', this.resolve);
            let indicator = this.resolve.indicator;
            this.varName = indicator.name;
            this.SurvivorService.getMeasureIndicatorChartData(indicator).then((data)=>{
                this.chartData = data.chartData;
                this.chartLabels = data.chartLabels;
                this.chartSeries = data.chartSeries;
            });
        }

        $onChanges(changesObj) {
            this.$log.debug('SurvivorChartDialogMeasureComponent.$onChanges', changesObj);
        }

        clear() {
            this.close();
        }

    }
}

