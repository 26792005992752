import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {array, number, object} from 'prop-types';
import {useTranslation} from 'react-i18next';
import WorkingCapitalTable from './working-capital-table';
import {
    getBudgetDataByGlobalId,
    getWorkingCapital,
    postWorkingCapital,
    putWorkingCapital
} from '../../../service/react-service';
import {mapWorkingDataSheet} from './workingDataSheet';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';

const WorkingCapitalWrapper = ({
                                   companyId,
                                   budgetId,
                                   companyFiscalYears,
                                   actualDate
                               }) => {
    const {t} = useTranslation('workingCapital');
    const [workingCapitalData, setWorkingCapitalData] = useState({});
    const [currentAssets, setCurrentAssets] = useState([]);
    const [accountsPayables, setAccountsPayables] = useState([]);
    const [shortTermDebts, setShortTermDebts] = useState([]);
    const [aineetBudgetData, setAineetBudgetData] = useState(null);
    const [liikevaihtoBudgetData, setliikevaihtoBudgetData] = useState(null);

    useEffect(() => {
        getWorkingCapital(companyId, budgetId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                setWorkingCapitalData(res.data);
                setAccountsPayables(res.data.lists.accountsPayables);
                setCurrentAssets(res.data.lists.currentAssets);
                setShortTermDebts(res.data.lists.shortTermDebts);
            }
        });
        (companyId && budgetId) && getBudgetDataByGlobalId(companyId, budgetId, 156).then(res => setliikevaihtoBudgetData(res.data));
        (companyId && budgetId) && getBudgetDataByGlobalId(companyId, budgetId, 189).then(res => setAineetBudgetData(res.data));
    }, []);

    const getTurnoverValue = (account, id) => {
        if (id === undefined || id === null) return account.turnoverCalculated;
        return account.turnover;
    };

    /* utility object for additional data */
    const utilityValues = {
        companyFiscalYears: companyFiscalYears,
        actualDate: actualDate,
        aineetGroup: aineetBudgetData,
        revenueGroup: liikevaihtoBudgetData,
    };

    const handleOnSubmit = (values) => {
        const dataToSave = mapWorkingDataSheet(workingCapitalData, values, utilityValues);
        if (workingCapitalData.id === null) {
            postWorkingCapital(companyId, budgetId, dataToSave).then((res, rej) => {
                if (rej !== undefined) {
                    console.log(rej);
                } else {
                    getWorkingCapital(companyId, budgetId).then((res, rej) => {
                        if (rej !== undefined) {
                            console.log(rej);
                        } else {
                            setWorkingCapitalData(res.data);
                        }
                    });
                }
            });
        } else {
            putWorkingCapital(companyId, budgetId, dataToSave).then((res, rej) => {
                if (rej !== undefined) {
                    console.log(rej);
                } else {
                    getWorkingCapital(companyId, budgetId).then((res, rej) => {
                        if (rej !== undefined) {
                            console.log(rej);
                        } else {
                            setWorkingCapitalData(res.data);
                        }
                    });
                }
            });
        }
    }

    return (
        <div>
            <Formik
                initialValues={{
                    ca_30_calculationType: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 30)
                        ? currentAssets.find(element => element.categoryId === 30).calculationType
                        : 'value',
                    /*ca_30_percent: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 30)
                        ? currentAssets.find(element => element.categoryId === 30).percent
                        : 0,*///
                    ca_30_turnover: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 30)
                        ? getTurnoverValue(currentAssets.find(element => element.categoryId === 30), workingCapitalData.id)
                        : 0,
                    ca_30_value: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 30)
                        ? currentAssets.find(element => element.categoryId === 30).value
                        : 0,
                    ca_31_calculationType: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 31)
                        ? currentAssets.find(element => element.categoryId === 31).calculationType
                        : 'value',
                    /*ca_31_percent: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 31)
                        ? currentAssets.find(element => element.categoryId === 31).percent
                        : 0,*/
                    ca_31_turnover: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 31)
                        ? getTurnoverValue(currentAssets.find(element => element.categoryId === 31), workingCapitalData.id)
                        : 0,
                    ca_31_value: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 31)
                        ? currentAssets.find(element => element.categoryId === 31).value
                        : 0,
                    ca_32_calculationType: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 32)
                        ? currentAssets.find(element => element.categoryId === 32).calculationType
                        : 'value',
                    /*ca_32_percent: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 32)
                        ? currentAssets.find(element => element.categoryId === 32).percent
                        : 0,*/
                    ca_32_turnover: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 32)
                        ? getTurnoverValue(currentAssets.find(element => element.categoryId === 32), workingCapitalData.id)
                        : 0,
                    ca_32_value: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 32)
                        ? currentAssets.find(element => element.categoryId === 32).value
                        : 0,
                    ca_33_calculationType: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 33)
                        ? currentAssets.find(element => element.categoryId === 33).calculationType
                        : 'value',
                    /*ca_33_percent: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 33)
                        ? currentAssets.find(element => element.categoryId === 33).percent
                        : 0,*/
                    ca_33_turnover: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 33)
                        ? getTurnoverValue(currentAssets.find(element => element.categoryId === 33), workingCapitalData.id)
                        : 0,
                    ca_33_value: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 33)
                        ? currentAssets.find(element => element.categoryId === 33).value
                        : 0,
                    ca_34_calculationType: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 34)
                        ? currentAssets.find(element => element.categoryId === 34).calculationType
                        : 'value',
                    /*ca_34_percent: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 34)
                        ? currentAssets.find(element => element.categoryId === 34).percent
                        : 0,*/
                    ca_34_value: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 34)
                        ? currentAssets.find(element => element.categoryId === 34).value
                        : 0,
                    ca_35_calculationType: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 35)
                        ? currentAssets.find(element => element.categoryId === 35).calculationType
                        : 'value',
                    ca_35_percent: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 35)
                        ? currentAssets.find(element => element.categoryId === 35).percent
                        : 0,
                    ca_35_value: currentAssets.length > 0 && currentAssets.find(element => element.categoryId === 35)
                        ? currentAssets.find(element => element.categoryId === 35).value
                        : 0,
                    std_23_calculatedType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 23)
                        ? shortTermDebts.find(element => element.categoryId === 23).calculationType
                        : 'value',
                    std_23_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 23)
                        ? shortTermDebts.find(element => element.categoryId === 23).value
                        : 0,
                    std_25_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 25)
                        ? shortTermDebts.find(element => element.categoryId === 25).calculationType
                        : 'value',
                    std_25_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 25)
                        ? shortTermDebts.find(element => element.categoryId === 25).value
                        : 0,
                    std_27_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 27)
                        ? shortTermDebts.find(element => element.categoryId === 27).calculationType
                        : 'value',
                    std_27_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 27)
                        ? shortTermDebts.find(element => element.categoryId === 27).value
                        : 0,
                    std_47_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 47)
                        ? shortTermDebts.find(element => element.categoryId === 47).calculationType
                        : 'value',
                    std_47_turnover: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 47)
                        ? getTurnoverValue(shortTermDebts.find(element => element.categoryId === 47), workingCapitalData.id)
                        : 0,
                    std_47_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 47)
                        ? shortTermDebts.find(element => element.categoryId === 47).value
                        : 0,
                    std_48_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 48)
                        ? shortTermDebts.find(element => element.categoryId === 48).calculationType
                        : 'value',
                    std_48_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 48)
                        ? shortTermDebts.find(element => element.categoryId === 48).value
                        : 0,
                    std_49_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 49)
                        ? shortTermDebts.find(element => element.categoryId === 49).calculationType
                        : 'value',
                    std_49_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 49)
                        ? shortTermDebts.find(element => element.categoryId === 49).value
                        : 0,
                    std_50_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 50)
                        ? shortTermDebts.find(element => element.categoryId === 50).calculationType
                        : 'value',
                    std_50_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 50)
                        ? shortTermDebts.find(element => element.categoryId === 50).value
                        : 0,
                    std_51_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 51)
                        ? shortTermDebts.find(element => element.categoryId === 51).calculationType
                        : 'value',
                    std_51_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 51)
                        ? shortTermDebts.find(element => element.categoryId === 51).value
                        : 0,
                    std_52_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 52)
                        ? shortTermDebts.find(element => element.categoryId === 52).calculationType
                        : 'value',
                    std_52_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 52)
                        ? shortTermDebts.find(element => element.categoryId === 52).value
                        : 0,
                    std_53_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 53)
                        ? shortTermDebts.find(element => element.categoryId === 53).calculationType
                        : 'value',
                    std_53_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 53)
                        ? shortTermDebts.find(element => element.categoryId === 53).value
                        : 0,
                    std_57_calculationType: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 57)
                        ? shortTermDebts.find(element => element.categoryId === 57).calculationType
                        : 'value',
                    std_57_value: shortTermDebts.length > 0 && shortTermDebts.find(element => element.categoryId === 57)
                        ? shortTermDebts.find(element => element.categoryId === 57).value
                        : 0,
                    ap_116_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 116)
                        ? accountsPayables.find(element => element.categoryId === 116).calculationType
                        : 'value',
                    ap_116_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 116)
                        ? accountsPayables.find(element => element.categoryId === 116).value
                        : 0,
                    ap_117_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 117)
                        ? accountsPayables.find(element => element.categoryId === 117).calculationType
                        : 'value',
                    ap_117_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 117)
                        ? accountsPayables.find(element => element.categoryId === 117).value
                        : 0,
                    ap_118_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 118)
                        ? accountsPayables.find(element => element.categoryId === 118).calculationType
                        : 'value',
                    ap_118_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 118)
                        ? accountsPayables.find(element => element.categoryId === 118).value
                        : 0,
                    ap_140_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 140)
                        ? accountsPayables.find(element => element.categoryId === 140).calculationType
                        : 'value',
                    ap_140_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 140)
                        ? accountsPayables.find(element => element.categoryId === 140).value
                        : 0,
                    ap_141_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 141)
                        ? accountsPayables.find(element => element.categoryId === 141).calculationType
                        : 'value',
                    ap_141_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 141)
                        ? accountsPayables.find(element => element.categoryId === 141).value
                        : 0,
                    ap_142_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 142)
                        ? accountsPayables.find(element => element.categoryId === 142).calculationType
                        : 'value',
                    ap_142_turnover: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 142)
                        ? getTurnoverValue(accountsPayables.find(element => element.categoryId === 142), workingCapitalData.id)
                        : 0,
                    ap_142_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 142)
                        ? accountsPayables.find(element => element.categoryId === 142).value
                        : 0,
                    ap_144_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 144)
                        ? accountsPayables.find(element => element.categoryId === 144).calculationType
                        : 'value',
                    ap_144_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 144)
                        ? accountsPayables.find(element => element.categoryId === 144).value
                        : 0,
                    std_145_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 145)
                        ? accountsPayables.find(element => element.categoryId === 145).calculationType
                        : 'value',
                    ap_145_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 145)
                        ? accountsPayables.find(element => element.categoryId === 145).value
                        : 0,
                    ap_146_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 146)
                        ? accountsPayables.find(element => element.categoryId === 146).calculationType
                        : 'value',
                    ap_146_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 146)
                        ? accountsPayables.find(element => element.categoryId === 146).value
                        : 0,
                    ap_147_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 147)
                        ? accountsPayables.find(element => element.categoryId === 147).calculationType
                        : 'value',
                    ap_147_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 147)
                        ? accountsPayables.find(element => element.categoryId === 147).value
                        : 0,
                    ap_151_calculationType: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 151)
                        ? accountsPayables.find(element => element.categoryId === 151).calculationType
                        : 'value',
                    ap_151_value: accountsPayables.length > 0 && accountsPayables.find(element => element.categoryId === 151)
                        ? accountsPayables.find(element => element.categoryId === 151).value
                        : 0,
                }}
                validationSchema={Yup.object({
                    ca_30_calculationType: Yup.string(),
                    // ca_30_percent: Yup.number(),
                    ca_30_turnover: Yup.number(),
                    ca_30_value: Yup.number(),
                    ca_31_calculationType: Yup.string(),
                    // ca_31_percent: Yup.number(),
                    ca_31_turnover: Yup.number(),
                    ca_31_value: Yup.number(),
                    ca_32_calculationType: Yup.string(),
                    // ca_32_percent: Yup.number(),
                    ca_32_turnover: Yup.number(),
                    ca_32_value: Yup.number(),
                    ca_33_calculationType: Yup.string(),
                    // ca_33_percent: Yup.number(),
                    ca_33_turnover: Yup.number(),
                    ca_33_value: Yup.number(),
                    ca_34_calculationType: Yup.string(),
                    // ca_34_percent: Yup.number(),
                    ca_34_value: Yup.number(),
                    ca_35_calculationType: Yup.string(),
                    // ca_35_percent: Yup.number(),
                    ca_35_value: Yup.number(),
                    std_23_calculationType: Yup.string(),
                    std_23_value: Yup.number(),
                    std_25_calculationType: Yup.string(),
                    std_25_value: Yup.number(),
                    std_27_calculationType: Yup.string(),
                    std_27_value: Yup.number(),
                    std_47_calculationType: Yup.string(),
                    std_47_turnover: Yup.number(),
                    std_47_value: Yup.number(),
                    std_48_calculationType: Yup.string(),
                    std_48_value: Yup.number(),
                    std_49_calculationType: Yup.string(),
                    std_49_value: Yup.number(),
                    std_50_calculationType: Yup.string(),
                    std_50_value: Yup.number(),
                    std_51_calculationType: Yup.string(),
                    std_51_value: Yup.number(),
                    std_52_calculationType: Yup.string(),
                    std_52_value: Yup.number(),
                    std_53_calculationType: Yup.string(),
                    std_53_value: Yup.number(),
                    std_57_calculationType: Yup.string(),
                    std_57_value: Yup.number(),
                    ap_116_calculationType: Yup.string(),
                    ap_116_value: Yup.number(),
                    ap_117_calculationType: Yup.string(),
                    ap_117_value: Yup.number(),
                    ap_118_calculationType: Yup.string(),
                    ap_118_value: Yup.number(),
                    ap_140_calculationType: Yup.string(),
                    ap_140_value: Yup.number(),
                    ap_141_calculationType: Yup.string(),
                    ap_141_value: Yup.number(),
                    ap_142_calculationType: Yup.string(),
                    ap_142_turnover: Yup.number(),
                    ap_142_value: Yup.number(),
                    ap_144_calculationType: Yup.string(),
                    ap_144_value: Yup.number(),
                    ap_145_calculationType: Yup.string(),
                    ap_145_value: Yup.number(),
                    ap_146_calculationType: Yup.string(),
                    ap_146_value: Yup.number(),
                    ap_147_calculationType: Yup.string(),
                    ap_147_value: Yup.number(),
                    ap_151_calculationType: Yup.string(),
                    ap_151_value: Yup.number(),
                })}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    handleOnSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({values, isSubmitting}) => (
                    <Form>
                        <div style={{marginBottom: '5px'}}>
                            <Button bsStyle="primary"
                                    disabled={!(workingCapitalData && aineetBudgetData && liikevaihtoBudgetData) || isSubmitting}
                                    type='submit'>
                                {t('button.save')}
                            </Button>
                        </div>
                        {(workingCapitalData && aineetBudgetData && liikevaihtoBudgetData) ?
                            <WorkingCapitalTable tableData={workingCapitalData}
                                                 formValues={values}
                                                 utilityValues={utilityValues}
                            />
                            : null}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

WorkingCapitalWrapper.propTypes = {
    companyId: number,
    budgetId: number,
    companyFiscalYears: array,
    actualDate: object
};

export default WorkingCapitalWrapper;
