import template from './e-consultant.html';

export const EConsultantComponent = {
    template: template,
    controller: class EConsultantComponent {
        constructor(SelectedCompanyService, $state, EConsultantComponentService) {
            this.selectedCompanyService = SelectedCompanyService;
            this.state = $state;
            this.eConsultantService = EConsultantComponentService;
        }

        $onInit() {
            this.companyId = this.selectedCompanyService.getSelectedCompanyId();
            this.companyConsultationState = this.eConsultantService.companyConsultationState;
        }

    }
};
