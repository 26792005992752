import angular from 'angular';
import {EConsultantComponent} from "./e-consultant.component";
import {SharedModule} from "../shared/shared.module";
import {reactToAngularComponent} from "../../react-app/utils/react-to-angular";
import eConsultantFromReact from "../../react-app/components/e-consultant/e-consultant-wrapper";
import {EConsultantComponentService} from "./e-consultant.service";

EConsultantComponent.$inject = ["SelectedCompanyService", "$state", "EConsultantComponentService"];
export const EConsultantModule = angular
    .module(
        'e-consultant',
        [SharedModule]
    )
    .config(function ($stateProvider) {
        $stateProvider.state({
            parent: 'site',
            name: 'e-consultant',
            url: '/e-consultant',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION', 'ROLE_USER'],
                permissions: ['E_CONSULTANT'],
                pageTitle: 'global.title'
            },
            views: {
                'content@': {
                    template: '<e-consultant>E-consultant</e-consultant>'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate',  function ($translate) {
                    return $translate.refresh();
                }]
            }
        })
    })
    .service('EConsultantComponentService', EConsultantComponentService)
    .component('eConsultant', EConsultantComponent)
    .component('eConsultantReact', reactToAngularComponent(eConsultantFromReact))
    .name;

