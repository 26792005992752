'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('externalAccountingModel', {
                parent: 'entity',
                url: '/externalAccountingModels',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.externalAccountingModel.home.title'
                },
                views: {
                    'content@': {
                        template: require('./externalAccountingModels.html'),
                        controller: 'ExternalAccountingModelController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('externalAccountingModel');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('externalAccountingModel.detail', {
                parent: 'entity',
                url: '/externalAccountingModel/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.externalAccountingModel.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./externalAccountingModel-detail.html'),
                        controller: 'ExternalAccountingModelDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('externalAccountingModel');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ExternalAccountingModel', function ($stateParams, ExternalAccountingModel) {
                        return ExternalAccountingModel.get({id: $stateParams.id});
                    }]
                }
            })
            .state('externalAccountingModel.new', {
                parent: 'externalAccountingModel',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./externalAccountingModel-dialog.html'),
                        controller: 'ExternalAccountingModelDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    accountnmbr: null,
                                    name: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('externalAccountingModel', null, {reload: true});
                    }, function () {
                        $state.go('externalAccountingModel');
                    })
                }]
            })
            .state('externalAccountingModel.edit', {
                parent: 'externalAccountingModel',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./externalAccountingModel-dialog.html'),
                        controller: 'ExternalAccountingModelDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['ExternalAccountingModel', function (ExternalAccountingModel) {
                                return ExternalAccountingModel.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('externalAccountingModel', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('externalAccountingModel.delete', {
                parent: 'externalAccountingModel',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./externalAccountingModel-delete-dialog.html'),
                        controller: 'ExternalAccountingModelDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['ExternalAccountingModel', function (ExternalAccountingModel) {
                                return ExternalAccountingModel.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('externalAccountingModel', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
