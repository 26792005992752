import template from "./add-rows-dialog.html";
import {budgetFactories} from "../../budget-dialog/budget-dialog.component";


export const AddRowsDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class AddRowsDialogComponent {

        constructor(FEATURE_SEPARATE_BUDGET_INFO_ROWS) {
            "ngInject";
            this.items = [];
            this.createBudgetFactor = budgetFactories.EMPTY_BUDGET;
            this.infoRowName = "";
            this.separateInfoRowsFeature = FEATURE_SEPARATE_BUDGET_INFO_ROWS;
        }

        $onInit() {
            if (!this.resolve.current.addable && this.separateInfoRowsFeature) {
                this.createBudgetFactor = budgetFactories.INFO_ROW;
                this.items = [];
            } else {
                this.items = this.resolve.options.map((option)=>{
                    return {
                        id: option.id,
                        code: option.code,
                        name: option.name,
                        selected: false,
                        percentage: null
                    };
                });
            }
            this.current = this.resolve.current;
        }

        save() {
            let itemsToAdd = this.items.filter(item => item.selected);
            if (itemsToAdd.length > 0) {
                let result = {
                    createBudgetFactor: this.createBudgetFactor,
                    items: itemsToAdd,
                    parentId: this.current.id
                };
                this.close({$value: result});
            } if (this.createBudgetFactor === 'INFO_ROW') {
                let result = {
                    createBudgetFactor: this.createBudgetFactor,
                    items: null,
                    parentId: this.current.id,
                    infoRowName: this.infoRowName
                };
                this.close({$value: result});
            } else {
                this.clear();
            }
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }

    }
}