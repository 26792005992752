export class CompanyChart {
    constructor(id, name, roots, active) {
        this.id = id;
        this.name = name;
        this.roots = roots;
        this.active = active;
        this.incomeStatement = null;
        this.balanceSheet = null;
        roots.forEach((root)=>{
            if (root.name.replace(/\s+/g, '') == "TASE") {
                this.balanceSheet = root;
            }
            if (root.name.replace(/\s+/g, '') == "TULOSLASKELMA") {
                this.incomeStatement = root;
            }
        });

    }
}
