export default class SumRow {

    constructor(ordinal, id, name, level, main, augend, size) {
        this.ordinal = ordinal;
        this.id = id;
        this.name = name;
        this.level = level;
        this.sum = true;
        this.main = main;
        if (size) {
            this.values = [];
            for (var i = 0; i < size; i++) {
                this.values.push(0);
            }
        } else {
            this.values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        this.total = 0;
        this.augend = augend;
    }

}