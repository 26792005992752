'use strict';

angular.module('ecoeasyApp')
    .filter('numberformat', function ($filter) {
        return function (item) {
            if (!isNaN(item)) {
                item=item.toFixed(2);
                // split decimals
                var parts = item.toString().split('.')
                // format whole numbers
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                // put them back together
                item = parts[1] ? parts.join(',') : parts[0];
            }
            return item;
        };
}).service("NumberService", function () {

    this.numberFormatter = function (item) {
        if (!isNaN(item)) {
            item=item.toFixed(2);
            // split decimals
            var parts = item.toString().split('.')
            // format whole numbers
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            // put them back together
            item = parts[1] ? parts.join(',') : parts[0];
        }
        return item;

    }

});
