
export class CashFlowService {
    constructor($q, SelectedCompanyService, $http, $log) {
        "ngInject";
        this.$q = $q;
        this.selectedCompanyService = SelectedCompanyService;
        this.$http = $http;
        this.$log = $log;
    }

    getCashFlow() {
        return this.$q((resolve, reject)=>{
            this.$http.get("/api/companies/" + this.selectedCompanyService.getSelectedCompanyId()+"/cash-flow/")
                .then((success)=>{
                    try {
                        resolve(this._createCashFlow(success.data));
                    } catch (err) {
                        this.$log.error("Failed to parse the cash flow data", err);
                        reject(err);
                    }
                }, (error)=>{
                    this.$log.error("Failed to get the cash flow data", error);
                    reject(error);
                });
        });
    }

    _createCashFlow(jsonData) {
        this.$log.debug("CashFlowService._createCashFlow", jsonData);
        let cashFlow = {};
        cashFlow.isEmpty = jsonData.isEmpty;
        cashFlow.headerDates = jsonData.dates.map((item)=>{return item.month+'/'+item.year;});
        cashFlow.headerDatesPrediction12Months = jsonData.predictionDates.map((item)=>{return item.month+'/'+item.year;});
        cashFlow.operations = this._convertTypeValues(jsonData.operations);
        cashFlow.investment = this._convertTypeValues(jsonData.investment);
        cashFlow.financing = this._convertTypeValues(jsonData.financing);
        cashFlow.changeTotals = jsonData.changeTotals;
        cashFlow.changeTotalsPrediction = jsonData.changeTotalsPrediction;
        cashFlow.changeTotalsPrediction12Months = jsonData.changeTotalsPrediction12Months;
        cashFlow.beginTotals = jsonData.beginTotals;
        cashFlow.beginTotalsPrediction = jsonData.beginTotalsPrediction;
        cashFlow.beginTotalsPrediction12Months = jsonData.beginTotalsPrediction12Months;
        cashFlow.endTotals = jsonData.endTotals;
        cashFlow.endTotalsPrediction = jsonData.endTotalsPrediction;
        cashFlow.endTotalsPrediction12Months = jsonData.endTotalsPrediction12Months;
        return cashFlow;
    }

    _convertTypeValues(cashFlowType) {
        return {
            values: cashFlowType.values,
            predictionValues: cashFlowType.predictionValues,
            predictionValues12Months: cashFlowType.predictionValues12Months,
            groups: cashFlowType.groups.map((group)=>{return {name: group.name, values: group.values, predictionValues: group.predictionValues, predictionValues12Months: group.predictionValues12Months};})
        };
    }

}