'use strict';

import {variableTypes} from "./variable-type";

angular.module('ecoeasyApp')
    .controller('DrillController', function ($scope, $state, $translate, $q, Principal, indicatorService, variableid, monthly, variableType, year, month, $window, NumberService, DrillService, SelectedCompanyService) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
        $scope.$translate = $translate;
        $scope.varName = null;
        $scope.expression = null;
        $scope.formula = null;
        $scope.varValue = {};
        $scope.chartLabels = null;
        $scope.chartData = null;
        $scope.chartSeries = null;
        $scope.options = null;
        $scope.variables = [];
        $scope.variableDescription = "";
        $scope.threshold1 = null;
        $scope.threshold2 = null;
        $scope.threshold3 = null;
        $scope.threshold4 = null;
        $scope.threshold5 = null;
        $scope.highlight1 = false;
        $scope.highlight2 = false;
        $scope.highlight3 = false;
        $scope.highlight4 = false;
        $scope.highlight5 = false;

        const company_id = SelectedCompanyService.getSelectedCompanyId();

        $scope.options = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, chart) {
                        var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                        return datasetLabel + ': ' + NumberService.numberFormatter(tooltipItem.yLabel);
                    }
                }
            },
        }

        if ($window.innerWidth > 768) {
            $('#chart').addClass('fifty');
            $('#chart').removeClass('hundred');
        } else {
            $('#chart').addClass('hundred');
            $('#chart').removeClass('fifty');
        }

        angular.element($window).bind('resize', function () {
            if ($window.innerWidth > 768) {
                $('#chart').addClass('fifty');
                $('#chart').removeClass('hundred');
            } else {
                $('#chart').addClass('hundred');
                $('#chart').removeClass('fifty');
            }

        });
        if (variableType == variableTypes.COMPANY_VARIABLE) {
            DrillService.getDataForCompanyVariable(variableid, company_id, monthly, $scope.$root.fiscalYear).then((data)=>{
                $scope.variables = data.variables;
                $scope.varValue = data.varValue;
                $scope.expression = data.expression;
                $scope.varName = data.varName;
                $scope.chartLabels = data.chartLabels;
                $scope.chartData = data.chartData;
                $scope.chartSeries = data.chartSeries;
            });
        }
        else {
            DrillService.getBudget(company_id, year, month).then((budgetData)=>{
                switch (variableType) {
                    case variableTypes.INDICATOR:
                        DrillService.getDataForIndicator(variableid, company_id, monthly).then((data)=>{
                            $scope.variables = data.variables;
                            $scope.varValue = data.varValue;
                            $scope.varEconomicActivitiesValue = data.varEconomicActivitiesValue;
                            $scope.varName = data.varName;
                            $scope.chartLabels = data.chartLabels;
                            $scope.chartData = data.chartData;
                            $scope.chartSeries = data.chartSeries;
                            $scope.expression = data.variables[0].name;
                            $scope.setThresholdTexts(data);
                            $scope.setDescriptionText(data);
                            let budgetGroups = budgetData.filter((v) => {
                                return (data.companyAccountId && v.companyAccountId == data.companyAccountId) || (data.basicChartCategoryId && v.basicChartCategoryId == data.basicChartCategoryId);
                            })

                            if (budgetGroups.length != 0) {
                                $scope.chartSeries.push(data.varName + $scope.$translate.instant('survivor.budgeted'));
                                if (monthly == "true") {
                                    $scope.chartData.push(budgetGroups[0].values);
                                } else {
                                    var newValues= [];
                                    budgetGroups[0].values.reduce(function(a,b,i) { return newValues[i] = a+b; },0);
                                    $scope.chartData.push(newValues);
                                }
                            }
                        });
                        break;
                    case variableTypes.COMPANY_ACCOUNT:
                        DrillService.getDataForCompanyAccount(variableid, company_id, monthly, year, month).then((data)=>{
                            $scope.varName = data.varName;
                            $scope.variables = data.variables
                            $scope.chartLabels = data.chartLabels;
                            $scope.chartData = data.chartData;
                            $scope.chartSeries = data.chartSeries;
                            $scope.varValue = data.varValue;
                            let budgetGroups = budgetData.filter((v) => {
                                return v.companyAccountId == data.id;
                            })
                            if (budgetGroups.length != 0) {
                                $scope.chartSeries.push(data.varName + $scope.$translate.instant('survivor.budgeted'));
                                if (monthly == "true") {
                                    $scope.chartData.push(budgetGroups[0].values);
                                } else {
                                    var newValues= [];
                                    budgetGroups[0].values.reduce(function(a,b,i) { return newValues[i] = a+b; },0);
                                    $scope.chartData.push(newValues);
                                }
                            }
                        });
                        break;
                    case variableTypes.COMPANY_ACCOUNT_GROUP:
                        DrillService.getDataForCompanyAccountGroup(variableid, company_id, monthly, year, month).then((data)=>{
                            $scope.varName = data.varName;
                            $scope.variables = data.variables
                            $scope.chartLabels = data.chartLabels;
                            $scope.chartData = data.chartData;
                            $scope.chartSeries = data.chartSeries;
                            $scope.varValue = data.varValue;
                            let budgetGroups = budgetData.filter((v) => {
                                return v.companyAccountId == data.id;
                            })
                            if (budgetGroups.length != 0) {
                                $scope.chartSeries.push(data.varName + $scope.$translate.instant('survivor.budgeted'));
                                if (monthly == "true") {
                                    $scope.chartData.push(budgetGroups[0].values);
                                } else {
                                    var newValues= [];
                                    budgetGroups[0].values.reduce(function(a,b,i) { return newValues[i] = a+b; },0);
                                    $scope.chartData.push(newValues);
                                }

                            }
                            $scope.expression = data.variables
                                .map((variable) => {
                                    if (variable.accountNumber) {
                                        return variable.accountNumber + ' ' + variable.name;
                                    }
                                    return variable.name;
                                })
                                .join(' + ');
                        });
                        break;
                    case variableTypes.MEASURE:
                        DrillService.getDataForMeasure(variableid, company_id, monthly).then((data)=>{
                            $scope.varName = data.varName;
                            $scope.formula = data.formula;
                            $scope.variables = data.variables
                            $scope.chartLabels = data.chartLabels;
                            $scope.chartData = data.chartData;
                            $scope.chartSeries = data.chartSeries;
                            $scope.varValue = data.varValue;
                            $scope.varEconomicActivitiesValue = data.varEconomicActivitiesValue;
                            $scope.setThresholdTexts(data);
                            $scope.setDescriptionText(data);
                            let budgetGroups = budgetData.filter((v) => {
                                return (data.companyAccountId && v.companyAccountId == data.companyAccountId) || (data.basicChartCategoryId && v.basicChartCategoryId == data.basicChartCategoryId);
                            })

                            if (budgetGroups.length != 0) {
                                $scope.chartSeries.push(data.varName + $scope.$translate.instant('survivor.budgeted'));
                                if (monthly == "true") {
                                    $scope.chartData.push(budgetGroups[0].values);
                                } else {
                                    var newValues= [];
                                    budgetGroups[0].values.reduce(function(a,b,i) { return newValues[i] = a+b; },0);
                                    $scope.chartData.push(newValues);
                                }
                            }
                        });
                        break;
                }
            })
        }

        $scope.setDescriptionText = function (data) {
            let locale = $scope.$translate.use();
            if (locale === 'en') {
                if (data.descriptionEn) {
                    $scope.variableDescription = data.descriptionEn;
                } else {
                    $scope.variableDescription = data.descriptionFi;
                }
            } else if (locale === 'sv') {
                if (data.descriptionSv) {
                    $scope.variableDescription = data.descriptionSv;
                } else {
                    $scope.variableDescription = data.descriptionFi;
                }
            } else {
                $scope.variableDescription = data.descriptionFi;
            }

        }

        function getThresholdName(thresholdName, thresholdNameEn, thresholdNameSv, locale) {
            let name = null;
            if ((thresholdName != null && thresholdName != "") || (thresholdNameEn != null && thresholdNameEn != "") || thresholdNameSv != null && thresholdNameSv != "") {
                if (locale === 'en') {
                    if (thresholdNameEn != null && thresholdNameEn != "") {
                        name = thresholdNameEn;
                    } else if (thresholdName != null && thresholdName != "") {
                        name = thresholdName;
                    } else {
                        name = thresholdNameSv;
                    }
                }
                else if (locale === 'sv') {
                    if (thresholdNameSv != null && thresholdNameSv != "") {
                        name = thresholdNameSv;
                    } else if (thresholdName != null && thresholdName != "") {
                        name = thresholdName;
                    } else {
                        name = thresholdNameEn;
                    }
                }
                else {
                    if (thresholdName != null && thresholdName != "") {
                        name = thresholdName;
                    } else if (thresholdNameEn != null && thresholdNameEn != "") {
                        name = thresholdNameEn;
                    } else {
                        name = thresholdNameSv;
                    }
                }
            }
            return name;
        }

        $scope.setThresholdTexts = function (data) {
            let locale = $scope.$translate.use();
            let over = '';
            let under = '';
            if (locale === 'en') {
                over = 'over';
                under = 'under';
            } else if (locale === 'sv') {
                over = 'yli';
                under = 'alle';
            } else {
                over = 'yli';
                under = 'alle';
            }
            let name1 = getThresholdName(data.thresholdName1, data.thresholdName1En, data.thresholdName1Sv, locale);
            let name2 = getThresholdName(data.thresholdName2, data.thresholdName2En, data.thresholdName2Sv, locale);
            let name3 = getThresholdName(data.thresholdName3, data.thresholdName3En, data.thresholdName3Sv, locale);
            let name4 = getThresholdName(data.thresholdName4, data.thresholdName4En, data.thresholdName4Sv, locale);
            let name5 = getThresholdName(data.thresholdName5, data.thresholdName5En, data.thresholdName5Sv, locale);

            if (name1 != null && name1 != ""){
                if (data.thresholdValue1Low != null && data.thresholdValue1High == null) {
                    $scope.threshold1 = name1 + " " + over + " " + data.thresholdValue1Low;
                    if (data.thresholdValue1Low < $scope.varValue) {
                        $scope.highlight1 = true;
                    }
                } else if (data.thresholdValue1Low == null && data.thresholdValue1High != null) {
                    $scope.threshold1 = name1 + " " + under + " " + data.thresholdValue1High;
                    if (data.thresholdValue1High > $scope.varValue) {
                        $scope.highlight1 = true;
                    }
                } else if (data.thresholdValue1Low != null && data.thresholdValue1High != null) {
                    $scope.threshold1 = name1 + " " + data.thresholdValue1Low + " - " + data.thresholdValue1High;
                    if (data.thresholdValue1Low < $scope.varValue && data.thresholdValue1High > $scope.varValue) {
                        $scope.highlight1 = true;
                    }
                }
            }
            if (name2 != null && name2 != ""){
                if (data.thresholdValue2Low != null && data.thresholdValue2High == null) {
                    $scope.threshold2 = name2 + " " + over + " " + data.thresholdValue2Low;
                    if (data.thresholdValue2Low < $scope.varValue) {
                        $scope.highlight2 = true;
                    }
                } else if (data.thresholdValue2Low == null && data.thresholdValue2High != null) {
                    $scope.threshold2 = name2 + " " + under + " " + data.thresholdValue2High;
                    if (data.thresholdValue2High > $scope.varValue) {
                        $scope.highlight2 = true;
                    }
                } else if (data.thresholdValue2Low != null && data.thresholdValue2High != null) {
                    $scope.threshold2 = name2 + " " + data.thresholdValue2Low + " - " + data.thresholdValue2High;
                    if (data.thresholdValue2Low < $scope.varValue && data.thresholdValue2High > $scope.varValue) {
                        $scope.highlight2 = true;
                    }
                }
            }
            if (name3 != null && name3 != ""){
                if (data.thresholdValue3Low != null && data.thresholdValue3High == null) {
                    $scope.threshold3 = name3 + " " + over + " " + data.thresholdValue3Low;
                    if (data.thresholdValue3Low < $scope.varValue) {
                        $scope.highlight3 = true;
                    }
                } else if (data.thresholdValue3Low == null && data.thresholdValue3High != null) {
                    $scope.threshold3 = name3 + " " + under + " " + data.thresholdValue3High;
                    if (data.thresholdValue3High > $scope.varValue) {
                        $scope.highlight3 = true;
                    }
                } else if (data.thresholdValue3Low != null && data.thresholdValue3High != null) {
                    $scope.threshold3 = name3 + " " + data.thresholdValue3Low + " - " + data.thresholdValue3High;
                    if (data.thresholdValue3Low < $scope.varValue && data.thresholdValue3High > $scope.varValue) {
                        $scope.highlight3 = true;
                    }
                }
            }
            if (name4 != null && name4 != ""){
                if (data.thresholdValue4Low != null && data.thresholdValue4High == null) {
                    $scope.threshold4 = name4 + " " + over + " " + data.thresholdValue4Low;
                    if (data.thresholdValue4Low < $scope.varValue) {
                        $scope.highlight4 = true;
                    }
                } else if (data.thresholdValue4Low == null && data.thresholdValue4High != null) {
                    $scope.threshold4 = name4 + " " + under + " " + data.thresholdValue4High;
                    if (data.thresholdValue4High > $scope.varValue) {
                        $scope.highlight4 = true;
                    }
                } else if (data.thresholdValue4Low != null && data.thresholdValue4High != null) {
                    $scope.threshold4 = name4 + " " + data.thresholdValue4Low + " - " + data.thresholdValue4High;
                    if (data.thresholdValue4Low < $scope.varValue && data.thresholdValue4High > $scope.varValue) {
                        $scope.highlight4 = true;
                    }
                }
            }
            if (name5 != null && name5 != ""){
                if (data.thresholdValue5Low != null && data.thresholdValue5High == null) {
                    $scope.threshold5 = name5 + " " + over + " " + data.thresholdValue5Low;
                    if (data.thresholdValue5Low < $scope.varValue) {
                        $scope.highlight5 = true;
                    }
                } else if (data.thresholdValue5Low == null && data.thresholdValue5High != null) {
                    $scope.threshold5 = name5 + " " + under + " " + data.thresholdValue5High;
                    if (data.thresholdValue5High > $scope.varValue) {
                        $scope.highlight5 = true;
                    }
                } else if (data.thresholdValue5Low != null && data.thresholdValue5High != null) {
                    $scope.threshold5 = name5 + " " + data.thresholdValue5Low + " - " + data.thresholdValue5High;
                    if (data.thresholdValue5Low < $scope.varValue && data.thresholdValue5High > $scope.varValue) {
                        $scope.highlight5 = true;
                    }
                }
            }
        }

        $scope.back = function () {
            window.history.back();
        };

        $scope.variableSelected = function (variable) {
            if (variable.drillable) {
                $state.go('drill', {variableid: variable.id, monthly: monthly, variableType: variable.type});
            }
        };


    });

angular.module('ecoeasyApp')
    .directive('latex', function () {
        return {
            restrict: 'AE',
            link: function (scope, element) {
                var newDom = element.clone();
                element.replaceWith(newDom);
                var pre = "`",
                    post = "`";
                if (element[0].tagName === 'DIV') {
                    pre = "`";
                    post = "`";
                }
                scope.$watch(function () {
                    return element.html();
                }, function () {
                    newDom.html(pre + element.html() + post);
                    MathJax.Hub.Typeset(newDom[0]);
                });
            }
        }
    });
