import template from "./subbudgets-edit.html";


class SubBudget {
    constructor(id, name) {
        this.id = id;
        this.selected = false;
        this.name = name;
        this.prevYear = false;
        this.percentage = null;
    }
}

export const SubBudgetsEditComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class SubBudgetsEditComponent {

        constructor() {
            "ngInject";
            this.isSaving = false;
            this.subBudgets = [];
            this.newsb = new SubBudget();
        }

        $onInit() {
            this.subBudgets = this.resolve.costPools.map((value) => new SubBudget(value.id, value.name));
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }

        addSubBudget() {
            this.newsb.selected = true;
            this.subBudgets.push(this.newsb);
            this.newsb = new SubBudget();
        }

        save() {
            this.isSaving = true;
            const saveThese = this.subBudgets
                .filter(value => value.selected)
                .map((value) => {
                        return {
                            id: value.id,
                            name: value.name,
                            percentage: value.percentage,
                            prevYear: value.prevYear
                        }
                    }
                );
            this.close({$value: saveThese});
        }

    }
}