import React, {useState} from 'react';
import {Button, Glyphicon, Table,} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {getCorrectDecimalFormat, getNumberInThousands} from '../../../utils/util';
import EditFundingModal from './edit-funding-modal';
import DeleteFundingModal from './delete-funding-modal';

const getPaymentAmount = (payment, showThousands) => {
    if (payment !== undefined || payment === 0) {
        if (showThousands) {
            return getNumberInThousands(payment);
        } else {
            return getCorrectDecimalFormat(payment, 0);
        }
    }
    return showThousands ? 0 : getCorrectDecimalFormat(0, 0);
};

function renderTableData(setSelectedFunding, setDeleteModalShow, setEditModalShow, tableData, showThousands) {
    const {t} = useTranslation('fundingOptionalValues');
    return tableData.map((fundingDatum) => {
        return (
            <tr key={fundingDatum.id}>
                <td>
                    {fundingDatum.name}
                </td>
                <td align='right'>
                    {showThousands ? getNumberInThousands(fundingDatum.amount) : fundingDatum.amount}
                </td>
                <td>
                    {fundingDatum.capitalCategory === 'loan'
                        ? t('capitalCategory.loan')
                        : t('capitalCategory.capital')
                    }
                </td>
                <td>
                    {fundingDatum.termInMonths}
                </td>
                <td>
                    {fundingDatum.annualPaymentMonths.length}
                </td>
                <td>
                    {getCorrectDecimalFormat(fundingDatum.annualInterestRate, 2)} %
                </td>
                <td align='right'>
                    {getPaymentAmount(fundingDatum.shortTermPaymentsAmount, showThousands)}
                </td>
                <td align='right'>
                    {getPaymentAmount(fundingDatum.longTermPaymentsAmount, showThousands)}
                </td>
                <td>
                    <Button onClick={() => {
                        setSelectedFunding(fundingDatum);
                        setEditModalShow(true);
                    }}>
                        <Glyphicon glyph='pencil'/>
                    </Button>
                </td>
                <td>
                    <Button onClick={() => {
                        setSelectedFunding(fundingDatum);
                        setDeleteModalShow(true);
                    }}>
                        <Glyphicon glyph='trash'/>
                    </Button>
                </td>
            </tr>
        )
    });
}

const FundingTable = ({fundingData, setFundingData, companyId, budgetId, showThousands, actualDate}) => {
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [selectedFunding, setSelectedFunding] = useState();

    const {t} = useTranslation('fundingTable');
    return (
        <div>
            <Table striped bordered>
                <thead>
                <tr>
                    <th>
                        {t('title.name')}
                    </th>
                    <th>
                        {t('title.amount')}
                    </th>
                    <th>
                        {t('title.capitalCategory')}
                    </th>
                    <th>
                        {t('title.loanTime')}
                        <br/>
                        {t('title.inMonths')}
                    </th>
                    <th>
                        {t('title.amorizations')}
                        <br/>
                        {t('title.perYear')}
                    </th>
                    <th>
                        {t('title.interestRate')}
                    </th>
                    <th>
                        {t('title.shortTerm')}
                    </th>
                    <th>
                        {t('title.longTerm')}
                    </th>
                    <th>
                        {t('title.edit')}
                    </th>
                    <th>
                        {t('title.delete')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {renderTableData(setSelectedFunding, setDeleteModalShow, setEditModalShow, fundingData, showThousands)}
                </tbody>
            </Table>
            <DeleteFundingModal
                modalShow={deleteModalShow}
                setModalShow={setDeleteModalShow}
                setSelectedFunding={setSelectedFunding}
                selectedFunding={selectedFunding}
                companyId={companyId}
                budgetId={budgetId}
                setFundingData={setFundingData}
            />
            <EditFundingModal
                showModal={editModalShow}
                setShowModal={setEditModalShow}
                setFundingData={setFundingData}
                companyId={companyId}
                budgetId={budgetId}
                funding={selectedFunding}
                setFunding={setSelectedFunding}
                actualDate={actualDate}
            />
        </div>
    );
};

export default FundingTable;