'use strict';

angular.module('ecoeasyApp')
    .factory('FundingBudgetData', function ($resource, DateUtils) {
        return $resource('api/fundingBudgetDatas/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByBudgetId': {
            	url: 'api/fundingBudgetDatas/byBudgetId/:budgetId',
            	method: 'GET',
            	isArray: true
            },
            'getByBudgetIdAndInvestmentNull': {
            	url: 'api/fundingBudgetDatas/byBudgetId/:budgetId',
            	method: 'GET',
            	params: {'filter':'investmentbudgetdata-is-null'},
            	isArray: true
            },
            'getByBudgetIdAndInvestmentNotNull': {
            	url: 'api/fundingBudgetDatas/byBudgetId/:budgetId',
            	method: 'GET',
            	params: {'filter': 'investmentbudgetdata-is-not-null'},
            	isArray: true
            }
        });
    });
