import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormControl, FormGroup, Glyphicon} from 'react-bootstrap';
import {useField} from 'formik';

const SingleLineInput = ({label, ...props}) => {
    const [field, meta] = useField(props);

    const errorStyle = {
        fontSize: '12px',
        color: 'red',
        paddingTop: '0.5rem',
    };

    return (
        <FormGroup
            controlId={props.id}
            bsSize="small"
        >
            <ControlLabel htmlFor={props.id || props.name}>{label}</ControlLabel>
            <FormControl
                {...field}
                {...props}
            />
            {meta.touched && meta.error ? (
                <div className="errormessage" style={errorStyle}><Glyphicon glyph="remove"/> {meta.error}</div>
            ) : null}
        </FormGroup>
    );
}

SingleLineInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    help: PropTypes.string,
    setValue: PropTypes.func,
    readOnly: PropTypes.bool,
    usedFractions: PropTypes.number,
    name: PropTypes.string
};

export default SingleLineInput;


