'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('linking-table', {
                parent: 'site',
                url: '/linking-table',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LINKING_TABLES', 'LINKING_TABLES_EDIT'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./linking-tables.html'),
                        controller: 'LinkingTableController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('linkingTable');
                        $translatePartialLoader.addPart('linkingTableRule');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('linking-table-detail', {
                parent: 'linking-table',
                url: '/linking-table/{id}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LINKING_TABLES', 'LINKING_TABLES_EDIT'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./linking-table-detail.html'),
                        controller: 'LinkingTableDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('linkingTable');
                        $translatePartialLoader.addPart('linkingTableRule');
                        $translatePartialLoader.addPart('company');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'LinkingTable', function ($stateParams, LinkingTable) {
                        return LinkingTable.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'linking-table',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('linking-table-detail.edit', {
                parent: 'linking-table-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LINKING_TABLES_EDIT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./linking-table-dialog.html'),
                        controller: 'LinkingTableDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['LinkingTable', function (LinkingTable) {
                                return LinkingTable.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('linking-table.new', {
                parent: 'linking-table',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LINKING_TABLES_EDIT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./linking-table-dialog.html'),
                        controller: 'LinkingTableDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    description: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('linking-table', null, {reload: 'linking-table'});
                    }, function () {
                        $state.go('linking-table');
                    });
                }]
            })
            .state('linking-table.edit', {
                parent: 'linking-table',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LINKING_TABLES_EDIT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./linking-table-dialog.html'),
                        controller: 'LinkingTableDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            entity: ['LinkingTable', function (LinkingTable) {
                                return LinkingTable.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('linking-table', null, {reload: 'linking-table'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('linking-table.delete', {
                parent: 'linking-table',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LINKING_TABLES_EDIT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./linking-table-delete-dialog.html'),
                        controller: 'LinkingTableDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['LinkingTable', function (LinkingTable) {
                                return LinkingTable.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('linking-table', null, {reload: 'linking-table'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    });
