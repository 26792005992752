'use strict';

angular.module('ecoeasyApp')
    .controller('CustomerDetailController', function ($scope, $rootScope, $http, companyId, Principal, FEATURE_COMPANY_ACCOUNT_CHART, BasicChartOfAccountsService) {
        $scope.FEATURE_COMPANY_ACCOUNT_CHART = FEATURE_COMPANY_ACCOUNT_CHART;
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.orc = 1;
            for (var i = 0; i < account.roles.length; i++) {
                if (account.roles[i].company_id === companyId) {
                    $scope.orc = account.roles[i].organisationId;
                }
            }
            $scope.customer;
            if (companyId !== null) {
                $http.get("/api/customer/" + companyId + "/" + $scope.orc).then(function (response) {
                    $scope.customer = response.data;

                    if ($scope.FEATURE_COMPANY_ACCOUNT_CHART) {
                        BasicChartOfAccountsService.getBasicChartOfAccounts().then((data)=>{
                            let charts = data.filter((chart)=>{ return chart.id === $scope.customer.company.basicChartOfAccountsId});
                            if (charts.length == 1) {
                                $scope.basicChartOfAccounts = charts[0].name;
                            }
                        });
                    }
                });
            }
        });
        var unsubscribe = $rootScope.$on('ecoeasyApp:companyUpdate', function (event, result) {
            $scope.customer = result;
        });
        $scope.$on('$destroy', unsubscribe);


    });
