'use strict';

angular.module('ecoeasyApp').controller('LicenseProductPurchaseDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'LicenseProductPurchase', 'Organisation', 'LicenseProduct', 'Company',
        function ($scope, $stateParams, $uibModalInstance, entity, LicenseProductPurchase, Organisation, LicenseProduct, Company) {

            $scope.licenseProductPurchase = entity;
            $scope.organisations = Organisation.query();
            $scope.licenseproducts = LicenseProduct.query();
            $scope.companys = Company.query();
            $scope.load = function (id) {
                LicenseProductPurchase.get({id: id}, function (result) {
                    $scope.licenseProductPurchase = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:licenseProductPurchaseUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.licenseProductPurchase.id != null) {
                    LicenseProductPurchase.update($scope.licenseProductPurchase, onSaveSuccess, onSaveError);
                } else {
                    LicenseProductPurchase.save($scope.licenseProductPurchase, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.datePickerForPurchaseDate = {};

            $scope.datePickerForPurchaseDate.status = {
                opened: false
            };

            $scope.datePickerForPurchaseDateOpen = function ($event) {
                $scope.datePickerForPurchaseDate.status.opened = true;
            };
            $scope.datePickerForDeActivationDate = {};

            $scope.datePickerForDeActivationDate.status = {
                opened: false
            };

            $scope.datePickerForDeActivationDateOpen = function ($event) {
                $scope.datePickerForDeActivationDate.status.opened = true;
            };
        }]);
