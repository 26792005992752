'use strict';

angular.module('ecoeasyApp')
    .controller('LicenseProductDeleteController', function ($scope, $uibModalInstance, entity, LicenseProduct) {

        $scope.licenseProduct = entity;
        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            LicenseProduct.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
