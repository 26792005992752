import {GroupIncomeStatementRow} from "./group-income-statement-row";
import {AccountIncomeStatementRow} from "./account-income-statement-row";

export class CompanyIncomeStatementRowFactory {
    // helper values for Tuotto- ja kulujäämä
    stackedValueSum = 0.0;
    stackedCumulativeSum = 0.0;
    stackedPrevValueSum = 0.0;
    stackedPrevCumulativeSum = 0.0;

    constructor($log, $translate) {
        this.$log = $log;
        this.$translate = $translate;
    }

    createRows(currentData, previousData, basicCharts) {
        basicCharts = basicCharts.filter(chart => chart.id === 3);
        this.basicChart = basicCharts[0];
        let rows = [];
        const allPreviousItems = this._getAllItems(previousData.groups);
        if (currentData.groups[0].name === "VARSINAINEN TOIMINTA" || currentData.groups[0].name === "KIINTEISTÖN TUOTOT") {
            // Varsinainen toiminta disguised as salesMargin :)
            rows = rows.concat(this._getGroupRows2(currentData.groups, allPreviousItems, currentData.groups[0], previousData.groups[0]));
        } else {
            rows = rows.concat(this._getGroupRows2(currentData.groups[0].groups[0].groups[0].groups, allPreviousItems, currentData.salesMargin, previousData.salesMargin));
            rows = rows.concat(this._getGroupRows(currentData.groups[0].groups[0].groups, allPreviousItems));
            rows = rows.concat(this._getGroupRows(currentData.groups[0].groups, allPreviousItems));
            rows = rows.concat(this._getGroupRows(currentData.groups, allPreviousItems));
        }
        if (currentData.groups[0].name === "VARSINAINEN TOIMINTA") {
            const ylijaama = this.basicChart.roots[1].rows[this.basicChart.roots[1].rows.length - 1];
            this.addCustomSumRow(currentData, previousData, rows, ylijaama.nameFi, ylijaama.nameEn, ylijaama.nameSv, 0, currentData.groups[0], previousData.groups[0], ylijaama.name);
        }
        if (currentData.groups[0].name === "KIINTEISTÖN TUOTOT") {
            const ylijaama = this.basicChart.roots[1].rows[this.basicChart.roots[1].rows.length - 1];
            this.addCustomSumRow(currentData, previousData, rows, ylijaama.nameFi, ylijaama.nameEn, ylijaama.nameSv, 0, currentData.groups[0], previousData.groups[0], ylijaama.name);
        }
        return rows;
    }

    /** KIINTEISTÖ- JA YHDISTYSKIRJURIN SUMMA RIVIT */
    addCustomSumRow(currentGroup, previousGroup, rows, nameFi, nameEn, nameSv, indent, currentVarsinainenToiminta, prevVarsinainenToiminta, globalName) {
        const currentNewValue = angular.copy(currentGroup);
        const prevNewValue = angular.copy(previousGroup);
        let value = 0.0;
        let cumulative = 0.0;
        let prevValue = 0.0;
        let prevCumulative = 0.0;

        const kiinteistotYhteensa = this._findByGlobalIdFromGroups(currentGroup.groups, 2157);

        const YHDISTYSKIRJURI_VARSINAISEN_TOIMINNAN_YHTEISTUOTOT_JA_KULUT = 3555;
        const KIINTEISTOKIRJURI_KIINTEISTON_TUOTOT_YHTEENSA = 2157;
        const KIINTEISTOKIRJURI_HOITOKATE = 2158;

        // LASKETAAN KIINTEISTÖKIRJURIN TULOS
        if (globalName === 'TILIKAUDEN YLI-/ALIJÄÄMÄ' && kiinteistotYhteensa) {
            for (const current of currentGroup.groups) {
                if (current.globalId === KIINTEISTOKIRJURI_HOITOKATE) {
                    value = value + current.value;
                    cumulative = cumulative + current.cumulativeValue;
                    // do not calculate hoitokate, this value is overridden in backend
                    for (const groupsKey of current.groups) {
                        value = value + groupsKey.value;
                        cumulative = cumulative + groupsKey.cumulativeValue;
                    }
                }
            }
            for (const prev of previousGroup.groups) {
                if (prev.globalId === KIINTEISTOKIRJURI_HOITOKATE) {
                    prevValue = prevValue + prev.value;
                    prevCumulative = prevCumulative + prev.cumulativeValue;
                    // do not calculate hoitokate, this value is overridden in backend
                    for (const groupsKey of prev.groups) {
                        prevValue = prevValue + groupsKey.value;
                        prevCumulative = prevCumulative + groupsKey.cumulativeValue;
                    }
                }
            }
        } else if (globalName === 'TILIKAUDEN YLI-/ALIJÄÄMÄ') {
            // LASKETAAN YHDISTYSKIRJURIN TULOS
            for (const current of currentGroup.groups[0].groups) {
                value = value + current.value;
                cumulative = cumulative + current.cumulativeValue;
            }
            for (const prev of previousGroup.groups[0].groups) {
                prevValue = prevValue + prev.value;
                prevCumulative = prevCumulative + prev.cumulativeValue;
            }
        } else {
            // TUOTTO- JA KULUJÄÄMÄ are calculated with running values
            // first values are obtained from VARSINAINEN TOIMINTA
            if (currentGroup.globalId === YHDISTYSKIRJURI_VARSINAISEN_TOIMINNAN_YHTEISTUOTOT_JA_KULUT) {
                value = currentVarsinainenToiminta.value;
                cumulative = currentVarsinainenToiminta.cumulativeValue;
                prevValue = prevVarsinainenToiminta.value;
                prevCumulative = prevVarsinainenToiminta.cumulativeValue;

                this.stackedValueSum += value;
                this.stackedCumulativeSum += cumulative;

                for (const prev of previousGroup.groups) {
                    prevValue = prevValue + prev.value;
                    prevCumulative = prevCumulative + prev.cumulativeValue;
                }

                this.stackedPrevValueSum += prevValue;
                this.stackedPrevCumulativeSum += prevCumulative;

            } else {
                for (const current of currentGroup.groups) {
                    value = value + current.value;
                    cumulative = cumulative + current.cumulativeValue;
                }

                this.stackedValueSum += value;
                this.stackedCumulativeSum += cumulative;
                value = this.stackedValueSum;
                cumulative = this.stackedCumulativeSum;

                for (const prev of previousGroup.groups) {
                    prevValue = prevValue + prev.value;
                    prevCumulative = prevCumulative + prev.cumulativeValue;
                }

                this.stackedPrevValueSum += prevValue;
                this.stackedPrevCumulativeSum += prevCumulative;
                prevValue = this.stackedPrevValueSum;
                prevCumulative = this.stackedPrevCumulativeSum;

            }
        }
        currentNewValue.value = value;
        currentNewValue.cumulativeValue = cumulative;

        if (currentNewValue.value !== 0.0 && currentVarsinainenToiminta.value !== 0.0) {
            currentNewValue.percentageValue = (currentNewValue.value / currentVarsinainenToiminta.value) * 100;
        } else {
            currentNewValue.percentageValue = 0.0;
        }

        if (currentNewValue.cumulativeValue !== 0.0 && currentVarsinainenToiminta.cumulativeValue !== 0.0) {
            currentNewValue.percentageCumulativeValue = (currentNewValue.cumulativeValue / currentVarsinainenToiminta.cumulativeValue) * 100;
        } else {
            currentNewValue.percentageCumulativeValue = 0.0;
        }

        prevNewValue.value = prevValue;
        prevNewValue.cumulativeValue = prevCumulative;

        if (prevNewValue.value !== 0.0 && prevVarsinainenToiminta.value !== 0.0) {
            prevNewValue.percentageValue = (prevNewValue.value / prevVarsinainenToiminta.value) * 100;
        } else {
            prevNewValue.percentageValue = 0.0;
        }

        if (prevNewValue.cumulativeValue !== 0.0 && prevVarsinainenToiminta.cumulativeValue !== 0.0) {
            prevNewValue.percentageCumulativeValue = (prevNewValue.cumulativeValue / prevVarsinainenToiminta.cumulativeValue) * 100;
        } else {
            prevNewValue.percentageCumulativeValue = 0.0;
        }
        currentNewValue.name = nameFi;
        currentNewValue.nameFi = nameFi;
        currentNewValue.nameEn = nameEn;
        currentNewValue.nameSv = nameSv
        prevNewValue.name = nameFi;
        prevNewValue.nameFi = nameFi;
        prevNewValue.nameEn = nameEn;
        prevNewValue.nameSv = nameSv;
        rows.push(new GroupIncomeStatementRow(currentNewValue, prevNewValue, indent, this.$translate.use()));
    }

    _getAllItems(groups) {
        let array = [];
        array = array.concat(groups);
        groups.forEach((group) => {
            this.$log.debug("group.accounts", group.accounts);
            array = array.concat(group.accounts);
            array = array.concat(this._getAllItems(group.groups));
        });
        return array;
    }

    _getGroupRows2(groups, allPreviousItems, currentSalesMargin, previousSalesMargin) {
        let rows = [];
        let group = groups[0];
        rows = rows.concat(this._initRows([group], allPreviousItems, 0, currentSalesMargin, previousSalesMargin));
        let rest = groups.slice(1);
        if (rest)
            rows = rows.concat(this._initRows(rest, allPreviousItems, 0, currentSalesMargin, previousSalesMargin));
        return rows;
    }

    _getGroupRows(groups, allPreviousItems) {
        let rows = [];
        let group = groups[0];
        let prevGroup = this._getPrev(allPreviousItems, group);
        rows.push(new GroupIncomeStatementRow(group, prevGroup, 0, this.$translate.use()));
        let rest = groups.slice(1);
        if (rest)
            rows = rows.concat(this._initRows(rest, allPreviousItems, 0));
        if (group.accounts[0]) {
            group.accounts.forEach((account) => {
                let prevAccount = this._getPrev(allPreviousItems, account);
                if (this._hasValue(account) || this._hasValue(prevAccount)) {
                    rows.push(new AccountIncomeStatementRow(account, prevAccount, this.$translate.use()));
                }
            });
        }
        return rows;
    }

    _getPrev(allPreviousItems, item) {
        return allPreviousItems.find((i) => {
            return i.id === item.id
        });
    }

    _initRows(groups, allPreviousItems, indent, currentSalesMargin, previousSalesMargin) {
        let rows = []
        groups.forEach((group) => {
            let prevGroup = this._getPrev(allPreviousItems, group);
            if (this._hasValue(group) || this._hasValue(prevGroup)) {
                if (group.name.startsWith("Henkilöstökulut") && currentSalesMargin && previousSalesMargin && currentSalesMargin.name !== "VARSINAINEN TOIMINTA") {
                    rows.push(new GroupIncomeStatementRow(currentSalesMargin, previousSalesMargin, 0, this.$translate.use()));
                }
                rows.push(new GroupIncomeStatementRow(group, prevGroup, indent, this.$translate.use()));
                group.accounts.forEach((account) => {
                    let prevAccount = this._getPrev(allPreviousItems, account);
                    if (this._hasValue(account) || this._hasValue(prevAccount)) {
                        rows.push(new AccountIncomeStatementRow(account, prevAccount, this.$translate.use()));
                    }
                });
            }
            rows = rows.concat(this._initRows(group.groups, allPreviousItems, indent + 1, currentSalesMargin, previousSalesMargin));
            if (group.globalId === 3555) {
                let tuototKulut1 = this.findNameFromBasicChart(3558);
                this.addCustomSumRow(group, prevGroup, rows, tuototKulut1.nameFi, tuototKulut1.nameEn, tuototKulut1.nameSv , indent, currentSalesMargin, previousSalesMargin, tuototKulut1.name);
            }
            if (group.globalId === 3556) {
                let tuototKulut2 = this.findNameFromBasicChart(3559);
                this.addCustomSumRow(group, prevGroup, rows, tuototKulut2.nameFi, tuototKulut2.nameEn, tuototKulut2.nameSv, indent+1, currentSalesMargin, previousSalesMargin, tuototKulut2.name);
            }
            if (group.globalId === 3557) {
                let tuototKulut3 = this.findNameFromBasicChart(3560);
                this.addCustomSumRow(group, prevGroup, rows, tuototKulut3.nameFi, tuototKulut3.nameEn, tuototKulut3.nameSv, indent+1, currentSalesMargin, previousSalesMargin, tuototKulut3.name);
            }
        });
        return rows;
    }

    findNameFromBasicChart(globalId){
        for (let i = 0; i < this.basicChart.roots[1].rows.length; i++) {
            if (this.basicChart.roots[1].rows[i].globalId === globalId) {
                return this.basicChart.roots[1].rows[i];
            }
        }
    }
    _hasValue(item) {
        return this._isNonZero(item.value) || this._isNonZero(item.cumulativeValue);
    }

    _isNonZero(value) {
        return value !== 0;
    }

    _findByGlobalIdFromGroups(groups, id) {
        if (!groups) return;
        for (const group of groups) {
            if (group.globalId === id) return group;
            if (group.groups) {
                const child = this._findByGlobalIdFromGroups(group.groups, id);
                if (child) return child;
            }
        }
    }

}
