'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('licenseProductPrice', {
                parent: 'entity',
                url: '/licenseProductPrices',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLING'],
                    pageTitle: 'ecoeasyApp.licenseProductPrice.home.title'
                },
                views: {
                    'content@': {
                        template: require('./licenseProductPrices.html'),
                        controller: 'LicenseProductPriceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('licenseProductPrice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('licenseProductPrice.detail', {
                parent: 'entity',
                url: '/licenseProductPrice/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLING'],
                    pageTitle: 'ecoeasyApp.licenseProductPrice.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./licenseProductPrice-detail.html'),
                        controller: 'LicenseProductPriceDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('licenseProductPrice');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'LicenseProductPrice', function ($stateParams, LicenseProductPrice) {
                        return LicenseProductPrice.get({id: $stateParams.id});
                    }]
                }
            })
            .state('licenseProductPrice.new', {
                parent: 'licenseProductPrice',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLING'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./licenseProductPrice-dialog.html'),
                        controller: 'LicenseProductPriceDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    unitPrice: null,
                                    unitAmount: null,
                                    totalPrice: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('licenseProductPrice', null, {reload: true});
                    }, function () {
                        $state.go('licenseProductPrice');
                    })
                }]
            })
            .state('licenseProductPrice.edit', {
                parent: 'licenseProductPrice',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLING'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./licenseProductPrice-dialog.html'),
                        controller: 'LicenseProductPriceDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['LicenseProductPrice', function (LicenseProductPrice) {
                                return LicenseProductPrice.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('licenseProductPrice', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('licenseProductPrice.delete', {
                parent: 'licenseProductPrice',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLING'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./licenseProductPrice-delete-dialog.html'),
                        controller: 'LicenseProductPriceDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['LicenseProductPrice', function (LicenseProductPrice) {
                                return LicenseProductPrice.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('licenseProductPrice', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
