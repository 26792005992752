import React, { useState, useEffect } from 'react';
import {
  Button, 
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getFundingData, deleteFunding} from '../../../service/react-service';

const DeleteFundingModal = ({ 
  modalShow, 
  setModalShow,
  setSelectedFunding,
  selectedFunding,
  companyId,
  budgetId,
  setFundingData
}) => {
  const { t, i18n } = useTranslation('fundingModal');

  const handleCloseModal = () => {
    setSelectedFunding();
    setModalShow(false);
  };

  const handleOnClick = () => {
    deleteFunding(companyId, budgetId, selectedFunding.id).then((res, rej) => {
      if(rej !== undefined) {
        console.log(rej);
      } else {
        getFundingData(companyId, budgetId).then((res, rej) => {
          if(rej !== undefined) {
            console.log(rej);
          } else {
            setFundingData(res.data);
          }
        });
        setModalShow(false);
      } 
    });
  }

  return (
    <Modal show={modalShow} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title.deleteFunding')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedFunding !== undefined
          ? <div>
              {t('description.deleteFunding')}
              <ul>
                <li>
                  {selectedFunding.name}
                </li>
              </ul>
            </div> 
          : <div></div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>
          <span className='glyphicon glyphicon-ban-circle'></span> {t('button.cancel')}
        </Button>
        <Button bsStyle="primary" onClick={() => handleOnClick()}>{t('button.deleteFunding')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteFundingModal;