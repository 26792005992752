(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('LinkingTableController', LinkingTableController);

    LinkingTableController.$inject = ['$scope', '$state', 'LinkingTable', 'ParseLinks', 'AlertService'];

    function LinkingTableController($scope, $state, LinkingTable, ParseLinks, AlertService) {
        var vm = this;

        vm.tables;

        loadAll();

        function loadAll() {
            vm.tables = LinkingTable.query();
        }

    }
})();
