'use strict';

angular.module('ecoeasyApp')
    .factory('BudgetData', function ($resource, DateUtils) {
        return $resource('api/budgets/:id/data', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true
            },
            'update': { method:'PUT' },
            'queryBalance': { 
            	url:'api/budgets/:id/balance-data',
            	method: 'GET', 
            	isArray: true
            },
            'getBalance': {
            	url:'api/budgets/:id/balance-data',
                method: 'GET',
                isArray: true
            },
            'updateBalance': { 
            	url:'api/budgets/:id/balance-data',
            	method:'PUT' 
            }
        });
    });
