(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('UserGroupDialogController', UserGroupDialogController);

    UserGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'UserGroup', 'User', 'Organisation', 'UserService', 'Principal', 'OrganisationService', '$translate', '$state']; //'UserService'

    function UserGroupDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, UserGroup, User, Organisation, UserService, Principal, OrganisationService, $translate, $state) {

        var vm = this;

        vm.userGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = [];
        vm.organisations = Organisation.query();
        vm.organisation = null;
        vm.selectedUsers = [];
        $scope.toolTipText = $translate.instant('ecoeasyApp.userGroup.toolTipText');

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;

            var orgId = account.sourceId;

            UserService.getByOrganisation(orgId).then(function (users) {
                vm.users = users;
                if (users) {
                    angular.forEach(users, function (user) {
                        if (user.userGroups !== null && user.userGroups.length > 0) {
                            angular.forEach(user.userGroups, function (userGroup) {
                                if (userGroup.id === vm.userGroup.id) {
                                    vm.selectedUsers.push(user);
                                }
                            }, this);
                        }
                    });
                }
            });

            OrganisationService.getById($scope.account.sourceId).then(function (organisation) {
                vm.organisation = organisation;
            });


        });


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;


            var id = {id: vm.userGroup.id};
            var name = {name: vm.userGroup.name};
            var users = {users: vm.selectedUsers};
            var organisation = {organisation: vm.organisation};
            var obj = {};

            angular.extend(obj, id, name, users, organisation);

            if (vm.userGroup.id !== null) {
                UserGroup.update(obj, onSaveSuccess, onSaveError);
            } else {
                vm.userGroup.organisation = vm.organisation;
                UserGroup.save(obj, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('ecoeasyApp:userGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.updateSelectedUsers = function (selecedUsers) {
            vm.selectedUsers = selecedUsers;
        }
    }
})();
