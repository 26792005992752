import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {number, object} from 'prop-types';
import {useTranslation} from 'react-i18next';
import FundingTable from './funding-table';
import AddFundingModal from './add-funding-modal';
import {getFundingData, getInvestmentFundingData} from '../../../service/react-service';
import InvestmentFundingTable from "./investement-funding/investment-funding-table";

function FundingWrapper({companyId, budgetId, accountChartId, actualDate}) {
    const [fundingModalShow, setFundingModalShow] = useState(false);
    const [fundingData, setFundingData] = useState([]);
    const [showThousands, setShowThousands] = useState(false);
    const [investmentFundingData, setInvestmentFundingData] = useState([]);

    useEffect(() => {
        getFundingData(companyId, budgetId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                setFundingData(res.data);
            }
        });
        getInvestmentFundingData(companyId, budgetId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                setInvestmentFundingData(res.data);
            }
        });
    }, [budgetId]);

    const {t} = useTranslation('funding');
    return (
        <div>
            <p>
                <Button bsStyle="primary" onClick={() => setFundingModalShow(true)}>{t('button.addFunding')}</Button>
            </p>
            <p>
                <Button bsStyle="primary"
                        onClick={() => setShowThousands(!showThousands)}>{showThousands ? t('button.showActual') : t('button.showThousands')}</Button>
            </p>
            <AddFundingModal
                showModal={fundingModalShow}
                setShowModal={setFundingModalShow}
                setFundingData={setFundingData}
                companyId={companyId}
                budgetId={budgetId}
                actualDate={actualDate}
            />
            <FundingTable
                fundingData={fundingData}
                setFundingData={setFundingData}
                companyId={companyId}
                budgetId={budgetId}
                showThousands={showThousands}
                actualDate={actualDate}
            />
            {investmentFundingData.length > 0 ?
                <div style={{marginTop: '35px'}}>
                    <h4>{t('investments')}</h4>
                    <InvestmentFundingTable
                        investmentFundingData={investmentFundingData}
                        setInvestmentFundingData={setInvestmentFundingData}
                        companyId={companyId}
                        budgetId={budgetId}
                        showThousands={showThousands}
                        accountChartId={accountChartId}
                    />
                </div>
                :
                null
            }
        </div>
    );
}

FundingWrapper.propTypes = {
    companyId: number.isRequired,
    budgetId: number.isRequired,
    accountChartId: number.isRequired,
    actualDate: object
};

export default FundingWrapper;