export class PollingService {

    constructor($http, $timeout, $q, $log) {
        "ngInject";
        this.$http = $http;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$log = $log;
        this.timeOutPromise = null;
        this.rejectFn = null;
    }

    poll(url, seconds, untilFn) {
        return this.$q((resolve, reject)=>{
            this.rejectFn = reject;
            let defaultDelay = seconds*1000;
            let errorCount = 0;
            let getData = () => {
                this.$http.get(url).then((success)=>{
                    this.$log.debug('get success: ', success);
                    errorCount = 0;
                    if (untilFn(success)) {
                        resolve(success);
                    } else {
                        nextLoad();
                    }
                }, (error)=>{
                    this.$log.error('get error: ', error);
                    errorCount++;
                    nextLoad(errorCount * 2 * defaultDelay);
                });
            }
            let nextLoad = (millis) => {
                let delay =  millis || defaultDelay
                this._cancelTimeout();
                this.timeOutPromise = this.$timeout(getData, delay);
            }
            getData();
        });
    }

    _cancelTimeout() {
        this.$timeout.cancel(this.timeOutPromise);
    }

    stopPolling() {
        this._cancelTimeout();
        if (this.rejectFn) {
            this.rejectFn("stopped");
        }
    }

}