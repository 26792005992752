'use strict';

angular.module('ecoeasyApp')
    .factory('Company', function ($resource, DateUtils) {
        return $resource('api/companys/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.lastModelUpdate = DateUtils.convertDateTimeFromServer(data.lastModelUpdate);
                    data.lastAccountingDataUpdate = DateUtils.convertDateTimeFromServer(data.lastAccountingDataUpdate);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    });

angular.module('ecoeasyApp') 
        .service('companyService', function ($http) {
            
            var urlBase = '/api/companys';
              
                this.getCompanyIdByCurrentUser = function(){
                  return $http.get(urlBase+'/user');  
                };


               this.getCompanyIdAndNameForOrg = function(orgId){
                   return $http({
                       method: "get",
                       url: urlBase+'/companyIdAndNames/' + orgId
                   }).then(function(response){
                       return response.data;
                   });
               }
               
               this.getCompaniesForOrganisation = function(orgId){
                   return $http({
                       method: "get",
                       url: urlBase+'/forOrganisation/' + orgId
                   }).then(function(response){
                       return response.data;
                   });
               }
              
});