angular.module('ecoeasyApp')
    .controller('NavbarTopController', function ($scope, NavbarService) {
        $scope.$root.$on("navUpdate", function () {
          NavbarService.getOrganisationDisplayName().then(function (value) {
               $scope.displayName = value;
            });

            });

    });
