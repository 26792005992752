import React, {useState} from 'react';
import {array, bool, func, number, object} from 'prop-types';
import {Button, Glyphicon, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import DeleteInvestmentModal from "./delete-investment-modal";
import EditInvestmentModal from "./edit-investment-modal";
import {findAccountKey} from "../budget-utils";
import './styles.css';

const InvestmentTable = ({
                             companyId,
                             budgetId,
                             tableData,
                             options,
                             fundingOptions,
                             yearMonths,
                             actualDate,
                             showInvestmentPopup,
                             setShowInvestmentPopup
                         }) => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const parsePosition = (month, year) => {
        return `${month}/${year}`;
    }

    const calculateLeftoverValue = (value, startMonth, startYear, devaluationTime, devaluationTimeDelay, devaluationInterest) => {
        const firstPaymentDate = startYear * yearMonths.length + startMonth + devaluationTimeDelay;
        const currentMonth = () => {
            if (actualDate) {
                return actualDate.year * yearMonths.length + actualDate.month;
            }
            return yearMonths[0].year * yearMonths.length + yearMonths[0].month;
        };

        const timeBetween = currentMonth() - firstPaymentDate;

        // if devaluationInterest is null, calculate leftover value by months
        if (devaluationInterest !== null) {
            let currentValue = value;
            if (timeBetween - devaluationTime > 12) {
                for (let index = (timeBetween - devaluationTime); index > 12; index - 12) {
                    currentValue = currentValue - (currentValue * (devaluationInterest * 100));
                }
            }

            const monthlyPayment = currentValue * (devaluationInterest / 100) / 12;
            currentValue = currentValue - (((timeBetween - devaluationTime) % 12) * monthlyPayment);

            return currentValue < 0 ? 0 : currentValue.toFixed(0);
        } else {
            const monthlyValue = value / devaluationTime;
            if (timeBetween > 0) {
                const staticValue = Math.round(value - (monthlyValue * timeBetween)).toFixed(0);
                return staticValue < 0 ? 0 : staticValue;
            }

            return Math.round(value).toFixed(0);
        }
    }

    const renderTableData = tableData => {
        return tableData.map(data => {
            const leftoverValue = calculateLeftoverValue(data.value, data.month, data.year, data.devaluationTime, data.devaluationTimeDelay, data.devaluationInterest);
            const percentage = data.devaluationInterest ? <>{data.devaluationInterest} %</> : null;
            return (
                <tr key={data.id}>
                    <td>{parsePosition(data.month, data.year)}</td>
                    <td>{data.name}</td>
                    <td>{data.value} €</td>
                    <td>{data.vatValue} €</td>
                    <td>{data.remnantValue.toFixed(2)} €</td>
                    <td>{data.devaluationTime}</td>
                    <td>{`${leftoverValue} €`}</td>
                    <td>{percentage}</td>
                    <td>{findAccountKey(options, 'id', parseFloat(data.bcoaCategoryId))}</td>
                    <td>
                        <Button onClick={() => {
                            setSelectedData(data);
                            setEditModal(!editMode);
                        }}>
                            <Glyphicon glyph='pencil'/>
                        </Button>
                    </td>
                    <td>
                        <Button onClick={() => {
                            setSelectedData(data);
                            setShowModal(!showModal);
                        }}>
                            <Glyphicon glyph='trash'/>
                        </Button>
                    </td>
                </tr>
            )
        });
    };

    return (
        <div>
            <Table striped bordered className={'InvestmentTable'}>
                <thead>
                <tr>
                    <th>{t('investments:values.acquisitionDate')}</th>
                    <th>{t('investments:values.name')}</th>
                    <th>{t('investments:values.investmentValue')}</th>
                    <th>{t('investments:values.vatValue')}</th>
                    <th>{t('investments:values.remnantValue')}</th>
                    <th>{t('investments:values.devaluationTime')}</th>
                    <th>{t('investments:values.currentlyLeftValue')}</th>
                    <th>{t('investments:values.devaluationInterest')}</th>
                    <th>{t('investments:values.balanceAccount')}</th>
                    <th>{t('investments:modal.buttons.edit')}</th>
                    <th>{t('investments:modal.buttons.delete')}</th>
                </tr>
                </thead>
                <tbody>
                    {renderTableData(tableData)}
                </tbody>
            </Table>
            {selectedData ?
                <>
                    <EditInvestmentModal
                        budgetId={budgetId}
                        companyId={companyId}
                        options={options}
                        fundingOptions={fundingOptions}
                        showModal={editMode}
                        setShowModal={setEditModal}
                        setSelectedData={setSelectedData}
                        investmentData={selectedData}
                        showInvestmentPopup={showInvestmentPopup}
                        setShowInvestmentPopup={setShowInvestmentPopup}
                        actualDate={actualDate}
                    />
                    <DeleteInvestmentModal
                        budgetId={budgetId}
                        companyId={companyId}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        investmentData={selectedData}
                        setSelectedData={setSelectedData}
                        showInvestmentPopup={showInvestmentPopup}
                        setShowInvestmentPopup={setShowInvestmentPopup}
                    />
                </>
                : null}
        </div>
    );
}

InvestmentTable.propTypes = {
    companyId: number.isRequired,
    budgetId: number.isRequired,
    tableData: array.isRequired,
    options: array.isRequired,
    fundingOptions: array.isRequired,
    yearMonths: array.isRequired,
    showInvestmentPopup: bool.isRequired,
    setShowInvestmentPopup: func.isRequired,
    actualDate: object
};

export default InvestmentTable;