'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('organisation-billing', {
                parent: 'site',
                url: '/organisation-billing',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLING'],
                    permissions: [],
                    pageTitle: 'ecoeasyApp.organisationBilling.title.main'
                },
                views: {
                    'content@': {
                        template: require('./organisation-billing.html'),
                        controller: 'OrganisationBillingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('organisationBilling');
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    });
