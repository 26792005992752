import template from "./performance-measure-formula-edit.html";
import "./performance-measure-formula.css";

class PerformanceMeasureFormulaEditComponent {

    constructor($log) {
        "ngInject";
        this.$log = $log;
        this.isOperator = false;
        this.isMeasure = false;
        this.isAccount = false;
    }

    $onInit() {
        this.$log.debug('$onInit()', this.formula);
    }
}

export default {
    bindings: {
        formula: '<',
        addFormulaItemToIndex: '&',
        outsideClick: '&'
    },
    template: template,
    controller: PerformanceMeasureFormulaEditComponent
}
