'use strict';

angular.module('ecoeasyApp').controller('IndustryCollectionDefinitionDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'IndustryCollectionDefinition', 'Industry',
        function ($scope, $stateParams, $uibModalInstance, entity, IndustryCollectionDefinition, Industry) {

            $scope.industryCollectionDefinition = entity;
            $scope.industrys = Industry.query();
            $scope.load = function (id) {
                IndustryCollectionDefinition.get({id: id}, function (result) {
                    $scope.industryCollectionDefinition = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:industryCollectionDefinitionUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.industryCollectionDefinition.id != null) {
                    IndustryCollectionDefinition.update($scope.industryCollectionDefinition, onSaveSuccess, onSaveError);
                } else {
                    IndustryCollectionDefinition.save($scope.industryCollectionDefinition, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
