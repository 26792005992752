import {ChartFinder} from "./chart-finder";
import {BudgetTableRow} from "./budget-table-row";
import {RowCalculator} from "./row-calculator";
import {RowChildrenShowHide} from "./row-children-show-hide";

export class BudgetTableRowModel {

    constructor(dataRows, companyIncomeStatementChart, locale, actualDate, monthNumbers) {

        this.chartFinder = new ChartFinder(companyIncomeStatementChart);
        this.rows = dataRows
            .map((row) => {
                return new BudgetTableRow(row, locale, this.chartFinder, this.getAddRowOptions(row));
            });
        this.rows.sort((a, b) => {
            return a.ordinal - b.ordinal
        });
        this._rowCalculator = new RowCalculator(this.rows, actualDate,  monthNumbers);
        this._rowShowHide = new RowChildrenShowHide(this.rows);
        //Aita oli tässä matala jostain syystä??
        this.toggleThousands();
    }

    getRows() {
        return this.rows;
    }

    toggleThousands() {
        for (var i in this.rows) {
            for (var j in this.rows[i].values) {
                this._rowCalculator.calculateByColumnWithoutAction(this.rows[i], j);
                this._rowCalculator.calculateGrowthColumnsWithoutAction(this.rows[i], j);
                this._rowCalculator.calculateRefRowsWithoutAction(this.rows[i], j);
            }
        }
        this._rowCalculator.calculateRowTotals();
    }

    rowValueChanged(row, index, callbackFn) {
        this._rowCalculator.calculateGrowthColumns(row, index, callbackFn);
        this._rowCalculator.calculateRefRows(row, index, callbackFn);
        this._rowCalculator.calculateByColumn(row, index, callbackFn);
        this._rowCalculator.calculateRowTotals();

        for (var i in this.rows) {
            this._rowCalculator.calculateByColumn(this.rows[i], index, callbackFn);
            this._rowCalculator.calculateGrowthColumns(this.rows[i], index, callbackFn);
            this._rowCalculator.calculateRefRows(this.rows[i], index, callbackFn);
        }
        this._rowCalculator.calculateRowTotals();
    }

    rowValueChangedWithFastCalculation(row, callbackFn) {
        for (var i in row.values.length) {
            this._rowCalculator.calculateGrowthColumns(row, i, callbackFn);
            this._rowCalculator.calculateRefRows(row, i, callbackFn);
            this._rowCalculator.calculateByColumn(row, i, callbackFn);
            this._rowCalculator.calculateRowTotals();
        }
    }

    toggleIsOpen(row) {
        row.isOpen = !row.isOpen;
        if (row.isOpen) {
            this._rowShowHide.showChildren(row);
        } else {
            this._rowShowHide.hideChildren(row);
        }
    }

    toggleIsOpenAll(showAllRows) {
        this.rows.forEach(row => {
            row.isOpen = showAllRows;
            if (row.isOpen) {
                this._rowShowHide.showChildren(row);
            } else {
                this._rowShowHide.hideChildren(row);
            }
        });
    }

    getAddRowOptions(row) {
        const chartFinder = this.chartFinder;
        if (row.basicChartCategoryId) {
            let found = chartFinder.findGroupByBasicChartCategoryId(row.basicChartCategoryId);
            if (found) {
                let options = found.subGroups.map((g) => {
                    return {id: g.id, name: g.nameFi, code: null};
                })
                options = options.concat(found.companyAccounts.map((g) => {
                    return {id: g.id, name: g.nameFi, code: g.code};
                }));
                return options
            }
        }
        return null
    }

    updateRowSums() {
        for (var i in this.rows) {
            for (var j in this.rows[i].values) {
                this._rowCalculator.calculateByColumnWithoutAction(this.rows[i], j);
                this._rowCalculator.calculateGrowthColumnsWithoutAction(this.rows[i], j);
                this._rowCalculator.calculateRefRowsWithoutAction(this.rows[i], j);
            }
        }
        this._rowCalculator.calculateRowTotals();
    }

    getRevenueRow() {
        let revenueRow = this.rows.find(row => row.name === 'LIIKEVAIHTO');
        return revenueRow;
    }

}
