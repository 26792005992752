export class SelectedCompanyService {
    constructor($log) {
        "ngInject";
        this.$log = $log;
        this.selectedCompany = null;
        this.listeners = [];
    }

    listenChanges(func) {
        this.listeners.push(func);
        func();
    }

    setSelectedCompany(selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.listeners.forEach((listener)=>{
            listener();
        });
    }

    getSelectedCompany() {
        this.$log.debug('selectedCompany', this.selectedCompany);
        return this.selectedCompany;
    }

    getSelectedCompanyId() {
        let c = this.getSelectedCompany();
        if (c) {
            return c.id;
        }
        return null;
    }

    getBasicChartOfAccountsId() {
        let c = this.getSelectedCompany();
        if (c) {
            return c.basicChartOfAccountsId;
        }
        return null;
    }

    isBasicChartSelected() {
        return this.getBasicChartOfAccountsId() != null;
    }
}