import angular from 'angular';
import {CashFlowComponent} from "./cash-flow.component";
import {CashFlowService} from "./cash-flow.service";
import {reactToAngularComponent} from "../../react-app/utils/react-to-angular";
import NumberLabel from "../../react-app/components/budget/budget-table/number-label/number-label";

export const CashFlowModule =
    angular.module('cashFlow', ['ui.router','pascalprecht.translate'])
        .config(function ($stateProvider) {
            $stateProvider.state({
                parent: 'site',
                name: 'cashflow2',
                url: '/cashflow2',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['CASH_FLOW'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: '<cash-flow></cash-flow>'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cashflow');
                        return $translate.refresh();
                    }]
                }
            })
        })
        .service('cashFlowService', CashFlowService)
        .component('cashFlow', CashFlowComponent)
        .component('cfNumberLabel', reactToAngularComponent(NumberLabel))
        .name;