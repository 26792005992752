'use strict';

angular.module('ecoeasyApp')
    .controller('InvestmentBudgetDataDeleteController', function ($scope, $uibModalInstance, entity, InvestmentBudgetData) {

        $scope.investmentBudgetData = entity;
        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            InvestmentBudgetData.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
