'use strict';

angular.module('ecoeasyApp').controller('CompanyIndicatorDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Indicator', 'CompanyVariable', 'company_id', 'indicatorService', 'companyVariableService', '$translate', 'FEATURE_COMPANY_ACCOUNT_CHART', 'SelectedCompanyService', 'CompanyIndicatorService',
        function ($scope, $stateParams, $uibModalInstance, entity, Indicator, CompanyVariable, company_id, indicatorService, companyVariableService, $translate, FEATURE_COMPANY_ACCOUNT_CHART, SelectedCompanyService, CompanyIndicatorService) {
            $scope.company_id = $stateParams.companyId;
            $scope.indicator = entity;
            $scope.companyvariables = [];
            $scope.open=false;
            $scope.sameLimits = false;
            $scope.show_measure_select = FEATURE_COMPANY_ACCOUNT_CHART && SelectedCompanyService.isBasicChartSelected();
            if ($scope.show_measure_select) {
                $scope.measures = CompanyIndicatorService.getMeasureOptions();
            }
            companyVariableService.getForSelect($scope.company_id).then(function (response) {
                var cv = response.data;
                for (var i = 0; i < cv.length; i++) {
                    if (cv[i].accountnmbr != null && cv[i].accountnmbr != 0) {
                        cv[i].name = cv[i].accountnmbr + " - " + $translate.instant("variable." + cv[i].code);
                    } else {
                        cv[i].name = $translate.instant("variable." + cv[i].code);
                    }
                    if ($scope.indicator.companyVariable && cv[i].id == $scope.indicator.companyVariable.id) {
                        $scope.indicator.companyVariable.name = cv[i].name;
                    }
                }
                // cv.sort(compare);
                $scope.companyvariables = cv;
            });

            $scope.load = function (id) {
                Indicator.get({id: id}, function (result) {
                    $scope.indicator = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:indicatorUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {

                $scope.isSaving = true;
                if ($scope.indicator.id != null) {
                    Indicator.update($scope.indicator, onSaveSuccess, onSaveError);
                } else {
                    indicatorService.save($scope.company_id, $scope.indicator).then(function (response) {
                        if (response.status == 200) {
                            onSaveSuccess(response.data);
                        }
                        else {
                            onSaveError(response.date);
                        }
                    });
                }

                $scope.isSaving = false;

            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function variableChanged() {
                if ($scope.indicator.companyVariable.periodInstruction == 'MONTHLY_USAGE_NOT_ALLOWED') {
                    $scope.indicator.monthly = false;
                }
            }

        }]);

angular.module('ecoeasyApp').directive('sameAs', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            sameAs: '='
        },
        link: function (scope, elm, attr, ngModel) {
            if (!ngModel) return;

            attr.$observe('ngModel', function (value) {
                // observes changes to this ngModel
                ngModel.$validate();
            });

            scope.$watch('sameAs', function (sameAs) {
                // watches for changes from sameAs binding
                ngModel.$validate();
            });

            ngModel.$validators.sameAs = function (value) {
                return scope.sameAs != value;
            };
        }
    };
});
