'use strict';

angular.module('ecoeasyApp')
        .controller('NavbarController', function ($window, $scope, $rootScope ,$location, $state, $q, $timeout, $interval, $http, $uibModal, Auth, Principal, ENV, FiscalYear, NavbarService, FEATURE_COMPANY_ACCOUNT_CHART, SelectedCompanyService, FEATURE_THIRD_PHASE) {

            NavbarService.refresh();
            $scope.show_basic_account_of_charts = FEATURE_COMPANY_ACCOUNT_CHART;
            $scope.show_third_phase_features = FEATURE_THIRD_PHASE;

            SelectedCompanyService.listenChanges(()=>{
                $scope.basicChartSelected = $scope.show_basic_account_of_charts && SelectedCompanyService.isBasicChartSelected();
            });
            var myInterval;
            var calculation = angular.element(document.getElementById('navbarCalculation'));
            var calculationError = angular.element(document.getElementById('navbarCalculationError'));
            var balanceOrCashflowError = angular.element(document.getElementById('navbarBalanceOrCashflowError'))
            var differenceBalance = 0;
            var differenceCashflow = 0;
            $scope.logout = function () {
                $scope.$root.userCompanies = null;
                $scope.$root.selectedCompanyId = undefined;
                Auth.logout();
                $scope.$root.setSelectedCompany(null);
                $state.go('login');
            };
            var company_id;
            $rootScope.$on('$stateChangeStart',
                function(event, toState, toParams, fromState, fromParams){
                    if($window.innerWidth <= 768) {
                        $('#sidebar-collapse').collapse('hide');
                    }
                })

            angular.element($window).bind('resize', function(){
                if($window.innerWidth > 768){
                    $('#sidebar-collapse').collapse('show');
                    $scope.$root.fiscalYear = FiscalYear.current({companyId: $scope.$root.selectedCompanyId});
                    NavbarService.refresh();
                }

            });
            $scope.showDifferences = function () {
                $uibModal.open({
                    component: 'navbarCalculationAlarm',
                    resolve: {
                        balanceDifference: () => {
                            return differenceBalance;
                        },
                        cashflowDifference: () => {
                            return differenceCashflow;
                        }
                    },
                    backdrop: 'static',
                    size: 'lg'
                }).result.then((result) => {
                    console.log(result, "jeppis");
                });
            }

            function checkPollCalculation (){
                      $http.get("/api/agent/recalculateid/" + $scope.$root.selectedCompanyId).then(function (response) {
                            $http.get("/api/agent/recalculatepoll/" + response.data).then(function (result) {
                                if (result.data === "FINISHED") {
                                    $http.get("/api/check-company/" + $scope.$root.selectedCompanyId).then(function (resultFromChecker){
                                        if($scope.$root.selectedCompany.companyChart && $scope.$root.selectedCompany.showCalculationError && (resultFromChecker.data.differenceBalance != 0 || resultFromChecker.data.differenceCashflow != 0)) {
                                            if(balanceOrCashflowError.hasClass('ng-hide')) {
                                                differenceBalance = resultFromChecker.data.differenceBalance;
                                                differenceCashflow = resultFromChecker.data.differenceCashflow;
                                                balanceOrCashflowError.removeClass('ng-hide');
                                            }
                                        } else {
                                            if (!calculation.hasClass('ng-hide')) {
                                                calculation.addClass('ng-hide');
                                            }
                                            if (!calculationError.hasClass('ng-hide')) {
                                                calculationError.addClass('ng-hide');
                                            }
                                            if (!balanceOrCashflowError.hasClass('ng-hide')) {
                                                balanceOrCashflowError.addClass('ng-hide');
                                            }
                                            stopPollCalculation();
                                        }
                                    })
                                } else if (result.data === "ERROR_FINISHED") {
                                    if (!calculation.hasClass('ng-hide')) {
                                        calculation.addClass('ng-hide');
                                    }
                                    if (calculationError.hasClass('ng-hide')) {
                                        calculationError.removeClass('ng-hide');
                                    }
                                    if(!balanceOrCashflowError.hasClass('ng-hide')) {
                                        balanceOrCashflowError.addClass('ng-hide');
                                    }
                                    stopPollCalculation();
                                } else if (result.data === "ONGOING") {
                                    if (calculation.hasClass('ng-hide')) {
                                        calculation.removeClass('ng-hide');
                                    }
                                    if (!calculationError.hasClass('ng-hide')) {
                                        calculationError.addClass('ng-hide');
                                    }
                                    if(!balanceOrCashflowError.hasClass('ng-hide')) {
                                        balanceOrCashflowError.addClass('ng-hide');
                                    }
                                }

                            });
                    });
            };
            function startPollCalculation () {
                stopPollCalculation();
                checkPollCalculation ();
                if($scope.$root.selectedCompanyId !== undefined && $scope.$root.selectedCompanyId > 0) {
                    myInterval = $interval(checkPollCalculation, 10000, 0, true);
                }
            };
            function stopPollCalculation () {
                $interval.cancel(myInterval);
                myInterval = undefined;
             };

            $scope.$on('$destroy', function() {
                stopPollCalculation();
            });

            $scope.$on('$routeChangeStart', function() {
                stopPollCalculation();
            });

            $scope.$watch(function(scope) { return scope.$root.selectedCompany },
                function() {
                    if(myInterval){
                        stopPollCalculation();
                    }
                    if($scope.$root.selectedCompany != undefined && $scope.$root.selectedCompany != null)
                        startPollCalculation();
                }
            );

            $scope.$root.$on("navUpdate", function () {
                NavbarService.refresh();
                if (typeof $scope.$root.selectedCompanyId != 'undefined')
                    $scope.$root.fiscalYear = FiscalYear.current({companyId: $scope.$root.selectedCompanyId});
            });

            $scope.$root.$on('actualizedPositionChanged', function () {
                $scope.$root.fiscalYear = FiscalYear.current({companyId: $scope.$root.selectedCompanyId});
            });

            $scope.companyHomePage = function () {
                $state.go('survivor', {'companyid': $scope.selectedCompanyId});
            };
       });
