'use strict';

angular.module('ecoeasyApp').controller('InvestmentBudgetDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'investmentBudgetEntity', 'fundingBudgetEntity', 'InvestmentBudgetData', 'Budget', 'companyVariableService', 'targetBudget', '$q',
        function ($scope, $stateParams, $uibModalInstance, investmentBudgetEntity, fundingBudgetEntity, InvestmentBudgetData, Budget, companyVariableService, targetBudget, $q) {

            $scope.months = getMonths();
            $scope.possibleInvestmentYears = [];
            $scope.company = null;
            //
            $scope.investmentBudgetData = investmentBudgetEntity;

            $scope.includesVat = true;


            $q.when($scope.$root.getSelectedCompany()).then(function (selComp) {
                var company = selComp;
                $scope.company = company;

                if (company == null) {
                    //TODO something
                }
                else {
                    init(company);
                }
            });

            var init = function (company) {
                companyVariableService.getForNewInvestment(company.id)
                    .then(function (result) {
                        $scope.companyvariables = result.data;
                    });
                companyVariableService.getForNewFunding(company.id)
                    .then(function (result) {
                        $scope.fundingCompanyVariables = result.data;
                    });


                if (investmentBudgetEntity != null && investmentBudgetEntity.id != null) {
                    Budget.get({id: investmentBudgetEntity.budgetId}, function (result) {
                        $scope.targetBudget = result;
                        setPossibleInvestmentYears($scope.targetBudget.targetPeriod);
                        setSelectedYear($scope.investmentYear);

                    });

                    $scope.fundingBudgetData = investmentBudgetEntity.fundingBudgetData;
                    $scope.investmentYear = getYearFromPosition(investmentBudgetEntity.position);

                    $scope.selectedMonth = $scope.months[0];

                    if ($scope.fundingBudgetData.loanAmount != null && $scope.fundingBudgetData.loanAmount > 0) {
                        $scope.useLoan = true;
                    } else {
                        $scope.useLoan = false;
                    }

                } else {
                    setPossibleInvestmentYears(targetBudget.targetPeriod);
                    $scope.fundingBudgetData = fundingBudgetEntity;
                    $scope.targetBudget = targetBudget;

                    $scope.investmentYear = targetBudget.targetPeriod;
                    setSelectedYear($scope.investmentYear);

                    $scope.selectedMonth = $scope.months[0];

                    $scope.investmentBudgetData.position = '' + $scope.investmentYear + '' + $scope.selectedMonth.name;
                    $scope.fundingBudgetData.position = $scope.investmentBudgetData.position;
                    $scope.useLoan = false;
                }

            }


            $scope.load = function (id) {
                InvestmentBudgetData.get({id: id}, function (result) {
                    $scope.investmentBudgetData = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:investmentBudgetDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.investmentBudgetData.id != null) {
                    $scope.investmentBudgetData.fundingBudgetData = $scope.fundingBudgetData;
                    InvestmentBudgetData.update($scope.investmentBudgetData, onSaveSuccess, onSaveError);
                } else {
                    $scope.investmentBudgetData.budgetId = $scope.targetBudget.id;
                    $scope.fundingBudgetData.budgetId = $scope.targetBudget.id;
                    $scope.investmentBudgetData.fundingBudgetData = $scope.fundingBudgetData;
                    InvestmentBudgetData.save($scope.investmentBudgetData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.investmentNameChanged = function (investmentName) {
                $scope.fundingBudgetData.name = investmentName;
            }

            $scope.investmentPositionChanged = function (investmentPosition) {
                $scope.fundingBudgetData.position = investmentPosition;
            }

            $scope.investmentValueChanged = function (investmentValue) {
                if ($scope.includesVat) {
                    $scope.investmentBudgetData.vatValue = investmentValue * 0.24;
                } else {
                    $scope.investmentBudgetData.vatValue = undefined;
                    //$scope.investmentBudgetData.vatValue = investmentValue * 1.24;
                }
                $scope.fundingBudgetData.initialCashEffect = investmentValue;
            }

            $scope.useLoanChanged = function (value) {
                if (value == false) {
                    $scope.fundingBudgetData.initialCashEffect = $scope.investmentBudgetData.value;
                    $scope.fundingBudgetData.loanAmount = null;
                    $scope.fundingBudgetData.paymentTerm = null;
                    $scope.fundingBudgetData.interestPercentage = null;
                    $scope.fundingBudgetData.paymentFreeMonths = null;
                }

            }

            $scope.loanAmountChanged = function (loanAmount) {
                $scope.fundingBudgetData.initialCashEffect = $scope.investmentBudgetData.value - loanAmount;
            }

            $scope.setInvestmentPosition = function (selectedInvestmentYear) {
                setSelectedYear(selectedInvestmentYear.name);

                $scope.investmentBudgetData.position = '' + selectedInvestmentYear.name + '' + $scope.selectedMonth.name;
                $scope.fundingBudgetData.position = $scope.investmentBudgetData.position;

            }

            $scope.setinvestmentPositionByMonth = function (selectedMonth) {
                $scope.investmentBudgetData.position = '' + $scope.investmentYear + '' + selectedMonth.name;
                $scope.fundingBudgetData.position = $scope.investmentBudgetData.position;
            }

            function getMonths() {
                return [{name: "1", id: 1},
                    {name: "2", id: 2},
                    {name: "3", id: 3},
                    {name: "4", id: 4},
                    {name: "5", id: 5},
                    {name: "6", id: 6},
                    {name: "7", id: 7},
                    {name: "8", id: 8},
                    {name: "9", id: 9},
                    {name: "10", id: 10},
                    {name: "11", id: 11},
                    {name: "12", id: 12}
                ];
            }

            function setPossibleInvestmentYears(targetperiod) {
                var startYear = targetperiod;
                var endYear = targetperiod;

                var startMonth = $scope.company.accountingStartMonth;
                var endMonth = $scope.company.accountingStartMonth + $scope.company.accountingPeriodLength - 1;

                if (endMonth > 12) {
                    endYear++;
                    endMonth = endMonth % 13 + 1;
                }

                $scope.possibleInvestmentYears.push({name: startYear, id: 1});

                if (startYear !== endYear) {
                    $scope.possibleInvestmentYears.push({name: endYear, id: 2});
                }
            }

            function setSelectedYear(year) {
                for (var i = 0; i < $scope.possibleInvestmentYears.length; i++) {
                    if ($scope.possibleInvestmentYears[i].name === year) {
                        $scope.selectedYear = $scope.possibleInvestmentYears[i];
                        $scope.investmentYear = $scope.possibleInvestmentYears[i].name;
                        setPossibleMonthsByYear($scope.investmentYear);
                    }
                }
            }

            function getYearFromPosition(position) {
                var month = getMonthFromPosition(position);
                var year = position;
                year = year - month;
                if (month < 10) {
                    return year / 10;
                } else {
                    return year / 100;
                }
            }

            function getMonthFromPosition(position) {
                if (position < 100000) {
                    return position % 10;
                } else {
                    return position % 100;
                }
            }

            function setPossibleMonthsByYear(year) {
                var startYear = $scope.targetBudget.targetPeriod;
                var endYear = $scope.targetBudget.targetPeriod;

                var startMonth = $scope.company.accountingStartMonth;
                var endMonth = $scope.company.accountingStartMonth + $scope.company.accountingPeriodLength - 1;

                if (endMonth > 12) {
                    endYear++;
                    endMonth = endMonth % 13 + 1;
                }


                if (year === startYear) {
                    initializeMonths($scope.company.accountingStartMonth, 12);
                } else {
                    initializeMonths(1, endMonth);
                }
            }

            function initializeMonths(firstMonth, lastMonth) {
                var months = [];
                for (var i = firstMonth; i <= lastMonth; i++) {
                    months.push({name: firstMonth, id: firstMonth});
                    firstMonth++;
                }

                $scope.months = months;
                $scope.selectedMonth = $scope.months[0];
            }

            /*
            function getInvestmentMonth(position){
                var month = getMonthFromPosition(position);
                for(var i = 0; i < $scope.months.length; i++){
                    if($scope.months[i].name === month){
                        return $scope.months[i];
                    }
                }
                return $scope.months[0];
            }
            */

        }]);
