import template from "./performance-measures.html";

import "./performance-measures.css";

export default {
    bindings: {
        measures: '<',
        measureOptionsLoader: '<',
        isAccounts: '<',
        bsAccounts: '<',
        onAdd: '&',
        onUpdate: '&',
        onRemove: '&'
    },
    template: template,
    controller: class PerformanceMeasuresComponent {

        constructor($uibModal, $log) {
            "ngInject";
            this.$uibModal = $uibModal;
            this.$log = $log;
        }

        $onInit() {
            this.$log.debug('PerformanceMeasuresComponent.$onInit');
        }

        $onChanges(changesObj) {
            this.$log.debug('PerformanceMeasuresComponent.$onChanges', changesObj);
            this.$log.debug('PerformanceMeasuresComponent.$onChanges: this.measures', this.measures);
        }

        newMeasure() {
            const ctrl = this;
            this.$uibModal.open({
                component: 'performanceMeasureEdit',
                resolve: {
                    measure: function () {
                        return {name: '', formula: []}
                    },
                    measureOptions: function () {
                        return (ctrl.measureOptionsLoader==null) ? ctrl.measures : ctrl.measureOptionsLoader();
                    },
                    isAccounts: () => {
                        return ctrl.isAccounts;
                    },
                    bsAccounts: () => {
                        return ctrl.bsAccounts;
                    }
                },
                backdrop: 'static',
                size: 'lg'
            }).result.then((result) => {
                this.onAdd({measure: result});
            });
        }

        editMeasure(measure) {
            const ctrl = this;
            this.$uibModal.open({
                component: 'performanceMeasureEdit',
                resolve: {
                    measure: () => {
                        return measure
                    },
                    measureOptions: () => {
                        return (ctrl.measureOptionsLoader==null) ? ctrl.measures : ctrl.measureOptionsLoader();
                    },
                    isAccounts: () => {
                        return this.isAccounts;
                    },
                    bsAccounts: () => {
                        return this.bsAccounts;
                    }
                },
                backdrop: 'static',
                size: 'lg'
            }).result.then((result) => {
                measure.name = result.name;
                measure.formula = result.formula;
                measure.calcType = result.calcType;
                measure.helper = result.helper;
                measure.thresholdName1 = result.thresholdName1;
                measure.thresholdName1En = result.thresholdName1En;
                measure.thresholdName1Sv = result.thresholdName1Sv;
                measure.thresholdName2 = result.thresholdName2;
                measure.thresholdName2En = result.thresholdName2En;
                measure.thresholdName2Sv = result.thresholdName2Sv;
                measure.thresholdName3 = result.thresholdName3;
                measure.thresholdName3En = result.thresholdName3En;
                measure.thresholdName3Sv = result.thresholdName3Sv;
                measure.thresholdName4 = result.thresholdName4;
                measure.thresholdName4En = result.thresholdName4En;
                measure.thresholdName4Sv = result.thresholdName4Sv;
                measure.thresholdName5 = result.thresholdName5;
                measure.thresholdName5En = result.thresholdName5En;
                measure.thresholdName5Sv = result.thresholdName5Sv;
                measure.thresholdValue1Low = result.thresholdValue1Low;
                measure.thresholdValue2Low = result.thresholdValue2Low;
                measure.thresholdValue3Low = result.thresholdValue3Low;
                measure.thresholdValue4Low = result.thresholdValue4Low;
                measure.thresholdValue5Low = result.thresholdValue5Low;
                measure.thresholdValue1High = result.thresholdValue1High;
                measure.thresholdValue2High = result.thresholdValue2High;
                measure.thresholdValue3High = result.thresholdValue3High;
                measure.thresholdValue4High = result.thresholdValue4High;
                measure.thresholdValue5High = result.thresholdValue5High;
                measure.descriptionFi = result.descriptionFi;
                measure.descriptionEn = result.descriptionEn;
                measure.descriptionSv = result.descriptionSv;
                this.onUpdate({measure: measure});
            });
        }

        removeMeasure(measure) {
            this.onRemove({measure: measure});
        }

    }

}
