'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyDataController', function ($scope, $state, CompanyData) {

        $scope.companyDatas = [];
        $scope.loadAll = function () {
            CompanyData.query(function (result) {
                $scope.companyDatas = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.companyData = {
                position: null,
                value: null,
                id: null
            };
        };
    });
