angular.module('ecoeasyApp')
    .service('EconomicActivitiesImportService', function ($http, $q, $rootScope, $log) {

        function sendFile(file, onSuccess, onError) {

            return $q.when().then(function () {
                var r = new FileReader();
                r.onloadend = function (event) {
                    var fileData = event.target.result;
                    console.log(file,fileData, event, event.target);
                    var uInt8Array = new Uint8Array(fileData);
                    var byteArray = [];
                    for (var i = 0; i < uInt8Array.length; ++i) {
                        byteArray.push(uInt8Array[i]);
                    }
                    var data = {
                        sourceFile: byteArray
                    }

                    $http.post("/api/economicactivitiesimport/import", data).then(onSuccess, onError);
                }
                r.readAsArrayBuffer(new Blob([file]));
            });
        }

        return ({
            sendFile: sendFile,
        });
    });
