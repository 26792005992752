(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('CostAccountAreaController', CostAccountAreaController);

    CostAccountAreaController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'cid', 'area' ,'$translate', '$http'];

    function CostAccountAreaController($timeout, $scope, $stateParams, $uibModalInstance, entity, cid, area, $translate, $http) {
        var vm = this;
        var id = null;
        vm.costAccountCategories = entity;
        vm.selectedCostAccounts = [];
        vm.costAccounts = [];
        vm.name;

        if(area != null && area.id != null){
            vm.selectedCostAccounts = area.accountCategoryValues;
            vm.name = area.name;
            id = area.id;
        }

        if (vm.costAccountCategories != null) {
            for (var i = 0; i < vm.costAccountCategories.length; i++) {
                 var category ={}; // {name: "Taso: " + vm.costAccountCategories[i].level, id: i * 0.1, disabled: true};
                // vm.costAccounts.push(category);
                var cat = vm.costAccountCategories[i].costAccounts;
                for (var j = 0; j < cat.length; j++) {
                      vm.costAccounts.push(cat[j]);
                }
            }
        }

        vm.clear = clear;
        vm.save = save;
        vm.selectAll = false;

        vm.toolTipText = $translate.instant('reporting.modalDialog.toolTipText');

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var selected = {};
            selected.id = id;
            selected.companyId = cid;
            selected.name = vm.name;
            selected.accountCategoryValues = vm.selectedCostAccounts;
            $http.put("/api/accountCategoryValueAreas",selected).then( function(value){
                $uibModalInstance.close(value.data);
                vm.isSaving = false;
            });
        }
        vm.groupFunction = function(item){
            for(var i=0; i < vm.costAccountCategories.length; i++){
                for (var j = 0; j < vm.costAccountCategories[i].costAccounts.length; j++){
                    if(vm.costAccountCategories[i].costAccounts[j].id === item.id){
                        return vm.costAccountCategories[i].level;
                    }
                }
            }
        }
    }
})();
