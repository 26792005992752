'use strict';

angular.module('ecoeasyApp')
    .controller('CustomerDeleteController', function ($scope, $uibModalInstance, $stateParams, entity, Company, CompanyDataVersionService) {

        $scope.company = entity;
        $scope.target = $stateParams.target;

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            if ($scope.target === 'entity') {
                Company.delete({id: id},
                    function () {
                        $uibModalInstance.close(true);
                    });
            }
            if ($scope.target === 'data') {
                CompanyDataVersionService.deleteByCompany(entity.id).then(function () {
                    $uibModalInstance.close(true);
                });
            }
        };

    });
