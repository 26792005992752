import template from "./delete-row-dialog.html";

export const DeleteRowDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class DeleteRowDialogComponent {

        constructor() {
            "ngInject";
        }

        $onInit() {
            this.deleteRow = this.resolve.deleteRow;
        }

        save() {
            this.close({$value: this.deleteRow.id});
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }


    }
}
