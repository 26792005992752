'use strict';

angular.module('ecoeasyApp').controller('BudgetDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Budget', 'BudgetService', 'hasDataForEstimates','$http','$translate',
        function ($scope, $stateParams, $uibModalInstance, $q, entity, Budget, BudgetService, hasDataForEstimates, $http, $translate) {

            $scope.budget = entity;
            $scope.budget.master = false;
            $scope.baseFigMethod = "1";
            $scope.hasDataForEstimates = hasDataForEstimates;
            $scope.positions = [];
            $scope.selectedPos;
            $scope.selectedParentBudget;
            $scope.estimatesForLastMonth = true;


            $scope.possibleParentBudgets = [];//Budget.getPossibleParentBudgets()
            $scope.includeBalanceBudget = false;
            window.bts = $scope;

            var company;

            $q.when($scope.$root.getSelectedCompany()).then(function (comp) {
                var now = new Date();
                company = comp;


                var currentYear = now.getFullYear();
                $http.get("/api/fiscalYears/company/" + company.id + "/sorted").then(function (result) {
                    var start = 0;
                    var fyList = result.data;
                    for (var i = 0; i < fyList.length; i++) {
                        if (new Date(fyList[i].startDate).getFullYear() == currentYear) {
                            if( fyList[i].length < 12){
                                if( i+1 < fyList.length && new Date(fyList[i+1].startDate).getFullYear() == currentYear){
                                    start = i+1;
                                    break;
                                } else {
                                    start = i;
                                    break;
                                }

                            }else {
                                start = i;
                                break;
                            }
                        }
                    }
                    if (start < 2){
                        start = 2;
                    }
                    for (var i = 0; i < 5; i++) {
                        var fy = fyList[start - 2 + i];
                        var startDate = new Date(fy.startDate)
                        var pos = {
                            pos: startDate.getFullYear(),
                            posString: getPositionString(fy),
                            disabled: fy.length !== 12
                        }
                        $scope.positions.push(pos);
                    }
                    for(var i = 0; i < $scope.positions.length; i++) {
                      if(!$scope.positions[i].disabled) {
                          $scope.selectedPos = $scope.positions[i];
                          break;
                      }

                    }
                    $scope.selectedPosChanged($scope.selectedPos);
                });
            });
            function getPositionString(fiscalYear) {
                var startDate= new Date(fiscalYear.startDate);
                var endDate= new Date(fiscalYear.endDate);
                var s = (startDate.getMonth()+1) + "/" +startDate.getFullYear() + " - ";
                s = s + (endDate.getMonth()+1) + "/" + endDate.getFullYear();
                if(fiscalYear.length !== 12){
                    s = s +"   "+ $translate.instant("budget.fiscalyear_length");
                }
                return s;
            }

            $scope.includeBalanceBudgetChanged = function (value) {
                $scope.includeBalanceBudget = value;
            }

            $scope.load = function (id) {
                if (id && id != "new") {
                    Budget.get({id: id}, function (result) {
                        $scope.budget = result;
                    });
                }
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:budgetUpdate', result);
                $scope.$emit('ecoeasyApp:navUpdate');
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;

                $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                    $scope.budget.company = company;
                    $scope.budget.targetPeriod = $scope.selectedPos.pos;

                    if ($scope.selectedParentBudget != null) {
                        $scope.budget.parentBudgetId = $scope.selectedParentBudget.budgetId;
                    }

                    $scope.budget.includeBalanceBudget = $scope.includeBalanceBudget;

                    if ($scope.budget.id != null) {
                        Budget.update($scope.budget, onSaveSuccess, onSaveError);
                    } else {
                        var result = BudgetService.createBudget($scope.budget, $scope.baseFigMethod, $scope.estimatesForLastMonth);
                        if (result) {
                            onSaveSuccess(result);
                        }
                        else {
                            onSaveError(result);
                        }
                    }
                });
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.selectedPosChanged = function (selectedPos) {
                $scope.selectedPos = selectedPos;
                BudgetService.getPossibleParentBudgets(company.id, selectedPos.pos).then(function (data) {
                    $scope.possibleParentBudgets = data;
                });
            };

            $scope.selectedParentBudgetChanged = function (selected) {
                $scope.selectedParentBudget = selected;
            }
        }]);
