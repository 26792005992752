'use strict';

angular.module('ecoeasyApp').controller('OrganisationCompanyIndicatorDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'OrganisationCompanyIndicator', 'Company', 'Organisation', 'Indicator',
        function ($scope, $stateParams, $uibModalInstance, entity, OrganisationCompanyIndicator, Company, Organisation, Indicator) {

            $scope.organisationCompanyIndicator = entity;
            $scope.companys = Company.query();
            $scope.organisations = Organisation.query();
            $scope.indicators = Indicator.query();
            $scope.load = function (id) {
                OrganisationCompanyIndicator.get({id: id}, function (result) {
                    $scope.organisationCompanyIndicator = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:organisationCompanyIndicatorUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.organisationCompanyIndicator.id != null) {
                    OrganisationCompanyIndicator.update($scope.organisationCompanyIndicator, onSaveSuccess, onSaveError);
                } else {
                    OrganisationCompanyIndicator.save($scope.organisationCompanyIndicator, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
