'use strict';

angular.module('ecoeasyApp')
        .factory('Principal', function Principal($q, Account, $rootScope) { //rootscope josta companyId
            var _identity,
                    _authenticated = false;

            return {
                isIdentityResolved: function () {
                    return angular.isDefined(_identity);
                },
                isAuthenticated: function () {
                    return _authenticated;
                },
                hasLicenseStatus: function (status) {
                	
                    if (!_authenticated || !_identity) {
                        return false;
                    }
                    if (_identity.licenseStatus) {
                        return _identity.licenseStatus === status;
                    } else {
                        return true;
                    }
                },
                hasAuthority: function (authority) {
                    if (!_authenticated) {
                        return $q.when(false);
                    }
                    return this.identity().then(function (_id) {
                        return _id.authorities && _id.authorities.indexOf(authority) !== -1;//hasPermission -> 
                    }, function (err) {
                        return false;
                    });
                },
                hasAnyAuthority: function (authorities) {
                    if (!_authenticated || !_identity || !_identity.authorities) {
                        return false;
                    }

                    for (var i = 0; i < authorities.length; i++) {
                        if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                            return true;
                        }
                    }
                    return false;
                },

                hasPermission: function (permission) {
                    if (!_authenticated) {
                        return $q.when(false);
                    }
                    return this.identity().then(function (_id) {
                        return _id.permissions && _id.permissions.indexOf(permission) !== -1;
                    }, function (err) {
                        return false;
                    });
                },

                hasAnyPermission: function (permissions) {
                    if (!_authenticated || !_identity || !_identity.permissions) {
                        return false;
                    }

                    var companyId = $rootScope.getSelectedCompanyIdSync();
                                            
                    for (var i = 0; i < permissions.length; i++) {
                        for (var j = 0; j < _identity.permissions.length; j++) {
                            if (_identity.permissions[j].code === permissions[i] && _identity.permissions[j].companyId === null) {
                                return true;
                            } else if (_identity.permissions[j].code === permissions[i] && _identity.permissions[j].companyId === companyId) {
                                return true;
                            } // This is needed for management menu to be visible on users who has rights to
                              // access the management menu with company-level permissions
                            else if(_identity.permissions[j].code === permissions[i] && companyId == null 
                                    && (permissions[i] === "USER_ORGANISATION" || permissions[i] === "COMPANY_MANAGEMENT" ||
                                    permissions[i] === "USER_GROUP" || permissions[i] === "LINKING_TABLES" ||
                                    permissions[i] === "LINKING_TABLES_EDIT" || permissions[i] === "MANUAL_IMPORT")) {
                                return true;
                            }
                        }
                    }
                    return false;                    
                },

                hasPermissionOrAuthority: function (permission, authority) {
                    if (!_authenticated || !_identity || (!_identity.permissions && !_identity.authorities)) {
                        return false;
                    }
                    if (_identity.authorities && _identity.authorities.indexOf(authority) !== -1) {
                        return true;
                    } else {
                        var companyId = $rootScope.getSelectedCompanyIdSync();

                        for (var j = 0; j < _identity.permissions.length; j++) {
                            if (_identity.permissions[j].code === permission && _identity.permissions[j].companyId === null) {
                                return true;
                            } else if (_identity.permissions[j].code === permission && _identity.permissions[j].companyId === companyId) {
                                return true;
                            }
                        }
                        return false
                    }
                },
                
                hasOrgLvlPermissionfor: function(permissions){
                    if (!_authenticated || !_identity || !_identity.permissions) {
                        return false;
                    }
                    
                    for(var i = 0; i < permissions.length; i++){
                        for(var j = 0; j < _identity.permissions.length; j++) {
                            if(_identity.permissions[j].code === permissions[i] && _identity.permissions[j].companyId === null){
                                return true;
                            }
                        }
                    }
                    return false;
                },

                authenticate: function (identity) {
                    _identity = identity;
                    _authenticated = identity !== null;
                },

                identity: function (force) {
                    var deferred = $q.defer();

                    if (force === true) {
                        _identity = undefined;
                    }

                    // check and see if we have retrieved the identity data from the server.
                    // if we have, reuse it by immediately resolving
                    if (angular.isDefined(_identity)) {
                        deferred.resolve(_identity);

                        return deferred.promise;
                    }

                    // retrieve the identity data from the server, update the identity object, and then resolve.
                    Account.get().$promise
                            .then(function (account) {
                                _identity = account.data;
                                _authenticated = true;
                                deferred.resolve(_identity);
                            })
                            .catch(function () {
                                _identity = null;
                                _authenticated = false;
                                deferred.resolve(_identity);
                            });
                    return deferred.promise;
                }
            };
        });
