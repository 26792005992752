import {FiscalYearOption} from "./fiscal-year-option";
import BudgetTableData from "./budget-table-data";
import {BudgetTableDataRow, BudgetTableRowType} from "./budget-table-data-row";

let ordinal = 0;
let KIRJURI_TULOS_ID = null;

const YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_GLOBAL_ID = 3558;
const YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_GLOBAL_ID = 3559;
const YHDISTYSKIRJURI_TUOTOT_JA_KULUT_3_GLOBAL_ID = 3560;

const YHDISTYSKIRJURI_VARSINAISEN_TOIMINNAN_YHTEISTUOTOT_JA_KULUT = 665;
const YHDISTYSKIRJURI_VARAINHANKINTA = 673;
const YHDISTYSKIRJURI_RAHOITUS = 677;
const YHDISTYSKIRJURI_YLEISAVUSTUKSET = 685;
const YHDISTYSKIRJURI_OMAKATTEISET_RAHASTOT = 688;

const YHDISTYSKIRJURI_GROUPS_BCOAS = [
    YHDISTYSKIRJURI_VARSINAISEN_TOIMINNAN_YHTEISTUOTOT_JA_KULUT,
    YHDISTYSKIRJURI_VARAINHANKINTA,
    YHDISTYSKIRJURI_RAHOITUS
];

const YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_BCOA_ID = 672;
const YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_BCOA_ID = 676;
const YHDISTYSKIRJURI_TUOTOT_JA_KULUT_3_BCOA_ID = 684;

const YHDISTYSKIRJURI_TUOTOT_BCOAS = [
    YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_BCOA_ID,
    YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_BCOA_ID,
    YHDISTYSKIRJURI_TUOTOT_JA_KULUT_3_BCOA_ID
];

export function convertBudget(budget) {
    let listBudget = {};
    listBudget.id = budget.id;
    listBudget.name = budget.name;
    listBudget.fiscalYear = new FiscalYearOption(budget.fiscalYear.id, new Date(budget.fiscalYear.startDate), new Date(budget.fiscalYear.endDate));
    listBudget.yearMonths = budget.fiscalYear.yearMonths;
    listBudget.primary = budget.primary;
    listBudget.basicChartCategoryId = budget.basicChartCategoryId;
    listBudget.hasSubBudgets = budget.hasSubBudgets;
    listBudget.isCashBudget = budget.cashBudget;
    listBudget.actualDate = budget.actualDate;
    listBudget.cashFlowValuesList = budget.cashFlowValuesList;
    return listBudget;
}

export function convertOperatingBudget(operatingBudget, incomeStatement) {
    ordinal = 0;
    _addChildCounts(operatingBudget.groups);
    let months = operatingBudget.fiscalYear.yearMonths.map((yearMonth) => {
        return yearMonth.month;
    });
    let listBudget = {};
    listBudget.id = operatingBudget.budgetId;

    if (incomeStatement !== null) {
        const kirjuriRow = incomeStatement.subCategories[incomeStatement.subCategories.length - 1];
        KIRJURI_TULOS_ID = kirjuriRow.id;
        const customKirjuriSumRow = createCustomKirjuriSumRow(kirjuriRow);
        //operatingBudget.groups.push(customKirjuriSumRow);
    }

    let rootRows = _toRootRows(operatingBudget.groups, incomeStatement);
    listBudget.data = new BudgetTableData(months, _createBudgetRows(rootRows, months.length, operatingBudget.actualDate), operatingBudget.cashFlowValuesList);
    listBudget.subBudgets = operatingBudget.subBudgets.map((sub) => {
        _addChildCounts(sub.groups);
        return {
            subBudgetId: sub.costPoolId,
            name: sub.name,
            data: new BudgetTableData(months, _createBudgetRows(_toRootRows(sub.groups, incomeStatement), months.length, operatingBudget.actualDate), operatingBudget.cashFlowValuesList)
        };
    });
    return listBudget;
}

function _toRootRows(groups, incomeStatement) {
    let myGroups = [];
    if (groups[0].nameFi.toUpperCase() === 'KIINTEISTÖN TUOTOT') {
        for (const group of groups) {
            myGroups.push(group);
        }
    } else if (groups[0].nameFi.toUpperCase() === 'VARSINAINEN TOIMINTA' || groups[0].basicChartCategoryId === 622) {
        myGroups = createYhdistysKirjuriGroups(groups);
    } else {
        let currentTilikaudenVoitto = groups[0];
        let currentTulosEnnenVeroja = currentTilikaudenVoitto.groups.shift();
        let currentLiikevoitto = currentTulosEnnenVeroja.groups.shift();
        let currentLiikevaihto = currentLiikevoitto.groups.shift();
        myGroups.push(currentLiikevaihto);
        myGroups.push(currentLiikevoitto);
        myGroups.push(currentTulosEnnenVeroja);
        myGroups.push(currentTilikaudenVoitto);
    }
    return myGroups;
}

function createYhdistysKirjuriGroups(groups) {
    let yhdistysGroups = [];
    for (const group of groups) {
        yhdistysGroups.push(group);
    }
    return yhdistysGroups;
}

function _addChildCounts(groups) {
    groups.forEach((group) => {
        let groupCount = group.groups.length;
        let accountCount = group.accounts.length;
        group.childCount = groupCount + accountCount;
        group.accounts.forEach((account) => {
            account.childCount = 0;
        });
        _addChildCounts(group.groups);
    });
}

function _createBudgetRows(groups, size, actualDate) {
    let rows = [];
    if (groups[0].nameFi === 'KIINTEISTÖN TUOTOT') {
        for (let i in groups) {
            if (i == 0) {
                rows = rows.concat(_createGroupRow(0, groups[i], size, groups[i + 1], actualDate));
            } else {
                rows = rows.concat(_createGroupRowReverse(0, groups[i], size, groups[i + 1], actualDate));
            }
        }
    } else if (groups[0].nameFi === 'VARSINAINEN TOIMINTA') {
        for (let i in groups) {
            if (i == 0) {
                rows = rows.concat(_createYhdistysGroupRow(0, groups[i], size, null, actualDate, groups[0]));
            } else {
                rows = rows.concat(_createGroupRowReverse(0, groups[i], size, null, actualDate));
            }
        }
    } else {
        let currentLiikevaihto = groups[0];
        let currentLiikevoitto = groups[1];
        let currentTulosEnnenVeroja = groups[2];
        let currentTilikaudenVoitto = groups[3];
        rows = rows.concat(_createGroupRow(0, currentLiikevaihto, size, currentLiikevoitto, actualDate));
        rows = rows.concat(_createGroupRowReverse(0, currentLiikevoitto, size, currentTulosEnnenVeroja, actualDate));
        rows = rows.concat(_createGroupRowReverse(0, currentTulosEnnenVeroja, size, currentTilikaudenVoitto, actualDate));
        rows = rows.concat(_createGroupRowReverse(0, currentTilikaudenVoitto, size, null, actualDate));
    }
    return rows;
}

function _createYhdistysGroupRow(level, group, monthCount, parent, actualDate, groups) {
    let rows = [];
    let addTo = parent;

    if (isYhdistysKirjuriTulos(group)) {
        addTo = KIRJURI_TULOS_ID;
    }

    if (isVarsinainenToiminta(group)) {
        addTo = YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_BCOA_ID;
    }

    // IS VARAINHANKINTA
    if (groups.groups.filter(it => it.basicChartCategoryId === YHDISTYSKIRJURI_VARAINHANKINTA).length > 0 &&
        (group.basicChartCategoryId === YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_BCOA_ID || group.basicChartCategoryId === YHDISTYSKIRJURI_VARAINHANKINTA)) {
        addTo = YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_BCOA_ID;
    } else if (groups.groups.filter(it => it.basicChartCategoryId === YHDISTYSKIRJURI_VARAINHANKINTA).length === 0 &&
        group.basicChartCategoryId === YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_BCOA_ID) {
        addTo = KIRJURI_TULOS_ID;
    }

    // IS RAHOITUSTOIMINTA
    if (groups.groups.filter(it => it.basicChartCategoryId === YHDISTYSKIRJURI_RAHOITUS).length > 0 &&
        (group.basicChartCategoryId === YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_BCOA_ID ||
            group.basicChartCategoryId === YHDISTYSKIRJURI_RAHOITUS)) {
        addTo = YHDISTYSKIRJURI_TUOTOT_JA_KULUT_3_BCOA_ID;
    } else if (groups.groups.filter(it => it.basicChartCategoryId === YHDISTYSKIRJURI_RAHOITUS).length === 0 &&
        group.basicChartCategoryId === YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_BCOA_ID) {
        addTo = KIRJURI_TULOS_ID;
    }

    if (group.basicChartCategoryId === YHDISTYSKIRJURI_TUOTOT_JA_KULUT_1_BCOA_ID) addTo = [622, YHDISTYSKIRJURI_TUOTOT_JA_KULUT_2_BCOA_ID];

    rows.push(createGroupRow(group, level, addTo, monthCount, actualDate));
    group.accounts.forEach((account) => {
        rows = rows.concat(_createAccountRow(level + 1, account, group));
    });
    group.groups.forEach((subGroup) => {
        rows = rows.concat(_createYhdistysGroupRow(level + 1, subGroup, monthCount, group.basicChartCategoryId, actualDate, groups));
    });

    return rows;
}

function _createGroupRow(level, group, monthCount, parentGroup, actualDate) {
    let rows = [];
    let addTo = null;
    if (group.basicChartCategoryId ==491 || group.basicChartCategoryId == 495){
        addTo=514;
    }

    if (parentGroup && parentGroup.basicChartCategoryId != 490) {
        addTo = parentGroup.basicChartCategoryId;
    }

    if (isMyyntikateGroup(group)) {
        addTo = "myyntikate";
    }

    // KIINTEISTÖN TUOTOT YHTEENSÄ
    if (group.basicChartCategoryId === 450) {
        addTo = 467;
    }

    if (isKiinteistoKirjuri(group)) {
        addTo = KIRJURI_TULOS_ID;
    }

    if (group.basicChartCategoryId === 467 || group.basicChartCategoryId ===470){
        addTo = 490;
    }



    rows.push(createGroupRow(group, level, addTo, monthCount, actualDate));
    group.accounts.forEach((account) => {
        rows = rows.concat(_createAccountRow(level + 1, account, group));
    });

    group.groups.forEach((subGroup) => {
        rows = rows.concat(_createGroupRow(level + 1, subGroup, monthCount, group, actualDate));
    });


    return rows;
}

function createGroupRow(group, level, addTo, monthCount, actualDate) {
    const theLevel = (level === 0 || level === 1) ? 0 : level - 1;
    if (YHDISTYSKIRJURI_TUOTOT_BCOAS.includes(group.basicChartCategoryId)) {
        return createSumRow(group, level, level === 0, addTo, monthCount, actualDate);
    } else if (isReferenceGroup(group)) {
        return createRefRowOfGroup(group, theLevel, addTo);
    } else if (isGrowthGroup(group)) {
        return createGrowthRowOfGroup(group, theLevel, addTo);
    } else if (hasChildren(group)) {
        return createSumRow(group, theLevel, level === 0, addTo, monthCount, actualDate);
    } else {
        return createValueRowAbsoluteOfGroup(group, theLevel, addTo);
    }
}

function createGroupRow2(group, level, addTo, monthCount, actualDate) {
    if (group.basicChartCategoryId === KIRJURI_TULOS_ID) {
        return createSumRow(group, level, level === 0, addTo, monthCount, actualDate);
    } else if (isReferenceGroup(group)) {
        return createRefRowOfGroup(group, level, addTo);
    } else if (isGrowthGroup(group)) {
        return createGrowthRowOfGroup(group, level, addTo);
    } else if (hasChildren(group)) {
        return createSumRow(group, level, level === 0, addTo, monthCount, actualDate);
    } else {
        return createValueRowAbsoluteOfGroup(group, level, addTo);
    }
}

function hasChildren(group) {
    return (group.accounts && group.accounts.length > 0) || (group.groups && group.groups.length > 0);
}

function isReferenceGroup(group) {
    return group.referenceId;
}

function isGrowthGroup(group) {
    return group.referenceId == null && group.referenceRatio;
}

function createSumRow(group, level, main, addTo, monthCount, actualDate) {
    let values = [];
    let groupWithInfoRow = group.accounts && group.accounts.length > 0 && group.accounts.filter(account => account.basicChartCategoryId === null);
    for (let i = 0; i < monthCount; i++) {
        if (actualDate && groupWithInfoRow) {
            values.push(group.values[i]);
        } else {
            values.push(0);
        }
    }
    let childCount = group.childCount;
    return new BudgetTableDataRow(_nextOrdinal(), group.id, group.nameFi, group.nameEn, group.nameSv, level, addTo, group.basicChartCategoryId, BudgetTableRowType.SUM, main, values, childCount, null, null, group.memo, group.removable, group.companyAccountId);
}

function createMyyntikateSumRow(monthCount) {
    let group = {
        id: "myyntikate",
        nameFi: "MYYNTIKATE",
        nameEn: "SALES MARGIN",
        nameSv: "FÖRSALJNINGSBIDRAG",
        basicChartCategoryId: "myyntikate",
        removable: false
    };
    return createSumRow(group, 0, true, 155, monthCount);
}

function createRefRowOfGroup(group, level, addTo) {
    let childCount = group.childCount;
    return new BudgetTableDataRow(_nextOrdinal(), group.id, group.nameFi, group.nameEn, group.nameSv, level, addTo, group.basicChartCategoryId, BudgetTableRowType.REFERENCE, group.basicChartCategoryId === 156, angular.copy(group.values), childCount, group.referenceId, group.referenceRatio, group.memo, group.removable, group.companyAccountId);
}

function createGrowthRowOfGroup(group, level, addTo) {
    let childCount = group.childCount;
    return new BudgetTableDataRow(_nextOrdinal(), group.id, group.nameFi, group.nameEn, group.nameSv, level, addTo, group.basicChartCategoryId, BudgetTableRowType.GROWTH, group.basicChartCategoryId === 156, angular.copy(group.values), childCount, null, group.referenceRatio, group.memo, group.removable, group.companyAccountId);
}

function createValueRowAbsoluteOfGroup(group, level, addTo) {
    let childCount = group.childCount;
    return new BudgetTableDataRow(_nextOrdinal(), group.id, group.nameFi, group.nameEn, group.nameSv, level, addTo, group.basicChartCategoryId, BudgetTableRowType.ABSOLUTE, group.basicChartCategoryId === 156, angular.copy(group.values), childCount, null, null, group.memo, group.removable, group.companyAccountId);
}

function createValueRowAbsoluteOfAccount(account, level, parentGroup) {
    return new BudgetTableDataRow(_nextOrdinal(), account.id, account.nameFi, account.nameEn, account.nameSv, level, parentGroup.basicChartCategoryId, null, BudgetTableRowType.ABSOLUTE, false, angular.copy(account.values), 0, null, null, account.memo, account.removable, account.companyAccountId)
}

function createRefRowOfAccount(account, level, parentGroup) {
    return new BudgetTableDataRow(_nextOrdinal(), account.id, account.nameFi, account.nameEn, account.nameSv, level, parentGroup.basicChartCategoryId, null, BudgetTableRowType.REFERENCE, false, angular.copy(account.values), 0, account.referenceId, account.referenceRatio, account.memo, account.removable, account.companyAccountId)
}

function createGrowthRowOfAccount(account, level, parentGroup) {
    return new BudgetTableDataRow(_nextOrdinal(), account.id, account.nameFi, account.nameEn, account.nameSv, level, parentGroup.basicChartCategoryId, null, BudgetTableRowType.GROWTH, false, angular.copy(account.values), 0, null, account.referenceRatio, account.memo, account.removable, account.companyAccountId)
}

function isMyyntikateGroup(group) {
    return group.basicChartCategoryId === 156 ||
        group.basicChartCategoryId === 179 ||
        group.basicChartCategoryId === 188 ||
        group.basicChartCategoryId === 177 ||
        group.basicChartCategoryId === 178 ||
        group.basicChartCategoryId === 213;
}

function isKiinteistoKirjuri(group) {
    return group.basicChartCategoryId === 490;
}

function isVarsinainenToiminta(group) {
    return group.basicChartCategoryId === 623 ||
        group.basicChartCategoryId === 630 ||
        group.basicChartCategoryId === 637 ||
        group.basicChartCategoryId === 644 ||
        group.basicChartCategoryId === 665;
}

function isYhdistysKirjuriTulos(group) {
    return group.basicChartCategoryId === 695 ||
        group.basicChartCategoryId === 701 ||
        group.basicChartCategoryId === YHDISTYSKIRJURI_YLEISAVUSTUKSET ||
        group.basicChartCategoryId === YHDISTYSKIRJURI_OMAKATTEISET_RAHASTOT;
}

function _nextOrdinal() {
    ordinal = ordinal + 1;
    return ordinal;
}

function _createAccountRow(level, account, parentGroup) {
    let accountRow;
    if (isReferenceGroup(account)) {
        accountRow = createRefRowOfAccount(account, level, parentGroup);
    } else if (isGrowthGroup(account)) {
        accountRow = createGrowthRowOfAccount(account, level, parentGroup);
    } else {
        accountRow = createValueRowAbsoluteOfAccount(account, level, parentGroup);
    }
    return [accountRow];
}

function _createGroupRowReverse(level, group, monthCount, parentGroup, actualDate) {
    let rows = [];
    let addTo = null;
    if (parentGroup) {
        addTo = parentGroup.basicChartCategoryId;
    }
    if (isMyyntikateGroup(group)) {
        addTo = "myyntikate";
    }
    if (group.basicChartCategoryId === 467 || group.basicChartCategoryId ===470){
        addTo = 490;
    }
    if (isKiinteistoKirjuri(group) || isYhdistysKirjuriTulos(group)) {
        addTo = KIRJURI_TULOS_ID;
    }
    group.accounts.forEach((account) => {
        rows = rows.concat(_createAccountRow(level + 1, account, group));
    });

    group.groups.forEach((subGroup) => {
        if (subGroup.basicChartCategoryId === 228) {
            rows = rows.concat(createMyyntikateSumRow(monthCount));
        }
        rows = rows.concat(_createGroupRow(level + 1, subGroup, monthCount, group, actualDate));
    });

    if (group.basicChartCategoryId ==491 || group.basicChartCategoryId == 495){
        addTo=514;
    }

    rows.push(createGroupRow2(group, level, addTo, monthCount, actualDate));
    return rows;
}

function createCustomKirjuriSumRow(kirjuriRow) {
    return {
        accounts: [],
        basicChartCategoryId: kirjuriRow.id,
        childCount: 0,
        companyAccountId: `kirjuri-${kirjuriRow.id}`,
        groups: [],
        id: `kirjuri-${kirjuriRow.id}`,
        memo: null,
        nameEn: kirjuriRow.nameEn,
        nameFi: kirjuriRow.nameFi,
        nameSv: kirjuriRow.nameSv,
        referenceId: null,
        referenceRatio: null,
        removable: false,
        values: []
    };
}

function _findByGlobalIdFromGroups(groups, globalId) {
    if (!groups) return;
    for (const group of groups) {
        if (group.globalId === globalId) return group;
        if (group.subCategories.length > 0) {
            const child = _findByGlobalIdFromGroups(group.subCategories, globalId);
            if (child) return child;
        }
    }
}
