'use strict';

angular.module('ecoeasyApp')
    .controller('AccountingModelController', function ($scope, $state, AccountingModel) {

        $scope.accountingModels = [];
        $scope.loadAll = function () {
            AccountingModel.query(function (result) {
                $scope.accountingModels = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.accountingModel = {
                name: null,
                id: null
            };
        };
    });
