'use strict';

angular.module('ecoeasyApp')
    .controller('OrganisationDeleteController', function ($scope, $uibModalInstance, entity, Organisation) {

        $scope.organisation = entity;
        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            Organisation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
