'use strict';

angular.module('ecoeasyApp') 
    .directive('hasAnyPermission', ['Principal', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        if(!element.hasClass('hidden-no-company')) {
                            element.removeClass('hidden');
                        }
                        element.removeClass('hidden-no-permission');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                        element.addClass('hidden-no-permission');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }
                        result = Principal.hasAnyPermission(permissions);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    },
                    permissions = attrs.hasAnyPermission.replace(/\s+/g, '').split(',');
                    
                if (permissions.length > 0) {
                    defineVisibility(true);
                    
                    scope.$watch(function(scope) {
                        return Principal.isAuthenticated();
                    }, function(newValue) {
                        defineVisibility(true);
                    });
                }
            }
        };
    }]);

