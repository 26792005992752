import template from './number-label.html';

export const NumberLabelComponent = {
    bindings: {
        value: '<',
        isThousands: '<'
    },
    template: template,
    controller: class NumberLabelComponent {

        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.trueValue = 0;
            this.labelValue = this.trueValue;
            this.thousands = false;
        }

        $onInit() {
            //this.$log.debug('NumberLabelComponent.$onInit');
        }

        $onChanges(changesObj) {
            //this.$log.debug('NumberLabelComponent.$onChanges: changesObj', changesObj);
            if (changesObj.value) {
                this._changeTrueValue(changesObj.value.currentValue);
                if (this.thousands) {
                    this._changeLabelValue(Math.round(this.trueValue / 1000));
                } else {
                    this._changeLabelValue(this.trueValue);
                }
            }
            if (changesObj.isThousands) {
                this._toggleThousands(changesObj.isThousands.currentValue);
            }
        }

        _changeTrueValue(newValue) {
            this.trueValue = newValue;
        }

        _changeLabelValue(newValue) {
            this.labelValue = newValue;
        }

        _toggleThousands(newValue) {
            this.thousands = newValue;
            if (this.thousands) {
                this._changeLabelValue(Math.round(this.trueValue / 1000));
            } else {
                this._changeLabelValue(this.trueValue);
            }
        }


    }

}