(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('LinkingTableDialogController', LinkingTableDialogController);

    LinkingTableDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LinkingTable', 'Principal'];

    function LinkingTableDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, LinkingTable, Principal) {
        var vm = this;

        vm.linkingTable = entity;
        vm.clear = clear;
        vm.save = save;
        vm.deleteRule = deleteRule;
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.linkingTable.id !== null) {
                LinkingTable.update(vm.linkingTable, onSaveSuccess, onSaveError);
            } else {
                Principal.identity().then(function (account) {
                    var org = {id: account.sourceId};
                    vm.linkingTable.organisation = org;
                    LinkingTable.save(vm.linkingTable, onSaveSuccess, onSaveError);
                });
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('ecoeasyApp:linkingTableUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function deleteRule(rule) {
            for (var i = 0; i < vm.linkingTable.linkingTableRules.length; i++) {
                if (rule.id === vm.linkingTable.linkingTableRules[i].id) {
                    vm.linkingTable.linkingTableRules.splice(i, 1);
                }
            }
        }

    }
})();
