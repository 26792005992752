import {variableTypes} from "../../drill/variable-type";

export class CompanyIndicatorService {

    constructor($resource, SelectedCompanyService) {
        "ngInject";
        this.CompanyMeasure = $resource('/api/companies/:companyId/account-chart/measures');
        this.BasicChartMeasure = $resource('/api/basic-chart-of-accounts/:chartId/measures');
        this.CompanyChart = $resource('/api/companies/:companyId/account-chart');
        this.selectedCompanyService = SelectedCompanyService;
    }

    getMeasureOptions() {
        let options = [];
        const company = this.selectedCompanyService.getSelectedCompany();
        let companyMeasures = this.CompanyMeasure.query({companyId: company.id}, ()=>{
            companyMeasures.filter(measure => !measure.helper).forEach((measure)=>{
                options.push({id: measure.id, name: measure.name, type: variableTypes.MEASURE});
            });
        });
        let basicChartMeasures = this.BasicChartMeasure.query({chartId: company.basicChartOfAccountsId}, ()=>{
            basicChartMeasures.filter(measure => !measure.helper).forEach((measure)=>{
                options.push({id: measure.id, name: measure.name, type: variableTypes.MEASURE});
            });
        });
        let companyChart = this.CompanyChart.get({companyId: company.id}, ()=>{
            console.log('companyChart', companyChart);
            companyChart.groups.forEach((root)=>{
                options.push({id: root.id, name: root.nameFi, type: variableTypes.COMPANY_ACCOUNT_GROUP});
                this._populateSubGroups(root.subGroups, options);
                this._populateAccounts(root.companyAccounts, options);
            });
        });
        return options;
    }

    _populateSubGroups(groups, options) {
        groups.forEach((group)=>{
            options.push({id: group.id, name: group.nameFi, type: variableTypes.COMPANY_ACCOUNT_GROUP});
            this._populateAccounts(group.companyAccounts, options);
            this._populateSubGroups(group.subGroups, options);
        });
    }

    _populateAccounts(accounts, options) {
        accounts.forEach((account)=>{
            options.push({id: account.id, name: account.code + ' ' + account.nameFi, type: variableTypes.COMPANY_ACCOUNT_GROUP});
        });
    }
}