'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('economicactivitiesimport', {
                parent: 'site',
                url: '/economicactivitiesimport',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./economicActivitiesImport.html'),
                        controller: 'EconomicActivitiesImportController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('economicactivitiesimport');
                        return $translate.refresh();
                    }]
                }
            })
            .state('economicactivitiesimport.done', {
                parent: 'economicactivitiesimport',
                url: '/economicactivitiesimport/done',
                params: {
                    importOk: true
                },
                data: {
                    authorities: ['ROLE_ADMIN',],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./economicActivitiesImport-done.html'),
                        controller: function ($scope, $stateParams) {
                            $scope.importOk = $stateParams.importOk;
                        }
                    }
                }
            });
    });
