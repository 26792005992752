'use strict';

angular.module('ecoeasyApp').factory('DrillExpression', function ($translate) {

    function formulaExpression(expression, varsByCode, varsByAccount) {
        if (expression != null) {
            var pieces = expression.split(" ");
            var patt = /\w/;
            var number = /^[-]?\d+/;
            var account = /A[0-9]+/;
            // var operators = /[~!@#$%&*\[\]\{\}\<\>\^+-=:,;?/\\]/;
            expression = "";
            for (var i = 0; i < pieces.length; i++) {
                if (pieces[i].endsWith("PPrevPE")) {
                    var key = "variable." + pieces[i].substring(0, pieces[i].length - 7)
                    expression = expression + " " + $translate.instant(key) + " " + $translate.instant("drill.PPrevPE");
                } else if (pieces[i].endsWith("PPrev")) {
                    var key = "variable." + pieces[i].substring(0, pieces[i].length - 5)
                    expression = expression + " " + $translate.instant(key) + " " + $translate.instant("drill.PPrev");
                } else if (patt.test(pieces[i]) && !number.test(pieces[i])) {
                    var token = pieces[i];
                    var translationKey = "variable.";
                    var isAccount = account.test(token);

                    if (isAccount) {
                        token = token.substr(1);
                    }
                    translationKey += token;

                    var translation = $translate.instant(translationKey);

                    if (translation.indexOf("variable.") >= 0) {
                        var v;

                        if (isAccount) {
                            v = varsByAccount[parseInt(token)];
                        } else {
                            v = varsByCode[token];
                        }

                        if (v && v.value && v.value != 0) {
                            expression = expression + " " + translation + " ";
                        }

                    } else {
                        expression = expression + " " + translation + " ";
                    }

                } else {
                    expression = expression + " " + pieces[i];
                }
            }
            return expression;
        }
    }
    return {
        formulaExpression: formulaExpression
    }
});
