'use strict';

angular.module('ecoeasyApp')
    .controller('FinancialsController', function ($scope, $http, $q, $state, Principal, SelectedCompanyService, FEATURE_COMPANY_ACCOUNT_CHART) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
        const new_calc_active = FEATURE_COMPANY_ACCOUNT_CHART && SelectedCompanyService.isBasicChartSelected();
        $scope.show_new_balance_sheet = new_calc_active;
        $scope.show_new_income_statement = new_calc_active;
        var is_raw;
        $scope.is;
        $scope.isReady = false;
        var bs_assets_raw;
        $scope.bs_assets;
        $scope.bsAssetsReady = false;
        var bs_liab_raw;
        $scope.bs_liab;
        $scope.bsLiabReady = false;
        $scope.selectedPeriod;
        $scope.selectedMonth;
        $scope.periods;
        $scope.selectedPeriodMonths;
        var availablePositions;
        var codeToId = {};
        var company;
        $scope.isValidPreviousData = true;

        $q.when($scope.$root.getSelectedCompany()).then(function (comp) {
            if (comp != null) {
                company = comp;
                $scope.companyId = company.id;
                init();
            }
            else {
                $q.when($scope.$root.getDefaultCompany()).then(function (comp) {
                    if (comp != null) {
                        company = comp;
                        $scope.companyId = company.id;
                        init();
                    }
                });
            }
        });

        function numberWithSeparator(x) {
            var number = x.toFixed(2);
            var parts = number.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(",");
        }

        function init() {
            $http.get("/api/reports/" + company.id + "/availablePositions").then(function (response) {
                availablePositions = response.data;
                var years = [];
                var maxYear = 0;
                for (var key in availablePositions) {
                    if (availablePositions.hasOwnProperty(key)) {
                        years.push(key);
                        if (key > maxYear) {
                            maxYear = key;
                        }
                    }
                }
                $scope.periods = years;

                if (company.actualizedPosition &&
                    company.actualizedPosition != null &&
                    company.actualizedPosition > 0) {
                    var year = 0;
                    var month = 0;
                    if (company.actualizedPosition < 100000) {
                        month = company.actualizedPosition % 10;
                        year = Math.round((company.actualizedPosition - month) / 10);
                    }
                    else {
                        month = company.actualizedPosition % 100;
                        year = Math.round((company.actualizedPosition - month) / 100);
                    }
                    for (var i = 0; i < years.length; i++) {
                        if (years[i] == year) {
                            $scope.selectedPeriod = years[i];
                        }
                    }
                    $scope.updateMonths(year, month);
                }
                else {
                    $scope.selectedPeriod = maxYear;
                    $scope.updateMonths(maxYear);
                }
                loadData();
            });
        }

        function loadData(month) {
            if (new_calc_active) return;

            var pos = $scope.selectedPeriod;

            if (month) {
                pos = pos + "" + month;
            }
            else {
                pos = pos + "" + $scope.selectedMonth;
            }
            $http.get('/api/statements/isvaliddata/' + company.id + '/' + pos).then(function (response) {
                $scope.isValidPreviousData = response.data;
            });
            $http.get('/api/statements/incomestatement/' + company.id + '/' + pos).then(function (response) {
                is_raw = response.data;
                var isTemp = [];
                is_raw.forEach(function (elem) {
                    var item = {
                        code: elem.code,
                        value: numberWithSeparator(elem.value),
                        percent: numberWithSeparator(elem.percent),
                        prevValue: numberWithSeparator(elem.prevValue),
                        periodicValue: numberWithSeparator(elem.periodicValue),
                        periodicPercent: numberWithSeparator(elem.periodicPercent),
                        prevPercent: numberWithSeparator(elem.previousPercent),
                        child: false
                    };
                    codeToId[elem.code] = elem.id;
                    isTemp.push(item);
                    elem.children.forEach(function (iChild) {
                        var subItem = {
                            code: iChild.code,
                            value: numberWithSeparator(iChild.value),
                            percent: numberWithSeparator(iChild.percent),
                            prevValue: numberWithSeparator(iChild.prevValue),
                            periodicValue: numberWithSeparator(iChild.periodicValue),
                            periodicPercent: numberWithSeparator(iChild.periodicPercent),
                            prevPercent: numberWithSeparator(iChild.previousPercent),
                            child: true
                        };
                        codeToId[iChild.code] = iChild.id;
                        isTemp.push(subItem);
                    });
                });
                $scope.is = isTemp;
                $scope.isReady = true;
                $http.get('/api/statements/balancesheet/assets/' + company.id + '/' + pos).then(function (response) {
                    bs_assets_raw = response.data;
                    var bsTemp = [];
                    bs_assets_raw.forEach(function (elem) {
                        var item = {
                            code: elem.code,
                            value: numberWithSeparator(elem.value),
                            prevValue: numberWithSeparator(elem.prevValue),
                            periodicValue: numberWithSeparator(elem.periodicValue),
                            child: false
                        };
                        codeToId[elem.code] = elem.id;
                        bsTemp.push(item);
                        elem.children.forEach(function (iChild) {
                            var subItem = {
                                code: iChild.code,
                                value: numberWithSeparator(iChild.value),
                                prevValue: numberWithSeparator(iChild.prevValue),
                                periodicValue: numberWithSeparator(iChild.periodicValue),
                                child: true
                            };
                            codeToId[iChild.code] = iChild.id;
                            bsTemp.push(subItem);
                        });
                    });
                    $scope.bs_assets = bsTemp;
                    $scope.bsAssetsReady = true;
                    $http.get('/api/statements/balancesheet/liabilities/' + company.id + '/' + pos).then(function (response) {
                        bs_liab_raw = response.data;
                        var bsTemp = [];
                        bs_liab_raw.forEach(function (elem) {
                            var item = {
                                code: elem.code,
                                value: numberWithSeparator(elem.value),
                                prevValue: numberWithSeparator(elem.prevValue),
                                periodicValue: numberWithSeparator(elem.periodicValue),
                                child: false
                            };
                            codeToId[elem.code] = elem.id;
                            bsTemp.push(item);
                            elem.children.forEach(function (iChild) {
                                var subItem = {
                                    code: iChild.code,
                                    value: numberWithSeparator(iChild.value),
                                    prevValue: numberWithSeparator(iChild.prevValue),
                                    periodicValue: numberWithSeparator(iChild.periodicValue),
                                    child: true
                                };
                                codeToId[iChild.code] = iChild.id;
                                bsTemp.push(subItem);
                            });
                        });
                        $scope.bs_liab = bsTemp;
                        $scope.bsLiabReady = true;
                    });
                });
            });


        }

        $scope.monthChanged = function (newMonth) {
            $scope.isReady = false;
            $scope.bsReady = false;
            loadData(newMonth);
        }

        $scope.updateMonths = function (year, preSelectedMonth) {
            $scope.selectedPeriodMonths = availablePositions[year];
            if (preSelectedMonth) {
                for (var i = 0; i < availablePositions[year].length; i++) {
                    if (availablePositions[year][i] === preSelectedMonth) {
                        $scope.selectedMonth = availablePositions[year][i];
                    }
                }
            }
            else {
                var max = 0;
                for (var i = 0; i < availablePositions[year].length; i++) {
                    var month = availablePositions[year][i];
                    if (month > max) {
                        max = month;
                    }
                }
                $scope.selectedMonth = max;
            }
            loadData($scope.selectedMonth);
        }

        $scope.rowSelected = function (code) {
            $state.go('drill', {variableid: codeToId[code]});
        }

        $scope.getChildClass = function (varRow) {
            if (varRow.child) {
                return "subvariable";
            }
            else {
                return "variable";
            }
        }
    });


