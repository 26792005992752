'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('company-user-management', {
                parent: 'site',
                url: '/company-user-management/{companyId}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE'],
                    pageTitle: 'user-management.home.title'
                },
                views: {
                    'content@': {
                        template: require('./user-management.html'),
                        controller: 'CompanyUserManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    companyId: ['$stateParams', function ($stateParams) {
                        return $stateParams.companyId;
                    }],
                    sourceState: function () {
                        return "COMPANY";
                    }
                }
            })
            .state('company-user-management-detail', {
                parent: 'company-user-management',
                url: '/user/:userId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE'],
                    pageTitle: 'user-management.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./user-management-detail.html'),
                        controller: 'UserManagementDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    sourceState: function () {
                        return "COMPANY";
                    }
                }
            })

            .state('company-user-management.new', {
                parent: 'company-user-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['LICENSE_PURCHASE', 'LICENSE_PURCHASE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./user-management-dialog.html'),
                        controller: 'UserManagementDialogController',

                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null, login: null, firstName: null, lastName: null, email: null,
                                    activated: true, langKey: null, createdBy: null, createdDate: null,
                                    lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                    resetKey: null, authorities: []
                                };
                            },
                            companyId: [function () {
                                return $stateParams.companyId;
                            }],
                            sourceState: function () {
                                return "COMPANY";
                            },
                            executionMethod: function () {
                                return "ADD";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('company-user-management', null, {reload: true});
                    }, function () {
                        $state.go('company-user-management');
                    })
                }]
            })
            .state('company-user-management.edit', {
                parent: 'company-user-management',
                url: '/{userId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./user-management-dialog.html'),
                        controller: 'UserManagementDialogController',

                        size: 'lg',
                        resolve: {
                            entity: ['UserService', function (UserService) {
                                return UserService.getById($stateParams.userId);
                            }],
                            companyId: [function () {
                                return $stateParams.companyId;
                            }],
                            sourceState: function () {
                                return "COMPANY";
                            },
                            executionMethod: function () {
                                return "EDIT";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('company-user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('company-user-management.delete', {
                parent: 'company-user-management',
                url: '/{userId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./user-management-delete-dialog.html'),
                        controller: 'user-managementDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['UserService', function (UserService) {
                                return UserService.getById($stateParams.userId);
                            }],
                            sourceState: function () {
                                return "COMPANY";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('company-user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    });
