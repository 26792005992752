'use strict';

angular.module('ecoeasyApp')
    .controller('SourceSystemController', function ($scope, $state, SourceSystem) {

        $scope.sourceSystems = [];
        $scope.loadAll = function () {
            SourceSystem.query(function (result) {
                $scope.sourceSystems = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.sourceSystem = {
                defaultValue: null,
                id: null
            };
        };
    });
