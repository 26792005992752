export class BasicChartOfAccountsGroupsResource {

    constructor($resource) {
        "ngInject";
        this.resource = $resource('/api/basic-chart-of-accounts/:chartId/groups/:categoryId', {}, {'update': {method: 'PUT'}}, {});
    }

    update(chartId, groupId, json) {
        return this.resource
            .update(
                {
                    chartId: chartId,
                    categoryId: groupId
                },
                json)
            .$promise;
    }

}