import React, {useEffect, useState} from 'react';
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import {useTranslation} from "react-i18next";
import {findAccountKey} from "../budget-utils";
import PropTypes from "prop-types";
import Money from "../../shared/money";
import {getCorrectDecimalFormat} from "../../../utils/util";

const InvestmentsDepreciationTable = ({investments, options, isAllCollapsed, setDepreciations}) => {
    const {t} = useTranslation('depreciation');
    const [collapsed, setCollapsed] = useState(false);
    const allCollapsed = collapsed || isAllCollapsed;

    const investmentGroup = (group, isCollapsed) => {
        const [groupCollapsed, setGroupCollapsed] = useState(false);
        const isItemCollapsed = isCollapsed || groupCollapsed;

        return (
            <React.Fragment key={group.id}>
                <tr hidden={isCollapsed} onClick={() => setGroupCollapsed(!groupCollapsed)}>
                    <td style={{paddingLeft: '1em', fontWeight: 'bold'}}>
                        {investmentCategory(group.bcoaCategoryId)}:
                        {groupCollapsed ?
                            <Glyphicon glyph='chevron-down' style={{float: 'right'}}/>
                            : <Glyphicon glyph='chevron-up' style={{float: 'right'}}/>
                        }
                    </td>
                    <td colSpan='4'/>
                </tr>
                {investmentItem(group, isItemCollapsed)}
            </React.Fragment>
        );
    };


    const investmentItem = (investment, isCollapsed) => {
        const remainingInvestmentSum = investment.value - investment.depreciationAmount;

        useEffect(() => {
            setDepreciations(prev => [...prev, {id: investment.id, depreciationAmount: investment.depreciationAmount}]);
        }, []);

        return (
            <tr hidden={isCollapsed} key={investment.id}>
                <td style={{paddingLeft: '2em', alignContent: 'right'}}>{investment.name}</td>
                <td align='right'><Money value={investment.value} decimals={true}/> €</td>
                <td align='right'><Money value={investment.depreciationAmount} decimals={true}/> €</td>
                <td align='right'>{getCorrectDecimalFormat(investment.depreciationPercentage, 1)} %</td>
                <td align='right'><Money value={remainingInvestmentSum} decimals={true}/> €</td>
            </tr>
        );
    }

    const investmentCategory = investmentItemId => {
        return findAccountKey(options, 'id', investmentItemId);
    };

    return (
        <>
            <tr style={{background: '#EBEBEB'}} onClick={() => setCollapsed(!collapsed)}>
                <td style={{fontWeight: 'bold'}}>
                    {t('table.subHeaders.investments')}:
                    {allCollapsed ?
                        <Glyphicon glyph='chevron-down' style={{float: 'right'}}/>
                        : <Glyphicon glyph='chevron-up' style={{float: 'right'}}/>
                    }
                </td>
                <td colSpan='4'/>
            </tr>
            {investments.map(investment => investmentGroup(investment, allCollapsed))}
        </>
    );
}

InvestmentsDepreciationTable.propTypes = {
    investments: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    isAllCollapsed: PropTypes.bool.isRequired,
    setDepreciations: PropTypes.func.isRequired
};

export default InvestmentsDepreciationTable;