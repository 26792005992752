'use strict';

angular.module('ecoeasyApp')
    .controller('FiscalYearController', function ($scope, $state, $stateParams, FiscalYear, ParseLinks, Company) {

        $scope.fiscalYears = [];
        $scope.predicate = 'startDate';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.companyId = $stateParams.companyId;
        $scope.company = Company.get({id: $stateParams.companyId});
        $scope.loadAll = function () {
            FiscalYear.all({
                companyId: $stateParams.companyId,
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.fiscalYears = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.fiscalYear = {
                name: null,
                startPosition: null,
                endPosition: null,
                length: null,
                startDate: null,
                endDate: null,
                id: null
            };
        };
    });
