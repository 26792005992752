import {Measure} from "./measure";
import {formulaItemTypes} from "../performance-measures/performance-measure-formula/performance-measure-formula-item/PerformanceMeasureFormulaItem";

export function jsonToMeasure(jsonResponse) {
    let m =  new Measure(jsonResponse.id, jsonResponse.name, jsonResponse.formula);
    m.calcType = jsonResponse.calcType;
    m.helper = jsonResponse.helper;
    m.removable = jsonResponse.removable;
    m.thresholdName1 = jsonResponse.thresholdName1;
    m.thresholdName1En = jsonResponse.thresholdName1En;
    m.thresholdName1Sv = jsonResponse.thresholdName1Sv;
    m.thresholdName2 = jsonResponse.thresholdName2;
    m.thresholdName2En = jsonResponse.thresholdName2En;
    m.thresholdName2Sv = jsonResponse.thresholdName2Sv;
    m.thresholdName3 = jsonResponse.thresholdName3;
    m.thresholdName3En = jsonResponse.thresholdName3En;
    m.thresholdName3Sv = jsonResponse.thresholdName3Sv;
    m.thresholdName4 = jsonResponse.thresholdName4;
    m.thresholdName4En = jsonResponse.thresholdName4En;
    m.thresholdName4Sv = jsonResponse.thresholdName4Sv;
    m.thresholdName5 = jsonResponse.thresholdName5;
    m.thresholdName5En = jsonResponse.thresholdName5En;
    m.thresholdName5Sv = jsonResponse.thresholdName5Sv;
    m.thresholdValue1Low = jsonResponse.thresholdValue1Low;
    m.thresholdValue2Low = jsonResponse.thresholdValue2Low;
    m.thresholdValue3Low = jsonResponse.thresholdValue3Low;
    m.thresholdValue4Low = jsonResponse.thresholdValue4Low;
    m.thresholdValue5Low = jsonResponse.thresholdValue5Low;
    m.thresholdValue1High = jsonResponse.thresholdValue1High;
    m.thresholdValue2High = jsonResponse.thresholdValue2High;
    m.thresholdValue3High = jsonResponse.thresholdValue3High;
    m.thresholdValue4High = jsonResponse.thresholdValue4High;
    m.thresholdValue5High = jsonResponse.thresholdValue5High;
    m.descriptionFi = jsonResponse.descriptionFi;
    m.descriptionEn = jsonResponse.descriptionEn;
    m.descriptionSv = jsonResponse.descriptionSv;
    return m;
}

export function measureToJson(measure) {
    let formulaRest = [];
    measure.formula.forEach((f) => {
        let type = '';
        let value = 0.0;
        let symbol = '';
        let id = 0;
        if (f.name) {
            if (f.formula) {
                type = formulaItemTypes.MEASURE;
                id = f.id;
            } else {
                type = formulaItemTypes.BASIC_ACCOUNT_GROUP;
                id = f.id;
            }
        } else {
            if (f == '+' || f == '-' || f == '*' || f == '/') {
                type = 'OPERATOR';
                symbol = f;
            } else if (isNaN(f)) {
                type = 'PARENTHESIS';
                symbol = f;
            } else {
                type = 'CONSTANT';
                value = f;
            }
        }
        formulaRest.push({
            type: type,
            value: value,
            symbol: symbol,
            id: id
        });
    });
    let json = {
        name: measure.name,
        formula: formulaRest,
        calcType: measure.calcType,
        helper: measure.helper,
        thresholdName1: measure.thresholdName1,
        thresholdName1En: measure.thresholdName1En,
        thresholdName1Sv: measure.thresholdName1Sv,
        thresholdName2: measure.thresholdName2,
        thresholdName2En: measure.thresholdName2En,
        thresholdName2Sv: measure.thresholdName2Sv,
        thresholdName3: measure.thresholdName3,
        thresholdName3En: measure.thresholdName3En,
        thresholdName3Sv: measure.thresholdName3Sv,
        thresholdName4: measure.thresholdName4,
        thresholdName4En: measure.thresholdName4En,
        thresholdName4Sv: measure.thresholdName4Sv,
        thresholdName5: measure.thresholdName5,
        thresholdName5En: measure.thresholdName5En,
        thresholdName5Sv: measure.thresholdName5Sv,
        thresholdValue1Low: measure.thresholdValue1Low,
        thresholdValue2Low: measure.thresholdValue2Low,
        thresholdValue3Low: measure.thresholdValue3Low,
        thresholdValue4Low: measure.thresholdValue4Low,
        thresholdValue5Low: measure.thresholdValue5Low,
        thresholdValue1High: measure.thresholdValue1High,
        thresholdValue2High: measure.thresholdValue2High,
        thresholdValue3High: measure.thresholdValue3High,
        thresholdValue4High: measure.thresholdValue4High,
        thresholdValue5High: measure.thresholdValue5High,
        descriptionFi: measure.descriptionFi,
        descriptionEn: measure.descriptionEn,
        descriptionSv: measure.descriptionSv
    };
    return json;
}

export function companyMeasureToJson(measure) {
    let formulaRest = [];
    measure.formula.forEach((f) => {
        let type = '';
        let value = 0.0;
        let symbol = '';
        let id = 0;
        if (f.name) {
            if (f.formula) {
                type = formulaItemTypes.MEASURE;
                id = f.id;
            } else if (f.code) {
                type = formulaItemTypes.COMPANY_ACCOUNT;
                id = f.id;
            } else {
                if (f.id === 514) {
                    type = formulaItemTypes.BASIC_ACCOUNT_GROUP
                    if (f.name === 'TILIKAUDEN VOITTO/TAPPIO') {
                        id = 514;
                    }
                } else {
                    type = formulaItemTypes.COMPANY_ACCOUNT_GROUP;
                    id = f.id;
                }
            }
        } else {
            if (f == '+' || f == '-' || f == '*' || f == '/') {
                type = 'OPERATOR';
                symbol = f;
            } else if (isNaN(f)) {
                type = 'PARENTHESIS';
                symbol = f;
            } else {
                type = 'CONSTANT';
                value = f;
            }
        }
        formulaRest.push({
            type: type,
            value: value,
            symbol: symbol,
            id: id
        });
    });
    let json = {
        name: measure.name,
        formula: formulaRest,
        calcType: measure.calcType,
        helper: measure.helper,
        thresholdName1: measure.thresholdName1,
        thresholdName1En: measure.thresholdName1En,
        thresholdName1Sv: measure.thresholdName1Sv,
        thresholdName2: measure.thresholdName2,
        thresholdName2En: measure.thresholdName2En,
        thresholdName2Sv: measure.thresholdName2Sv,
        thresholdName3: measure.thresholdName3,
        thresholdName3En: measure.thresholdName3En,
        thresholdName3Sv: measure.thresholdName3Sv,
        thresholdName4: measure.thresholdName4,
        thresholdName4En: measure.thresholdName4En,
        thresholdName4Sv: measure.thresholdName4Sv,
        thresholdName5: measure.thresholdName5,
        thresholdName5En: measure.thresholdName5En,
        thresholdName5Sv: measure.thresholdName5Sv,
        thresholdValue1Low: measure.thresholdValue1Low,
        thresholdValue2Low: measure.thresholdValue2Low,
        thresholdValue3Low: measure.thresholdValue3Low,
        thresholdValue4Low: measure.thresholdValue4Low,
        thresholdValue5Low: measure.thresholdValue5Low,
        thresholdValue1High: measure.thresholdValue1High,
        thresholdValue2High: measure.thresholdValue2High,
        thresholdValue3High: measure.thresholdValue3High,
        thresholdValue4High: measure.thresholdValue4High,
        thresholdValue5High: measure.thresholdValue5High,
        descriptionFi: measure.descriptionFi,
        descriptionEn: measure.descriptionEn,
        descriptionSv: measure.descriptionSv
    };
    return json;
}
