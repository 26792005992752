'use strict';

const urlBase = '/api/indicators';

angular.module('ecoeasyApp') 
    .factory('Indicator', function ($resource) {
        return $resource(urlBase + '/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    });

    
angular.module('ecoeasyApp') 
        .service('indicatorService', function ($http) {

                this.findByCompanyId = function(company_id) {
                    return $http.get(urlBase + "/byCompany/" + company_id);
                }

                this.save = function(company_id, indicator) {
                    return $http.post(urlBase + "/company/" + company_id, indicator);
                }

                this.saveAll = function(company_id, indicators) {
                    return $http.post(urlBase + "/all", indicators);
                }

                this.getIndicator = function(id){
                  return $http.get(urlBase+'/'+id);  
                };
                
                this.getByCompany = function(company_id){
                    return $http.get(urlBase + '/company/' + company_id);
                };

                this.getValues = function(company_id){
                    return $http.get(urlBase + '/value/company/' + company_id);                
                };

                this.getCVValues = function(variable_id, company_id, monthly){
                    return $http.get(urlBase + '/value/variable/'+ variable_id + '/company/'+company_id+'/monthly/'+monthly);
                };

                this.getParent = function(variable_id, company_id, monthly){
                    return $http.get(urlBase + '/value/drilled/'+ variable_id + '/company/'+company_id+'/monthly/'+monthly);
                };
                
                this.getPeriodValues = function(variable_id, company_id, monthly, position){
                    return $http.get(urlBase + '/period/variable/'+ variable_id + '/company/'+company_id+'/monthly/'+monthly+"/"+position);
                };

                this.getIndicatorValue = function(indicator_id, company_id) {
                    return $http.get(urlBase + '/value/'+indicator_id+'/company/'+company_id);
                };

});