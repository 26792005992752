'use strict';

angular.module('ecoeasyApp')
    .controller('MergedFinancialsController', function ($scope, $http, $q, $state, Principal, SelectedCompanyService, FEATURE_COMPANY_ACCOUNT_CHART, companyids) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;

        });
        $scope.companyids = companyids;



        $scope.show_new_income_statement = true;
        var is_raw;
        $scope.is;
        $scope.isReady = false;
        var bs_assets_raw;
        $scope.bs_assets;
        $scope.bsAssetsReady = false;
        var bs_liab_raw;
        $scope.bs_liab;
        $scope.bsLiabReady = false;

        var codeToId = {};;
        $scope.isValidPreviousData = true;


        function numberWithSeparator(x) {
            var number = x.toFixed(2);
            var parts = number.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(",");
        }



        $scope.rowSelected = function (code) {
            $state.go('drill', {variableid: codeToId[code]});
        }

        $scope.getChildClass = function (varRow) {
            if (varRow.child) {
                return "subvariable";
            }
            else {
                return "variable";
            }
        }
    });


