'use strict';

angular.module('ecoeasyApp')
    .controller('AccountCategoryController', function ($scope, $state, AccountCategory) {

        $scope.accountCategorys = [];
        $scope.loadAll = function () {
            AccountCategory.query(function (result) {
                $scope.accountCategorys = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.accountCategory = {
                companyId: null,
                code: null,
                name: null,
                id: null
            };
        };
    });
