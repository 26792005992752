'use strict';
(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('PermissionManagementDialogController', PermissionManagementDialogController);

    PermissionManagementDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'User', 'Principal', 'sourceState', 'Permission', 'UserService', 'targetEntity', 'permissions', 'companyService', '$http', 'permissionMappingService', '$translate'];

    function PermissionManagementDialogController($timeout, $scope, $stateParams, $uibModalInstance, User, Principal, sourceState, Permission, UserService, targetEntity, permissions, companyService, $http, permissionMappingService, $translate) {
        $scope.targetEntityName = "";
        $scope.permissions = permissions;
        $scope.permissionSelected = false;
        $scope.companies = [];
        $scope.selectedCompanies = [];
        $scope.selectedPermission = undefined;
        $scope.sourceStateSref = "";
        $scope.sourceId = undefined;
        $scope.isCompanyLvlPermission = true;
        $scope.showCompanies = true;
        $scope.targetEntityId = undefined;
        $scope.isSaving = false;
        $scope.companyLvlConfiguration = true;
        $scope.targetEntityPermissions = [];
        $scope.toolTipText = $translate.instant('permissionManagement.dialog.toolTipText');
        var allCompaniesIds = [];

        if ($stateParams.sourceType === "user") {
            $scope.targetEntityName = targetEntity.login;
            permissionMappingService.getAllPermissionsForUser(targetEntity.id)
                .then(function (data) {
                    $scope.targetEntityPermissions = data;
                    angular.forEach($scope.targetEntityPermissions, function (permissionMapping) {
                    });
                });


        } else if ($stateParams.sourceType === "usergroup") {
            $scope.targetEntityName = targetEntity.name;
            permissionMappingService.getAllPermissionsForUserGroup(targetEntity.id)
                .then(function (data) {
                    $scope.targetEntityPermissions = data;
                    angular.forEach($scope.targetEntityPermissions, function (permissionMapping) {
                    });
                });
        }

        $scope.targetEntityId = $stateParams.sourceId;

        if (sourceState === "ORG") {
            $scope.sourceStateSref = "org-user-management";

            Principal.identity()
                .then(function (account) {
                    $scope.sourceId = account.sourceId;
                    companyService.getCompanyIdAndNameForOrg($scope.sourceId).then(function (companies) {
                        $scope.companies = companies;
                        angular.forEach(companies, function (company) {
                            allCompaniesIds.push(company.companyId);
                        });
                    });
                });
        } else {
            $scope.sourceStateSref = "company-user-management";
            //company level permission management 
        }


        $scope.configurePermission = function (selectedPermission) {
            $scope.selectedCompanies = [];
            $scope.selectedPermission = selectedPermission;
            $scope.permissionSelected = true;
            if (selectedPermission.companyLvl) {
                $scope.isCompanyLvlPermission = true;
                $scope.companyLvlConfiguration = true;

                angular.forEach($scope.targetEntityPermissions, function (permissionMapping) {
                    if (permissionMapping.permissionId === selectedPermission.id && permissionMapping.companyId == null) {
                        $scope.selectedCompanies = allCompaniesIds;
                    }
                    else if (permissionMapping.permissionId === selectedPermission.id) {
                        $scope.selectedCompanies.push(permissionMapping.companyId);
                    }
                });

            } else if (!selectedPermission.companyLvl) {
                $scope.isCompanyLvlPermission = false;
                $scope.companyLvlConfiguration = false;
                $scope.selectedCompanies = [];
            }

        };

        $scope.setCompanies = function (selectedCompanies) {
            $scope.selectedCompanies = selectedCompanies;
        };

        $scope.checkBoxChanged = function (companyLvlConfiguration) {
            if (companyLvlConfiguration) {
                $scope.companyLvlConfiguration = companyLvlConfiguration;
            } else {
                $scope.selectedCompanies = [];
            }
        };

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
            $uibModalInstance.close(true);
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        //TODO taytyy ottaa huomioon jos oikeus koskee usrgrouppia
        $scope.save = function () {
            $scope.isSaving = true;
            var permissionId = {permissionId: $scope.selectedPermission.id};
            var companyLvlPermissions = {companyLvlPermissions: $scope.companyLvlConfiguration};
            //var sourceType = {sourceType: $stateParams.type}; $stateParams
            var sourceType = {sourceType: $stateParams.sourceType};

            if ($stateParams.sourceType === "user") {
                var userId = {userId: $scope.targetEntityId};
                var userGroupId = {userGroupId: null};
            } else {
                var userGroupId = {userGroupId: $scope.targetEntityId};
                var userId = {userId: null};
            }

            //Company level permission 
            if ($scope.companyLvlConfiguration) {

                var companies = $scope.selectedCompanies;
                var array = new Array();
                angular.forEach(companies, function (companyId) {
                    array.push(companyId);
                });

                var companyIdList = {companyIdList: array};
                var obj = {};
                angular.extend(obj, companyIdList, userId, userGroupId, sourceType, companyLvlPermissions, permissionId);

                $http.post("/api/permissionMappings/company", obj)
                    .then(function (response) {
                        if (response.status === 200) {
                            onSaveSuccess(response.data);
                        } else {
                            onSaveError(response.data);
                        }

                    });

                //Organisation level permission
            } else {
                var companyIdList = [];
                var obj = {};
                angular.extend(obj, companyIdList, userId, userGroupId, sourceType, companyLvlPermissions, permissionId);

                $http.post("/api/permissionMappings/organisation", obj)
                    .then(function (response) {
                        if (response.status === 200) {
                            onSaveSuccess(response.data);
                        } else {
                            onsaveError(response.data);
                        }
                    });
            }
        };

    }
})();

