
export const percentageComponent = {
    bindings: {
        value: '<'
    },
    template: '<span ng-bind-html="$ctrl.percentageStr"></span>',
    controller: class PercentageComponent {
        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.percentageStr = '-';
        }

        $onChanges(changesObj) {
            if (typeof this.value !== "undefined" && this.value != null) {
                this.percentageStr = this._formatPercentage(this.value);
            }
        }

        _formatPercentage(input) {
            var number = input.toFixed(2);
            var parts = number.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
            return parts.join(",") + '%';
        }

    }
}

