export class OperatingBudgetService {

    constructor(BudgetRestClientService, CompanyAccountChartService) {
        "ngInject";
        this.budgetRestClient = BudgetRestClientService;
        this.companyAccountChartService = CompanyAccountChartService;
    }

    addRows(companyId, budgetId, data) {
        let addRows = {};
        if (data.createBudgetFactor === 'INFO_ROW') {
            addRows = {
                createBudgetFactor: data.createBudgetFactor,
                accounts: [{ id: null, infoRowName: data.infoRowName }],
                parentId: data.parentId,
            };
        } else {
            addRows = {
                createBudgetFactor: data.createBudgetFactor,
                accounts: data.items.map((item) => { return { id: item.id, percentage: item.percentage }; }),
                parentId: data.parentId
            };
        }
        return this.budgetRestClient.putBudget(companyId, budgetId, { addRows: addRows });
    }

    editRow(companyId, budgetId, data) {
        const editData = {
            id: data.row.id,
            refId: (data.refRow) ? data.refRow.id : null,
            percentage: data.refPercentage
        };
        return this.budgetRestClient.putBudget(companyId, budgetId, { editRow: editData });
    }

    deleteRow(companyId, budgetId, rowId){
        return this.budgetRestClient.deleteBudgetRow(companyId, budgetId, rowId);
    }

    saveMemo(companyId, budgetId, data) {
        const memoData = {
            id: data.row.id,
            memo: data.memo ? data.memo : ""
        };
        return this.budgetRestClient.putBudget(companyId, budgetId, { memoData: memoData });
    }

}