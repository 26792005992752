export class CompanyVariableService {

    constructor($q, indicatorService) {
        "ngInject";
        this.$q = $q;
        this.indicatorService = indicatorService;
    }

    getDrilled(companyVariableId, companyId, monthly) {
        return this.$q((resolve, reject) => {
            this.indicatorService.getParent(companyVariableId, companyId, monthly).then((success) => {
                resolve(success.data);
            }, (fail) => {
                reject(fail);
            });
        });
    }

    getVariables(companyVariableId, companyId, monthly) {
        return this.$q((resolve, reject) => {
            let varsByAccount = {};
            let varsByCode = {};
            let variables = [];
            this.indicatorService.getCVValues(companyVariableId, companyId, monthly).then((response) => {
                const allVariables = response.data;
                allVariables.forEach((v) => {
                    if (v.indicator.accountnmbr && v.indicator.accountnmbr > 0) {
                        varsByAccount[v.indicator.accountnmbr] = v;
                    }
                    if (v.indicator.code && v.indicator.code.length > 0) {
                        varsByCode[v.indicator.code] = v;
                    }
                    if (v.value != 0) {
                        variables.push(v);
                    }
                });
                resolve({
                    variables: variables,
                    varsByCode: varsByCode,
                    varsByAccount: varsByAccount
                });
            }, (fail) => {
                reject(fail);
            });
        });
    }

}