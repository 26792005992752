'use strict';

angular.module('ecoeasyApp')
    .factory('Budget', function ($resource, DateUtils) {
        return $resource('api/budgets/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.lastModified = DateUtils.convertDateTimeFromServer(data.lastModified);
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    });

angular.module('ecoeasyApp')
    .service('BudgetService', function ($http, $q, $rootScope, $log) {

        return ({
            getByCompany: getByCompany,
            createBudget: createBudget,
            getCodeToIdVariableMap: getCodeToIdVariableMap,
            saveBudgetData: saveBudgetData,
            hasDataForEstimates: hasDataForEstimates,
            saveMaster: saveMaster,
            getPossibleSubBudgets: getPossibleSubBudgets,
            createSubBudgets: createSubBudgets,
            saveFullBudgetData: saveFullBudgetData,
            getPossibleParentBudgets: getPossibleParentBudgets,
            realizeBudget: realizeBudget,
            realizeSubBudget: realizeSubBudget,
            realizeAll:realizeAll,
            getPossiblePositions: getPossiblePositions
        });

        function getByCompany(id) {
            return $http({
                method: "get",
                url: "/api/budgets/company/" + id
            });
        }

        function createBudget(budget, baseFigMethod, estForLast) {

            var method = "";

            if (baseFigMethod === "0") {
                method = "empty";
            }
            if (baseFigMethod === "1") {
                method = "prev";
            }
            else if (baseFigMethod === "2") {
                method = "est"
            }

            return $http.post("/api/budgets?templateSource=" + method + "&estForLast=" + estForLast, budget);
        }

        function getCodeToIdVariableMap() {
            return $http.get("/api/companyVariables/codetoidmap");
        }

        function saveBudgetData(budgetId, data) {
            return $http.put("/api/budgets/" + budgetId + "/data", data);
        }

        function saveFullBudgetData(budgetId, budgetDataList, balanceBudgetDataList) {
            return $http.put("/api/budgets/" + budgetId + "/fulldata", {
                budgetData: budgetDataList,
                balanceBudgetData: balanceBudgetDataList
            });
        }

        function hasDataForEstimates(companyId) {
            return $http.get("/api/companys/" + companyId + "/hasdataforestimates").then(function (response) {
                return response.data;
            });
        }

        function saveMaster(budget) {
            return $http.post("/api/budgets/master", budget).then(function (response) {
                return response.data;
            });
        }

        function getPossibleSubBudgets(companyId) {
            return $http.get("/api/budgets/possiblesubbudgets/" + companyId).then(function (response) {
                return response.data;
            });
        }

        function createSubBudgets(budgetId, subbudgets, successCb, errorCb) {
            $http.post("/api/budgets/" + budgetId + "/subbudgets", subbudgets)
                .then(function (response) {
                    successCb(response);
                }).catch(function (response) {
                errorCb(response);
            });
        }

        function getPossibleParentBudgets(companyId, budgetPosition) {
            return $http.put("/api/budgets/possibleparentbudgets/" + companyId, budgetPosition).then(function (response) {
                return response.data;
            });
        }
        function realizeBudget(id, position, company_id) {
            return $http.get("/api/budget/realization/"+id+"/"+position+"/"+company_id).then(function (response) {
                return response;
            });
        }
        function realizeSubBudget(id, position, company_id, acvId) {
            return $http.get("/api/subbudget/realization/"+id+"/"+position+"/"+company_id+"/"+acvId).then(function (response) {
                return response;
            });
        }
        function getPossiblePositions(id) {
            return $http.get("/api/budgets/possiblepositions/"+id).then(function (response) {
                return response.data;
            });
        }
        function realizeAll(id, position, company_id){
            return $http.get("/api/budget/realizateall/"+id+"/"+position+"/"+company_id).then(function (response) {
                return response;
            });
        }
    });
