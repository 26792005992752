import React from 'react';
import ReactDOM from 'react-dom';

function toBindings(propTypes) {
    const bindings = {};
    Object.keys(propTypes).forEach(key => bindings[key] = '<');
    return bindings;
}

function toProps(propTypes, controller) {
    const props = {};
    Object.keys(propTypes).forEach(key => props[key] = controller[key]);
    return props;
}

/** Helper-function to enable react components use in angularjs.
 *  This function is called in <b>angular module</b>, where react components is wanted to be used.
 *  React component is wired-up by adding <b>.component('reactComponentNameToUse', reactToAngularComponent(ImportedReactComponent))</b>
 *  to angular module configuration.
 */
export function reactToAngularComponent(Component) {
    const propTypes = Component.propTypes || {};

    return {
        bindings: toBindings(propTypes),
        controller: /*@ngInject*/ function($element) {
            this.$onChanges = () => {
                const props = toProps(propTypes, this);
                ReactDOM.render(<Component { ...props } />, $element[0]);
            };
            this.$onDestroy = () => ReactDOM.unmountComponentAtNode($element[0]);
        }
    };
}
