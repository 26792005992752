import template from './company-balance-sheet.html';

import './company-balance-sheet.css';
import {variableTypes} from "../drill/variable-type";

function getRowName(row, locale) {
    if (locale === 'en') {
        if (row.nameEn) {
            return row.nameEn;
        } else {
            return row.nameFi;
        }
    } else if (locale === 'sv') {
        if (row.nameSv) {
            return row.nameSv;
        } else {
            return row.nameFi;
        }
    } else {
        return row.nameFi;
    }
}

class BalanceSheetRow {

    constructor(id, name, currentMonthValue, currentCumulativeMonthValue, lastYearCumulativeMonthValue, indent) {
        this.id = id;
        this.name = name;
        this.currentMonthValue = currentMonthValue;
        this.currentCumulativeMonthValue = currentCumulativeMonthValue;
        this.lastYearCumulativeMonthValue = lastYearCumulativeMonthValue;
        this.indent = indent;
        this.header = indent < 10;
    }

}

class GroupBalanceSheetRow extends BalanceSheetRow {
    constructor(group, prevCumulativeValue, indent, locale) {
        super(group.id, getRowName(group, locale), group.value, group.cumulativeValue, prevCumulativeValue, indent);
    }
}

class AccountBalanceSheetRow extends BalanceSheetRow {
    constructor(account, prevAccountCumulativeValue, locale) {
        super(account.id,account.code + " " + getRowName(account, locale), account.value, account.cumulativeValue, prevAccountCumulativeValue, 10);
    }
}

export const companyBalanceSheetComponent = {
    bindings: {
        companyId: '<',
        year: '<',
        month: '<'
    },
    template: template,
    controller: class CompanyBalanceSheetComponent {
        constructor($log, $http, $q, $state, $translate) {
            "ngInject";
            this.$log = $log;
            this.$http = $http;
            this.$q = $q;
            this.$state = $state;
            this.$translate = $translate;
            this.rows = [];
            this.currentYear = null;
            this.currentMonth = null;
            this.previousYear = null;
            this.previousMonth = null;
            this.assets = null;
        }

        $onInit() {
            this.$log.debug("CompanyBalanceSheetComponent.$onInit()");
        }

        $onChanges(changesObj) {
            this.$log.debug("CompanyBalanceSheetComponent.$onChanges()", changesObj);
            if (this.month && this.year && this.companyId) {
                this.$q.all([
                    this.getBalanceSheetData(this.companyId, this.year, this.month),
                    this.getBalanceSheetData(this.companyId, this.year-1, this.month)]).then(
                    (success)=>{
                        this.$log.debug("got response", success);
                        this.currentYear = success[0].data.year;
                        this.currentMonth = success[0].data.month;
                        this.previousYear = success[1].data.year;
                        this.previousMonth = success[1].data.month;
                        const currentAssets = success[0].data.assets;
                        const prevAssets = success[1].data.assets;
                        const currentCaps = success[0].data.capitalReservesAndLiabilities;
                        const prevCaps = success[1].data.capitalReservesAndLiabilities;
                        this.assets = this.toAssetsRows(currentAssets, prevAssets, this.$translate.use());
                        this.capitalReservesAndLiabilities = this.toAssetsRows(currentCaps, prevCaps, this.$translate.use());
                    },
                    (fail)=>{
                        this.$log.error("failed", fail);
                    })
            }
        }

        getBalanceSheetData(companyId, year, month) {
            const getUrl = "/api/companies/"+companyId+"/balance-sheet/"+year+"/"+month;
            this.$log.debug("going to get", getUrl);
            return this.$http.get(getUrl);
        }

        toAssetsRows(currentAssets, prevAssets, locale) {
            let allPreviousItems = this.getAllItems(prevAssets.groups);
            let assets = new GroupBalanceSheetRow(currentAssets, prevAssets.cumulativeValue, 0, locale);
            assets.rows = this.initRows(currentAssets.groups, allPreviousItems, 1, locale);
            return assets;
        }

        initRows(groups, allPreviousItems, indent, locale) {
            let rows = []
            groups.forEach((group)=>{
                let groupRow = this.createGroupRow(group, indent, allPreviousItems, locale);
                if (groupRow) rows.push(groupRow);
                group.accounts.forEach((account)=>{
                    let accountRow = this.createAccountRow(account, allPreviousItems, locale);
                    if (accountRow) rows.push(accountRow);
                });
                rows = rows.concat(this.initRows(group.groups, allPreviousItems, indent + 1, locale));
            });
            return rows;
        }

        createGroupRow(group, indent, allPreviousItems, locale) {
            const prevGroup = allPreviousItems.find((g)=>{return g.id == group.id});
            let prevCumulativeValue;
            if (prevGroup) {
                prevCumulativeValue = prevGroup.cumulativeValue;
            } else {
                prevCumulativeValue = null;
            }
            if (group.value != 0 || group.cumulativeValue != 0 || prevCumulativeValue != 0) {
                return new GroupBalanceSheetRow(group, prevCumulativeValue, indent, locale);
            }
            return null;
        }

        createAccountRow(account, allPreviousItems, locale) {
            const prevAccount = allPreviousItems.find((g)=>{return g.id == account.id});
            let prevAccountCumulativeValue;
            if (prevAccount) {
                prevAccountCumulativeValue = prevAccount.cumulativeValue;
            } else {
                prevAccountCumulativeValue = null;
            }
            if (account.value != 0 || account.cumulativeValue != 0 || prevAccountCumulativeValue != 0) {
                return new AccountBalanceSheetRow(account, prevAccountCumulativeValue, locale);
            }
            return null;
        }

        getAllItems(groups) {
            let array = [];
            array = array.concat(groups);
            groups.forEach((group)=>{
                this.$log.debug("group.accounts", group.accounts);
                array = array.concat(group.accounts);
                array = array.concat(this.getAllItems(group.groups));
            });
            return array;
        }

        drillMonthly(row, year, month) {
            this.$log.debug("CompanyBalanceSheetComponent.drill(row)", row);
            this.$state.go('drill', {
                variableid: row.id,
                monthly: true,
                variableType: (row.header) ? variableTypes.COMPANY_ACCOUNT_GROUP : variableTypes.COMPANY_ACCOUNT,
                year: year,
                month: month
            });
        }
        drillCumulative(row, year, month) {
            this.$log.debug("CompanyBalanceSheetComponent.drillCumulative(row)", row);
            this.$state.go('drill', {
                variableid: row.id,
                monthly: false,
                variableType: (row.header) ? variableTypes.COMPANY_ACCOUNT_GROUP : variableTypes.COMPANY_ACCOUNT,
                year: year,
                month: month
            });
        }


    }
}