(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('LinkingTableDetailController', LinkingTableDetailController);

    LinkingTableDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LinkingTable', 'Organisation'];

    function LinkingTableDetailController($scope, $rootScope, $stateParams, previousState, entity, LinkingTable, Organisation) {
        var vm = this;

        vm.linkingTable = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ecoeasyApp:linkingTableUpdate', function (event, result) {
            vm.linkingTable = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
