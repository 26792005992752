import React, {useState} from 'react';
import {Table,} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import './working-capital-table.css';
import {workingCapitalInputDetails as inputDetails} from "./working-capital-form-input-details";
import WorkingCapitalTableData from "./working-capital-table-data";
import {object} from "prop-types";

const WorkingCapitalTable = ({tableData, formValues, utilityValues}) => {
    const {t} = useTranslation('workingCapital');
    const [showCurrentAssets, setShowCurrentAssets] = useState(false);
    const [showShortTermDebts, setShowShortTermDebts] = useState(false);
    const [showAccountsPayables, setShowAccountsPayables] = useState(false);

    //CALCULATION TYPES
    const [account30Type, setAccount30Type] = useState('value');
    const [account31Type, setAccount31Type] = useState('value');
    const [account32Type, setAccount32Type] = useState('value');
    const [account33Type, setAccount33Type] = useState('value');
    const [account34Type, setAccount34Type] = useState('value');
    const [account35Type, setAccount35Type] = useState('value');
    const [account23Type, setAccount23Type] = useState('value');
    const [account25Type, setAccount25Type] = useState('value');
    const [account27Type, setAccount27Type] = useState('value');
    const [account47Type, setAccount47Type] = useState('value');
    const [account48Type, setAccount48Type] = useState('value');
    const [account49Type, setAccount49Type] = useState('value');
    const [account50Type, setAccount50Type] = useState('value');
    const [account51Type, setAccount51Type] = useState('value');
    const [account52Type, setAccount52Type] = useState('value');
    const [account53Type, setAccount53Type] = useState('value');
    const [account57Type, setAccount57Type] = useState('value');
    const [account116Type, setAccount116Type] = useState('value');
    const [account117Type, setAccount117Type] = useState('value');
    const [account118Type, setAccount118Type] = useState('value');
    const [account140Type, setAccount140Type] = useState('value');
    const [account141Type, setAccount141Type] = useState('value');
    const [account142Type, setAccount142Type] = useState('value');
    const [account144Type, setAccount144Type] = useState('value');
    const [account145Type, setAccount145Type] = useState('value');
    const [account146Type, setAccount146Type] = useState('value');
    const [account147Type, setAccount147Type] = useState('value');
    const [account151Type, setAccount151Type] = useState('value');

    const states = {
        account_30: {
            type: account30Type,
            setType: setAccount30Type
        },
        account_31: {
            type: account31Type,
            setType: setAccount31Type
        },
        account_32: {
            type: account32Type,
            setType: setAccount32Type
        },
        account_33: {
            type: account33Type,
            setType: setAccount33Type
        },
        account_34: {
            type: account34Type,
            setType: setAccount34Type
        },
        account_35: {
            type: account35Type,
            setType: setAccount35Type
        },
        account_23: {
            type: account23Type,
            setType: setAccount23Type
        },
        account_25: {
            type: account25Type,
            setType: setAccount25Type
        },
        account_27: {
            type: account27Type,
            setType: setAccount27Type
        },
        account_47: {
            type: account47Type,
            setType: setAccount47Type
        },
        account_48: {
            type: account48Type,
            setType: setAccount48Type
        },
        account_49: {
            type: account49Type,
            setType: setAccount49Type
        },
        account_50: {
            type: account50Type,
            setType: setAccount50Type
        },
        account_51: {
            type: account51Type,
            setType: setAccount51Type
        },
        account_52: {
            type: account52Type,
            setType: setAccount52Type
        },
        account_53: {
            type: account53Type,
            setType: setAccount53Type
        },
        account_57: {
            type: account57Type,
            setType: setAccount57Type
        },
        account_116: {
            type: account116Type,
            setType: setAccount116Type
        },
        account_117: {
            type: account117Type,
            setType: setAccount117Type
        },
        account_118: {
            type: account118Type,
            setType: setAccount118Type
        },
        account_140: {
            type: account140Type,
            setType: setAccount140Type
        },
        account_141: {
            type: account141Type,
            setType: setAccount141Type
        },
        account_142: {
            type: account142Type,
            setType: setAccount142Type
        },
        account_144: {
            type: account144Type,
            setType: setAccount144Type
        },
        account_145: {
            type: account145Type,
            setType: setAccount145Type
        },
        account_146: {
            type: account146Type,
            setType: setAccount146Type
        },
        account_147: {
            type: account147Type,
            setType: setAccount147Type
        },
        account_151: {
            type: account151Type,
            setType: setAccount151Type
        }
    };

    //populating inputDetails: currentAssets
    if (tableData.lists !== undefined && tableData.lists.currentAssets.length > 0) {
        tableData.lists.currentAssets.map((account) => {
            const accountName = `account_${account.categoryId}`
            inputDetails[accountName].name = t(`table.currentAssetsAccounts.${accountName}`);
            const options = [{type: 'value', translation: t(`table.dropdownOptions.value`)}];

            if (account.percent !== null && options.find((option) => option !== t(`table.dropdownOptions.percent`))) {
                options.push({type: 'percent', translation: t(`table.dropdownOptions.percent`)});
            }
            if (account.turnover !== null && options.find((option) => option !== t(`table.dropdownOptions.turnover`))) {
                options.push({type: 'turnover', translation: t(`table.dropdownOptions.turnover`)});
            }
            inputDetails[accountName].options = options;
        });
    }

    //populating inputDetails: shortTermDebts
    if (tableData.lists !== undefined && tableData.lists.shortTermDebts.length > 0) {
        tableData.lists.shortTermDebts.map((account) => {
            const accountName = `account_${account.categoryId}`
            inputDetails[accountName].name = t(`table.shortTermDebtsAccounts.${accountName}`);
            const options = [{type: 'value', translation: t(`table.dropdownOptions.value`)}];

            if (account.percent !== null && options.find((option) => option !== t(`table.dropdownOptions.percent`))) {
                options.push({type: 'percent', translation: t(`table.dropdownOptions.percent`)});
            }
            if (account.turnover !== null && options.find((option) => option !== t(`table.dropdownOptions.turnover`))) {
                options.push({type: 'turnover', translation: t(`table.dropdownOptions.turnover`)});
            }
            inputDetails[accountName].options = options;
        });
    }

    //populating inputDetails: accountsPayables
    if (tableData.lists !== undefined && tableData.lists.accountsPayables.length > 0) {
        tableData.lists.accountsPayables.map((account) => {
            const accountName = `account_${account.categoryId}`
            inputDetails[accountName].name = t(`table.accountsPayablesAccounts.${accountName}`);
            const options = [{type: 'value', translation: t(`table.dropdownOptions.value`)}];

            if (account.percent !== null && options.find((option) => option !== t(`table.dropdownOptions.percent`))) {
                options.push({type: 'percent', translation: t(`table.dropdownOptions.percent`)});
            }
            if (account.turnover !== null && options.find((option) => option !== t(`table.dropdownOptions.turnover`))) {
                options.push({type: 'turnover', translation: t(`table.dropdownOptions.turnover`)});
            }
            inputDetails[accountName].options = options;
        });
    }

    const showSubTableData = (data, prefix, preCondition) => {
        return data.length > 0 && preCondition ?
            <WorkingCapitalTableData
                data={data}
                prefix={prefix}
                states={states}
                formValues={formValues}
                utilityValues={utilityValues}
            />
            :
            <tr/>;
    }

    return (
        <div>
            <div>
                {tableData.lists ?
                    <Table bordered>
                        <thead className='label-title-row'>
                        <tr>
                            <th>
                                {t('table.title.name')}
                            </th>
                            <th>
                                {t('table.title.inputMethod')}
                            </th>
                            <th>
                                {t('table.title.calculatedTurnover')}
                            </th>
                            <th colSpan="2">
                                {t('table.title.inputValue')}
                            </th>
                            <th>
                                {t('table.title.resultValue')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {tableData.lists.currentAssets.length > 0 ?
                                <tr className='title-row'>
                                    <td colSpan="">
                                        {showCurrentAssets ?
                                            <span className='glyphicon glyphicon-menu-up'
                                                    onClick={() => setShowCurrentAssets(false)}></span>
                                            : <span className='glyphicon glyphicon-menu-down'
                                                    onClick={() => setShowCurrentAssets(true)}></span>
                                        }
                                        <b>{t('table.subtitles.currentAssets')}</b></td>
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                                :
                                <tr className='title-row'>
                                    <td className='alt-title-name-cell'><b>Vaihto-omaisuus</b></td>
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                            }
                            {showSubTableData(tableData.lists.currentAssets, 'ca', showCurrentAssets)}
                            {tableData.lists.shortTermDebts.length > 0 ?
                                <tr className='title-row'>
                                    <td>
                                        {showShortTermDebts ?
                                            <span className='glyphicon glyphicon-menu-up'
                                                    onClick={() => setShowShortTermDebts(false)}></span>
                                            :
                                            <span className='glyphicon glyphicon-menu-down'
                                                    onClick={() => setShowShortTermDebts(true)}></span>
                                        }
                                        <b>{t('table.subtitles.shortTermDebts')}</b>
                                    </td>
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                                :
                                <tr className='title-row'>
                                    <td className='alt-title-name-cell'><b>Lyhytaikaiset saamiset</b></td>
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                            }
                            {showSubTableData(tableData.lists.shortTermDebts, 'std', showShortTermDebts)}
                            {tableData.lists.accountsPayables.length > 0 ?
                                <tr className='title-row'>
                                    <td>
                                        {showAccountsPayables ?
                                            <span className='glyphicon glyphicon-menu-up'
                                                    onClick={() => setShowAccountsPayables(false)}></span>
                                            :
                                            <span className='glyphicon glyphicon-menu-down'
                                                    onClick={() => setShowAccountsPayables(true)}></span>
                                        }
                                        <b>{t('table.subtitles.accountsPayables')}</b>
                                    </td>
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                                :
                                <tr className='title-row'>
                                    <td className='alt-title-name-cell'>
                                        <b>Korottomien lyhytaikaisten velkojen muutokset</b>
                                    </td>
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                            }
                            {showSubTableData(tableData.lists.accountsPayables, 'ap', showAccountsPayables)}
                        </tbody>
                    </Table>
                : null}
            </div>
        </div>
    );
};

WorkingCapitalTable.propTypes = {
    tableData: object,
    formValues: object,
    utilityValues: object,
};

export default WorkingCapitalTable;