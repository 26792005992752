'use strict';

angular.module('ecoeasyApp')
    .factory('Permission', function ($resource, DateUtils) {
        return $resource('api/permissions/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    });
    
angular.module('ecoeasyApp')
        .service('permissionMappingService', function($http, $q, $rootScope, $log){
            return({
                getAllPermissionsForUser: getAllPermissionsForUser,
                getAllPermissionsForUserGroup: getAllPermissionsForUserGroup,
                getPermissionMappingById: getPermissionMappingById,
                deletePermissionMapping: deletePermissionMapping,
                getAllUsersAuthorotiesPermissions: getAllUsersAuthorotiesPermissions
            });
            
            function getAllPermissionsForUserGroup(userGroupId){
                return $http({
                    method: "get",
                    url: "api/permissionMappings/userGroup/" + userGroupId
                }).then(function(response){
                    return response.data;
                });
            }

            function getAllPermissionsForUser(userId){
                return $http({
                    method: "get",
                    url: "api/permissionMapping/user/" + userId
                }).then(function(response){
                    return response.data;
                });
            }
            
            function getPermissionMappingById(permissionMappingId){
                return $http({
                    method: "get",
                    url: "api/permissionMappings/" + permissionMappingId
                }).then(function(response){
                    return response.data;
                });
            }
            
            function deletePermissionMapping(permissionMappingId){
                return $http({
                    method: "delete",
                    url: "api/permissionMappings/" + permissionMappingId
                }).then(function(response){
                    return response.data;
                });
            }
            
            function getAllUsersAuthorotiesPermissions(userId){
                return $http({
                    method: "get",
                    url: "api/permissionMappings/userAuthorities/" + userId
                }).then(function (response){
                    return response.data;
                });
            }
            
});
            
