'use strict';

angular.module('ecoeasyApp') 
    .factory('Industry', function ($resource, DateUtils) {
        return $resource('api/industrys/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    })
    .service('IndustryService', function($http){
        
        function getActualIndustries() {
            return $http.get('/api/industrys/tol2008/actual').then(function(response) {
                return response.data;
            });
        }
                
        return {
            getActualIndustries : getActualIndustries
        };
    });
