import {IncomeStatementRow} from "./income-statement-row";

function getRowName(row, locale) {
    if (locale === 'en') {
        if (row.nameEn) {
            return row.nameEn;
        } else {
            return row.nameFi;
        }
    } else if (locale === 'sv') {
        if (row.nameSv) {
            return row.nameSv;
        } else {
            return row.nameFi;
        }
    } else {
        return row.nameFi;
    }
}

export class GroupIncomeStatementRow extends IncomeStatementRow {

    constructor(group, prevGroup, indent, locale) {
        super(group.id, getRowName(group, locale), group.value, group.percentageValue, group.cumulativeValue, group.percentageCumulativeValue,
            prevGroup.value, prevGroup.percentageValue, prevGroup.cumulativeValue, prevGroup.percentageCumulativeValue, indent, true);
    }
}
