export class IncomeStatementRestClientService {

    constructor(HttpClientService) {
        "ngInject";
        this.httpClient = HttpClientService;
    }

    getIncomeStatement(companyId, year, month) {
        return this.httpClient.get("/api/companies/" + companyId + "/income-statement/" + year + "/" + month).then( response => response.data);
    }

    getMergedIncomeStatement(companyids) {
        return this.httpClient.get("/api/companies/merged-financials/"+ companyids +'').then( response => response.data);
    }

}
