'use strict';

import BudgetTableData from "./budget-table/budget-table-data";
import SumRow from "./budget-table/sum-row";
import ValueRowAbsolute from "./budget-table/value-row-absolute";
import BudgetDataManager from "./budget-data-manager";

angular.module('ecoeasyApp')
    .controller('BudgetController', function ($stateParams, $scope, $translate, $log, $q, $http, BudgetService, Budget, BudgetData, Company, Principal, $state, $timeout, InvestmentBudgetData, FundingBudgetData, DevaluationBudgetData, $uibModal) {
        $scope.selectedPosition = null;
        $scope.selectedVersion = null;
        $scope.linkedBudget = null;
        $scope.budgetVersions = [];
        $scope.showTable = false;
        $scope.positions = [];
        $scope.columnsLength = 0;
        $scope.balanceColumns = [];
        $scope.enableSave = true;
        $scope.devaluations = [];
        $scope.budgets = null;
        $scope.investmentBudgetDatas = [];
        $scope.fundingBudgetDatas = [];
        $scope.investmentFundingBudgetDatas = [];
        var possibleRealizePositions = [];

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });


        var company;
        var startPos;
        window.ts = $scope;
        $scope.balanceData = [];
        $scope.selectedSubBudget = null;
        var posToPosition = {};

        //Includes selected budgets' child and parent budgets
        $scope.budgetTree = [];

        $q.when($scope.$root.getSelectedCompany()).then(function (selComp) {
            var comp = selComp;
            if (comp == null) {
                $q.when($scope.$root.getDefaultCompany()).then(function (defComp) {
                    if (defComp != null) {
                        company = defComp;
                        init(defComp.id);
                    }
                });
            } else {
                company = comp;
                init(comp.id);
            }
        });


        function init(companyId) {
            BudgetService.getByCompany(companyId).then(function (budgets) {
                $scope.budgets = budgets.data;
                $http.get("/api/fiscalYears/company/" + company.id + "/sorted").then(function (result) {
                    if ($scope.budgets && $scope.budgets.length > 0) {
                        addFiscalYears(result.data);
                        handlePositions();
                        selectInitialBudget();
                        getPossibleRealizePositions();
                    }
                });
            });
        }

        function getPossibleRealizePositions() {
            BudgetService.getPossiblePositions($scope.selectedBudget.id).then(function (result) {
                possibleRealizePositions = [];
                result.forEach(function (item) {
                    var s = item + "";
                    var i = s.slice(4);
                    var tr = $translate.instant('months.' + i);
                    possibleRealizePositions.push({value: item, name: tr});
                });
            });
        }

        function addFiscalYears(fiscalYears) {
            for (var i = 0; i < $scope.budgets.length; i++) {
                $scope.budgets[i].fiscalYear = getFiscalYear(fiscalYears, $scope.budgets[i]);
            }
        }

        function getFiscalYear(fiscalYears, budget) {
            for (var i = 0; i < fiscalYears.length; i++) {
                if (new Date(fiscalYears[i].startDate).getFullYear() == budget.targetPeriod) {
                    if (fiscalYears[i].length < 12) {
                        if (i + 1 < fiscalYears.length && new Date(fiscalYears[i + 1].startDate).getFullYear() == budget.targetPeriod) {
                            return fiscalYears[i + 1];
                        } else {
                            return fiscalYears[i];
                        }

                    } else {
                        return fiscalYears[i];
                    }
                }
            }
            return null;
        }

        function handlePositions() {
            for (var i = 0; i < $scope.budgets.length; i++) {
                var found = false;
                for (var j = 0; j < $scope.positions.length; j++) {
                    if ($scope.positions[j].pos === $scope.budgets[i].targetPeriod) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    var posObject = {
                        pos: $scope.budgets[i].targetPeriod,
                        posString: getPositionString($scope.budgets[i])
                    };
                    posToPosition[$scope.budgets[i].targetPeriod] = posObject;
                    $scope.positions.push(posObject);
                }
            }
        }


        function getPositionString(budget) {
            var startDate = new Date(budget.fiscalYear.startDate);
            var endDate = new Date(budget.fiscalYear.endDate);
            var s = (startDate.getMonth() + 1) + "/" + startDate.getFullYear() + " - ";
            s = s + (endDate.getMonth() + 1) + "/" + endDate.getFullYear();
            return s;
        }

        function selectInitialBudget() {
            $log.debug("BudgetController.selectInitialBudget");

            var found = false;
            $scope.budgets.forEach(function (budget) {
                if (budget.master) {
                    $scope.selectedBudget = budget;
                    budget.description = budget.description + " (" + $translate.instant("budget.mastertitle") + ")";
                    found = true;
                }
            });
            if (!found) {
                $scope.selectedBudget = $scope.budgets[$scope.budgets.length - 1];
            }

            $scope.selectedPosition = posToPosition[$scope.selectedBudget.targetPeriod];
            $scope.selectedVersion = $scope.selectedBudget;
            $scope.versionSelected($scope.selectedBudget);
        }

        $scope.rowHead = [$translate.instant('variable.liikevaihto').toUpperCase(),
            "&nbsp;&nbsp;" + $translate.instant('variable.myyntituotot'),
            "&nbsp;&nbsp;" + $translate.instant('variable.myynnin_oikaisuerat'),
            $translate.instant('variable.valmiiden_ja_keskeneraisten_tuotteiden_varastojen_lisays_tai_vahennys'),
            $translate.instant('variable.valmistus_omaan_kayttoon'),
            $translate.instant('variable.liiketoiminnan_muut_tuotot'),
            $translate.instant('variable.materiaalit_ja_palvelut'),
            "&nbsp;&nbsp;" + $translate.instant('variable.aineet_tarvikkeet_ja_tavarat'),
            "&nbsp;&nbsp;" + $translate.instant('variable.ulkopuoliset_palvelut'),
            $translate.instant('variable.muuttuvat_kulut'),
            "&nbsp;&nbsp;" + $translate.instant('variable.muuttuvat_palkat'),
            "&nbsp;&nbsp;" + $translate.instant('variable.muuttuvat_henkilosivukulut'),
            "&nbsp;&nbsp;" + $translate.instant('variable.muut_muuttuvat_kulut_yhteensa'),
            $translate.instant('variable.myyntikate').toUpperCase(),
            $translate.instant('variable.henkilostokulut'),
            "&nbsp;&nbsp;" + $translate.instant('variable.palkat_ja_palkkiot'),
            "&nbsp;&nbsp;&nbsp;&nbsp;" + $translate.instant('variable.tyontekijoiden_palkat_ja_palkkiot'),
            "&nbsp;&nbsp;&nbsp;&nbsp;" + $translate.instant('variable.johdon_palkat'),
            "&nbsp;&nbsp;&nbsp;&nbsp;" + $translate.instant('variable.osakkaiden_omaisten_palkat'),
            "&nbsp;&nbsp;&nbsp;&nbsp;" + $translate.instant('variable.luontoisetujen_vastatili'),
            "&nbsp;&nbsp;" + $translate.instant('variable.henkilosivukulut'),
            $translate.instant('variable.liiketoiminnan_muut_kulut'),
            $translate.instant('variable.osuus_osakkuusyritysten_tuloksesta'),
            $translate.instant('variable.kayttokate').toUpperCase(),
            $translate.instant('variable.poistot_ja_arvonalentumiset'),
            $translate.instant('variable.liikevoitto').toUpperCase(),
            $translate.instant('variable.rahoitustuotot_ja_kulut'),
            $translate.instant('variable.satunnaiset_erat'),
            $translate.instant('variable.tilinpaatossiirrot'),
            $translate.instant('variable.tuloverot'),
            $translate.instant('variable.varallisuusverot_muut_valittomat_verot'),
            $translate.instant('variable.osuus_osakkuusyritysten_tuloksesta'),
            $translate.instant('variable.tilikauden_voitto_yht').toUpperCase()
        ];

        var rowHeadCodes = [
            "liikevaihto",
            "myyntituotot",
            "myynnin_oikaisuerat",
            "valmiiden_ja_keskeneraisten_tuotteiden_varastojen_lisays_tai_vahennys",
            "valmistus_omaan_kayttoon",
            "liiketoiminnan_muut_tuotot",
            "materiaalit_ja_palvelut",
            "aineet_tarvikkeet_ja_tavarat",
            "ulkopuoliset_palvelut",
            "muuttuvat_kulut",
            "muuttuvat_palkat",
            "muuttuvat_henkilosivukulut",
            "muut_muuttuvat_kulut_yhteensa",
            "myyntikate",
            "henkilostokulut",
            "palkat_ja_palkkiot",
            "tyontekijoiden_palkat_ja_palkkiot",
            "johdon_palkat_yhteensa",
            "osakkaiden_ja_omaisten_palkat_yhteensa",
            "luontoisetujen_vastatili",
            "henkilosivukulut",
            "liiketoiminnan_muut_kulut",
            "osuus_osakkuusyritysten_tuloksesta",
            "kayttokate",
            "poistot_ja_arvonalentumiset",
            "liikevoitto",
            "rahoitustuotot_ja_kulut",
            "satunnaiset_erat",
            "tilinpaatossiirrot",
            "tuloverot",
            "varallisuusverot_muut_valittomat_verot",
            "vahemmistoosuudet",
            "tilikauden_voitto_yht"
        ];

        $scope.readOnlyTable = false;

        var rawData = {};
        const budgetDataManager = new BudgetDataManager($log, BudgetService, BudgetData);

        var rowToFormula = {
            0: "=SUM(;col;2,;col;3)",
            6: "=SUM(;col;8,;col;9)",
            9: "=SUM(;col;11:;col;13)",
            13: "=SUM(;col;1,;col;4,;col;5,;col;6,;col;7,;col;10)",
            14: "=SUM(;col;16,;col;21)",
            15: "=SUM(;col;17:;col;20)",
            23: "=SUM(;col;14,;col;15,;col;22,;col;23)",
            25: "=SUM(;col;24,;col;25)",
            32: "=SUM(;col;26:;col;32)"
        };

        $scope.versionFilter = function (budget) {
            return $scope.selectedPosition && budget.targetPeriod === $scope.selectedPosition.pos;
        };

        $scope.versionSelected = function (selectedVersion) {
            $log.debug("BudgetController.versionSelected", selectedVersion);

            $scope.selectedBudget = selectedVersion;

            if (selectedVersion.subBudgets.length > 0) {
                if (selectedVersion.subBudgets[0].accountCategoryValueId != 0) {
                    var sub = {name: "Ylätaso", accountCategoryValueId: 0};
                    selectedVersion.subBudgets.splice(0, 0, sub);
                    $scope.selectedSubBudget = sub;
                } else {
                    $scope.selectedSubBudget = selectedVersion.subBudgets[0];
                }
                $scope.readOnlyTable = true;
            } else {
                $scope.readOnlyTable = false;
            }

            loadDataForBudget();
        };

        $scope.changeTargetPeriod = function (selectedPeriod) {
            $log.debug("BudgetController.changeTargetPeriod", selectedPeriod);

            var found = false;
            $scope.budgets.forEach(function (budget) {
                if (budget.master && (getPositionString(budget) === selectedPeriod.posString)) {
                    $scope.selectedBudget = budget;
                    found = true;

                }
            });
            if (!found) {
                var selBudget;
                $scope.budgets.forEach(function (budget) {
                    if (getPositionString(budget) === selectedPeriod.posString) {
                        selBudget = budget;
                    }
                });
                $scope.selectedBudget = selBudget;
            }
            $scope.selectedVersion = $scope.selectedBudget;

            if ($scope.selectedBudget.subBudgets.length > 0) {
                if ($scope.selectedBudget.subBudgets[0].accountCategoryValueId != 0) {
                    var sub = {name: "Ylätaso", accountCategoryValueId: 0};
                    $scope.selectedBudget.subBudgets.splice(0, 0, sub);
                    $scope.selectedSubBudget = sub;
                } else {
                    $scope.selectedSubBudget = $scope.selectedBudget.subBudgets[0];
                }
                $scope.readOnlyTable = true;
            } else {
                $scope.readOnlyTable = false;
            }

            loadDataForBudget();
        };

        $scope.changeSelectedBudgetOnBudgetTree = function (selectedLinkedBudget) {
            $log.debug("BudgetController.changeSelectedBudgetOnBudgetTree", selectedLinkedBudget);

            if (selectedLinkedBudget) {
                $scope.selectedBudget = selectedLinkedBudget;

                loadDataForBudget();
                $scope.selectedPosition = posToPosition[$scope.selectedBudget.targetPeriod];
                $scope.selectedVersion = $scope.selectedBudget;
            }
        }

        function refreshBudgetModule() {
            loadDataForBudget();
            $scope.selectedPosition = posToPosition[$scope.selectedBudget.targetPeriod];
            $scope.selectedVersion = $scope.selectedBudget;
        }

        $scope.saveBudget = function () {
            budgetDataManager.saveBudgetData($scope.selectedBudget.id);
        };

        function loadDataForBudget() {
            // var bug_2369 = false;
            rawData = {};

            budgetDataManager.getBudgetData($scope.selectedBudget.id, function (budgetData) {
                budgetData.forEach(function (entity) {
                    if (!rawData[entity.position]) {
                        rawData[entity.position] = {};
                        if ($scope.selectedBudget.subBudgets.length > 0) {
                            for (var i = 0; i < $scope.selectedBudget.subBudgets.length; i++) {
                                rawData[entity.position][$scope.selectedBudget.subBudgets[i].accountCategoryValueId] = {}
                            }
                        } else {
                            rawData[entity.position][0] = {};
                        }
                    }
                    rawData[entity.position][entity.accountCategoryValueId][entity.companyVariable.code] = entity.value;
                });

                getPossibleRealizePositions();
                initializeTable($scope.selectedBudget, 0);

                if ($stateParams.sub != null) {
                    $scope.subBudgetSelected($stateParams.sub, $scope.selectedSubBudget.accountCategoryValueId);
                    $scope.selectedSubBudget = $stateParams.sub;
                    $stateParams.sub = null;
                }
            });
        }

        $scope.hilightRenderer = function (hotInstance, td, row, col, prop, value, cellProperties) {
            td.innerHTML = value;
        };

        $scope.subBudgetSelected = function (selectedSub, oldACV) {
            $log.debug("BudgetController.subBudgetSelected", selectedSub);
            if (selectedSub) {
                initializeTable($scope.selectedBudget, selectedSub.accountCategoryValueId);
                $scope.selectedSubBudget = selectedSub;
            }
        };

        function intializeColumns(selectedBudgetFiscalYearStartDate) {
            let monthNumbers = [];
            let startMonth = new Date(selectedBudgetFiscalYearStartDate).getMonth() + 1;
            for (let i = 0; i < 12; i++) {
                let month = i + startMonth;
                if ((month) <= 12) {
                    monthNumbers.push(month);
                } else {
                    monthNumbers.push(month - 12);
                }
            }
            return monthNumbers;
        }

        function initData(_startPos, _rowHead, _rowHeadCodes, _selectedData, _acvId, _rowToFormula) {
            let data = [];
            let currentPos = _startPos;
            const colToChar = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
            for (let i = 0; i < _rowHead.length; i++) {
                let row = [];
                let code = _rowHeadCodes[i];
                for (let j = 1; j < 13; j++) {
                    let value = 0;

                    if (angular.isDefined(_selectedData[currentPos]) && angular.isDefined(_selectedData[currentPos][_acvId][code])) {
                        value = Math.round(_selectedData[currentPos][_acvId][code]);
                    }
                    if (!value) {
                        value = 0.0;
                    }
                    if (angular.isDefined(_rowToFormula[i])) {
                        row.push(_rowToFormula[i].replace(/;col;/g, colToChar[j]));
                    } else {
                        row.push(value);
                    }
                    currentPos = getNextPos(currentPos);
                }

                row.push("=SUM(A" + (i + 1) + ":L" + (i + 1) + ")");

                data.push(row);
                currentPos = _startPos;
            }
            return data;
        }

        function initializeTable(_selectedBudget, acvId) { // = accountCategoryValueId
            $scope.showTable = false;
            startPos = _selectedBudget.fiscalYear.startPosition;
            const selectedData = angular.copy(rawData);
            $log.debug("BudgetController.initializeTable: selectedData=", selectedData);

            const data = initData(startPos, $scope.rowHead, rowHeadCodes, selectedData, acvId, rowToFormula);

            $log.debug("BudgetController.initializeTable: data=", data);

            if (_selectedBudget.subBudgets.length > 0) {
                if (acvId === 0) {
                    $scope.readOnlyTable = true;
                } else {
                    $scope.readOnlyTable = false;
                }
            }
            let monthNumbers = intializeColumns(_selectedBudget.fiscalYear.startDate);
            $scope.columnsLength = monthNumbers.length;
            let accountCategoryValueId = getAccountCategoryValueId();
            $scope.tableData = initTableData(monthNumbers, $scope.rowHead, data, accountCategoryValueId);
            $scope.showTable = true;

        }

        function initTableData(monthNumbers, tableSettingsRowHeaders, data, accountCategoryValueId) {
            let rowHeaders = [];
            tableSettingsRowHeaders.forEach((header, index) => {
                let myHeader = header;
                let level = 0;
                while (myHeader.indexOf("&nbsp;")>=0) {
                    level += 1;
                    myHeader = myHeader.substring("&nbsp;".length);
                }
                let main = myHeader == myHeader.toUpperCase();
                let id = accountCategoryValueId + 'A' + (index+1);
                rowHeaders.push({name: myHeader, ordinal: index, level: level, id: id, main: main, total: 0});
            });
            data.forEach((r, index)=>{
                rowHeaders[index].sum = typeof r[0] != 'number';
                if (rowHeaders[index].sum) {
                    rowHeaders[index].values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    let addendsWithRanges = r[0].substring("=SUM(".length, r[0].length-1).split(',');
                    let addendIds = [];
                    addendsWithRanges.forEach((item)=>{
                        let ranges = item.split(':');
                        if (ranges.length == 2) {
                            let inRange = false;
                            for (let i = 0; i < 40; i++) {
                                let current = 'A'+i;
                                if (!inRange && current == ranges[0]) {
                                    inRange = true;
                                }
                                if (inRange) {
                                    let addendId = accountCategoryValueId + current;
                                    addendIds.push(addendId);
                                    if (current == ranges[1]) {
                                        inRange = false;
                                    }
                                }
                            }
                        } else {
                            let addendId = accountCategoryValueId + item;
                            addendIds.push(addendId);
                        }
                    });
                    rowHeaders
                        .filter((r)=>{
                            return addendIds.includes(r.id)
                        })
                        .forEach((r)=>{ r.augend = rowHeaders[index].id});
                } else {
                    let values = angular.copy(r);
                    values.pop();
                    rowHeaders[index].values = values;
                }
            });
            let rows = rowHeaders.map((rh)=>{
                if (rh.sum) {
                    return new SumRow(rh.ordinal, rh.id, rh.name, rh.level, rh.main, rh.augend);
                } else {
                    return new ValueRowAbsolute(rh.ordinal, rh.id, rh.name, rh.level, rh.values, rh.augend);
                }
            });
            return new BudgetTableData(monthNumbers, rows);
        }

        function getNextPos(pos) {
            var month = 0;
            var year = 0;
            if (pos < 100000) {
                month = pos % 10;
                year = (pos - month) / 10;
            } else {
                month = pos % 100;
                year = (pos - month) / 100;
            }

            var next = 0;
            month++;
            if (month > 9) {
                if (month === 13) {
                    month = 1;
                    year++;
                    next = year * 10 + month
                } else {
                    next = year * 100 + month;
                }
            } else {
                next = year * 10 + month;
            }
            return next;
        }

        $scope.setMaster = function () {
            var budget = $scope.selectedBudget;
            if (budget.master == true) {
                budget.master = false;
                var replace = " (" + $translate.instant("budget.mastertitle") + ")";
                budget.description = budget.description.replace(replace, "");
            } else {
                $scope.budgets.forEach(function (bud) {
                    if (bud.targetPeriod === budget.targetPeriod && bud.master === true) {
                        var replace = " (" + $translate.instant("budget.mastertitle") + ")";
                        bud.description = bud.description.replace(replace, "");
                    }
                });
                budget.master = true;
                budget.description = budget.description + " (" + $translate.instant("budget.mastertitle") + ")";
            }
            budget.company = company;
            BudgetService.saveMaster(budget)
                .then(function () {
                        $log.debug("BudgetController.setMaster : Master value saved.");
                    }
                );
        };

        $scope.calculateDevaluationPercent = function (devaluation) {
            if (devaluation.valueEuro == undefined) {
                devaluation.valuePercent = undefined;
            } else {
                devaluation.valuePercent = getPercentFromValue(devaluation.valueEuro, devaluation.devaluationValueOnAccountPeriod);
                calclulateClosingBalanceForCvDevaluation(devaluation);
            }

        }

        $scope.calculateDevaluationValue = function (devaluation) {
            if (devaluation.valuePercent == undefined) {
                devaluation.valueEuro = undefined;
            } else {
                var percent = devaluation.valuePercent;
                devaluation.valueEuro = getValueForPercent(percent, devaluation.devaluationValueOnAccountPeriod);
                calclulateClosingBalanceForCvDevaluation(devaluation);
            }
        }

        $scope.calculateInvestmentDevaluationValue = function (investment) {
            if (investment.valuePercent == undefined) {
                investment.valueEuro = undefined;
            } else {
                investment.valueEuro = getValueForPercent(investment.valuePercent, investment.undevaluatedValueInTargetPeriod);
                calculateClosingBalanceForInvestment(investment);
            }
        }

        $scope.calclulateInvestmentDevaluationPercent = function (investment) {
            if (investment.valueEuro == undefined) {
                investment.valuePercent = undefined;
            } else {
                investment.valuePercent = getPercentFromValue(investment.valueEuro, investment.undevaluatedValueInTargetPeriod);
                calculateClosingBalanceForInvestment(investment);
            }
        }

        function getPercentFromValue(percentValue, totalValue) {
            return Math.round(((percentValue / totalValue) * 100) * 100) / 100;
        }

        function getValueForPercent(percent, totalValue) {
            return Math.round(((percent / 100) * totalValue) * 100) / 100;
        }

        function calculateClosingBalanceForInvestment(investment) {
            investment.closingBalance = investment.undevaluatedValueInTargetPeriod - investment.valueEuro;
        }

        function calclulateClosingBalanceForCvDevaluation(devaluation) {
            devaluation.closingBalance = devaluation.devaluationValueOnAccountPeriod - devaluation.valueEuro;
        }

        $scope.saveDevaluationPlan = function () {
            var devaluations = $scope.devaluations;
            var devaluationTotal = 0;
            for (var i = 0; i < devaluations.length; i++) { //cv accounts
                devaluationTotal = 0;
                devaluationTotal = devaluations[i].valueEuro;
                if (devaluations[i].investments.length > 0) {
                    var investments = devaluations[i].investments;
                    for (var j = 0; j < investments.length; j++) { //investments
                        devaluationTotal = devaluationTotal + investments[j].valueEuro;
                    }
                }
                devaluations[i].devaluationTotal = devaluationTotal;
            }
            DevaluationBudgetData.saveDevaluationPlan(devaluations, onSaveDevaluationPlanSuccess, onSaveDevaluationPlanError);
        }

        var onSaveDevaluationPlanSuccess = function (result) {
            refreshBudgetModule();
        };

        var onSaveDevaluationPlanError = function (result) {
            //TODO
        };

        function budgetRealization(realizePosition) {
            $scope.enableSave = false;
            if ($scope.selectedSubBudget != null && $scope.selectedSubBudget.accountCategoryValueId != 0) {
                BudgetService.realizeSubBudget($scope.selectedBudget.id, realizePosition.value, $scope.$root.selectedCompany.id, $scope.selectedSubBudget.accountCategoryValueId).then(function () {
                    $scope.enableSave = true;
                    $state.go('budget', {sub: $scope.selectedSubBudget}, {reload: true});
                });
            } else if ($scope.selectedBudget.subBudgets.length > 0) {
                BudgetService.realizeAll($scope.selectedBudget.id, realizePosition.value, $scope.$root.selectedCompany.id).then(function () {
                    loadDataForBudget();
                    $scope.enableSave = true;
                });
            } else {
                BudgetService.realizeBudget($scope.selectedBudget.id, realizePosition.value, $scope.$root.selectedCompany.id).then(function () {
                    loadDataForBudget();
                    $scope.enableSave = true;
                });
            }
        }

        $scope.selectBudgetMonths = function () {
            $uibModal.open({
                template: require('./budget-month-dialog.html'),
                controller: 'BudgetMonthSelectController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve: {
                    entity: function () {
                        return possibleRealizePositions;
                    }
                },
            }).result.then(function (selection) {
                budgetRealization(selection);
            });
        };

        $scope.budgetValueChanged = function(row, index) {
            $log.debug("BudgetController.budgetValueChanged", row, index);
            const monthNumber = $scope.tableData.monthNumbers[index];
            //$log.debug("monthNumber", monthNumber);
            const fiscalYearStartDate = new Date($scope.selectedBudget.fiscalYear.startDate);
            const fiscalYearEndDate = new Date($scope.selectedBudget.fiscalYear.endDate);
            let positionDate = new Date(fiscalYearStartDate);
            positionDate.setMonth(monthNumber-1);
            if (fiscalYearStartDate <= positionDate && positionDate <= fiscalYearEndDate) {
            } else {
                positionDate = new Date(fiscalYearEndDate);
                positionDate.setMonth(monthNumber-1);
            }
            const position = positionDate.getFullYear() +''+monthNumber;
            //$log.debug("position", position);
            let accountCategoryValueId = getAccountCategoryValueId();
            const newValue = row.values[index];
            const name = rowHeadCodes[row.ordinal];
            $log.debug("BudgetController.budgetValueChanged: coordinates ", position, accountCategoryValueId, name, newValue);
            rawData[position][accountCategoryValueId][name] = newValue;
            budgetDataManager.changeValue(position, newValue, accountCategoryValueId, name);
            if (accountCategoryValueId != 0) {
                // sub-budget value changed
                updateMainBudgetValue(position, name, $scope.selectedBudget.subBudgets);
            }
        };

        function updateMainBudgetValue(position, name, subBudgets) {
            let sum = 0;
            for (let i = 0; i < subBudgets.length; i++) {
                let acvid = subBudgets[i].accountCategoryValueId;
                if (acvid != 0) {
                    sum += rawData[position][acvid][name];
                }
            }
            $log.debug("BudgetController.updateMainBudgetValue: sum= ", sum);
            rawData[position][0][name] = sum;
            budgetDataManager.changeValue(position, sum, 0, name);
        }

        function getAccountCategoryValueId() {
            if ($scope.selectedSubBudget) {
                return $scope.selectedSubBudget.accountCategoryValueId;
            }
            return 0;
        }
    });


angular.module('ecoeasyApp')
    .filter('devaluationTotal', function () {
        return function (devaluation) {
            if (devaluation == undefined) {
                return 0;
            }

            var amount = 0;
            if (devaluation.valueEuro != undefined) {
                amount += devaluation.valueEuro;
            } else {
                amount += devaluation.devaluationValueOnAccountPeriod;
            }


            devaluation.investments.forEach(function (investment) {
                amount += calculate(investment);
            });
            return amount;
        };

        function calculate(investment) {
            if (investment.valueEuro != undefined) {
                return investment.valueEuro;
            } else {
                return investment.targetAccountingPeriodValue;
            }

        }
    });