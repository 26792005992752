import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {Button, Glyphicon, Table} from "react-bootstrap";
import {getCorrectDecimalFormat, getNumberInThousands, getPaymentAmount} from "../../../../utils/util";
import DeleteInvestmentFunding from "./delete-investment-funding";
import EditInvestmentFunding from "./edit-investment-funding";
import {getBasicChartOfAccountsData, getInvestmentData} from "../../../../service/react-service";
import {findAccountKey, firstCharToUpper, getFundingAccountOptions} from "../../budget-utils";

const InvestmentFundingTable = ({
                                    investmentFundingData,
                                    setInvestmentFundingData,
                                    companyId,
                                    budgetId,
                                    showThousands,
                                    accountChartId
                                }) => {

    const {t, i18n} = useTranslation();
    const [selectedData, setSelectedData] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [fundingAccountOptions, setFundingAccountOptions] = useState([]);
    const [investmentData, setInvestmentData] = useState([]);

    const matchedData = id => {
        let match = null;
        investmentData.filter(item => {
            if (item.fundingObject !== null) {
                if (item.fundingObject['id'] === id) return match = item;
            }
        });
        return match;
    }

    const localNameKey = `name${firstCharToUpper(i18n.language)}`;

    useEffect(() => {
        getBasicChartOfAccountsData().then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                const liikekirjuri = res.data.find(item => item.id === accountChartId);
                liikekirjuri && getFundingAccountOptions(liikekirjuri.balanceSheet, setFundingAccountOptions, localNameKey);
            }
        });
        getInvestmentData(companyId, budgetId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej.message);
            } else {
                setInvestmentData(res.data);
            }
        });
    }, []);

    const renderInvestmentFundings = () => {
        return investmentFundingData.map((investmentFunding) => {
            return (
                <tr key={investmentFunding.id}>
                    <td>
                        {investmentFunding.name}
                    </td>
                    <td align='right'>
                        {showThousands ? getNumberInThousands(investmentFunding.amount) : investmentFunding.amount}
                    </td>
                    <td>
                        {findAccountKey(fundingAccountOptions, 'id', parseFloat(investmentFunding.bcoaCategoryId))}
                    </td>
                    <td>
                        {investmentFunding.termInMonths}
                    </td>
                    <td>
                        {investmentFunding.annualPaymentMonths.length}
                    </td>
                    <td>
                        {getCorrectDecimalFormat(investmentFunding.annualInterestRate, 2)} %
                    </td>
                    <td align='right'>
                        {getPaymentAmount(investmentFunding.shortTermPaymentsAmount, showThousands)}
                    </td>
                    <td align='right'>
                        {getPaymentAmount(investmentFunding.longTermPaymentsAmount, showThousands)}
                    </td>
                    <td>
                        <Button onClick={() => {
                            setSelectedData(investmentFunding);
                            setShowEdit(!showEdit);
                        }}>
                            <Glyphicon glyph='pencil'/>
                        </Button>
                    </td>
                    <td>
                        <Button onClick={() => {
                            setSelectedData(investmentFunding);
                            setShowDelete(!showDelete);
                        }}>
                            <Glyphicon glyph='trash'/>
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Table striped bordered>
                <thead>
                <tr>
                    <th>{t('fundingTable:title.name')}</th>
                    <th>{t('fundingTable:title.amount')}</th>
                    <th>{t('investments:values.balanceAccount')}</th>
                    <th>
                        {t('fundingTable:title.loanTime')}
                        <br/>
                        {t('fundingTable:title.inMonths')}
                    </th>
                    <th>
                        {t('fundingTable:title.amorizations')}
                        <br/>
                        {t('fundingTable:title.perYear')}
                    </th>
                    <th>{t('fundingTable:title.interestRate')}</th>
                    <th>{t('fundingTable:title.shortTerm')}</th>
                    <th>{t('fundingTable:title.longTerm')}</th>
                    <th>{t('fundingTable:title.edit')}</th>
                    <th>{t('fundingTable:title.delete')}</th>
                </tr>
                </thead>
                <tbody>
                {investmentData && fundingAccountOptions && renderInvestmentFundings()}
                </tbody>
            </Table>
            {selectedData ?
                <>
                    <EditInvestmentFunding
                        budgetId={budgetId}
                        companyId={companyId}
                        fundingOptions={fundingAccountOptions}
                        showModal={showEdit}
                        setShowModal={setShowEdit}
                        selectedData={selectedData}
                        investmentData={matchedData(selectedData.id)}
                        setSelectedData={setSelectedData}
                        setInvestmentFundingData={setInvestmentFundingData}
                    />
                    <DeleteInvestmentFunding
                        modalShow={showDelete}
                        setModalShow={setShowDelete}
                        setSelectedData={setSelectedData}
                        selectedData={matchedData(selectedData.id)}
                        companyId={companyId}
                        budgetId={budgetId}
                        setInvestmentFundingData={setInvestmentFundingData}
                    />
                </>
                : null
            }
        </div>
    );
};

InvestmentFundingTable.propTypes = {
    investmentFundingData: PropTypes.array.isRequired,
    setInvestmentFundingData: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    budgetId: PropTypes.number.isRequired,
    showThousands: PropTypes.bool.isRequired,
    accountChartId: PropTypes.number.isRequired
};

export default InvestmentFundingTable;