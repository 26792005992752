'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('manualimport', {
                parent: 'site',
                url: '/manualimport',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_USER_ORGANISATION'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./manualimport.html'),
                        controller: 'ManualImportController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('manualimport');
                        return $translate.refresh();
                    }]
                }
            })
            .state('manualimport.done', {
                parent: 'manualimport',
                url: '/manualimport/done',
                params: {
                    importOk: true
                },
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_USER_ORGANISATION'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./manualimport-done.html'),
                        controller: function ($scope, $stateParams) {
                            $scope.importOk = $stateParams.importOk;
                        }
                    }
                }
            });
    });
