import React, {useState} from 'react';
import {workingCapitalInputDetails as inputDetails} from "./working-capital-form-input-details";
import SingleSelectDropdown from "./single-select-dropdown";
import {useTranslation} from "react-i18next";
import {array, object, string} from "prop-types";

const VAIHTO_OMAISUUS = [30, 31, 32, 33, 34, 35];
const OSTOVELAT_ID = 142;

const WorkingCapitalTableData = ({data, prefix, states, formValues, utilityValues}) => {
    const {t} = useTranslation('workingCapital');
    const {aineetGroup, revenueGroup} = utilityValues;

    return data.map((row) => {
            const accountName = 'account_' + row.categoryId;
            const accountValue = `${prefix}_${row.categoryId}_value`;
            // const accountPercentage = `${prefix}_${row.categoryId}_percent`;
            const accountTurnover = `${prefix}_${row.categoryId}_turnover`;

            let fromTurnoverToAbsoluteValue;

            const [rowValue, setRowValue] = useState(Math.abs(parseFloat(formValues[accountValue])));
            // const [rowPercent, setRowPercent] = useState(Math.abs(parseFloat(formValues[accountPercentage])));
            const [rowTurnover, setRowTurnover] = useState(Math.abs(parseFloat(formValues[accountTurnover])));

            const calculateFinalValueFromTurnover = (turnover, budgetGroupData) => {
                let totalValue = budgetGroupData[0];
                let previousMonthValue = 0;
                if (Math.abs(totalValue) > 0) previousMonthValue = totalValue / 365 * turnover;
                let currentMonthValue;
                for (let i = 1; i < budgetGroupData.length; i++) {
                    const currentValue = budgetGroupData[i];
                    if (Math.abs(currentValue) > 0) currentMonthValue = currentValue / 365 * turnover;
                    totalValue += (previousMonthValue - currentMonthValue);
                    previousMonthValue = currentMonthValue;
                }
                return totalValue;
            }

            if (states[accountName].type === 'turnover') {
                if ((row.categoryId === OSTOVELAT_ID || VAIHTO_OMAISUUS.includes(row.categoryId)) && row.turnover !== null) {
                    fromTurnoverToAbsoluteValue = parseFloat(calculateFinalValueFromTurnover(rowTurnover, aineetGroup).toFixed(2));
                } else if (row.turnover !== null) {
                    fromTurnoverToAbsoluteValue = parseFloat(calculateFinalValueFromTurnover(rowTurnover, revenueGroup).toFixed(2));
                }
            }

            const calculateValueFromPercentage = (percentage) => {
                let totalValue = revenueGroup[0];
                let previousMonthValue = revenueGroup[0];
                let currentMonthValue;
                for (let index = 1; index < revenueGroup.length; index++) {
                    const changePercent = Math.abs(aineetGroup[index]) / Math.abs(revenueGroup[index]) * 100;
                    if (changePercent !== percentage) {
                        currentMonthValue = revenueGroup[index] * (percentage / 100);
                        totalValue += (previousMonthValue - currentMonthValue);
                        previousMonthValue = currentMonthValue;
                    }
                }
                return totalValue;
            }

            // const calculateAbsoluteValueFromPercentage = calculateValueFromPercentage(rowPercent).toFixed(2);

            const valueToNumber = value => value ? value : "0";

            /*
            const otherCalculationTypes = states[accountName].type === 'turnover' ?
                <>{valueToNumber(Math.abs(fromTurnoverToAbsoluteValue))}</>
                :
                <>{valueToNumber(Math.abs(calculateAbsoluteValueFromPercentage))}</>;
            */

            const turnoverValue = row.turnoverCalculated !== row.turnover ? row.turnover : row.turnoverCalculated;

            const resultValue = states[accountName].type === 'value' ? <>{valueToNumber(Math.abs(parseFloat(rowValue.toFixed(2))))}</> : <>{valueToNumber(Math.abs(fromTurnoverToAbsoluteValue))}</>;

            return (
                <tr key={row.companyAccountId}>
                    <td className='name-cell'>{inputDetails[accountName].name}</td>
                    <td>
                        <SingleSelectDropdown
                            id={`account-${row.categoryId}-select`}
                            name={`${prefix}_${row.categoryId}_calculationType`}
                            setValue={states[accountName].setType}
                            options={inputDetails[accountName].options}
                        />
                    </td>
                    <td align='right'>
                        {row.turnoverCalculated ? `${turnoverValue} ${t('table.turnoverUnit.title')}` : ' '}
                    </td>
                    <td>
                        {states[accountName].type === 'value' ?
                            <input
                                id={`${row.type}_${row.categoryId}_value`}
                                name={`${prefix}_${row.categoryId}_${states[accountName].type}`}
                                type='number'
                                step='any'
                                style={{
                                    height: '30px',
                                    padding: '5px 10px',
                                    fontSize: '12px',
                                    lineHeight: '1.5',
                                    borderRadius: '3px',
                                    border: '1px solid #ddd',
                                    boxShadow: 'none',
                                    width: '70% !important'
                                }}
                                value={rowValue}
                                onInput={e => {
                                    formValues[accountValue] = parseFloat(e.target.value);
                                    setRowValue(formValues[accountValue]);
                                }}
                            />
                            : <span/>}
                        {/*states[accountName].type === 'percent' ?
                            <input
                                id={`${row.type}_${row.categoryId}_percent`}
                                name={`${prefix}_${row.categoryId}_${states[accountName].type}`}
                                type='number'
                                step='any'
                                style={{
                                    height: '30px',
                                    padding: '5px 10px',
                                    fontSize: '12px',
                                    lineHeight: '1.5',
                                    borderRadius: '3px',
                                    border: '1px solid #ddd',
                                    boxShadow: 'none',
                                    width: '70% !important'
                                }}
                                value={rowPercent}
                                onInput={e => {
                                    formValues[accountPercentage] = parseFloat(e.target.value);
                                    setRowPercent(formValues[accountPercentage]);
                                }}
                            />
                            : <span/>*/}
                        {states[accountName].type === 'turnover' ?
                            <input
                                id={`${row.type}_${row.categoryId}_turnover`}
                                name={`${prefix}_${row.categoryId}_${states[accountName].type}`}
                                type='number'
                                style={{
                                    height: '30px',
                                    padding: '5px 10px',
                                    fontSize: '12px',
                                    lineHeight: '1.5',
                                    borderRadius: '3px',
                                    border: '1px solid #ddd',
                                    boxShadow: 'none',
                                    width: '70% !important'
                                }}
                                min={0}
                                value={rowTurnover}
                                onInput={e => {
                                    formValues[accountTurnover] = Math.abs(parseFloat(e.target.value));
                                    setRowTurnover(formValues[accountTurnover]);
                                }}
                            />
                            : <span/>}
                    </td>
                    <td>
                        {states[accountName].type === 'value' ? <span>€</span> : <span/>}
                        {/*{states[accountName].type === 'percent' ? <span>%</span> : <span/>}*/}
                        {states[accountName].type === 'turnover' ? <span>{t('table.turnoverUnit.title')}</span> :
                            <span/>}
                    </td>
                    <td>{resultValue}<span> €</span></td>
                </tr>
            );
        }
    )
        ;
}

WorkingCapitalTableData.propTypes = {
    data: array,
    prefix: string,
    states: object,
    formValues: object,
    utilityValues: object
};

export default WorkingCapitalTableData;