import React, {useEffect} from 'react';
import {FormControl, FormGroup} from 'react-bootstrap';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const SingleSelectDropdown = (props) => {
    const {t, i18n} = useTranslation();
    const [field, meta] = useField(props);

    useEffect(() => {
        if (field.value !== undefined) {
            props.setValue(field.value);
        }
    }, [field]);

    return (
        <FormGroup controlId={props.id}>
            {props.options.length > 1 ?
                <FormControl componentClass='select' name={props.name}{...field}>
                    {props.options.map((option) => (
                        <option value={option.type} key={option.type}>{option.translation}</option>))}
                </FormControl>
                :
                <FormControl componentClass='select' name={props.name} disabled={true}{...field}>
                    <option value={props.options[0].type} key={props.options[0].type}>{props.options[0].translation}</option>
                </FormControl>

            }
        </FormGroup>
    );
}

export default SingleSelectDropdown;