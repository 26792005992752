import template from "./performance-measure-formula-item-edit.html";
import "./performance-measure-formula-item.css";
import {formulaItemTypes} from "./PerformanceMeasureFormulaItem";

class PerformanceMeasureFormulaItemEditComponent {

    constructor($log) {
        "ngInject";
        this.$log = $log;
        this.itemType = null;
    }

    $onInit() {
        this.$log.debug('$onInit()', this.item);
        if (this.item.account.name && this.item.account.formula) {
            this.itemType = formulaItemTypes.MEASURE;
        } else if (this.item.account.id && this.item.account.code) {
            this.itemType = formulaItemTypes.COMPANY_ACCOUNT;
        } else if (this.item.account.id) {
            this.itemType = formulaItemTypes.COMPANY_ACCOUNT_GROUP;
        } else if (this.item.account.key) {
            this.itemType = 'CODE_KEY';
        } else {
            this.itemType = 'OPERATOR';
        }
    }
}

export default {
    bindings: {
        item: '<',
        addFormulaItemToIndex: '&',
        index: '=',
        outsideClick: '&'
    },
    template: template,
    controller: PerformanceMeasureFormulaItemEditComponent
}
