'use strict';

angular.module('ecoeasyApp')
    .controller('BalanceBudgetDataDeleteController', function ($scope, $uibModalInstance, entity, BalanceBudgetData) {

        $scope.balanceBudgetData = entity;
        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            BalanceBudgetData.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
