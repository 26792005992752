(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('CostAccountModifyController', CostAccountModifyController);

    CostAccountModifyController.$inject = ['$scope', '$translate', '$q', '$http', 'company_id', 'Company'];

    function CostAccountModifyController($scope, $translate, $q, $http, company_id, Company) {
        init();

        $scope.company = Company.get({id: company_id});

        function init() {
            $http.get("/api/companys/" + company_id + "/costaccounts").then(function (response) {
                $scope.costAccountCategories = response.data;
                if ($scope.costAccountCategories.length > 0) {
                    $scope.selectedCostAccountCategory = $scope.costAccountCategories[0];
                    $scope.hasManyCostAccounts = true;
                } else {
                    $scope.hasManyCostAccounts = false;
                }
            });
        }

        $scope.save = function () {
            $scope.isSaving = true;
            $http.put("/api/companys/costaccounts", $scope.selectedCostAccountCategory);
            for (var i = 0; i < $scope.costAccountCategories.length; i++) {
                if ($scope.costAccountCategories[i].id === $scope.selectedCostAccountCategory.id) {
                    $scope.costAccountCategories[i] = $scope.selectedCostAccountCategory;
                }
            }
            $scope.isSaving = false;
        };
    }
})();
