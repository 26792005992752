export class BasicChartOfAccountsResource {
    constructor($resource) {
        "ngInject";
        this.resource = $resource('/api/basic-chart-of-accounts/:chartId', {}, {}, {});
    }

    query() {
        return this.resource.query().$promise;
    }

}