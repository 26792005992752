
function getRowName(row, locale) {
    if (locale === 'en') {
        if (row.nameEn) {
            return row.nameEn;
        } else {
            return row.nameFi;
        }
    } else if (locale === 'sv') {
        if (row.nameSv) {
            return row.nameSv;
        } else {
            return row.nameFi;
        }
    } else {
        return row.nameFi;
    }
}

function isAddable(row, chartFinder) {
    if (row.basicChartCategoryId) {
        let found = chartFinder.findGroupByBasicChartCategoryId(row.basicChartCategoryId);
        if (found) {
            let accountCount = (found.companyAccounts) ? found.companyAccounts.length : 0;
            let groupCount = (found.subGroups) ? found.subGroups.length : 0;
            let childCount = accountCount + groupCount;
            return row.childCount != childCount || row.childCount != 0;
        }
    }
    return false;
}

function isProjectionRow(row, locale) {
    const name = getRowName(row, locale);
    const r = /\d+/g;
    const match = name.match(r);
    if (match && (match.includes('9995') || match.includes('96941'))) return true;
}

function projectionType(row, locale) {
    if (isProjectionRow(row, locale)) return 'LOCKED';
}

export class BudgetTableRow {

    constructor(dataRow, locale, chartFinder, options) {
        let addable = isAddable(dataRow, chartFinder);
        let editable = !dataRow.main && dataRow.childCount == 0;
        let toolSelect = addable || editable && !isProjectionRow(dataRow, locale);
        let absolute = dataRow.type === 'ABSOLUTE';
        this.augend = dataRow.augend;
        this.basicChartCategoryId = dataRow.basicChartCategoryId;
        this.id = dataRow.id;
        this.level = dataRow.level;
        this.main = dataRow.main;
        this.name = getRowName(dataRow, locale);
        this.ordinal = dataRow.ordinal;
        this.sum = dataRow.sum;
        this.total = dataRow.total;
        this.values = angular.copy(dataRow.values);
        this.openable = !dataRow.main && dataRow.childCount > 0;
        this.isOpen = true;
        this.isShown = true;
        this.toolSelect = toolSelect;
        this.editable = editable;
        this.addable = addable;
        this.childCount = dataRow.childCount;
        this.type = projectionType(dataRow, locale) || dataRow.type;
        this.refId = dataRow.refId;
        this.refRatio = dataRow.refRatio;
        this.memo = dataRow.memo;
        this.hasMemo = !!dataRow.memo;
        this.removable = dataRow.removable;
        this.isAbsolute = absolute;
        this.options = options;
        this.companyAccountId = dataRow.companyAccountId;
    }
}
