import template from './number-input.html';

export const NumberInputComponent = {
    bindings: {
        row: '<',
        columnIndex: '<',
        value: '<',
        isThousands: '<',
        onValueChange: '&'
    },
    template: template,
    controller: class NumberInputComponent {

        constructor($log, integerFilter) {
            "ngInject";
            this.$log = $log;
            this.integerFilter = integerFilter;
            this.thousands = false;
            this.inputValue = null
            this.trueValue = 0;
            this._setInputValue(this.trueValue);
            this.userIsEditing = false;
        }

        $onInit() {
            //this.$log.debug('NumberInputComponent.$onInit');
        }

        $onChanges(changesObj) {
            //this.$log.debug('NumberInputComponent.$onChanges: changesObj', changesObj);
            if (changesObj.value && !this.userIsEditing) {
                this.trueValue = changesObj.value.currentValue;
                this._setInputValue((this.thousands) ? Math.round(this.trueValue / 1000) : this.trueValue);
            }
            if (changesObj.isThousands) {
                this._toggleThousands(changesObj.isThousands.currentValue);
            }
        }

        _changeTrueValue(newValue) {
            this.trueValue = newValue;
            this.onValueChange({row: this.row, index: this.columnIndex, newValue: this.trueValue});
        }

        _setInputValue(newValueNumber) {
            this.inputValue = "" + this.integerFilter(newValueNumber);
        }

        _toggleThousands(newValue) {
            this.thousands = newValue;
            this._setInputValue((this.thousands) ? Math.round(this.trueValue / 1000) : this.trueValue);
        }

        onInputFocus() {
            //this.$log.debug('NumberInputComponent.onInputFocus', this.inputValue);
            this.userIsEditing = true;
            this.inputValue = this.inputValue.replace(/\s/g, '');
        }

        onInputChange() {
            //this.$log.debug('NumberInputComponent.onInputChange', this.inputValue);
            let newTrueValue = parseInt(this.inputValue, 10);
            if (!isNaN(newTrueValue) && this.trueValue != newTrueValue) {
                this._changeTrueValue((this.thousands) ? 1000*newTrueValue : newTrueValue);
            }
        }

        onInputBlur() {
            //this.$log.debug('NumberInputComponent.onInputBlur', this.inputValue);
            this.userIsEditing = false;

            let newTrueValue = parseInt(this.inputValue, 10);
            if (isNaN(newTrueValue)) {
                this._changeTrueValue(0);
            }
            this._setInputValue(this.trueValue);
        }

    }

}