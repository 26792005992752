import React from 'react';
import {useTranslation} from "react-i18next";
import {updateInvestment, getInvestmentFundingData} from "../../../../service/react-service";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

const DeleteInvestmentFunding = ({
                                     modalShow,
                                     setModalShow,
                                     setSelectedData,
                                     selectedData,
                                     companyId,
                                     budgetId,
                                     setInvestmentFundingData
                                 }) => {
    const {t} = useTranslation('fundingModal');

    const handleCloseModal = () => {
        setSelectedData(null);
        setModalShow(!modalShow);
    };

    const handleOnDelete = () => {
        const updatedData = {
            id: selectedData.id,
            name: selectedData.name,
            value: selectedData.value,
            bcoaCategoryId: selectedData.bcoaCategoryId,
            remnantValue: selectedData.remnantValue,
            month: selectedData.month,
            year: selectedData.year,
            devaluationTime: selectedData.devaluationTime,
            devaluationTimeDelay: selectedData.devaluationTimeDelay,
            vatValue: selectedData.vatValue,
            devaluationInterest: selectedData.devaluationInterest,
            fundingObject: null
        }

        updateInvestment(companyId, budgetId, updatedData).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                getInvestmentFundingData(companyId, budgetId).then((res, rej) => {
                    if (rej !== undefined) {
                        console.log(rej);
                    } else {
                        setInvestmentFundingData(res.data);
                    }
                });
            }
        });
        handleCloseModal();
    };

    return (
        <Modal show={modalShow} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('title.deleteFunding')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('description.deleteFunding')}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseModal}>
                    <Glyphicon glyph={'ban-circle'}/>
                    {t('button.cancel')}
                </Button>
                <Button bsStyle="primary" onClick={() => handleOnDelete()}>
                    {t('button.deleteFunding')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteInvestmentFunding;