"use strict";

angular
    .module("ecoeasyApp")
    .controller("BigBrotherController", function (
        $scope,
        $interval,
        $state,
        $q,
        $sce,
        filters,
        indicatorService,
        Principal,
        $http,
        $translate,
        RootScopeWrapperService,
        FEATURE_THIRD_PHASE
    ) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.$root.selectedCompanyId = undefined;
            $scope.isAuthenticated = Principal.isAuthenticated;
            $scope.orgUsers = [
                {id: 0, name: $translate.instant("bigbrother.responsibles.all")}
            ];
            $scope.currentUser = $scope.orgUsers[0];
            $scope.filters = [
                {id: 0, desc: $translate.instant("bigbrother.filter.all")},
                {
                    id: 1,
                    desc: $translate.instant("bigbrother.filter.warning_or_alarm")
                },
                {id: 2, desc: $translate.instant("bigbrother.filter.alarm")},
                {id: 3, desc: $translate.instant("bigbrother.filter.responsible")}
            ];
            $scope.currentFilter = $scope.filters[0];
            $http
                .get("/api/accountans/" + $scope.account.sourceId)
                .then(function (response) {
                    response.data.forEach(function (user) {
                        $scope.orgUsers.push({
                            id: user.id,
                            name: user.firstName + " " + user.lastName
                        });
                    });
                });

        });

        $scope.$root.setSelectedCompany(null);
        window.ts = $scope;
        $scope.show_third_phase_features = FEATURE_THIRD_PHASE;

        let getData = false;
        let getDataRunning = false;
        let companiesWithValues = [];
        let filteredCompanies = [];
        $scope.companies = [];
        $scope.companyIds =[];
        $scope.initializeData = function () {
            $http.get("/api/companys/controlroom?with=false").then(function (response) {
                $scope.companies = response.data;
                filteredCompanies = response.data;
                var companyIdsLists = [];
                var companyIdsList = [];
                for (var i = 0; i < response.data.length; i++) {
                    companyIdsList.push(response.data[i].id);
                    if (companyIdsList.length === 20 || i === response.data.length-1) {
                        companyIdsLists.push(companyIdsList);
                        companyIdsList = [];
                    }
                }
                getData = true;
                getCompanyIndicatorValues(companyIdsLists);
            });
            $scope.lastUpdate = new Date();

        };

        $scope.refreshData = function () {
            $http.get("/api/companys/controlroom?with=false").then(function (response) {
                companiesWithValues = [];
                $scope.companies = response.data;
                filteredCompanies = response.data;
                $scope.updateFilteredCompanies();
            });
            $scope.lastUpdate = new Date();
        };
        $scope.mergedFinancials = function () {
            $state.go('merged-financials',  {companyids: $scope.companyIds});
        };

        $scope.toggleSelection = function toggleSelection(id) {
            var idx = $scope.companyIds.indexOf(id);

            // Is currently selected
            if (idx > -1) {
                $scope.companyIds.splice(idx, 1);
            }

            // Is newly selected
            else {
                $scope.companyIds.push(id);
            }
        };
        $scope.updateFilteredCompanies = function () {
            getData = false;
            if ($scope.filterCo == undefined) {
                $scope.filterCo = '';
            }
            filteredCompanies = $scope.companies.filter((it) => $scope.companyFilter(it)).filter((it) => it.name.toLowerCase().indexOf($scope.filterCo.toLowerCase()) != -1);
            getDataForUnloadedCompanies();
        }

        RootScopeWrapperService.onStateChangeStart((_event) => {
            getData = false;
        })

        $scope.lastUpdate = new Date();
        $scope.filters = filters;
        $scope.$root.selectedCompany = null;

        $scope.companySelected = function (company) {
            $scope.$root.setSelectedCompany(company);
            $scope.$root.selectedCompanyId = company.id;
            $state.go('survivor', {companyid: company.id});
        };

        $scope.accountingDone = function (company){
            company.status = "UPDATE";
            $http.put("/api/companys/status", company);
            company.isUpdated = true;
        }

        $scope.deleteAccountingData = function (company){
            $http.delete("/api/companyDataVersions/company/"+company.id);
        };
        $scope.initializeData();

        $scope.lastUpdate = new Date();
        $scope.filters = filters;
        $scope.$root.selectedCompany = null;

        $scope.companyFilter = function (company) {
            if ($scope.currentFilter.id === 0) {
                return true;
            } else if ($scope.currentFilter.id === 1) {
                return company.hasWarningOrAlert;
            } else if ($scope.currentFilter.id === 2) {
                return company.hasAlert;
            } else if ($scope.currentFilter.id === 3) {
                if ($scope.currentUser.id === 0) {
                    return true;
                } else if (company.accountantId === $scope.currentUser.id) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        };

        async function getCompanyIndicatorValues(companyIdsLists) {
            getDataRunning = true;
            for (let i in companyIdsLists) {
                let request = $http.get("/api/companys/controlroom?with=true&companyIds=" + companyIdsLists[i].join(",") + "&include=true")
                let response = await request;
                setCompanyIndicators(response);
                if(!getData) {
                    break;
                }
            }
            getDataRunning = false;
        }

        function getDataForUnloadedCompanies() {
            let companyIdsLists = [];
            let companyIdsList = [];
            for (let i in filteredCompanies) {
                if (!companiesWithValues.includes(filteredCompanies[i].id)) {
                    companyIdsList.push(filteredCompanies[i].id);
                }
                if (companyIdsList.length === 20 || i == filteredCompanies.length-1 && companyIdsList.length != 0) {
                    companyIdsLists.push(companyIdsList);
                    companyIdsList = [];
                }
            }
            let getDataInterval = setInterval(tryGetData, 10);
            function tryGetData() {
                if (!getDataRunning) {
                    getData = true;
                    getCompanyIndicatorValues(companyIdsLists);
                    clearInterval(getDataInterval);
                }
            }
        }

        function setCompanyIndicators(response) {
            let companyArray = response.data;
            $scope.companies.forEach(function (company) {
                let filtered = companyArray.filter((c) => {
                    return company.id == c.id;
                });
                if (filtered.length > 0) {
                    let sameCompany = filtered[0];
                    company.unlinkedAccount = sameCompany.unlinkedAccount;
                    company.calculation = sameCompany.calculation;
                    company.calculationerror = sameCompany.calculationerror;
                    company.indicators = sameCompany.indicators;
                    if (company.indicators) {
                        company.indicators.forEach(function (indicator) {
                            indicator.tooltip = $sce.trustAsHtml(
                                getToolTipForIndicator(indicator)
                            );
                            indicator.valueClass = getValueClass(indicator);
                            if (indicator.valueClass === "color-orange" || indicator.valueClass === "color-red") {
                                company.hasWarningOrAlert = true;
                            }
                            if (indicator.valueClass === "color-red") {
                                company.hasAlert = true;
                            }
                            indicator.arrowClass = getArrowClass(indicator);
                        })
                    }
                    companiesWithValues.push(company.id);
                }
            })
        }

        function getValueClass(indicator) {
            var reverse = false;
            var alertLimit = 0;
            var warnLimit = 0;
            var isValidData = indicator.previousValue !== null;
            if (isValidData) {
                if (indicator.relative) {
                    if (indicator.previousValue < 0) {
                        alertLimit =
                            indicator.previousValue +
                            (1 - indicator.alertLimit / 100) * indicator.previousValue;
                        warnLimit =
                            indicator.previousValue +
                            (1 - indicator.warnLimit / 100) * indicator.previousValue;
                    } else {
                        alertLimit = indicator.alertLimit * indicator.previousValue / 100;
                        warnLimit = indicator.warnLimit * indicator.previousValue / 100;
                    }
                } else {
                    alertLimit = indicator.alertLimit;
                    warnLimit = indicator.warnLimit;
                }

                if (alertLimit > warnLimit) {
                    reverse = true;
                }

                if (reverse) {
                    if (indicator.value < warnLimit) {
                        // ADDED BY Digimen Oy
                        return "color-green";
                    } else if (
                        indicator.value < alertLimit &&
                        indicator.value >= warnLimit
                    ) {
                        // ADDED BY Digimen Oy
                        return "color-orange";
                    } else {
                        // ADDED BY Digimen Oy
                        return "color-red";
                    }
                } else {
                    if (indicator.value <= alertLimit) {
                        // ADDED BY Digimen Oy
                        return "color-red";
                    } else if (
                        indicator.value > alertLimit &&
                        indicator.value <= warnLimit
                    ) {
                        // ADDED BY Digimen Oy
                        return "color-orange";
                    } else {
                        // ADDED BY Digimen Oy
                        return "color-green";
                    }
                }
            } else {
                if (indicator.relative) {
                    // ADDED BY Digimen Oy
                    return "color-orange";
                } else {
                    if (alertLimit > warnLimit) {
                        reverse = true;
                    }

                    if (reverse) {
                        if (indicator.value < warnLimit) {
                            // ADDED BY Digimen Oy
                            return "color-green";
                        } else if (
                            indicator.value < alertLimit &&
                            indicator.value >= warnLimit
                        ) {
                            // ADDED BY Digimen Oy
                            return "color-orange";
                        } else {
                            // ADDED BY Digimen Oy
                            return "color-red";
                        }
                    } else {
                        if (indicator.value <= alertLimit) {
                            // ADDED BY Digimen Oy
                            return "color-red";
                        } else if (
                            indicator.value > alertLimit &&
                            indicator.value <= warnLimit
                        ) {
                            // ADDED BY Digimen Oy
                            return "color-orange";
                        } else {
                            // ADDED BY Digimen Oy
                            return "color-green";
                        }
                    }
                }
            }
        };

        function getArrowClass(indicator) {
            var reverse = false;
            var alertLimit = 0;
            var warnLimit = 0;
            var ind = indicator;
            var resultClass = "fa fa-xs fa-arrow-";
            var isValidData = indicator.previousValue !== null;
            if (isValidData) {
                if (indicator.relative) {
                    if (indicator.previousValue < 0) {
                        alertLimit =
                            indicator.previousValue +
                            (1 - indicator.alertLimit / 100) * indicator.previousValue;
                        warnLimit =
                            indicator.previousValue +
                            (1 - indicator.warnLimit / 100) * indicator.previousValue;
                    } else {
                        alertLimit = indicator.alertLimit * indicator.previousValue / 100;
                        warnLimit = indicator.warnLimit * indicator.previousValue / 100;
                    }
                } else {
                    alertLimit = ind.alertLimit;
                    warnLimit = ind.warnLimit;
                }

                if (alertLimit > warnLimit) {
                    reverse = true;
                }

                if (reverse) {
                    if (ind.value < ind.previousValue) {
                        return (resultClass += "up");
                    } else if (ind.value === ind.previousValue) {
                        return (resultClass = "fa fa-xs fa-exchange");
                    } else {
                        return (resultClass += "down");
                    }
                } else {
                    if (ind.value > ind.previousValue) {
                        return (resultClass += "up");
                    } else if (ind.value === ind.previousValue) {
                        return (resultClass = "fa fa-xs fa-exchange");
                    } else {
                        return (resultClass += "down");
                    }
                }
            } else {
                return (resultClass = "fa fa-xs fa-exchange");
            }
            return;
        };

        var loop = $interval(function () {
            $scope.refreshData();
        }, 300000);

        $scope.$on("$destroy", function () {
            $interval.cancel(loop);
        });


        function getToolTipForIndicator(ind) {
            if (ind.relative === true) {
                var value =
                    "<div>" +
                    $translate.instant("bigbrother.tooltip.indicator") +
                    ": " +
                    ind.name;
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.value") +
                    ": " +
                    ind.value;
                if (ind.previousValue !== null) {
                    value =
                        value +
                        "<br>" +
                        $translate.instant("bigbrother.tooltip.previousvalue") +
                        ": " +
                        ind.previousValue;
                } else {
                    value =
                        value +
                        "<br>" +
                        $translate.instant("bigbrother.tooltip.previousvalue") +
                        ": N/A";
                }
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.warninglimit") +
                    ": " +
                    ind.warnLimit +
                    "%";
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.alarmlimit") +
                    ": " +
                    ind.alertLimit +
                    "%";
                value = value + "</div>";
            } else if (ind.relative === false) {
                var value =
                    "<div>" +
                    $translate.instant("bigbrother.tooltip.indicator") +
                    ": " +
                    ind.name;
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.value") +
                    ":" +
                    ind.value;
                if (ind.previousValue !== null) {
                    value =
                        value +
                        "<br>" +
                        $translate.instant("bigbrother.tooltip.previousvalue") +
                        ": " +
                        ind.previousValue;
                } else {
                    value =
                        value +
                        "<br>" +
                        $translate.instant("bigbrother.tooltip.previousvalue") +
                        ": N/A";
                }
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.warninglimit") +
                    ": " +
                    ind.warnLimit;
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.alarmlimit") +
                    ": " +
                    ind.alertLimit;
                value = value + "</div>";
            } else {
                var value =
                    "<div>" +
                    $translate.instant("bigbrother.tooltip.indicator") +
                    ": " +
                    ind.name;
                value =
                    value +
                    "<br>" +
                    $translate.instant("bigbrother.tooltip.value") +
                    ":" +
                    ind.value;
                if (ind.previousValue !== null) {
                    value =
                        value +
                        "<br>" +
                        $translate.instant("bigbrother.tooltip.previousvalue") +
                        ": " +
                        ind.previousValue;
                } else {
                    value =
                        value +
                        "<br>" +
                        $translate.instant("bigbrother.tooltip.previousvalue") +
                        ": N/A";
                }
                value = value + "</div>";
            }
            return value;
        }
    });
