import template from './budget-table.html';
import './budget-table.css'
import {BudgetTableRowType} from "../../budget2/budget-table-data-row";

export const BudgetTableComponent = {
    bindings: {
        data: '<',
        readonly: '<',
        onValueChange: '&'
    },
    template: template,
    controller: class BudgetComponent {

        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.months = [];
            this.rows = [];
            this.readonly = false;
            this.monthNames = [
                "Tammi",
                "Helmi",
                "Maalis",
                "Huhti",
                "Touko",
                "Kesä",
                "Heinä",
                "Elo",
                "Syys",
                "Loka",
                "Marras",
                "Joulu"
            ]
            this.thousands = false;
        }

        $onChanges(changesObj) {
            //this.$log.debug('BudgetComponent.$onChanges: changesObj', changesObj);
            if (changesObj.data && changesObj.data.currentValue) {
                if (changesObj.data.currentValue.monthNumbers) {
                    let myMonthNumbers = angular.copy(changesObj.data.currentValue.monthNumbers);
                    this.months = myMonthNumbers.map((monthNumber)=>{return this.monthNames[monthNumber-1]});
                }
                if (changesObj.data.currentValue.rows) {
                    let myRows = angular.copy(changesObj.data.currentValue.rows);
                    myRows.sort((a, b) => {
                        return a.ordinal - b.ordinal
                    });
                    this.rows = myRows;
                    this.updateTotals();
                }
            }
            if (changesObj.readonly && changesObj.readonly.currentValue) {
                this.readonly = changesObj.readonly.currentValue
            }
        }

        $onInit() {
            //this.$log.debug('BudgetComponent.$onInit');
            this.updateTotals();
        }

        _recalculate(row, $index, valueInt) {
            row.changeValue($index, valueInt);
            this.onValueChange({row: row, index: $index});
            this.calculateByColumn(row, $index);
            this.calculateByRows();
        }

        updateTotals() {
            //this.$log.debug('BudgetComponent.updateTotals');
            for (const i in this.rows) {
                for (const j in this.rows[i].values) {
                    this.calculateByColumn(this.rows[i], j);
                }
            }
            this.calculateByRows();
        }

        calculateByColumn(row, columnIndex) {
            if (row.augend) {
                let augendRow = this.rows.find((r) => r.id == row.augend);
                let addendRows = this.rows.filter((r) => r.augend == augendRow.id);
                augendRow.values[columnIndex] = addendRows.map((r) => r.values[columnIndex]).reduce((a, b) => a + b, 0);
                this.onValueChange({row: row, index: columnIndex});
                this.calculateByColumn(augendRow, columnIndex);
            }
        }

        calculateByColumns() {
            let rootRow = this.rows.find((r) => !r.augend);
            let dependants = this.rows.filter((r) => r.augend == rootRow.id);
            //this.$log.debug('BudgetComponent.calculateByColumns', rootRow, dependants);
            rootRow.values.forEach((value, index) => {
                rootRow.values[index] = dependants.map((dr) => {
                    return this.calculateSumOfDependants(dr, index)
                }).reduce((a, b) => a + b, 0);
            });
        }

        calculateSumOfDependants(row, columnIndex) {
            if (BudgetTableRowType.SUM === row.type) {
                row.values[columnIndex] = this.rows
                    .filter((r) => r.augend == row.id)
                    .map((dr) => {
                        return this.calculateSumOfDependants(dr, columnIndex)
                    })
                    .reduce((a, b) => a + b, 0);
            }
            return row.values[columnIndex];
        }

        calculateByRows() {
            this.rows.forEach((r) => {
                r.total = r.values.reduce((a, b) => a + b, 0);
            });
        }

        toggleThousands() {
            this.thousands = !this.thousands;
            this.updateTotals();
        }

        numberInputValueChanged(row,index,newValue) {
            this.$log.debug('BudgetComponent.numberInputValueChanged', row,index,newValue);
            this._recalculate(row, index, newValue);
        }
    }

}