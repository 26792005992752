
export const formulaItemTypes = {
    MEASURE: 'MEASURE',
    BASIC_ACCOUNT_GROUP: 'BASIC_ACCOUNT_GROUP',
    COMPANY_ACCOUNT_GROUP: 'COMPANY_ACCOUNT_GROUP',
    COMPANY_ACCOUNT: 'COMPANY_ACCOUNT'
}

export class PerformanceMeasureFormulaItem {

}

export class PerformanceMeasureFormulaItemMeasure extends PerformanceMeasureFormulaItem {
    constructor(id, name) {
        super();
        this.id = id;
        this.name = name;
    }
}

export class PerformanceMeasureFormulaItemAccountGroup extends PerformanceMeasureFormulaItem {
    constructor(id, name) {
        super();
        this.id = id;
        this.name = name;
    }
}

export class PerformanceMeasureFormulaItemUserValue extends PerformanceMeasureFormulaItem {
    constructor(value) {
        super();
        this.value = value;
    }
}

export class PerformanceMeasureFormulaItemOperator extends PerformanceMeasureFormulaItem {
    constructor(symbol) {
        super();
        this.symbol = symbol;
    }
}

export class PerformanceMeasureFormulaItemParenthesis extends PerformanceMeasureFormulaItem {
    constructor(symbol) {
        super();
        this.symbol = symbol;
    }
}