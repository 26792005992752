import template from "./edit-row-dialog.html";

const liikevaihto_basic_id = 156;

export const rowTypes = {
    ABSOLUTE: 'ABSOLUTE',
    PERCENTAGE_OF: 'PERCENTAGE_OF',
    GROWTH_OF: 'GROWTH_OF'
}

function getAllChildren(row, rows) {
    let allChildren = [];
    if (row.basicChartCategoryId) {
        let children = rows.filter(current => current.augend === row.basicChartCategoryId);
        allChildren = allChildren.concat(children);
        children.forEach(child => {
            allChildren = allChildren.concat(getAllChildren(child, rows));
        });
    }
    return allChildren;
}

function getAllParents(row, rows) {
    let allParents = [];
    let parents = rows.filter(current => current.basicChartCategoryId && current.basicChartCategoryId === row.augend);
    allParents = allParents.concat(parents);
    parents.forEach(parent => {
        allParents = allParents.concat(getAllParents(parent, rows));
    });
    return allParents;
}

export function getRefOptions(editRow, rows) {
    let allowedRows = rows;
    if (editRow) {
        let children = getAllChildren(editRow, rows);
        allowedRows = allowedRows.filter(row => children.indexOf(row) === -1);

        let parents = getAllParents(editRow, rows);
        allowedRows = allowedRows.filter(row => parents.indexOf(row) === -1);

        allowedRows = allowedRows.filter(row => row.id !== editRow.id);
    }
    return allowedRows.map(row => {
        return {id: row.id, name: row.name, basicChartCategoryId: row.basicChartCategoryId}
    });
}

export const EditRowDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class EditRowDialogComponent {

        constructor() {
            "ngInject";
            this.refOptions = [];
            this.refSelected = null;
            this.rowType = null;
            this.percentage = null;
        }

        $onInit() {
            let editRow = this.resolve.editRow;
            this.refOptions = getRefOptions(editRow, this.resolve.rows);
            if (editRow.refId) {
                this.refSelected = this.refOptions.find(opt => opt.id === editRow.refId);
            }
            if (this.refSelected == null) {
                this.refSelected = this.refOptions.find(opt => opt.basicChartCategoryId === liikevaihto_basic_id);
                if (!this.refSelected && this.refOptions.length > 0) {
                    this.refSelected = this.refOptions[0];
                }
            }
            this.rowType = (editRow.refId) ? rowTypes.PERCENTAGE_OF : (editRow.refRatio) ? rowTypes.GROWTH_OF : rowTypes.ABSOLUTE;
            this.percentage = parseFloatToString(editRow.refRatio);
        }

        save() {
            let result = {};
            result.row = this.resolve.editRow;
            result.rowType = this.rowType;
            if (this.rowType === rowTypes.PERCENTAGE_OF) {
                result.refRow = this.refSelected;
                result.refPercentage = parseStringToFloat(this.percentage);
            } else if (this.rowType === rowTypes.GROWTH_OF) {
                result.refRow = null;
                result.refPercentage = parseStringToFloat(this.percentage);
            } else {
                result.refRow = null;
                result.refPercentage = null;
            }
            this.close({$value: result});
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }


    }
}

export function parseStringToFloat(str) {
    let percentage = 0;
    if (str) {
        let decimalSeparatorFixed = str.replace(',', '.');
        percentage = parseFloat(decimalSeparatorFixed);
    }
    if (isNaN(percentage)) {
        percentage = 0;
    }
    return percentage;
}

export function parseFloatToString(floatNumber) {
    return (floatNumber) ? floatNumber.toString().replace('.', ',') : '0';
}