import HttpClient from './http-client';


export function getFundingData(companyId, budgetId) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/fundings`;
    const promise = HttpClient().get(address);
    return promise;
}

export function postFunding(companyId, budgetId, fundingData) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/fundings`;
    const promise = HttpClient().post(address, fundingData);
    return promise;
}

export function deleteFunding(companyId, budgetId, fundingId) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/fundings/${fundingId}`;
    const promise = HttpClient().delete(address);
    return promise;
}

export function editFunding(companyId, budgetId, funding) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/fundings/${funding.id}`;
    const promise = HttpClient().put(address, funding);
    return promise;
}

export const getInvestmentData = (companyId, budgetId) => {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/investments`;
    return HttpClient().get(address);
}

export const getInvestmentFundingData = (companyId, budgetId) => {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/investments/fundings`;
    return HttpClient().get(address);
}

export const postInvestment = (companyId, budgetId, data) => {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/investments`;
    return HttpClient().post(address, data);
}

export const deleteInvestment = (companyId, budgetId, investmentId) => {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/investments/${investmentId}`;
    return HttpClient().delete(address);
}

export const updateInvestment = (companyId, budgetId, investment) => {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/investments/${investment.id}`;
    return HttpClient().put(address, investment);
}

export const getBasicChartOfAccountsData = () => {
    return HttpClient().get('/api/basic-chart-of-accounts/');
}

export const getDepreciations = (companyId, budgetId) => {
    return HttpClient().get(`/api/companies/${companyId}/budgets/${budgetId}/depreciations`);
}

export const postDepreciations = (companyId, budgetId, data) => {
    return HttpClient().post(`/api/companies/${companyId}/budgets/${budgetId}/depreciations`, data);
}

export const updateDepreciations = (companyId, budgetId, data, depreciationId) => {
    return HttpClient().put(`/api/companies/${companyId}/budgets/${budgetId}/depreciations/${depreciationId}`, data);
}

export function getWorkingCapital(companyId, budgetId) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/workingcapitals`;
    return HttpClient().get(address);
}

export function postWorkingCapital(companyId, budgetId, workingCapitalData) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/workingcapitals`;
    return HttpClient().post(address, workingCapitalData);
}

export function putWorkingCapital(companyId, budgetId, workingCapitalData) {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/workingcapitals/${workingCapitalData.id}`;
    return HttpClient().put(address, workingCapitalData);
}

export const getCompanyAccountValuesById = (companyId, accountId, isCumulative) => {
    const address = `/api/companies/${companyId}/account-groups/${accountId}?cumulative=${isCumulative}`;
    return HttpClient().get(address);
}

export const getCompanyMeasures = (companyId) => {
    const address = `/api/companies/${companyId}/measures`;
    return HttpClient().get(address);
}

export const getAnalysisGroups = () => {
    const address = `/api/econsult/groups`;
    return HttpClient().get(address);
}

export const getEConsultAnalysis = (companyId, cumulative, selectedElements) => {
    const address = `/api/econsult/${companyId}?cumulative=${cumulative}`;
    return HttpClient().post(address, selectedElements);
}

export const getEConsultPdf = (companyId, selectedElements, cumulative) => {
    const address = `/api/reports/econsult/${companyId}?cumulative=${cumulative}`;
    return HttpClient().post(address, selectedElements);
}

export const getBudgetDataByGlobalId = (companyId, budgetId, globalId) => {
    const address = `/api/companies/${companyId}/budgets/${budgetId}/workingcapitals/budgetvalues/${globalId}`;
    return HttpClient().get(address);
}

export const getConfig= (companyId)=>{
    const address =  `/api/econsult/${companyId}/config`;
    return HttpClient().get(address);
}
