import template from "./budget-month-dialog.html";


export const BudgetMonthDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class BudgetMonthDialogComponent {

        constructor($translate) {
            "ngInject";
            this.realizePosition = null;
            this.possibleRealizePositions = [];
            this.$translate = $translate;
        }

        $onInit() {
            this.possibleRealizePositions = this.resolve.possibleMonths.map((value) => {return {year: value.year, month: value.month, name: this.$translate.instant('months.' + value.month)}; });
        }

        save() {
            this.close({$value: this.realizePosition});
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }

    }
}