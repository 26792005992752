'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('costaccount-modify', {
                parent: 'site',
                url: '/costaccountname/{companyId}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE'],
                    pageTitle: 'ecoeasyApp.accountCategory.home.costAccount'
                },
                views: {
                    'content@': {
                        template: require('./cost-account-modify.html'),
                        controller: 'CostAccountModifyController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    company_id: ['$stateParams', function ($stateParams) {
                        return $stateParams.companyId;
                    }]
                }
            })
    });
