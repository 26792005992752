import angular from 'angular';
import {BudgetComponent} from './budget.component';
import {SubBudgetsEditComponent} from "./subbudgets-edit/subbudgets-edit.component";
import {BudgetMonthDialogComponent} from "./budget-month-dialog/budget-month-dialog.component";
import {Budget2ComponentService} from "./budget.service";
import {Budget2TableModule} from "./budget-table/budget-table.module";
import {SharedModule} from "../shared/shared.module";
import {BudgetRestClientService} from "./budget-rest-client.service";
import {BudgetDialogComponent} from "./budget-dialog/budget-dialog.component";
import {reactToAngularComponent} from "../../react-app/utils/react-to-angular";
import FundingWrapper from "../../react-app/components/budget/funding/funding-wrapper";
import InvestmentWrapper from "../../react-app/components/budget/investment/investment-wrapper";
import DepreciationWrapper from "../../react-app/components/budget/depreciation/depreciation-wrapper";
import WorkingCapitalWrapper from "../../react-app/components/budget/working-capital-changes/working-capital-wrapper";
import {BudgetUpdateDialogComponent} from './budget-update-dialog/budget-update-dialog.component';

export const BudgetModule = angular
    .module(
        'budget',
        [SharedModule, Budget2TableModule]
    )
    .config(function ($stateProvider) {
        $stateProvider.state({
            parent: 'site',
            name: 'budget2',
            url: '/budget2',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.title'
            },
            views: {
                'content@': {
                    template: '<budget>Budget2</budget>'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('budget');
                    return $translate.refresh();
                }]
            }
        })
    })
    .service('BudgetRestClientService', BudgetRestClientService)
    .service('Budget2ComponentService', Budget2ComponentService)
    .component('budget', BudgetComponent)
    .component('subBudgetsEdit', SubBudgetsEditComponent)
    .component('budgetMonthDialog', BudgetMonthDialogComponent)
    .component('budgetDialog', BudgetDialogComponent)
    .component('fundingBudget', reactToAngularComponent(FundingWrapper))
    .component('investmentBudget', reactToAngularComponent(InvestmentWrapper))
    .component('depreciationBudget', reactToAngularComponent(DepreciationWrapper))
    .component('workingCapitalBudget', reactToAngularComponent(WorkingCapitalWrapper))
    .component('budgetUpdateDialog', BudgetUpdateDialogComponent)
    .name;

