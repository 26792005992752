'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('accountingModel', {
                parent: 'entity',
                url: '/accountingModels',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.accountingModel.home.title'
                },
                views: {
                    'content@': {
                        template: require('./accountingModels.html'),
                        controller: 'AccountingModelController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountingModel');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountingModel.detail', {
                parent: 'entity',
                url: '/accountingModel/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.accountingModel.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./accountingModel-detail.html'),
                        controller: 'AccountingModelDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountingModel');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AccountingModel', function ($stateParams, AccountingModel) {
                        return AccountingModel.get({id: $stateParams.id});
                    }]
                }
            })
            .state('accountingModel.new', {
                parent: 'accountingModel',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./accountingModel-dialog.html'),
                        controller: 'AccountingModelDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('accountingModel', null, {reload: true});
                    }, function () {
                        $state.go('accountingModel');
                    })
                }]
            })
            .state('accountingModel.edit', {
                parent: 'accountingModel',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./accountingModel-dialog.html'),
                        controller: 'AccountingModelDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['AccountingModel', function (AccountingModel) {
                                return AccountingModel.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('accountingModel', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('accountingModel.delete', {
                parent: 'accountingModel',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./accountingModel-delete-dialog.html'),
                        controller: 'AccountingModelDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['AccountingModel', function (AccountingModel) {
                                return AccountingModel.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('accountingModel', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
