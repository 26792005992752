import template from './indicator-list.html';

export const IndicatorListComponent = {
    bindings: {
        basicChartId: '<',
        measures: '<',
        indicators: '<',
        onListChange: '&'
    },
    template: template,
    controller: class IndicatorListComponent {
        constructor($log, $uibModal, $http, Indicator) {
            "ngInject";
            this.Indicator = Indicator;
            this.$log = $log;
            this.$uibModal = $uibModal;
            this.$http = $http;
        }

        $onInit() {
            this.$log.debug('IndicatorListComponent.$init');
        }

        $onChanges(changesObj) {
            this.$log.debug('IndicatorListComponent.$onChanges', changesObj);
        }

        createIndicator() {
            this.$log.debug('IndicatorListComponent.createIndicator');
            const ctrl = this;
            this.$uibModal.open({
                component: 'indicatorEdit',
                resolve: {
                    indicator: function () {
                        return {}
                    },
                    measures: function () {
                        return ctrl.measures;
                    }
                },
                backdrop: 'static',
                size: 'lg'
            }).result.then((modalResult) => {
                this.$log.debug('IndicatorListComponent.createIndicator, modal result', modalResult);
                this.$http.post('/api/basic-chart-of-accounts/'+this.basicChartId+'/indicators', modalResult)
                    .then(
                        (result)=>{
                            this.$log.debug('Saving the indicator succeeded', result);
                            this.onListChange();
                        },
                        (error)=>{
                            this.$log.error('Saving the indicator failed', error);
                        });
            });
        }

        editIndicator(indicator) {
            this.$log.debug('IndicatorListComponent.editIndicator: indicator', indicator);
            const ctrl = this;
            this.$uibModal.open({
                component: 'indicatorEdit',
                resolve: {
                    indicator: function () {
                        return indicator
                    },
                    measures: function () {
                        return ctrl.measures;
                    }
                },
                backdrop: 'static',
                size: 'lg'
            }).result.then((modalResult) => {
                this.$log.debug('IndicatorListComponent.editIndicator, modal result', modalResult);
                this.$http.put('/api/basic-chart-of-accounts/'+this.basicChartId+'/indicators/'+modalResult.id, modalResult)
                    .then(
                        (result)=>{
                            this.$log.debug('Saving the indicator succeeded', result);
                            this.onListChange();
                        },
                        (error)=>{
                            this.$log.error('Saving the indicator failed', error);
                        });
            });
        }

        removeIndicator(indicator) {
            this.$log.debug('IndicatorListComponent.removeIndicator: indicator', indicator);
            if (confirm('Haluatko varmasti poistaa mittarin "' + indicator.name + '"?')) {
                this.$http.delete('/api/basic-chart-of-accounts/'+this.basicChartId+'/indicators/'+indicator.id)
                    .then(
                        (result)=>{
                            this.$log.debug('Removing the indicator succeeded', result);
                            this.onListChange();
                        },
                        (error)=>{
                            this.$log.error('Removing the indicator failed', error);
                        });
            }
        }

    }
};
