export function moneyFilter(input) {
    var number = input.toFixed(2);
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
}

export function moneyFilterFactory() {
    return moneyFilter;
}
