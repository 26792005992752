import template from './budget-table.html';
import './budget-table.css'
import {BudgetTableRowModel} from "./budget-table-row-model";
import {BudgetTableRowType} from "../budget-table-data-row";

export const BudgetTableComponent = {
    bindings: {
        data: '<',
        readonly: '<',
        budgetFiscalYearId: '<',
        companyId: '<',
        budgetId: '<',
        changedValuesContainer: '<',
        companyIncomeStatementChart: '<',
        onValueChange: '&',
        onBudgetChange: '&',
        actualDate: '<',
        yearMonths: '<',
        cashBudget: '<'
    },
    template: template,
    controller: class BudgetTableComponent {

        constructor($log, $translate, $uibModal, FiscalYear, IncomeStatementRestClientService, $q, OperatingBudgetService, FEATURE_SEPARATE_BUDGET_INFO_ROWS, BudgetRestClientService) {
            "ngInject";
            this.$log = $log;
            this.$translate = $translate;
            this.$uibModal = $uibModal;
            this.months = null;
            this.rows = [];
            this.cashFlowValuesList = [];
            this.readonlyTable = false;
            this.thousands = false;
            this.showAllRows = true;
            this._rowModel = null;
            this.fiscalYearResource = FiscalYear;
            this.incomeStatementRestClientService = IncomeStatementRestClientService;
            this.$q = $q;
            this.operatingBudgetService = OperatingBudgetService;
            this.separateInfoRowsFeature = FEATURE_SEPARATE_BUDGET_INFO_ROWS;
            this.decimals = false;
            this.budgetRestClientService = BudgetRestClientService;
            this.isCashBudget = this.cashBudget;
        }

        $onChanges(changesObj) {
            this.$log.debug('BudgetComponent.$onChanges: changesObj', changesObj, this.companyIncomeStatementChart, this.data);
            if (this.data) {
                if (this.data.monthNumbers) {
                    this.months = this.data.monthNumbers;
                }
                if (this.data.rows && this.companyIncomeStatementChart) {
                    this._rowModel = new BudgetTableRowModel(this.data.rows, this.companyIncomeStatementChart, this.$translate.use(), this.actualDate, this.months);
                    this.rows = this._rowModel.getRows();
                    this.showAllRows = true;
                }
                if (this.data.cashFlowValuesList) {
                    this.cashFlowValuesList = this.data.cashFlowValuesList;
                }
            }

            this.readonlyTable = this.readonly;
        }

        $onInit() {
            //this.$log.debug('BudgetTableComponent.$onInit', this.companyIncomeStatementChart, this.data);
        }

        toggleThousands() {
            this.thousands = !this.thousands;
            this._rowModel.toggleThousands();
        }

        numberInputValueChanged(row, index, newValue) {
            this.$log.debug('BudgetTableComponent.numberInputValueChanged(row,index,newValue)', row, index, newValue);
            row.values[index] = newValue;
            this.onValueChange({row: row, index: index});
            this._rowModel.rowValueChanged(row, index, this.onValueChange);
        }

        toggleIsOpen(row) {
            this._rowModel.toggleIsOpen(row);
        }

        toggleIsOpenAll() {
            this.showAllRows = !this.showAllRows;
            this._rowModel.toggleIsOpenAll(this.showAllRows);
        }

        _confirmationCheck() {
            return this.changedValuesContainer.hasValues() && !window.confirm("Budjetissa on tallentamattomia muutoksia, jotka katoavat ilman tallentamista. Haluatko silti jatkaa?");
        }

        addRows(row) {
            if (this._confirmationCheck()) return;
            if (row.options) {
                this.$uibModal.open({
                    size: 'lg',
                    backdrop: 'static',
                    component: 'addRowsDialog',
                    resolve: {
                        options: () => row.options,
                        current: () => row
                    },
                }).result.then((addThese) => {
                    this.operatingBudgetService.addRows(this.companyId, this.budgetId, addThese).then(() => {
                        this.onBudgetChange({budgetId: this.budgetId});
                    });
                });
            }
        }

        editRow(row) {
            if (this._confirmationCheck()) return;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'editRowDialog',
                resolve: {
                    editRow: () => row,
                    rows: () => this.rows
                },
            }).result.then((result) => {
                this.operatingBudgetService.editRow(this.companyId, this.budgetId, result).then(() => {
                    this.onBudgetChange({budgetId: this.budgetId});
                });
            });
        }

        deleteRow(row) {
            if (this._confirmationCheck()) return;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'deleteRowDialog',
                resolve: {
                    deleteRow: () => row
                },
            }).result.then((result) => {
                this.operatingBudgetService.deleteRow(this.companyId, this.budgetId, result).then(() => {
                    this.onBudgetChange({budgetId: this.budgetId});
                });
            });
        }

        isLabelValue(row, valueIndex) {
            return this.readonlyTable || row.sum || (BudgetTableRowType.GROWTH === row.type && valueIndex > 0) || row.type === 'LOCKED';

        }

        handleMemo(row) {
            if (this._confirmationCheck()) return;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'memoDialog',
                resolve: {
                    memoData: () => row,
                }
            }).result.then((result) => {
                this.operatingBudgetService.saveMemo(this.companyId, this.budgetId, result).then(() => {
                    this.onBudgetChange({budgetId: this.budgetId});
                });
            });
        }

        calculateRow(row, index) {
            let absoluteTypeRows = [];

            this.rows.forEach(row => {
                if (row.type === 'ABSOLUTE') {
                    absoluteTypeRows.push(row);
                }
            });

            const data = {
                rows: absoluteTypeRows,
                months: this.months,
                row: row,
                index: index
            };

            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'rowCalculationDialog',
                resolve: {
                    data: () => data
                }
            }).result.then(result => {
                if (result.calculate === 'SINGLE_ROW') {
                    row.values = result.values;
                    this.onValueChange({row: row, index: index});
                    this._rowModel.rowValueChangedWithFastCalculation(row, this.onValueChange);
                } else {
                    absoluteTypeRows.forEach((row, i) => {
                        row.values = result.values[i].values;
                        this.onValueChange({row: row, index: index});
                        this._rowModel.rowValueChangedWithFastCalculation(row, this.onValueChange);
                    });
                }
                this._rowModel.updateRowSums();
            });
        }

        allocateRowValues(row) {
            this.$log.debug('BudgetTableComponent.allocateRowValues', row, this.budgetFiscalYearId);
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'allocateValuesDialogComponent',
                resolve: {
                    data: () => {}
                }
            }).result.then(result => {
                if (result.evenly) {
                    const newValues = allocateEvenly(result.total, row.values.length);
                    row.values.forEach((value, index) => {
                        this.numberInputValueChanged(row, index, newValues[index]);
                    });
                } else {
                    this._getRevenueRow().then(revenueValues => {
                        const newValues = allocateRevenuePercent(result.total, revenueValues);
                        row.values.forEach((value, index) => {
                            this.numberInputValueChanged(row, index, newValues[index]);
                        });
                    });
                }
            });
        }

        _getRevenueRow() {
            return this.$q((resolve, reject) => {
                this.fiscalYearResource.get({id: this.budgetFiscalYearId}).$promise.then(fiscalYear => {
                    let promiseArray = [];
                    getYearMonths(fiscalYear).forEach((yearMonth) => {
                        promiseArray.push(this.incomeStatementRestClientService.getIncomeStatement(this.companyId, yearMonth.year, yearMonth.month));
                    });
                    this.$q.all(promiseArray)
                        .then(results => {
                            let revenueValues = [];
                            results.forEach(result => revenueValues.push(getRevenueValue(result.previous)));
                            resolve(revenueValues);
                        });
                });
            });
        }

        isActualizedMonth(index) {
            if (this.actualDate !== null) {
                return this.yearMonths[index].year <= this.actualDate.year && this.yearMonths[index].month <= this.actualDate.month;
            }
            return false;
        }
    }
}

function getYearMonths(fiscalYear) {
    let current = new Date(fiscalYear.startDate.getFullYear(), fiscalYear.startDate.getMonth(), 15);
    let yearMonths = [];
    for (let i = 0; i < fiscalYear.length; i++) {
        yearMonths.push({
            year: current.getFullYear(),
            month: current.getMonth() + 1
        });
        current.setMonth(current.getMonth() + 1);
    }
    return yearMonths;
}

function getRevenueValue(incomeStatement) {
    let revenueGroup = findGroup('LIIKEVAIHTO', incomeStatement.groups);
    return revenueGroup.value;
}

function findGroup(groupName, groups) {
    for (const group of groups) {
        if (group.name.replace(/\s+/g, '').toUpperCase() === groupName) return group;
        const found = findGroup(groupName, group.groups);
        if (found) return found;
    }
    return null;
}

function allocateEvenly(total, fiscalYearLength) {
    const evenPart = total / fiscalYearLength;
    const rounded = Math.round(evenPart); // Bitwise OR to remove decimals
    const values = [];
    for (let i = 0; i < fiscalYearLength; i++) {
        values.push(rounded);
    }
    return values;
}

function allocateRevenuePercent(total, revenueValues) {
    const fiscalYearLength = revenueValues.length;
    const totalRevenue = revenueValues.reduce((total, current) => {
        return total + current;
    }, 0);
    if (totalRevenue === 0) {
        return allocateEvenly(total, fiscalYearLength);
    }
    const ratio = total / totalRevenue;
    const values = [];
    revenueValues.forEach((value, index) => {
        let newValue = Math.round(value * ratio); // Bitwise OR to remove decimals
        values.push(newValue);
    });
    return values;
}
