import React from 'react';
import { 
  ControlLabel,
  FormGroup,
  Glyphicon,
} from 'react-bootstrap';
import { useField, Field } from 'formik';
import {useTranslation} from 'react-i18next';

const RadioButtonInput = ({ label, options, ...props}) => {
  const [field, meta] = useField(props);
    const {t} = useTranslation();

  const errorStyle = {
    fontSize: '12px',
    color: 'red',
    paddingTop: '0rem',
  };
  const labelStyle = {
    marginRight: '1rem',
    marginBottom: '0rem'
  };

  return (
    <FormGroup 
      controlId={props.id}
    >
      <ControlLabel htmlFor={props.id || props.name}>{t(label)}</ControlLabel>
      <br />
      {options.map((option) => (
        <label key={option.value} style={labelStyle}><Field type="radio" name={props.name} value={option.value} disabled={props.readOnly || false} />
            <span style={{marginLeft: '0.5rem'}}>{t(option.translation)}</span>
        </label>
      ))}
      {meta.touched && meta.error ? (
        <div className="errormessage" style={errorStyle}><Glyphicon glyph="remove" />{meta.error}</div>
      ) : null}
    </FormGroup>
  );
}

export default RadioButtonInput;