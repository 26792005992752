(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('CostAccountSelectController', CostAccountSelectController);

    CostAccountSelectController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'cid', 'area' ,'$translate', '$http'];

    function CostAccountSelectController($timeout, $scope, $stateParams, $uibModalInstance, entity, cid, area, $translate, $http) {
        var vm = this;
        var id = null;
        vm.costAccountCategories = entity;
        vm.selectedCostAccounts = [];
        vm.costAccounts = [];
        vm.createArea = false;
        vm.name;
        vm.hasOldArea = false;

        if(area != null && area.id != null){
            vm.selectedCostAccounts = area.accountCategoryValues;
            vm.name = area.name;
            id = area.id;
            vm.hasOldArea = true;
        }

        if (vm.costAccountCategories != null) {
            for (var i = 0; i < vm.costAccountCategories.length; i++) {
                 var category ={}; // {name: "Taso: " + vm.costAccountCategories[i].level, id: i * 0.1, disabled: true};
                // vm.costAccounts.push(category);
                var cat = vm.costAccountCategories[i].costAccounts;
                for (var j = 0; j < cat.length; j++) {
                    vm.costAccounts.push(cat[j]);
                }
            }
        }

        vm.clear = clear;
        vm.save = function(){
            if(vm.createArea === false){
              save();
            } else{
              saveArea();
            }
        }

        vm.selectAll = false;

        vm.toolTipText = $translate.instant('reporting.modalDialog.toolTipText');

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.selectAllCheckBoxChanged = function (selection) {
            if (selection === true) {
                vm.selectAll = true;
                vm.createArea = false;
            } else {
                vm.selectAll = false;
            }
        };

        vm.createAreaCheckBoxChanged = function (selection) {
            if (selection === true) {
                vm.createArea = true;
                vm.selectAll = false;
            } else {
                vm.createArea = false;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var selected = vm.selectedCostAccounts;
            var selection = {
                selectAll: vm.selectAll,
                selectedCostAccounts: selected,
                area: null
            };
            $uibModalInstance.close(selection);
            vm.isSaving = false;
        }

        function saveArea() {
            vm.isSaving = true;
            var select = {};
            select.id = id;
            select.companyId = cid;
            select.name = vm.name;
            select.accountCategoryValues = vm.selectedCostAccounts;
            $http.put("/api/accountCategoryValueAreas",select).then( function(value){
                var selection = {
                    area: value.data.id
                };
                $uibModalInstance.close(selection);
                vm.isSaving = false;
            });
        }

        vm.groupFunction = function(item){
            for(var i=0; i < vm.costAccountCategories.length; i++){
                for (var j = 0; j < vm.costAccountCategories[i].costAccounts.length; j++){
                    if(vm.costAccountCategories[i].costAccounts[j].id === item.id){
                        return vm.costAccountCategories[i].level;
                    }
                }
            }
        }
    }
})();
