'use strict';

angular.module('ecoeasyApp')
    .factory('AccountMapping', function ($resource, DateUtils) {
        return $resource('api/accountMappings/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    });

angular.module('ecoeasyApp')
    .service('AccountMappingService', function ($http) {
        var baseUrl = "/api/accountMappings/";

        this.getByCompany = function (companyId) {
            return $http.get(baseUrl + "byCompany/" + companyId);
        }

    });
