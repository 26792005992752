'use strict';

angular.module('ecoeasyApp')
    .controller('RecalculateController', function ($scope, $log, $http, $q, Principal, $interval, $translate, $timeout, FEATURE_COMPANY_ACCOUNT_CHART, SelectedCompanyService) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });

        $scope.new_calculation_enabled = SelectedCompanyService.isBasicChartSelected();

        $scope.isRunning = false;
        var poll;
        var timer;

        function startTiming(cid) {
                poll = $interval(recalculatePolling, 10000, 0, true, cid);
                timer = $timeout(function () {
                    alert($translate.instant("recalculate.timeout"));
                    stopTiming();
                }, 900000);
        }

        function stopTiming() {
            $interval.cancel(poll);
            poll = undefined;
            $('#modal-spinner').hide();
            $scope.isRunning = false;
            $timeout.cancel(timer);
        }

        function recalculatePolling(id) {
            $http.get("/api/agent/recalculatepoll/" + id).then(function (response) {
                    if (response.data === "FINISHED") {
                        stopTiming();
                    } else if (response.data === "ERROR_FINISHED") {
                        stopTiming();
                        alert($translate.instant("recalculate.calcError"));
                    }

                }
            );
        }

        $scope.startRecalculate = function () {
            $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                $scope.isRunning = true;
                $('#modal-spinner').show();
                $http.get("/api/agent/recalculatefull/" + company.id)
                            .then(function (response) {
                                startTiming(response.data);
                            })
                            .catch(function (response) {
                                alert($translate.instant("recalculate.calcError"));
                                stopTiming();
                            });
                    }
                );
        }

    });
