'use strict';

angular.module('ecoeasyApp').controller('AccountingModelDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccountingModel', 'CompanyVariable',
        function ($scope, $stateParams, $uibModalInstance, entity, AccountingModel, CompanyVariable) {

            $scope.accountingModel = entity;
            $scope.companyvariables = CompanyVariable.query();
            $scope.load = function (id) {
                AccountingModel.get({id: id}, function (result) {
                    $scope.accountingModel = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:accountingModelUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.accountingModel.id != null) {
                    AccountingModel.update($scope.accountingModel, onSaveSuccess, onSaveError);
                } else {
                    AccountingModel.save($scope.accountingModel, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
