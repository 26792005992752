'use strict';

angular.module('ecoeasyApp')
    .factory('InvestmentBudgetData', function ($resource, DateUtils) {
        return $resource('api/investmentBudgetDatas/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            
            'getByBudgetId': {
            	url: 'api/investmentBudgetDatas/byBudgetId/:budgetId',
            	method: 'GET',
            	isArray: true
            },
            
            'getByBudgetIdAndHasStillDevaluation' : {
            	url: 'api/investmentBudgetDatas/byBudgetIdAndHasStillDevaluation/:budgetId',
            	method: 'GET',
            	isArray: true
            }
        });
    });
