
function isDefined(some) {
    return typeof some !== "undefined" && some != null;
}

export class ChartFinder {
    constructor(chart) {
        if (!isDefined(chart)) throw "chart missing!";
        this.chart = chart;
    }

    findGroupByBasicChartCategoryId(basicChartCategoryId) {
        return this.findGroupByTest(this.chart, (item)=>{
            return item.basicChartGroupId === basicChartCategoryId;
        })
    }

    findGroupByTest(item, testFn) {
        if (testFn(item)) return item;
        if (item.subGroups) {
            for (let i = 0; i < item.subGroups.length; i++) {
                let found = this.findGroupByTest(item.subGroups[i], testFn);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }
}

