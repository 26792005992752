import template from './basic-chart-of-accounts.html';

export const BasicChartOfAccountsComponent = {
    template: template,
    controller: class BasicChartOfAccountsComponent {
        constructor(ChartService, BasicChartOfAccountsService, $log, $http) {
            "ngInject";
            this.chartService = ChartService;
            this.basicChartOfAccountsService = BasicChartOfAccountsService;
            this.charts = [];
            this.editingRow = null;
            this.$log = $log;
            this.measures = [];
            this.chartId = null;
            this.$http = $http;
            this.indicators = [];
        }

        $onInit() {
            this.$log.debug('BasicChartOfAccountsComponent.$onInit');
            this.basicChartOfAccountsService.getBasicChartOfAccounts().then((response) => {
                this.charts = this.chartService.fromJsonToCharts(response);
                this.chartId = this.charts[0].id;
                this.loadMeasures();
            });
            this.$log.debug('BasicChartOfAccountsComponent.$onInit: this.measures', this.measures);
        }

        loadMeasures() {
            this.basicChartOfAccountsService.getBasicChartMeasures(this.chartId).then((result) => {
                this.$log.debug('BasicChartOfAccountsComponent: getBasicChartMeasures got', result);
                this.measures = result;
                this.$log.debug('BasicChartOfAccountsComponent: this.measures', this.measures);
            });
        }

        $onChanges(changesObj) {
            this.$log.debug('BasicChartOfAccountsComponent.$onChanges', changesObj);
            this.$log.debug('BasicChartOfAccountsComponent.$onChanges: this.measures', this.measures);
        }

        addMeasure(m) {
            this.$log.debug('BasicChartOfAccountsComponent.addMeasure', m);
            this.basicChartOfAccountsService.addBasicChartMeasure(this.chartId, m)
                .then(
                    (success)=>{
                        this.$log.debug('BasicChartOfAccountsComponent.addMeasure: success', success);
                        this.loadMeasures();
                    },
                    (fail)=>{
                        this.$log.error('BasicChartOfAccountsComponent.addMeasure: fail', fail);
                    });
        }

        updateMeasure(m) {
            this.$log.debug('BasicChartOfAccountsComponent.updateMeasure', m);
            this.basicChartOfAccountsService.updateBasicChartMeasure(this.chartId, m)
                .then(
                    (success)=>{
                        this.$log.debug('BasicChartOfAccountsComponent.updateMeasure: success', success);
                        this.loadMeasures();
                    },
                    (fail)=>{
                        this.$log.error('BasicChartOfAccountsComponent.updateMeasure: fail', fail);
                    });
        }


        removeMeasure(m) {
            this.$log.debug('BasicChartOfAccountsComponent.removeMeasure', m);
            this.basicChartOfAccountsService.removeBasicChartMeasure(this.chartId, m)
                .then(
                    (success)=>{
                        this.$log.debug('BasicChartOfAccountsComponent.removeMeasure: success', success);
                        this.loadMeasures();
                    },
                    (fail)=>{
                        this.$log.error('BasicChartOfAccountsComponent.removeMeasure: fail', fail);
                    });
        }

        indicatorsTabSelected() {
            this.$log.debug('BasicChartOfAccountsComponent.indicatorsTabSelected');
            this._fetchIndicators();
        }
        chartTabSelected(chartId){
            this.chartId = chartId;
            this.loadMeasures();
        }
        _fetchIndicators() {
            this.$http.get('/api/basic-chart-of-accounts/' + this.chartId + '/indicators')
                .then(
                    (response)=>{
                        this.$log.debug('IndicatorListComponent: GET indicators', response);
                        this.indicators = response.data;
                    },
                    (error)=>{
                        this.$log.error('IndicatorListComponent: GET indicators', error);
                    }
                );
        }

        indicatorListChanged() {
            this.$log.debug('BasicChartOfAccountsComponent.indicatorListChanged');
            this._fetchIndicators();
        }

    }
};
