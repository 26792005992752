import i18next from "i18next";

export class CompanyChartAccount {
    constructor(id, code, nameFi, nameEn, nameSv, isOrphanAccount, level, parentGroup) {
        this.id = id;
        this.code = code;
        this.nameFi = nameFi;
        this.nameEn = nameEn;
        this.nameSv = nameSv;
        this.isAccount = true;
        this.isOrphanAccount = isOrphanAccount;
        this.level = level*3;
        this.selectedYTKRow = parentGroup;
        if(i18next.language == "en" && nameEn != null && nameEn != undefined && nameEn != "") {
            this.name = nameEn
        } else {
            this.name = nameFi;
        }
    }

}
