'use strict';

angular.module('ecoeasyApp')
    .controller('IndustryCollectionDefinitionDetailController', function ($scope, $rootScope, $stateParams, entity, IndustryCollectionDefinition, Industry) {
        $scope.industryCollectionDefinition = entity;
        $scope.load = function (id) {
            IndustryCollectionDefinition.get({id: id}, function (result) {
                $scope.industryCollectionDefinition = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:industryCollectionDefinitionUpdate', function (event, result) {
            $scope.industryCollectionDefinition = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
