'use strict';

angular.module('ecoeasyApp').controller('BalanceBudgetDataDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'BalanceBudgetData', 'CompanyVariable', 'Budget',
        function ($scope, $stateParams, $uibModalInstance, entity, BalanceBudgetData, CompanyVariable, Budget) {

            $scope.balanceBudgetData = entity;
            $scope.companyvariables = CompanyVariable.query();
            $scope.budgets = Budget.query();
            $scope.load = function (id) {
                BalanceBudgetData.get({id: id}, function (result) {
                    $scope.balanceBudgetData = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:balanceBudgetDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.balanceBudgetData.id != null) {
                    BalanceBudgetData.update($scope.balanceBudgetData, onSaveSuccess, onSaveError);
                } else {
                    BalanceBudgetData.save($scope.balanceBudgetData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
