export class HttpClientService {

    constructor($http) {
        "ngInject";
        this.httpClient = $http;
    }

    get(url) {
        return this.httpClient.get(url);
    }

    put(url, data) {
        return this.httpClient.put(url, data);
    }

    post(url, data) {
        return this.httpClient.post(url, data);
    }

    delete(url) {
        return this.httpClient.delete(url);
    }

}