import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from "react-bootstrap/lib/Modal";
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import Button from "react-bootstrap/lib/Button";
import {deleteInvestment} from "../../../service/react-service";
import {bool, func, number} from "prop-types";

const DeleteInvestmentModal = ({
                                   budgetId,
                                   companyId,
                                   showModal,
                                   setShowModal,
                                   investmentData,
                                   setSelectedData,
                                   showInvestmentPopup,
                                   setShowInvestmentPopup
                               }) => {
    const {t} = useTranslation();

    const handleCloseModal = () => {
        setSelectedData(null);
        setShowModal(!showModal);
    };

    const handleOnDelete = () => {
        deleteInvestment(companyId, budgetId, investmentData.id).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                setShowInvestmentPopup(!showInvestmentPopup);
            }
        });
        handleCloseModal();
    }

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('investments:modal.deleteInvestment')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('investments:modal.actions.confirmDelete')}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCloseModal}>
                    <Glyphicon glyph={'ban-circle'}/>
                    {t('investments:modal:buttons:cancel')}
                </Button>
                <Button bsStyle="primary" onClick={() => handleOnDelete()}>
                    {t('investments:modal:buttons:delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

DeleteInvestmentModal.propTypes = {
    budgetId: number.isRequired,
    companyId: number.isRequired,
    showModal: bool.isRequired,
    setShowModal: func.isRequired,
    setSelectedData: func.isRequired,
    showInvestmentPopup: bool.isRequired,
    setShowInvestmentPopup: func.isRequired
};

export default DeleteInvestmentModal;