'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('accountCategory', {
                parent: 'entity',
                url: '/accountCategorys',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.accountCategory.home.title'
                },
                views: {
                    'content@': {
                        template: require('./accountCategorys.html'),
                        controller: 'AccountCategoryController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountCategory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountCategory.detail', {
                parent: 'entity',
                url: '/accountCategory/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.accountCategory.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./accountCategory-detail.html'),
                        controller: 'AccountCategoryDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountCategory');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'AccountCategory', function ($stateParams, AccountCategory) {
                        return AccountCategory.get({id: $stateParams.id});
                    }]
                }
            })
            .state('accountCategory.new', {
                parent: 'accountCategory',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./accountCategory-dialog.html'),
                        controller: 'AccountCategoryDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    companyId: null,
                                    code: null,
                                    name: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('accountCategory', null, {reload: true});
                    }, function () {
                        $state.go('accountCategory');
                    })
                }]
            })
            .state('accountCategory.edit', {
                parent: 'accountCategory',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./accountCategory-dialog.html'),
                        controller: 'AccountCategoryDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['AccountCategory', function (AccountCategory) {
                                return AccountCategory.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('accountCategory', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('accountCategory.delete', {
                parent: 'accountCategory',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./accountCategory-delete-dialog.html'),
                        controller: 'AccountCategoryDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['AccountCategory', function (AccountCategory) {
                                return AccountCategory.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('accountCategory', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
