'use strict';

angular.module('ecoeasyApp')
    .controller('LicenseProductPurchaseController', function ($scope, $state, LicenseProductPurchase, ParseLinks) {

        $scope.licenseProductPurchases = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function () {
            LicenseProductPurchase.query({
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.licenseProductPurchases = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.licenseProductPurchase = {
                unitAmount: null,
                unitPrice: null,
                purchasePrice: null,
                purchaseAmount: null,
                purchaseDate: null,
                deActivationDate: null,
                active: false,
                id: null
            };
        };
    });
