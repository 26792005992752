'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('customermanagement', {
                parent: 'entity',
                url: '/customermanagement',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE'],
                    pageTitle: 'ecoeasyApp.customer.home.title'
                },
                views: {
                    'content@': {
                        template: require('./customers.html'),
                        controller: 'CustomerController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('company');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('customermanagement.detail', {
                parent: 'customermanagement',
                url: '/customermanagement/{id}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE'],
                    pageTitle: 'ecoeasyApp.customer.detail.title'

                },
                views: {
                    'content@': {
                        template: require('./customer-detail.html'),
                        controller: 'CustomerDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('company');
                        return $translate.refresh();
                    }],
                    companyId: ['$stateParams', function ($stateParams) {
                        return $stateParams.id;
                    }]
                }
            })
            .state('customermanagement.new', {
                parent: 'customermanagement',
                url: '/{orc}/new',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./customer-dialog.html'),
                        controller: 'CustomerDialogController',
                        size: 'lg',
                        resolve: {
                            companyId: [function () {
                                return null;
                            }],
                            orc: [function () {
                                return $stateParams.orc;
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('customermanagement', null, {reload: true});
                    }, function () {
                        $state.go('customermanagement');
                    })
                }]
            })
            .state('customermanagement.edit', {
                parent: 'customermanagement',
                url: '/{id}/{orc}/edit',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./customer-dialog.html'),
                        controller: 'CustomerDialogController',
                        size: 'lg',
                        resolve: {
                            companyId: [function () {
                                return $stateParams.id;
                            }],
                            orc: [function () {
                                return $stateParams.orc;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('customermanagement', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('customermanagement.delete', {
                parent: 'customermanagement',
                url: '/{id}/delete/{target}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT', 'LICENSE_PURCHASE'],
                    id: 'id',
                    target: 'target'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./customer-delete-dialog.html'),
                        controller: 'CustomerDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Company', function (Company) {
                                return Company.get({id: $stateParams.id}).$promise;
                            }]
                        }

                    }).result.then(function (result) {
                        $state.go('customermanagement', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
