'use strict';

angular.module('ecoeasyApp')
    .controller('LicenseProductDetailController', function ($scope, $rootScope, $stateParams, entity, LicenseProduct) {
        $scope.licenseProduct = entity;
        $scope.load = function (id) {
            LicenseProduct.get({id: id}, function (result) {
                $scope.licenseProduct = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:licenseProductUpdate', function (event, result) {
            $scope.licenseProduct = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
