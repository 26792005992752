'use strict';

angular.module('ecoeasyApp').controller('UserManagementDialogController',
    ['$scope', '$http', '$stateParams', '$uibModalInstance', 'entity', 'User', 'Language', 'companyId', '$translate', 'sourceState', 'UserGroupService', 'Principal', 'executionMethod', '$sce',
        function ($scope, $http, $stateParams, $uibModalInstance, entity, User, Language, companyId, $translate, sourceState, UserGroupService, Principal, executionMethod, $sce) {
            var orgId = undefined;

            if (sourceState === "ORG") {
                $scope.showUserGroups = true;
                Principal.identity().then(function (account) {
                    $scope.currentAccount = account;
                    //poista ja muuta refactoroinnissa
                    orgId = account.sourceId;

                    UserGroupService.getAllForOrganisation(orgId)
                        .then(function (userGroups) {
                            $scope.orgUserGroups = userGroups;
                        });
                });
            }

            if (executionMethod === "ADD") {
                $scope.sendActivationMail = true;
                $scope.editPassword = true;
            } else {
                $scope.editPassword = false;
                $scope.sendActivationMail = false;
            }

            $scope.toolTipText = $translate.instant('user-management.toolTipText');

            $scope.sourceState = sourceState;
            $scope.executionMethod = executionMethod;
            $scope.user = {};
            $scope.sendreport = false;
            $scope.password = "";
            $scope.confirmPassword = "";
            $scope.passwordRequired = false;
            $scope.doNotMatch = false;
            $scope.selectedUserGroups = [];

            //TODO muokkauksessa usergroupit ei mee
            //TODO tyhjennettava salasanakentat kun checki vaihetaa pois
            $scope.checkBoxChanged = function (checkedValue) {
                if ($scope.executionMethod === "ADD" && checkedValue === true) {
                    $scope.passwordRequired = false;
                }
                if ($scope.executionMethod === "ADD" && checkedValue === false) {
                    $scope.passwordRequired = true;
                }
                if ($scope.executionMethod === "EDIT" && checkedValue === false) {
                    $scope.passwordRequired = false;
                }
                if ($scope.executionMethod === "EDIT" && checkedValue === true) {
                    $scope.passwordRequired = true;
                }
            };

            if (entity != null) {
                $scope.user = entity;
                if ($scope.user.userGroups != null && sourceState === "ORG") {
                    for (var i = 0; i < $scope.user.userGroups.length; i++) {
                        $scope.selectedUserGroups.push($scope.user.userGroups[i].id);
                    }
                }
            }


            if (entity != null && sourceState === "COMPANY") {
                if (entity.id != null) {
                    $http.get("/api/roles/sendreport/" + companyId).then(function (result) {
                        $scope.sendreport = result.data;
                    });
                }
            }

            /*
            if(entity.login != null && sourceState === "COMPANY") {
                $http.get("/api/users/"+entity.login).then(function(result){
                    $scope.user = result.data;
                    if($scope.user.id != null){
                        $http.get("/api/roles/sendreport/"+company_id).then(function(result){
                            $scope.sendreport = result.data; 
                        });
                    }
                });
            }
            */

            $scope.authorities = ["ROLE_USER", "ROLE_ADMIN", "ROLE_AGENT", "ROLE_USER_ORGANISATION", "ROLE_ADMIN_ORGANISATION", "ROLE_BILLING", "ROLE_BILLING_ORGANISATION"];
            Language.getAll().then(function (languages) {
                $scope.languages = languages;
            });
            var onSaveSuccess = function (result) {
                $scope.isSaving = false;
                $uibModalInstance.close(result);
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            //TODO usergroups parametrin voi poistaa
            $scope.save = function (usergroups) {
                $scope.isSaving = true;
                if ($scope.sourceState === "ORG") {
                    var organisationId = {organisationId: orgId};

                    var usrGroups = {userGroupIds: $scope.selectedUserGroups};

                    if ($scope.executionMethod === "ADD") {
                        $scope.user.sourceId = orgId;
                        $scope.user.sourceType = "ORGANISATION";
                    } else if ($scope.user.sourceId == null) {
                        $scope.user.sourceId = orgId;
                        $scope.user.sourceType = "ORGANISATION";
                    }

                    var user = {user: $scope.user};

                    var sendreport = {sendReport: $scope.sendreport};

                    if ($scope.passwordRequired && $scope.password !== $scope.confirmPassword) {
                        $scope.doNotMatch = true;
                        $scope.isSaving = false;
                        return;
                    } else if ($scope.passwordRequired) {
                        $scope.doNotMatch = false;
                        var pw = {password: $scope.password};
                    } else {
                        var pw = {password: null};
                    }

                    var auth = {authorities: angular.copy($scope.user.authorities)};

                    var obj = {};
                    angular.extend(obj, user, organisationId, sendreport, pw, usrGroups, auth);

                    if ($scope.user.id != null) {
                        $http.put("/api/organisationuser", obj).then(function (response) {
                            if (response.status === 200) {
                                onSaveSuccess(response.data);
                            }
                            else {
                                onSaveError(response.data);
                            }
                        });
                    } else {
                        $http.post("/api/organisationuser", obj).then(function (response) {
                            if (response.status === 201) {
                                onSaveSuccess(response.data);
                            }
                            else {
                                onSaveError(response.data);
                            }
                        });
                    }
                }
                //

                if ($scope.sourceState === "COMPANY") {

                    if ($scope.executionMethod === "ADD") {
                        $scope.user.sourceId = companyId;
                        $scope.user.sourceType = "COMPANY";
                    } else if ($scope.user.sourceId == null) {
                        $scope.user.sourceId = companyId;
                        $scope.user.sourceType = "COMPANY";
                    }

                    var comp = {companyId: companyId}; //poista refactoroinnissa
                    var user = {user: $scope.user};
                    var sendreport = {sendReport: $scope.sendreport};
                    var auth = {authorities: angular.copy($scope.user.authorities)};
                    var obj = {};
                    angular.extend(obj, user, comp, sendreport, auth);

                    if ($scope.passwordRequired && $scope.password !== $scope.confirmPassword) {
                        $scope.doNotMatch = true;
                        $scope.isSaving = false;
                        return;
                    }
                    else if ($scope.passwordRequired) {
                        $scope.doNotMatch = false;
                        obj.password = $scope.password;
                    } else {
                        obj.password = null;
                    }

                    if ($scope.user.id != null) {
                        $http.put("/api/companyuser", obj).then(function (response) {
                            if (response.status === 200) {
                                onSaveSuccess(response.data);
                            }
                            else {
                                onSaveError(response.data);
                            }
                        });
                    } else {
                        $http.post("/api/companyuser", obj).then(function (response) {
                            if (response.status === 201) {
                                onSaveSuccess(response.data);
                            }
                            else {
                                onSaveError(response.data);
                            }
                        });
                    }
                    //}
                }
            };

            $scope.updateSelectedUserGroups = function (selectedUserGroup) {
                $scope.selectedUserGroups = selectedUserGroup;
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
