import './company-account-chart-edit-dialog.css'

export class CompanyAccountChartEditDialogController {
    constructor($log, $translate, $uibModalInstance, row, tase, tuloslaskelma, PTKtase, PTKtuloslaskelma, PTKButtons) {
        "ngInject";
        this.$log = $log;
        this.$translate = $translate;
        this.$uibModalInstance = $uibModalInstance;
        this.row = row;
        this.tase = tase;
        this.tuloslaskelma = tuloslaskelma;
        this.PTKtase = PTKtase;
        this.PTKtuloslaskelma = PTKtuloslaskelma;
        this.open=false;
        this.ptkButtonsVisible = PTKButtons;
        this.clearButtonPressedLast = false;
        this.setSelectedYTK(row.selectedYTKRow);
        this.editingRow = angular.copy(this.row);
    }

    $onInit() {
        this.setNotSelectableGroups();
    };

    clear(){
        this.clearNotSelectables();
        this.$uibModalInstance.dismiss();
    }

    save(){
        if (this.row.isAccount){
            this.$uibModalInstance.close({selectedYTKRow: this.selectedYTKRow, selectedPTKRow: this.selectedPTKRow, account: this.editingRow, ptkButtonsVisible: this.ptkButtonsVisible});
        }
        else{
            this.$uibModalInstance.close({selected: this.selectedYTKRow, group: this.row});
        }
    }
    setSelectedYTK(row){
        this.selectedYTKRow = row;
        this.selectedPTKRow = null;
        this.clearButtonPressedLast = false;
    }
    setSelectedPTK(row){
        this.selectedPTKRow = row;
        this.selectedYTKRow = null;
        this.clearButtonPressedLast = false;
    }
    setNotSelectableGroups(){
        for (var i=0; i<this.tuloslaskelma.rows.length;i++) {
            var tulosStartGroup = this.tuloslaskelma.rows[i];
            if (tulosStartGroup.id===this.row.id){
                tulosStartGroup.notSelectable = true;
                var tulosStartLevel = tulosStartGroup.level;
                while(true){
                    var tulosSubGroup = this.tuloslaskelma.rows[i+1];
                    if (!tulosSubGroup){
                        break;
                    }
                    if (!tulosSubGroup.isAccount){
                        if(tulosSubGroup.level<=tulosStartLevel){
                            break;
                        }
                        tulosSubGroup.notSelectable = true;
                    }
                    i++;
                }
                break;
            }
        }

        for (var l=0; l<this.tase.rows.length; l++) {
            var taseStartGroup =  this.tase.rows[l];
            if (taseStartGroup.id===this.row.id){
                taseStartGroup.notSelectable = true;
                var taseStartLevel = taseStartGroup.level;
                while(true){
                    var taseSubGroup = this.tase.rows[l+1];
                    if (!taseSubGroup){
                        break;
                    }
                    if (!taseSubGroup.isAccount){
                        if (taseSubGroup.level<=taseStartLevel){
                            break;
                        }
                        taseSubGroup.notSelectable = true;

                    }

                    l++;
                }
                break;
            }
        }
    }
    clearNotSelectables(){
        for (var i=0; i<this.tase.rows.length; i++) {
            var taseRow = this.tase.rows[i];
            if (!taseRow.isAccount){
                taseRow.notSelectable =false;
            }
        }
        for (var l=0; l<this.tuloslaskelma.rows.length;l++){
            var tulosRow = this.tuloslaskelma.rows[l];
            if (!tulosRow.isAccount){
                tulosRow.notSelectable =false;
            }

        }
    }
    setPTKButtonsVisible(){
        this.ptkButtonsVisible = !this.ptkButtonsVisible;
    }

    clearAccountsParent() {
        this.clearButtonPressedLast = true;
        this.selectedYTKRow = null;
        this.selectedPTKRow = null;
    }
}

