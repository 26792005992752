'use strict';

angular.module('ecoeasyApp').controller('FiscalYearDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'FiscalYear', 'Company', '$translate',
        function ($scope, $stateParams, $uibModalInstance, entity, FiscalYear, Company, $translate) {
            $scope.fiscalYear = entity;
            $scope.companys = Company.query();
            $scope.load = function (id) {
                FiscalYear.get({id: id}, function (result) {
                    $scope.fiscalYear = result;
                });

            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:fiscalYearUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.fiscalYear.id != null) {
                    FiscalYear.update($scope.fiscalYear, onSaveSuccess, onSaveError);
                } else {
                    FiscalYear.save($scope.fiscalYear, onSaveSuccess, onSaveError);
                }
            };

            $scope.saveAndRecalculate = function () {
                $scope.isSaving = true;
                if ($scope.fiscalYear.id != null) {
                    FiscalYear.updateAndRecalculate($scope.fiscalYear, onSaveSuccess, onSaveError);
                } else {
                    FiscalYear.saveAndRecalculate($scope.fiscalYear, onSaveSuccess, onSaveError);
                }
            };

            $scope.saveOrSaveAndRecalculate = function () {
                $scope.isSaving = true;
                if ($scope.fiscalYear.id != null) {
                    FiscalYear.updateOrUpdateAndRecalculate($scope.fiscalYear, onSaveSuccess, onSaveError);
                } else {
                    FiscalYear.saveAndRecalculate($scope.fiscalYear, onSaveSuccess, onSaveError);
                }
            }

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.datePickerForStartDate = {};

            $scope.datePickerForStartDate.status = {
                opened: false
            };

            $scope.datePickerForStartDateOpen = function ($event) {
                $scope.datePickerForStartDate.status.opened = true;
            };
            $scope.datePickerForEndDate = {};

            $scope.datePickerForEndDate.status = {
                opened: false
            };

            $scope.datePickerForEndDateOpen = function ($event) {
                $scope.datePickerForEndDate.status.opened = true;
            };

            $scope.datepickerFormat = "MM-yyyy";
            $scope.datePickerOptions = {
                minMode: 'month',
                datepickerMode: 'month'
            };

        }]);
