'use strict';

angular.module('ecoeasyApp')
    .controller('CustomRowImportController', function ($scope, $http, $q, $state, Principal, CustomRowImportService) {
        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
        window.ms = $scope;
        $scope.isSaving = false;
        $scope.companyId = 0;
        $scope.accountNumber = 0;
        $scope.value = 0.0;
        $scope.selectedDate = null;
        $scope.selectedCompany = null;
        $scope.companys = [];
        $scope.selectedCompanyRows = [];

        $http.get("/api/companys/all").then(function (response) {
            $scope.companys = response.data;
        });

        $scope.loadCustomRowForCompany = function () {
            $http.get("/api/customrowimport/" + $scope.selectedCompany.id).then(function (response) {
                $scope.selectedCompanyRows = response.data;
            });
        };

        $scope.deleteAddedRow = function(id) {
            $http.delete("/api/customrowimport/" + id).then(function (response) {
                $scope.loadCustomRowForCompany();
            });
        }

        $scope.valuesOk = function () {
            if (!$scope.selectedDate || !checkThatTextIsNumber($scope.value) || $scope.value == 0.0 || $scope.selectedCompany == null || $scope.accountNumber == 0) {
                return false;
            } else {
                return true;
            }
        }

        $scope.submitData = function () {
            var row = {
                "companyId": $scope.selectedCompany.id,
                "accountNumber": $scope.accountNumber,
                "value": parseFloat($scope.value),
                "year": $scope.selectedDate.getFullYear(),
                "month": $scope.selectedDate.getMonth()+1,
                "day": $scope.selectedDate.getDate()
            }

            $scope.isSaving = true;
            $('#modal-spinner').show();
            CustomRowImportService.sendRow(row, onSuccess, onError);

        };

        function onSuccess(response) {
            $('#modal-spinner').hide();
            $scope.loadCustomRowForCompany();
            $scope.isSaving = false;
        }

        function onError(response) {
            $('#modal-spinner').hide();
            $scope.isSaving = false;
        }

        function checkThatTextIsNumber(str) {
            if (typeof str != "string") {
                return false
            }
            return !isNaN(str) && !isNaN(parseFloat(str))
        }

    });
