'use strict';

angular.module('ecoeasyApp')
    .controller('SurvivorChartDialogController', ['$scope', '$uibModalInstance',
        '$translate', 'SurvivorService',
        function ($scope, $uibModalInstance, $translate, SurvivorService) {

            $scope.clear = function () {
                $uibModalInstance.close();
            };

            let indicator = $scope.selectedIndicator.indicator;
            if (indicator.monthly === 'null' || indicator.monthly === null || indicator.monthly === 'undefined') {
                indicator.monthly = 'false';
            }

            $scope.varName = $translate.instant("variable." + indicator.companyVariable.code);

            SurvivorService.getCompanyVariableIndicatorChartData(indicator, $scope.$root.fiscalYear).then((data=>{
                $scope.chartLabels = data.chartLabels;
                $scope.chartData = data.chartData;
                $scope.chartSeries = data.chartSeries;
            }));

        }
    ]);
