export class RootScopeWrapperService {

    constructor($rootScope) {
        "ngInject";
        this.$rootScope = $rootScope;
    }

    onStateChangeStart(action) {
        return this.$rootScope.$on('$stateChangeStart', action);
    }



}