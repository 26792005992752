'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyIndicatorController', function ($scope, $state, Indicator, $http, company_id, Company, indicatorService) {
        $scope.indicators = [];
        $scope.company = Company.get({id: company_id});
        indicatorService.findByCompanyId(company_id).then(function (response) {
            $scope.indicators = response.data;
        });

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.indicator = {
                name: null,
                shortName: null,
                warnLimit: null,
                alertLimit: null,
                id: null
            };
        };

        $scope.saveOrdinals = function () {
            $scope.isSaving = true;
            indicatorService.saveAll(company_id, $scope.indicators).then(function (response) {
                $scope.isSaving = false;
            });
        }


        $scope.sortableOptions = {
            stop: function (e, ui) {
                for (var i = 0; i < $scope.indicators.length; i++) {
                    $scope.indicators[i].ordinal = i + 1;
                }
            }
        };
    });
