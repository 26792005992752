'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('companyData', {
                parent: 'entity',
                url: '/companyDatas',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.companyData.home.title'
                },
                views: {
                    'content@': {
                        template: require('./companyDatas.html'),
                        controller: 'CompanyDataController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('companyData');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('companyData.detail', {
                parent: 'entity',
                url: '/companyData/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.companyData.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./companyData-detail.html'),
                        controller: 'CompanyDataDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('companyData');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CompanyData', function ($stateParams, CompanyData) {
                        return CompanyData.get({id: $stateParams.id});
                    }]
                }
            })
            .state('companyData.new', {
                parent: 'companyData',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./companyData-dialog.html'),
                        controller: 'CompanyDataDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    position: null,
                                    value: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('companyData', null, {reload: true});
                    }, function () {
                        $state.go('companyData');
                    })
                }]
            })
            .state('companyData.edit', {
                parent: 'companyData',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./companyData-dialog.html'),
                        controller: 'CompanyDataDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['CompanyData', function (CompanyData) {
                                return CompanyData.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('companyData', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('companyData.delete', {
                parent: 'companyData',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./companyData-delete-dialog.html'),
                        controller: 'CompanyDataDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['CompanyData', function (CompanyData) {
                                return CompanyData.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('companyData', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
