import template from "./cash-flow.html";
import "./cash-flow.css";

class CashFlowComponentController {

    constructor(cashFlowService, $scope) {
        "ngInject";
        this.cashFlowService = cashFlowService;
        this.$scope = $scope;
        this.show12MonthsColumns = false;
        this.thousands = false;
        this.showDecimals = true;
    }

    $onInit() {
        this.selectedCompany=this.$scope.$root.getSelectedCompany();
        this.cashFlowService.getCashFlow().then((cashFlow)=>{
            this.isEmpty = cashFlow.isEmpty;
            this.headerDates = cashFlow.headerDates;
            this.headerDatesPrediction12Months = cashFlow.headerDatesPrediction12Months;

            this.operationsValues = cashFlow.operations.values;
            this.operationsPredictionValues = cashFlow.operations.predictionValues;
            this.operationsPredictionValues12Months = cashFlow.operations.predictionValues12Months;
            this.operationsGroups = cashFlow.operations.groups;

            this.investmentValues = cashFlow.investment.values;
            this.investmentPredictionValues = cashFlow.investment.predictionValues;
            this.investmentPredictionValues12Months = cashFlow.investment.predictionValues12Months;
            this.investmentGroups = cashFlow.investment.groups;

            this.financingValues = cashFlow.financing.values;
            this.financingPredictionValues = cashFlow.financing.predictionValues;
            this.financingPredictionValues12Months = cashFlow.financing.predictionValues12Months;
            this.financingGroups = cashFlow.financing.groups;

            this.cashFlowChangeTotals = cashFlow.changeTotals;
            this.cashFlowChangeTotalsPrediction = cashFlow.changeTotalsPrediction;
            this.cashFlowChangeTotalsPrediction12Months = cashFlow.changeTotalsPrediction12Months;
            this.cashFlowBeginTotals = cashFlow.beginTotals;
            this.cashFlowBeginTotalsPrediction = cashFlow.beginTotalsPrediction;
            this.cashFlowBeginTotalsPrediction12Months = cashFlow.beginTotalsPrediction12Months;
            this.cashFlowEndTotals = cashFlow.endTotals;
            this.cashFlowEndTotalsPrediction = cashFlow.endTotalsPrediction;
            this.cashFlowEndTotalsPrediction12Months = cashFlow.endTotalsPrediction12Months;
        });
    }

    toggleShow12MonthsColumns() {
        this.show12MonthsColumns = !this.show12MonthsColumns;
    }

    toggleThousands() {
        this.showDecimals = !this.showDecimals
        this.thousands = !this.thousands;
    }

}

export const CashFlowComponent = {
    template: template,
    controller: CashFlowComponentController
};
