'use strict';

angular.module('ecoeasyApp').controller('IndicatorDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Indicator', 'CompanyVariable',
        function ($scope, $stateParams, $uibModalInstance, entity, Indicator, CompanyVariable) {
            $scope.indicator = entity;
            $scope.companyvariables = CompanyVariable.query();
            $scope.load = function (id) {
                Indicator.get({id: id}, function (result) {
                    $scope.indicator = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:indicatorUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.indicator.id != null) {
                    Indicator.update($scope.indicator, onSaveSuccess, onSaveError);
                } else {
                    Indicator.save($scope.indicator, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
