'use strict';
//$q ks doc
angular.module('ecoeasyApp')
    .controller('OrganisationUserManagementController', function ($http, $scope, Principal, User, ParseLinks, Language, sourceState, PurchaseManagement) {

        $scope.state = sourceState;
        $scope.users = [];
        $scope.authorities = ["ROLE_USER", "ROLE_ADMIN"];

        var orgId = undefined;

        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });

        Principal.identity().then(function (account) {
            $scope.currentAccount = account;
        });


        $scope.page = 1;
        $scope.loadAll = function () {
            Principal.identity().then(function (account) {
                orgId = account.sourceId;
                $http.get('api/users/organisation/' + orgId).then(function (result) {
                    result.data.sort(function(a, b) {
                        return a.login.localeCompare(b.login)
                    });
                    $scope.users = result.data;
                });
            });
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.setActive = function (user, isActivated) {
            user.activated = isActivated;

            $http.put("/api/users/setActive", user)
                .then(function (response) {
                    $scope.loadAll();
                });
        };


        $scope.clear = function () {
            $scope.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
        };

        $scope.userSpots = PurchaseManagement.userSpots();
    });
