'use strict';

angular.module('ecoeasyApp') 
    .factory('OrganisationBilling', function ($resource) {
        
        var resourceUrl =  'api/organisation-billing';

        return {
            OrganisationCompanies: $resource(resourceUrl + '/organisation_companies', {}, {'query': {method: 'GET', isArray: true}}),
            OrganisationUsers: $resource(resourceUrl + '/organisation_users', {}, {'query': {method: 'GET', isArray: true}}),
            OrganisationCompanyUsers: $resource(resourceUrl + '/organisation_company_users', {}, {'query': {method: 'GET', isArray: true}})
        };
    });
