import {workingCapitalInputDetails as inputDetails} from "./working-capital-form-input-details";
import {differenceInMonths, isAfter, isBefore, parseISO} from "date-fns";
import {valuesChanged} from "../budget-utils";

const VAIHTO_OMAISUUS = [30, 31, 32, 33, 34, 35];
const OSTOVELAT_ID = 142;

export const mapWorkingDataSheet = (workingCapitalData, values, utilityValues) => {
    const {companyFiscalYears, actualDate, aineetGroup, revenueGroup} = utilityValues;

    const currentYearMonth = new Date(actualDate.year, actualDate.month + 1).toISOString();
    const currentFiscalYear = companyFiscalYears.find(it => isAfter(parseISO(currentYearMonth), parseISO(it.startDate)) && isBefore(parseISO(currentYearMonth), parseISO(it.endDate)));
    const fiscalYearLength = differenceInMonths(new Date(currentFiscalYear.endDate), new Date(currentFiscalYear.startDate)) + 1;

    const valueTypeCalculation = (account, prefix) => account.value !== null && (values[`${prefix}_${account.categoryId}_value`] === 0 || values[`${prefix}_${account.categoryId}_value`] === undefined)
        ? account.value
        : values[`${prefix}_${account.categoryId}_value`];

    const percentTypeCalculation = (account, prefix) => account.percent !== null && (values[`${prefix}_${account.categoryId}_percent`] === 0 || values[`${prefix}_${account.categoryId}_percent`] === undefined)
        ? account.percent
        : values[`${prefix}_${account.categoryId}_percent`];

    const calculateFinalValueFromTurnover = (turnover, budgetGroupData) => {
        let totalValue = budgetGroupData[0];
        let previousMonthValue = budgetGroupData[0] / 365 * turnover;
        let currentMonthValue;
        for (let i = 1; i < budgetGroupData.length; i++) {
            currentMonthValue = budgetGroupData[i] / 365 * turnover;
            totalValue += (previousMonthValue - currentMonthValue);
            previousMonthValue = currentMonthValue;
        }
        return totalValue;
    }

    /** absolute value is calculated from turnover, later absolute value is used in cashflow service to calculate changevalue for remaining months */
    const calculateValueFromType = (calculationType, account, prefix) => {
        if (valuesChanged(account, prefix, values)) {
            if (calculationType === 'turnover') {
                if (account.categoryId === OSTOVELAT_ID || VAIHTO_OMAISUUS.includes(account.categoryId)) {
                    return parseFloat(calculateFinalValueFromTurnover(values[`${prefix}_${account.categoryId}_turnover`], aineetGroup).toFixed(2));
                } else {
                    return parseFloat(calculateFinalValueFromTurnover(values[`${prefix}_${account.categoryId}_turnover`], revenueGroup).toFixed(2));
                }
            } else if (calculationType === 'percent') {
                return parseFloat(calculateValueFromPercentage(values[`${prefix}_${account.categoryId}_percent`]).toFixed(2));
            }
        } else {
            return account.value;
        }
    }

    const groupTotal = group => {
        let totalValue = group[0];
        let previousMonthValue = group[0];
        let currentMonthValue;
        for (let i = 1; i < group.length; i++) {
            currentMonthValue = group[i];
            totalValue += (previousMonthValue - currentMonthValue);
            previousMonthValue = currentMonthValue;
        }
        return totalValue;
    }

    const updateTurnover = (account, prefix) => {
        const accountName = 'account_' + account.categoryId;
        if (valuesChanged(account, prefix, values) && inputDetails[accountName].options.find(option => option.type === 'turnover')) {
            let updatedTurnover;
            if (account.calculationType === 'value') {
                if (account.categoryId === OSTOVELAT_ID) {
                    updatedTurnover = Math.abs(parseFloat((365 * values[`${prefix}_${account.categoryId}_value`] / parseFloat(groupTotal(aineetGroup).toFixed(2)) * -1).toFixed(0)));
                    return updatedTurnover >= 0 ? updatedTurnover : 0;
                } else if (VAIHTO_OMAISUUS.includes(account.categoryId)) {
                    updatedTurnover = Math.abs(parseFloat((365 * values[`${prefix}_${account.categoryId}_value`] / parseFloat(groupTotal(aineetGroup).toFixed(2)) * 12 / fiscalYearLength).toFixed(0)));
                    return updatedTurnover >= 0 ? updatedTurnover : 0;
                } else {
                    updatedTurnover = Math.abs(parseFloat((365 * values[`${prefix}_${account.categoryId}_value`] / parseFloat(groupTotal(revenueGroup).toFixed(2)) * -1).toFixed(0)));
                    return updatedTurnover >= 0 ? updatedTurnover : 0;
                }
            } else if (account.calculationType === 'percent') {
                const calculatedValue = Math.abs(parseFloat((values[`${prefix}_${account.categoryId}_value`] * (values[`${prefix}_${account.categoryId}_percent`] / 100)).toFixed(0)));
                updatedTurnover = Math.abs(parseFloat((365 * calculatedValue / parseFloat(groupTotal(aineetGroup).toFixed(2)) * 12 / fiscalYearLength).toFixed(0)));
                return updatedTurnover >= 0 ? updatedTurnover : 0;
            }
        } else {
            return account.turnover;
        }
    }

    const calculateValueFromPercentage = (percentage) => {
        let totalValue = revenueGroup[0];
        let previousMonthValue = revenueGroup[0];
        let currentMonthValue;
        for (let index = 1; index < revenueGroup.length; index++) {
            const changePercent = Math.abs(aineetGroup[index]) / Math.abs(revenueGroup[index]) * 100;
            if (changePercent !== percentage) {
                currentMonthValue = revenueGroup[index] * (percentage / 100);
                totalValue += (previousMonthValue - currentMonthValue);
                previousMonthValue = currentMonthValue;
            }
        }
        return totalValue;
    }

    const updatePercentageWithOtherType = (account, prefix) => {
        const accountName = 'account_' + account.categoryId;
        if (valuesChanged(account, prefix, values) && inputDetails[accountName].options.find(option => option.type === 'percent')) {
            let updatedValue;
            if (account.calculationType === 'turnover' || values[`${prefix}_${account.categoryId}_calculationType`] === 'turnover') {
                if (account.categoryId === OSTOVELAT_ID || VAIHTO_OMAISUUS.includes(account.categoryId)) {
                    updatedValue = parseFloat(calculateFinalValueFromTurnover(values[`${prefix}_${account.categoryId}_turnover`], aineetGroup).toFixed(2));
                } else {
                    updatedValue = parseFloat(calculateFinalValueFromTurnover(values[`${prefix}_${account.categoryId}_turnover`], revenueGroup).toFixed(2));
                }
                return parseFloat((updatedValue / parseFloat(groupTotal(revenueGroup).toFixed(2)) * 100).toFixed(2));
            } else if (account.calculationType === 'value' || values[`${prefix}_${account.categoryId}_calculationType`] === 'value') {
                return parseFloat((values[`${prefix}_${account.categoryId}_value`] / parseFloat(groupTotal(revenueGroup).toFixed(2)) * 100).toFixed(2));
            }
        } else {
            return account.percent;
        }
    }

    const currentAssets = workingCapitalData.lists.currentAssets.map((account) => {
        return {
            calculationType: values[`ca_${account.categoryId}_calculationType`] === 'value' ? account.calculationType : values[`ca_${account.categoryId}_calculationType`],
            categoryId: account.categoryId,
            companyAccountId: account.companyAccountId,
            name: account.name,
            ordinal: account.ordinal,
            percent: null,
            /*
            percent: values[`ca_${account.categoryId}_calculationType`] === 'percent' ?
                percentTypeCalculation(account, 'ca') : updatePercentageWithOtherType(account, 'ca'),
             */
            turnover: account.turnover !== null && values[`ca_${account.categoryId}_calculationType`] === 'turnover' ?
                values[`ca_${account.categoryId}_turnover`] : updateTurnover(account, 'ca'),
            turnoverCalculated: account.turnoverCalculated,
            type: account.type,
            value: values[`ca_${account.categoryId}_calculationType`] === 'value' ?
                valueTypeCalculation(account, 'ca') : calculateValueFromType(values[`ca_${account.categoryId}_calculationType`], account, 'ca')
        };
    });

    const accountsPayables = workingCapitalData.lists.accountsPayables.map((account) => {
        return {
            calculationType: values[`ap_${account.categoryId}_calculationType`] === 'value' ? account.calculationType : values[`ap_${account.categoryId}_calculationType`],
            categoryId: account.categoryId,
            companyAccountId: account.companyAccountId,
            name: account.name,
            ordinal: account.ordinal,
            percent: null,
            turnover: account.turnover !== null && values[`ap_${account.categoryId}_calculationType`] === 'turnover' ?
                values[`ap_${account.categoryId}_turnover`] : updateTurnover(account, 'ap'),
            turnoverCalculated: account.turnoverCalculated,
            type: account.type,
            value: values[`ap_${account.categoryId}_calculationType`] === 'value' ?
                valueTypeCalculation(account, 'ap') : calculateValueFromType(values[`ap_${account.categoryId}_calculationType`], account, 'ap')
        };
    });

    const shortTermDebts = workingCapitalData.lists.shortTermDebts.map((account) => {
        return {
            categoryId: account.categoryId,
            calculationType: values[`std_${account.categoryId}_calculationType`] === 'value' ? account.calculationType : values[`std_${account.categoryId}_calculationType`],
            companyAccountId: account.companyAccountId,
            name: account.name,
            ordinal: account.ordinal,
            percent: null,
            turnover: account.turnover !== null && values[`std_${account.categoryId}_calculationType`] === 'turnover' ?
                values[`std_${account.categoryId}_turnover`] : updateTurnover(account, 'std'),
            turnoverCalculated: account.turnoverCalculated,
            type: account.type,
            value: values[`std_${account.categoryId}_calculationType`] === 'value' ?
                valueTypeCalculation(account, 'std') : calculateValueFromType(values[`std_${account.categoryId}_calculationType`], account, 'std')
        };
    });

    return {
        id: workingCapitalData.id,
        lists: {
            currentAssets: currentAssets,
            shortTermDebts: shortTermDebts,
            accountsPayables: accountsPayables
        }
    }
}