import template from "./budget-dialog.html";
import i18next, {i18n} from 'i18next';

const budgetLevels = {
    TOP_LEVEL_BUDGET: 'TOP_LEVEL_BUDGET',
    GROUP_LEVEL_BUDGET: 'GROUP_LEVEL_BUDGET',
    ACCOUNT_LEVEL_BUDGET: 'ACCOUNT_LEVEL_BUDGET'
}

export const budgetFactories = {
    EMPTY_BUDGET: 'EMPTY_BUDGET',
    INCOME_STATEMENT_BUDGET: 'INCOME_STATEMENT_BUDGET',
    FORECAST_BUDGET: 'FORECAST_BUDGET',
    INFO_ROW: 'INFO_ROW'
}

export const BudgetDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class BudgetDialogComponent {

        constructor(FEATURE_CASH_BUDGET) {
            "ngInject";
            this.formData = {
                selectedPos: null,
                selectedCashBudget: null,
                name: null,
                cashBudget: false
            };
            this.positions = [];
            this.selectableCashBudgets = [];
            this.selectedCashBudget = {};
            this.copyBudget = false;
            this.createBudgetLevel = budgetLevels.TOP_LEVEL_BUDGET;
            this.createBudgetFactor = budgetFactories.EMPTY_BUDGET;
            this.showCashBudgetSelection = FEATURE_CASH_BUDGET;
            this.isPrimaryBudget = false;
            this.language = i18next.language;
        }

        $onInit() {
            this.copyBudget = this.resolve.copyBudget;
            this.positions = this.resolve.positions;
            this.selectableCashBudgets = [{id: -1, name:  this.printNameForCreation()}].concat(this.resolve.selectableCashBudgets);
            this.selectedCashBudget = this.selectableCashBudgets[0];
            const fiscalYearId = this.resolve.fiscalYearId;
            if (fiscalYearId){
                for (let i=0; i<this.positions.length; i++) {
                    let position = this.positions[i];
                    if (position.id === fiscalYearId){
                        this.formData.name =position.name;
                        this.formData.selectedPos = position;
                    }
                }
            }
        }

        printNameForCreation() {
            switch (this.language) {
                case 'fi':
                    return "Luo uusi"
                case 'en':
                    return "Create new"
                case 'sv':
                    return "Skapa ny"
                default:
                    return "Luo uusi"
            }
        }

        save() {
            let data = {
                fiscalYearId: this.formData.selectedPos.id,
                name: this.formData.name,
                createBudgetFactor: this.createBudgetFactor,
                createBudgetLevel: this.createBudgetLevel,
                cashBudget: this.formData.cashBudget,
                cashBudgetId: this.selectedCashBudget.id,
                cashBudgetName: this.selectedCashBudget.name,
                isPrimaryBudget: this.isPrimaryBudget
            };
            this.close({$value: data});
        }

        clear() {
            this.dismiss({$value: 'dismissed'});
        }

        selectCashBudget(cashBudget) {
            this.selectedCashBudget = cashBudget;
        }
    }
}
