'use strict';

angular.module('ecoeasyApp').controller('InvestmentBudgetDataDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'InvestmentBudgetData', 'Budget', 'CompanyVariable',
        function ($scope, $stateParams, $uibModalInstance, entity, InvestmentBudgetData, Budget, CompanyVariable) {

            $scope.investmentBudgetData = entity;
            $scope.budgets = Budget.query();
            $scope.companyvariables = CompanyVariable.query();
            $scope.load = function (id) {
                InvestmentBudgetData.get({id: id}, function (result) {
                    $scope.investmentBudgetData = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:investmentBudgetDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.investmentBudgetData.id != null) {
                    InvestmentBudgetData.update($scope.investmentBudgetData, onSaveSuccess, onSaveError);
                } else {
                    InvestmentBudgetData.save($scope.investmentBudgetData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
