import React, {useState} from 'react';
import {array, bool, func, object, number} from 'prop-types';
import {useTranslation} from "react-i18next";

import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import Alert from "react-bootstrap/lib/Alert";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import Glyphicon from "react-bootstrap/lib/Glyphicon";

import SingleLineInput from "../../shared/inputs/single-line-input";
import SingleMonthPicker from "../../shared/inputs/single-month-picker";
import RadioButtonInput from "../../shared/inputs/radio-button-input";
import MultipleMonthCheckboxInput from "../../shared/inputs/multiple-month-checkbox-input";

import {determineIfLongTerm, devaluationMethodOptions} from '../budget-utils';
import {updateInvestment} from "../../../service/react-service";
import {getFreePeriodInMonths, parseDateToMonth, parseDateToYear, emptyIfAbsent} from "../../../utils/util";

import './styles.css';

const EditInvestmentModal = ({
                                 budgetId,
                                 companyId,
                                 options,
                                 fundingOptions,
                                 showModal,
                                 setShowModal,
                                 setSelectedData,
                                 investmentData,
                                 showInvestmentPopup,
                                 setShowInvestmentPopup,
                                 actualDate
                             }) => {
    const {t} = useTranslation();

    const vatInData = (investmentData.vatValue > 0);

    const [useVat, setUseVat] = useState(!!vatInData);
    const [errorMessage, setErrorMessage] = useState(null);

    /** if investment funded, no funding addition option provided */
    const fundedAlready = !!investmentData.fundingObject;

    const showParsedDate = date => {
        if (date !== null) {
            const monthInDate = parseDateToMonth(date);
            const yearInDate = parseDateToYear(date);
            return `${monthInDate}/${yearInDate}`;
        }
    }

    const {
        name,
        value,
        bcoaCategoryId,
        remnantValue,
        month,
        year,
        devaluationTime,
        devaluationTimeDelay,
        devaluationInterest
    } = investmentData;

    const onEditAcquisitionDate = () => {
        return new Date(year, (month - 1));
    }

    const getInitialDevaluationMethod = () => {
        if (devaluationInterest) {
            return 'MENOJÄÄNNÖS';
        } else if (devaluationTime) {
            return 'SUUNNITELMAN_MUKAINEN';
        }
        return '';
    }

    const initialValues = {
        investmentName: emptyIfAbsent(name, ''),
        investmentValue: emptyIfAbsent(value, ''),
        bcoaCategoryId: emptyIfAbsent(bcoaCategoryId, ''),
        remnantValue: emptyIfAbsent(remnantValue, ''),
        acquisitionDate: !!month ? onEditAcquisitionDate() : '',
        devaluationMethod: getInitialDevaluationMethod(),
        devaluationTime: emptyIfAbsent(devaluationTime, ''),
        devaluationInterest: emptyIfAbsent(devaluationInterest, ''),
        devaluationTimeDelay: emptyIfAbsent(devaluationTimeDelay, ''),
        useFunding: false,
        loanTime: '',
        fundingAmount: '',
        fundingAccount: '',
        firstAmortizationDate: '',
        repaymentMonths: [],
        annualInterestRate: '',
        repaymentMethod: 'fixed_annuity',
    }

    const actualizedDate = new Date(actualDate.year, actualDate.month);

    const validationSchema = Yup.object().shape({
        investmentName: Yup.string()
            .required(t('investments:modal.inputValidation.required.nameMissing'))
            .max(32, t('investments:modal.inputValidation.maxLength.nameLength')),
        investmentValue: Yup.number()
            .required(t('investments:modal.inputValidation.required.investmentAmount'))
            .positive(t('investments:modal.inputValidation.positiveCheck.positive')),
        bcoaCategoryId: Yup.number()
            .required(t('investments:modal.inputValidation.required.balanceAccount')),
        remnantValue: Yup.number()
            .positive(t('investments:modal.inputValidation.positiveCheck.positive')),
        acquisitionDate: Yup.date()
            .min(actualizedDate, t('investments:modal.inputValidation.earlierThanCheck.acquisitionDate'))
            .required(t('investments:modal.inputValidation.required.acquisitionDate'))
            .typeError(t('investments:modal.inputValidation.required.acquisitionDate')),
        devaluationMethod: Yup.string()
            .required(t('investments:modal.inputValidation.required.devaluationMethod')),
        devaluationTime: Yup.number().when('devaluationMethod', {
            is: 'SUUNNITELMAN_MUKAINEN',
            then: Yup.number()
                .required(t('investments:modal.inputValidation.required.devaluationTime'))
                .positive('investments:modal.inputValidation.positiveCheck.positive'),
            otherwise: Yup.number(),
        }),
        devaluationInterest: Yup.number().when('devaluationMethod', {
            is: 'MENOJÄÄNNÖS',
            then: Yup.number()
                .required(t('investments:modal.inputValidation.required.devaluationInterest'))
                .positive('investments:modal.inputValidation.positiveCheck.positive'),
            otherwise: Yup.number()
        }),
        devaluationTimeDelay: Yup.number()
            .min(0, t('investments:modal.inputValidation.positiveCheck.positive')),
        useFunding: Yup.boolean(),
        fundingAmount: Yup.number().when('useFunding', {
            is: true,
            then: Yup.number()
                .required(t('investments:modal.inputValidation.required.fundingAmount'))
                .positive(t('investments:modal.inputValidation.positiveCheck.positive')),
            otherwise: Yup.number()
        }),
        firstAmortizationDate: Yup.date().when('useFunding', {
            is: true,
            then: Yup.date()
                .min(actualizedDate, t('investments:modal.inputValidation.earlierThanCheck.acquisitionDate'))
                .required(t('investments:modal.inputValidation.required.firstAmortizationDate'))
                .typeError(t('investments:modal.inputValidation.required.firstAmortizationDate')),
            otherwise: Yup.date()
        }),
        fundingAccount: Yup.number().when('useFunding', {
            is: true,
            then: Yup.number()
                .required(t('investments:modal.inputValidation.required.balanceAccount'))
                .positive(t('investments:modal.inputValidation.positiveCheck.positive')),
            otherwise: Yup.number()
        }),
        loanTime: Yup.number().when('useFunding', {
            is: true,
            then: Yup.number()
                .required(t('investments:modal.inputValidation.required.loanTime'))
                .positive(t('investments:modal.inputValidation.positiveCheck.loanTime'))
                .integer(t('investments:modal.inputValidation.integerCheck.loanTime')),
            otherwise: Yup.number()
        }),
        repaymentMonths: Yup.array().when('useFunding', {
            is: true,
            then: Yup.array().min(1, t('investments:modal.inputValidation.required.amortizationsPerYear')),
            otherwise: Yup.array()
        }),
        annualInterestRate: Yup.number().when('useFunding', {
            is: true,
            then: Yup.number()
                .required(t('investments:modal.inputValidation.required.annualInterest'))
                .positive(t('investments:modal.inputValidation.positiveCheck.annualInterest')),
            otherwise: Yup.number()
        })
    });

    const handleOnHide = (formikReset) => {
        formikReset();
        setSelectedData(null);
        setShowModal(!showModal);
    }

    const parseFloatIfValuePresent = (formValue, defaultValue) => {
        if (Array.isArray(formValue)) {
            let temp = [];
            formValue.forEach(arrItem => {
                temp.push(parseFloat(arrItem));
            });
            return temp;
        }
        return formValue === '' ? defaultValue : parseFloat(formValue);
    }

    /** Handle submit data and create objects for PUT */
    const handleOnSubmit = (values, resetForm) => {
        let fundingBudget = null;

        if (fundedAlready) {
            fundingBudget = investmentData.fundingObject;

            // update name, initialCashEffect and time if changed
            fundingBudget.name = values.investmentName;
            fundingBudget.initialCashEffect = values.investmentValue;
            fundingBudget.month = parseDateToMonth(values.acquisitionDate);
            fundingBudget.year = parseDateToYear(values.acquisitionDate);
        }

        let vatValue = 0;
        let vatPercentage = 24;

        if (useVat) vatValue = parseFloat(((values.investmentValue * vatPercentage) / (100 + vatPercentage)).toFixed(0));

        if (values.useFunding) {
            /** Optional funding data if used for investment */
            fundingBudget = {
                name: values.investmentName,
                month: parseDateToMonth(values.acquisitionDate),
                year: parseDateToYear(values.acquisitionDate),
                initialCashEffect: values.investmentValue,
                amount: parseFloatIfValuePresent(values.fundingAmount, 0),
                bcoaCategoryId: parseFloat(values.fundingAccount),
                termInMonths: parseFloatIfValuePresent(values.loanTime, 0),
                freePeriodInMonths: getFreePeriodInMonths(values.acquisitionDate, values.firstAmortizationDate),
                annualPaymentMonths: parseFloatIfValuePresent(values.repaymentMonths, []),
                annualInterestRate: parseFloatIfValuePresent(values.annualInterestRate, 0),
                repaymentMethod: values.repaymentMethod
            }
        }

        /** Investment data object */
        const updatedInvestment = {
            id: investmentData.id,
            name: values.investmentName,
            value: values.investmentValue,
            bcoaCategoryId: parseFloat(values.bcoaCategoryId),
            remnantValue: parseFloatIfValuePresent(values.remnantValue, 0),
            month: parseDateToMonth(values.acquisitionDate),
            year: parseDateToYear(values.acquisitionDate),
            devaluationTime: parseFloatIfValuePresent(values.devaluationTime, null),
            devaluationTimeDelay: parseFloatIfValuePresent(values.devaluationTimeDelay, 0),
            vatValue: vatValue,
            devaluationInterest: parseFloatIfValuePresent(values.devaluationInterest, null),
            fundingObject: fundingBudget
        }

        updateInvestment(companyId, budgetId, updatedInvestment).then((res, rej) => {
            if (rej !== undefined) {
                setErrorMessage(rej);
            } else {
                setShowInvestmentPopup(!showInvestmentPopup);
            }
            handleOnHide(resetForm);
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            resetForm={initialValues}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setSubmitting(true);
                handleOnSubmit(values, resetForm);
                setSubmitting(false);
            }}
        >
            {({values, isSubmitting, resetForm}) => (
                <Modal show={showModal} onHide={() => handleOnHide(resetForm)}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('investments:modal.editInvestment')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {errorMessage !== null ?
                                <Alert bsStyle='danger'>{errorMessage}</Alert>
                                : null
                            }
                            <fieldset className="investment-border">
                                <legend className="investment-border">
                                    {t('investments:modal.investmentLegend')}
                                </legend>
                                <SingleLineInput
                                    id='investmentName'
                                    name='investmentName'
                                    type='text'
                                    label={t('investments:values.name')}
                                    placeholder={t('investments:values.addName')}
                                />
                                <SingleLineInput
                                    id='investmentValue'
                                    name='investmentValue'
                                    type='number'
                                    label={t('investments:values.investmentValue')}
                                    placeholder='0,00'
                                />
                                <div style={{marginBottom: '15px'}}>
                                    <input type='checkbox' defaultChecked={useVat} onClick={() => setUseVat(!useVat)}/>
                                    <label style={{fontWeight: 'bold', paddingLeft: '5px'}}>
                                        {t('investments:values.vatIncluded')}
                                    </label>
                                </div>
                                <div style={{marginBottom: '15px'}} id='bcoaCategoryId'>
                                    <label>{t('investments:values.balanceAccount')}</label>
                                    <Field
                                        className='BalanceAccount'
                                        component='select'
                                        name='bcoaCategoryId'
                                        id='bcoaCategoryId'
                                    >
                                        {options && options.map(option => (
                                            <optgroup key={option.id} label={option.name}>
                                                {option.subCategories.map(item => {
                                                    return (item.subCategories ?
                                                            item.subCategories.map(i => {
                                                                return <option value={i.id} key={i.id}>
                                                                    {item.name}: {i.name}
                                                                </option>
                                                            })
                                                            :
                                                            <option value={item.id} key={item.id}>
                                                                {item.name}
                                                            </option>
                                                    );
                                                })}
                                            </optgroup>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="bcoaCategoryId">
                                        {msg => <div className='ErrorMessage'>
                                            <Glyphicon glyph="remove"/>
                                            {msg}
                                        </div>}
                                    </ErrorMessage>
                                </div>
                                <SingleMonthPicker
                                    id='acquisitionDate'
                                    name='acquisitionDate'
                                    label={t('investments:values.acquisitionDate')}
                                />
                                <SingleLineInput
                                    id='remnantValue'
                                    name='remnantValue'
                                    type='number'
                                    label={t('investments:values.remnantValue')}
                                    placeholder='0'
                                />
                                <RadioButtonInput
                                    label={'investments:values.devaluationMethod'}
                                    id='devaluationMethod'
                                    name='devaluationMethod'
                                    options={devaluationMethodOptions}
                                    readOnly
                                />
                                {values.devaluationMethod === null ? null :
                                    values.devaluationMethod === 'SUUNNITELMAN_MUKAINEN' ?
                                        <SingleLineInput
                                            id='devaluationTime'
                                            name='devaluationTime'
                                            type='number'
                                            label={t('investments:values.devaluationTime')}
                                            placeholder='0'
                                        />
                                        :
                                        <SingleLineInput
                                            label={t('investments:values.devaluationInterest')}
                                            id={'devaluationInterest'}
                                            name='devaluationInterest'
                                            type='number'
                                            placeholder='0'
                                        />
                                }
                                <SingleLineInput
                                    id='devaluationTimeDelay'
                                    name='devaluationTimeDelay'
                                    type='number'
                                    label={t('investments:values.devaluationTimeDelay')}
                                />
                            </fieldset>
                            {fundedAlready ?
                                <>
                                    <label>{t('investments:modal.funded')}:</label>
                                    <p>{investmentData.fundingObject.name}</p>
                                </>
                                :
                                <>
                                    <>
                                        <Field type='checkbox' name='useFunding'/>
                                        <label style={{fontWeight: 'bold', paddingLeft: '5px'}}>
                                            {t('investments:values.addFunding')}
                                        </label>
                                    </>
                                    <>
                                        {values.useFunding ?
                                            <fieldset className="investment-border">
                                                <legend className="investment-border">
                                                    {t('investments:modal.fundingBudget')}
                                                </legend>
                                                <SingleLineInput
                                                    id='fundingName'
                                                    name='fundingName'
                                                    type='text'
                                                    label={t('investments:values.name')}
                                                    placeholder={values.investmentName || ''}
                                                    readOnly
                                                />
                                                <SingleLineInput
                                                    id='fundingPosition'
                                                    name='fundingPosition'
                                                    type='text'
                                                    label={t('investments:values.fundingPosition')}
                                                    placeholder={values.acquisitionDate ? showParsedDate(values.acquisitionDate) : ''}
                                                    readOnly
                                                />
                                                <SingleLineInput
                                                    id='initialCashEffect'
                                                    name='initialCashEffect'
                                                    type='text'
                                                    label={t('investments:values.initialCashEffect')}
                                                    placeholder={values.investmentValue ? values.investmentValue.toString() : '0'}
                                                    readOnly
                                                />
                                                <div>
                                                    <SingleLineInput
                                                        id='fundingAmount'
                                                        name='fundingAmount'
                                                        type='number'
                                                        label={t('investments:values.amount')}
                                                        placeholder='0'
                                                    />
                                                    <SingleLineInput
                                                        id='loanTime'
                                                        name='loanTime'
                                                        type='number'
                                                        label={t('investments:values.loanTime')}
                                                        placeholder='0'
                                                    />
                                                    <SingleMonthPicker
                                                        id='firstAmortizationDate'
                                                        name='firstAmortizationDate'
                                                        label={t('investments:values.firstAmortizationDate')}
                                                    />
                                                    <MultipleMonthCheckboxInput
                                                        id='repaymentMonths'
                                                        name='repaymentMonths'
                                                        label={t('investments:values.amortizationPerYear')}
                                                    />
                                                    <div style={{marginBottom: '15px'}}>
                                                        {values.firstAmortizationDate === '' ?
                                                            <label style={{color: "darkgray"}}>
                                                                {t('investments:values.balanceAccount')}
                                                            </label>
                                                            :
                                                            <label>{t('investments:values.balanceAccount')}</label>
                                                        }
                                                        <Field
                                                            className='BalanceAccount'
                                                            component='select'
                                                            name='fundingAccount'
                                                        >
                                                            {(values.acquisitionDate && values.firstAmortizationDate) &&
                                                            determineIfLongTerm(values.acquisitionDate, values.firstAmortizationDate) ?
                                                                fundingOptions[0].subCategories.map(option => {
                                                                    return (
                                                                        <option value={option.id}
                                                                                key={option.id}
                                                                                disabled={values.firstAmortizationDate === ''}
                                                                        >
                                                                            {option.name}
                                                                        </option>
                                                                    );
                                                                })
                                                                :
                                                                fundingOptions[1].subCategories.map(option => {
                                                                    return (
                                                                        <option value={option.id}
                                                                                key={option.id}
                                                                                disabled={values.firstAmortizationDate === ''}
                                                                        >
                                                                            {option.name}
                                                                        </option>
                                                                    );
                                                                })
                                                            }
                                                        </Field>
                                                        <ErrorMessage name="fundingAccount">
                                                            {msg => <div className='ErrorMessage'>
                                                                <Glyphicon glyph="remove"/>
                                                                {msg}
                                                            </div>}
                                                        </ErrorMessage>
                                                    </div>
                                                    <SingleLineInput
                                                        id='annualInterestRate'
                                                        name='annualInterestRate'
                                                        type='number'
                                                        label={t('investments:values.interestRate')}
                                                        placeholder='0,00'
                                                    />
                                                </div>
                                            </fieldset>
                                            : null
                                        }
                                    </>
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => handleOnHide(resetForm)}>
                                <Glyphicon glyph='ban-circle'/>
                                {t('investments:modal.buttons.cancel')}
                            </Button>
                            <Button bsStyle="primary" type='submit' disabled={isSubmitting}>
                                <Glyphicon glyph='save'/>
                                {t('investments:modal.buttons.save')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
}

EditInvestmentModal.propTypes = {
    budgetId: number.isRequired,
    companyId: number.isRequired,
    options: array.isRequired,
    fundingOptions: array.isRequired,
    showModal: bool.isRequired,
    setShowModal: func.isRequired,
    setSelectedData: func.isRequired,
    investmentData: object.isRequired,
    showInvestmentPopup: bool.isRequired,
    setShowInvestmentPopup: func.isRequired,
    actualDate: object
};

export default EditInvestmentModal;