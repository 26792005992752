'use strict';

angular.module('ecoeasyApp').controller('CompanyDataDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'CompanyData', 'CompanyDataVersion', 'CompanyVariable',
        function ($scope, $stateParams, $uibModalInstance, entity, CompanyData, CompanyDataVersion, CompanyVariable) {

            $scope.companyData = entity;
            $scope.companydataversions = CompanyDataVersion.query();
            $scope.companyvariables = CompanyVariable.query();
            $scope.load = function (id) {
                CompanyData.get({id: id}, function (result) {
                    $scope.companyData = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:companyDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.companyData.id != null) {
                    CompanyData.update($scope.companyData, onSaveSuccess, onSaveError);
                } else {
                    CompanyData.save($scope.companyData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
