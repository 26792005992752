export class RowChildrenShowHide {

    constructor(rows) {
        this.rows = rows;
    }

    _showChildren(row, allDescendants) {
        let showLevel = row.level + 1;
        let rowChildren = allDescendants.filter(descendant => descendant.level == showLevel);
        if (rowChildren.length > 0) {
            rowChildren.forEach(child => {
                child.isShown = true;
                if (child.isOpen) {
                    this._showChildren(child, this._getAllDescendants(child, allDescendants));
                }
            });
        } else {
            allDescendants.forEach(child => {
                child.isShown = true;
            });
        }
    }

    hideChildren(row) {
        this._getAllDescendants(row, this.rows).forEach(child => child.isShown = false);
    }

    _getAllDescendants(row, rows) {
        const allChildren = [];
        let found = false;
        rows.some((aRow)=>{
            if (aRow === row) {
                found = true;
            } else {
                if (found) {
                    if (row.level < aRow.level) {
                        allChildren.push(aRow);
                    } else {
                        return true;
                    }
                }
            }
            return false;
        });
        return allChildren;
    }

    showChildren(row) {
        const allDescendants = this._getAllDescendants(row, this.rows);
        this._showChildren(row, allDescendants);
    }



}
