import {Chart} from "./chart";
import {ChartRow} from "./chart-row";
import {ChartRoot} from "./chart-root";
import i18next from "i18next";

function newChartRow(jsonRowObject, level) {
    return new ChartRow(jsonRowObject.id, jsonRowObject.nameFi, jsonRowObject.nameEn, jsonRowObject.nameSv, level, jsonRowObject.accountRanges, jsonRowObject.ordinal, jsonRowObject.movable, jsonRowObject.cashFlowType, jsonRowObject.topLevel, jsonRowObject.globalId);
}

function createRow(jsonRowObject, level, rows) {
    rows.push(newChartRow(jsonRowObject, level));
    jsonRowObject.subCategories.forEach(function (value) {
        createRow(value, level + 1, rows);
    });
}

function createRowReverse(jsonRowObject, level, rows) {
    jsonRowObject.subCategories.forEach(function (value) {
        createRow(value, level + 1, rows);
    });
    rows.push(newChartRow(jsonRowObject, level));
}

function createRoot(jsonObject) {
    let rows = [];
    createRow(jsonObject, 0, rows);
    return new ChartRoot(jsonObject.nameFi, rows)
}

function createIncomeStatementRoot(incomeStatementJson) {
    let rows = [];
    incomeStatementJson.subCategories.forEach(function (value, index) {
        if (index > 0) {
            createRowReverse(value, 0, rows);
        } else {
            createRow(value, 0, rows);
        }
    });
    if(i18next.language == "en") {
        return new ChartRoot(incomeStatementJson.nameEn, rows)
    } else {
        return new ChartRoot(incomeStatementJson.nameFi, rows)
    }
}

function createBalanceSheetRoot(balanceSheetJson) {
    let rows = [];
    balanceSheetJson.subCategories.forEach(function (value) {
        createRow(value, 0, rows);
    });
    if(i18next.language == "en") {
        return new ChartRoot(balanceSheetJson.nameEn, rows)
    } else {
        return new ChartRoot(balanceSheetJson.nameFi, rows)
    }
}

function createChart(jsonChart) {
    let roots = [];
    if (jsonChart.balanceSheet)
        roots.push(createBalanceSheetRoot(jsonChart.balanceSheet));
    if (jsonChart.incomeStatement)
        roots.push(createIncomeStatementRoot(jsonChart.incomeStatement));
    return new Chart(jsonChart.id, jsonChart.name, roots)
}

export class ChartService {

    constructor() {
        "ngInject";
    }

    fromJsonToCharts(jsonArray) {
        let charts = [];
        if (!jsonArray) {
            console.error('undefined jsonArray', jsonArray);
            return charts;
        }
        if (!Array.isArray(jsonArray)) {
            console.error('not array jsonArray', jsonArray);
            return charts;
        }
        charts = jsonArray.map(function (currentValue) {
            return createChart(currentValue);
        });
        return charts;
    }

}
