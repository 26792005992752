'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyVariableDetailController', function ($scope, $rootScope, $stateParams, entity, CompanyVariable, AccountingModel) {
        $scope.companyVariable = entity;
        $scope.load = function (id) {
            CompanyVariable.get({id: id}, function (result) {
                $scope.companyVariable = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:companyVariableUpdate', function (event, result) {
            $scope.companyVariable = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
