'use strict';

import {variableTypes} from "../drill/variable-type";
import i18next from "i18next";

angular.module('ecoeasyApp')
    .controller('SurvivorController', function ($scope, $state, $uibModal, $q, Principal, indicatorService, companyService, company_id, $http, FiscalYear, SelectedCompanyService, FEATURE_COMPANY_ACCOUNT_CHART, FEATURE_SHOW_BUDGETS_IN_INDICATORS) {

        Principal.identity().then(function (account) {
            $scope.account = account;
            window.sc = $scope;
            $scope.isAuthenticated = Principal.isAuthenticated;
            $scope.showBudgetedValues=false;
            if (FEATURE_SHOW_BUDGETS_IN_INDICATORS){
                $scope.showBudgetedValues = true;
            }
            function activate() {
                if (company_id !== undefined && company_id !== "undefined" && company_id !== "null" && company_id !== null) {
                    $scope.$root.selectedCompanyId = company_id;
                    $q.when($scope.$root.getCompany(company_id)).then(function (company) {
                        if (account.sourceType === "ORGANISATION" && account.sourceId !== company.organisation.id) {
                            $scope.$root.setSelectedCompany(null);
                            $state.go('home');
                        } else {
                            $scope.$root.setSelectedCompany(company);
                            raiseAlertIfNoActualizedPosition(company);
                        }
                        // $scope.$root.setSelectedCompany(company);
                        // $scope.$root.$broadcast("navUpdate");
                    });
                    indicatorService.getValues(company_id).then(function (response) {
                        $scope.indicators = response.data;
                    });
                } else {
                    $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                        if (company != null) {
                            indicatorService.getValues(company.id).then(function (response) {
                                $scope.indicators = response.data;
                            });
                        } else {
                            $http.get("/api/companys/" + account.sourceId).then(function (response) {
                                $scope.$root.setSelectedCompany(response.data);
                                $scope.$root.fiscalYear = FiscalYear.current({companyId: $scope.$root.selectedCompany.id});
                                indicatorService.getValues(account.sourceId).then(function (response) {
                                    $scope.indicators = response.data;
                                });
                            });
                        }
                    });
                }
            }

            activate();
        });

        $scope.variableSelected = function (current) {
            //console.log('variableSelected', current);
            let indicator = current.indicator;
            if (FEATURE_COMPANY_ACCOUNT_CHART && SelectedCompanyService.isBasicChartSelected()) {
                $state.go('drill', {variableid: indicator.id, monthly: indicator.monthly, variableType: variableTypes.INDICATOR});
            } else {
                $state.go('drill', {variableid: indicator.companyVariable.id, monthly: indicator.monthly});
            }
        };


        $scope.getValueClass = function (current) {
            var reverse = false;
            var alertLimit = 0.1;
            var warnLimit = 0.1;
            var color = "";
            var direction = "";

            if (current.prev_value !== null) {
                if (current.indicator.relative === true) {
                    if (current.prev_value < 0) {
                        alertLimit = current.prev_value + (1 - current.indicator.alertLimit / 100) * current.prev_value;
                        warnLimit = current.prev_value + (1 - current.indicator.warnLimit / 100) * current.prev_value;

                    } else {
                        alertLimit = current.indicator.alertLimit * current.prev_value / 100;
                        warnLimit = current.indicator.warnLimit * current.prev_value / 100;
                    }
                } else {
                    alertLimit = current.indicator.alertLimit;
                    warnLimit = current.indicator.warnLimit;
                }
                if (alertLimit > warnLimit) {
                    reverse = true;
                }
                if (reverse) {
                    if (current.value < warnLimit) {
                        color = "color-green";
                    } else if (current.value < alertLimit && current.value >= warnLimit) {
                        color = "color-orange";
                    } else {
                        color = "color-red";
                    }
                    if (current.value < current.prev_value) {
                        direction = "fa-arrow-up";
                    } else if (current.value == current.prev_value) {
                        direction = "fa-arrow-right";
                    } else {
                        direction = "fa-arrow-down";
                    }
                } else {
                    if (current.value <= alertLimit) {
                        color = "color-red";
                    } else if (current.value > alertLimit && current.value <= warnLimit) {
                        color = "color-orange";
                    } else {
                        color = "color-green";
                    }
                    if (current.value > current.prev_value) {
                        direction = "fa-arrow-up";
                    } else if (current.value == current.prev_value) {
                        direction = "fa-arrow-right";
                    } else {
                        direction = "fa-arrow-down";
                    }
                }
            } else {
                direction = "fa-arrow-right";
                if (current.indicator.relative === true) {
                    color = "color-orange";
                } else {
                    if (alertLimit > warnLimit) {
                        if (current.value < warnLimit) {
                            color = "color-green";
                        } else if (current.value < alertLimit && current.value >= warnLimit) {
                            color = "color-orange";
                        } else {
                            color = "color-red";
                        }
                    } else {
                        if (current.value <= alertLimit) {
                            color = "color-red";
                        } else if (current.value > alertLimit && current.value <= warnLimit) {
                            color = "color-orange";
                        } else {
                            color = "color-green";
                        }
                    }
                }

            }
            return color + " " + direction;
        };

        $scope.getMarkClass = function (current) {
            var reverse = false;
            var alertLimit = 0;
            var warnLimit = 0;
            if (current.prev_value !== null) {
                if (current.indicator.relative) {
                    alertLimit = current.indicator.alertLimit * current.prev_value;
                    warnLimit = current.indicator.warnLimit * current.prev_value;
                } else {
                    alertLimit = current.indicator.alert_limit;
                    warnLimit = current.indicator.warn_limit;
                }
                if (alertLimit < warnLimit) {
                    reverse = true;
                }
                if (reverse) {
                    if (current.value <= alertLimit) {
                        return "mark-show";
                    } else {
                        return "mark-hide";
                    }
                } else {
                    if (current.value >= alertLimit) {
                        return "mark-show";
                    } else {
                        return "mark-hide";
                    }
                }
            } else {
                return "mark-hide";
            }
        };

        $scope.showCharts = true;
//     $q.when($scope.$root.getSelectedCompany()).then(function(company) {
//    	 $scope.showCharts = company.id === 1;
//     });
        $scope.selectedIndicator = null;

        $scope.showModalChart = function (indicator) {
            $scope.selectedIndicator = indicator;
            if (FEATURE_COMPANY_ACCOUNT_CHART && SelectedCompanyService.isBasicChartSelected()) {
                $uibModal.open({
                    backdrop: 'static',
                    component: 'survivorChartDialogMeasureComponent',
                    size: 'md',
                    resolve: {
                        indicator: () => { return $scope.selectedIndicator.indicator; }
                    }
                });
            } else {
                $uibModal.open({
                    backdrop: 'static',
                    template: require('./survivor.chart-dialog.html'),
                    controller: 'SurvivorChartDialogController',
                    size: 'md',
                    scope: $scope
                });
            }
        };

        function raiseAlertIfNoActualizedPosition(company) {
            if(!company.actualizedPosition) {
                var alertText;
                if (i18next.language == 'en') {
                    alertText = 'Actualized month is not set for the company. All features will not work without it. Set actualized month in reporting page.';
                } else {
                    alertText = 'Yhtiölle ei ole asetettu toteutunutta kuukautta, joten kaikki toiminnot eivät ole käytettävissä. Lukitse viimeisin toteutunut kuukausi raportointisivulla';
                }
                window.alert(alertText);
            }
        }
    });

