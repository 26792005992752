import template from "./navbar_calculation_alarm.html";
import i18next from "i18next";

export const NavbarCalculationAlarmComponent = {
    template: template,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: class NavbarCalculationAlarmComponent {

        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.balanceDifference = 0;
            this.cashflowDifference = 0;
            this.showHelp = false;
        }

        $onInit() {
            this.$log.debug('NavbarCalculationAlarm.$onInit');
            this.balanceDifference = this.resolve.balanceDifference;
            this.cashflowDifference = this.resolve.cashflowDifference;
            this.text = this.getText();

        }

        getText() {
            if (this.balanceDifference != 0) {
                if (i18next.language == "en") {
                    return "Balance's assets and liabilities has different values."
                } else {
                    return "Taseen vastaavaa ja vastattavaa eroaa toisistaan."
                }

            } else {
                if (i18next.language == "en") {
                    return "Balance's funds and cashflow's funds has different values."
                } else {
                    return "Taseen rahavarat eroaa kassavirran rahavaroihin verrattuna toisistaan."
                }

            }
        }

        getHelp() {
            this.showHelp = true;
        }

        $onChanges(changesObj) {
            this.$log.debug('NavbarCalculationAlarmComponent.$onChanges', changesObj);
        }

        ok() {
            this.close({$value: this.currentMeasure});
        }

        cancel() {
            this.dismiss({$value: 'dismissed'});
        }
    }
};