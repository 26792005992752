import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import funding_en from './translations/en/funding.json';
import funding_fi from './translations/fi/funding.json';
import fundingForm_en from './translations/en/fundingForm.json';
import fundingForm_fi from './translations/fi/fundingForm.json';
import fundingModal_en from './translations/en/fundingModal.json';
import fundingModal_fi from './translations/fi/fundingModal.json';
import fundingOptionalValues_en from './translations/en/fundingOptionalValues.json';
import fundingOptionalValues_fi from './translations/fi/fundingOptionalValues.json';
import fundingTable_en from './translations/en/fundingTable.json';
import fundingTable_fi from './translations/fi/fundingTable.json';
import inputs_en from './translations/en/inputs.json';
import inputs_fi from './translations/fi/inputs.json';
import investments_fi from './translations/fi/investments.json';
import investments_en from './translations/en/investments.json';
import depreciation_fi from './translations/fi/depreciation.json';
import depreciation_en from './translations/en/depreciation.json';
import shared_fi from './translations/fi/shared.json';
import shared_en from './translations/en/shared.json';
import workingCapital_en from './translations/en/workingCapital.json';
import workingCapital_fi from './translations/fi/workingCapital.json';
import funding_sv from './translations/sv/funding.json';
import fundingForm_sv from './translations/sv/fundingForm.json';
import fundingModal_sv from './translations/sv/fundingModal.json';
import fundingOptionalValues_sv from './translations/sv/fundingOptionalValues.json';
import fundingTable_sv from './translations/sv/fundingTable.json';
import inputs_sv from './translations/sv/inputs.json';
import workingCapital_sv from './translations/sv/workingCapital.json';


i18n
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        react: {
            useSuspense: false
        },
        supportedLngs: ['fi', 'en', 'sv'],
        fallbackLng: 'fi',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: 'i18n/{{lng}}/{{ns}}.json?nocache='+(new Date()).getTime()
        },
        detection: {
            //lookupLocalStorage: 'NG_TRANSLATE_LANG_KEY'
        },
        resources: {
            en: {
                funding: funding_en,
                fundingForm: fundingForm_en,
                fundingModal: fundingModal_en,
                fundingOptionalValues: fundingOptionalValues_en,
                fundingTable: fundingTable_en,
                inputs: inputs_en,
                investments: investments_en,
                depreciation: depreciation_en,
                shared: shared_en,
                workingCapital: workingCapital_en
            },
            fi: {
                funding: funding_fi,
                fundingForm: fundingForm_fi,
                fundingModal: fundingModal_fi,
                fundingOptionalValues: fundingOptionalValues_fi,
                fundingTable: fundingTable_fi,
                inputs: inputs_fi,
                investments: investments_fi,
                depreciation: depreciation_fi,
                shared: shared_fi,
                workingCapital: workingCapital_fi
            },
            sv: {
                funding: funding_sv,
                fundingForm: fundingForm_sv,
                fundingModal: fundingModal_sv,
                fundingOptionalValues: fundingOptionalValues_sv,
                fundingTable: fundingTable_sv,
                inputs: inputs_sv,
                workingCapital: workingCapital_sv
            },
        }
    });


export default i18n;