'use strict';

angular.module('ecoeasyApp').controller('AccountCategoryDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'AccountCategory', 'AccountCategoryValue',
        function ($scope, $stateParams, $uibModalInstance, entity, AccountCategory, AccountCategoryValue) {

            $scope.accountCategory = entity;
            $scope.accountcategoryvalues = AccountCategoryValue.query();
            $scope.load = function (id) {
                AccountCategory.get({id: id}, function (result) {
                    $scope.accountCategory = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:accountCategoryUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.accountCategory.id != null) {
                    AccountCategory.update($scope.accountCategory, onSaveSuccess, onSaveError);
                } else {
                    AccountCategory.save($scope.accountCategory, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
