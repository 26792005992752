'use strict';

angular.module('ecoeasyApp')
        .controller('MainController', function ($scope, $state, Principal, Auth) {

            if(Principal.isAuthenticated())
            {
                Principal.identity().then(function (account) {

                    if (account.sourceType === "ORGANISATION") {
                        $state.go("bigbrother");
                    } else if (account.sourceType === "COMPANY") {
                        $scope.$root.setSelectedCompany($scope.$root.getSelectedCompany());
                        $state.go("survivor");
                    }


                    //
                    /*
                    if (Principal.hasAnyAuthority(["ROLE_USER_ORGANISATION", "ROLE_ADMIN_ORGANISATION"])) {
                        $state.go("bigbrother");
                    } else if (Principal.hasAuthority("ROLE_USER")) {
                        $state.go("survivor");
                    }
                     */
                    //
                });
            }else{
                Auth.authorize();
            }
        });

