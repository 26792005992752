export const getCorrectDecimalFormat = (number, digits) => {
    const numberAsString = Number(number).toFixed(digits);
    return numberAsString.replace('.', ',');
};

/** used in budget funding & investments */
export const getFreePeriodInMonths = (withdrawalDate, firstAmortizationDate) => {
    if(withdrawalDate.getYear() < firstAmortizationDate.getYear()) {
        const yearsToMonths = (firstAmortizationDate.getFullYear() - withdrawalDate.getFullYear()) * 12;
        return (firstAmortizationDate.getMonth() - withdrawalDate.getMonth() + yearsToMonths);
    } else {
        return firstAmortizationDate.getMonth() - withdrawalDate.getMonth();
    }
};

export const parseDateToMonth = date => {
    return date.getMonth() + 1;
}

export const parseDateToYear = date => {
    return date.getFullYear();
}

export const getNumberInThousands = (number) => {
    const thousands = number / 1000;
    return Math.round(thousands);
}

export const emptyIfAbsent = (value, defaultEmptyValue) => {
    return value ? value : defaultEmptyValue;
};

export const getPaymentAmount = (payment, showThousands) => {
    if (payment !== undefined || payment === 0) {
        if (showThousands) {
            return getNumberInThousands(payment);
        } else {
            return getCorrectDecimalFormat(payment);
        }
    }
    return showThousands ? 0 : getCorrectDecimalFormat(0);
};
