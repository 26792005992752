'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyUserManagementController', function ($http, $scope, Principal, User, Company, ParseLinks, Language, companyId, sourceState, PurchaseManagement) {

        $scope.state = sourceState;
        $scope.users = [];
        $scope.authorities = ["ROLE_ADMIN_ORGANISATION"];

        $scope.companyName = "";

        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });
        $scope.companyId = companyId;
        Principal.identity().then(function (account) {
            $scope.currentAccount = account;
        });

        $scope.page = 1;
        $scope.loadAll = function () {
            $http.get('api/users/company/' + companyId).then(function (result) {
                $scope.users = result.data;
            });
        };
        $scope.loadAll();
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.setCompanyName = function () {
            $http.get('api/companys/' + companyId).then(function (result) {
                var company = result.data;
                $scope.companyName = company.name;
            })
        }
        $scope.setCompanyName();

        $scope.setActive = function (user, isActivated) {
            user.activated = isActivated;

            $http.put("/api/users/setActive", user)
                .then(function (response) {
                    $scope.loadAll();
                });
        };

        $scope.clear = function () {
            $scope.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
        };

        $scope.companyUserSpots = PurchaseManagement.companyUserSpots({companyId: $scope.companyId});
    });
