(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('UserGroupController', UserGroupController);

    UserGroupController.$inject = ['$scope', '$state', "$log", 'UserGroup', 'Principal', 'UserGroupService'];

    function UserGroupController($scope, $state, $log, UserGroup, Principal, UserGroupService) {
        var vm = this;

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
            var orgId = account.sourceId;

            var isAdmin = Principal.hasAuthority("ROLE_ADMIN");

            if (isAdmin) {
                vm.userGroups = loadAll();
            } else {
                UserGroupService.getAllForOrganisation(orgId)
                    .then(function (userGroups) {
                        vm.userGroups = userGroups;
                    });
            }
        });

        vm.userGroups = [];

        function loadAll() {
            UserGroup.query(function (result) {
                vm.userGroups = result;
            });
        }
    }
})();
