import template from "./performance-measure-edit.html";
import {formulaItemTypes} from "../performance-measure-formula/performance-measure-formula-item/PerformanceMeasureFormulaItem";


export const PerformanceMeasureEditComponent = {
    template: template,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: class PerformanceMeasureEditComponent {

        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.currentMeasure = null;
            this.measureOptions = [];
            this.isAccounts = [];
            this.bsAccounts = [];
            this.measure = null;
            this.isAccount = null;
            this.bsAccount = null;
            this.userNumber = null;
            this.validFormula = false;
            this.operatorEnabled = false;
            this.leftParenthesisEnabled = false;
            this.rightParenthesisEnabled = false;
            this.valueEnabled = false;
            this.leftParenthesisCount = 0;
            this.editFormula = [];
            this.runningNumber = 0;

        }

        $onInit() {
            this.$log.debug('PerformanceMeasureEditComponent.$onInit');
            const measure = this.resolve.measure;
            this.currentMeasure = angular.copy(measure);
            if (measure) {
                this.formulaToEditFormula(angular.copy(this.currentMeasure.formula));
            }
            this.measureOptions = this.resolve.measureOptions.filter((m) => {
                return m.id != measure.id;
            });
            this.isAccounts = this.mapToOptions(this.resolve.isAccounts);
            this.bsAccounts = this.mapToOptions(this.resolve.bsAccounts);
            this.validateFormula();
            this.currentMeasure.calcType ="ABSOLUTE";
        }

        checkThatTextIsNumber(str) {
            if (str == null || str == "") {
                return true;
            }
            return !isNaN(str) && !isNaN(parseFloat(str))
        }

        valuesOk() {
            if (!this.checkThatTextIsNumber(this.currentMeasure.thresholdValue1Low) || !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue2Low) ||
                !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue3Low) || !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue4Low) ||
                !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue5Low) || !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue1High) ||
                !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue2High) || !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue3High) ||
                !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue4High) || !this.checkThatTextIsNumber(this.currentMeasure.thresholdValue5High)) {
                return false;
            } else {
                return true;
            }
        }



        mapToOptions(accounts) {
            return accounts.map((value) => {
                let account = {};
                account.id = value.id;
                if (value.code) {
                    account.name = value.nameFi;
                    account.code = value.code;
                    account.title = account.code + ' ' + account.name;
                } else {
                    account.name = value.name;
                    account.title = account.name;
                }
                return account;
            });
        }

        $onChanges(changesObj) {
            this.$log.debug('PerformanceMeasureEditComponent.$onChanges', changesObj);
        }

        ok() {
            this.close({$value: this.currentMeasure});
        }

        cancel() {
            this.dismiss({$value: 'dismissed'});
        }

        balanceSheetChanged() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, this.bsAccount);
                this.editFormula.splice(this.index + 1, 0, {account: this.bsAccount, id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push(this.bsAccount);
                this.editFormula.push({account: this.bsAccount, id: this.runningNumber});
            }

            this.bsAccount = null;
            this.valueAdded();
        }

        incomeStatementChanged() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, this.isAccount);
                this.editFormula.splice(this.index + 1, 0, {account: this.isAccount, id: this.runningNumber});

                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push(this.isAccount);
                this.editFormula.push({account: this.isAccount, id: this.runningNumber});
            }

            this.isAccount = null;
            this.valueAdded();
        }

        measureChanged() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, this.measure);
                this.editFormula.splice(this.index + 1, 0, {account: this.measure, id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push(this.measure);
                this.editFormula.push({account: this.measure, id: this.runningNumber});
            }

            this.measure = null;
            this.valueAdded();
        }

        addUserValue() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, this.userNumber);
                this.editFormula.splice(this.index + 1, 0, {account: this.userNumber, id: this.runningNumber});

                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push(this.userNumber);
                this.editFormula.push({account: this.userNumber, id: this.runningNumber});
            }

            this.userNumber = null;
            this.valueAdded();
        }

        valueAdded() {
            this.validateFormula();
            this.runningNumber++;
            this.clearStyles();
        }

        operatorAdded() {
            this.validateFormula();
            this.runningNumber++;
            this.clearStyles();
        }

        rightParenthesisAdded() {
            this.validateFormula();
            this.runningNumber++;
            this.clearStyles();
        }

        leftParenthesisAdded() {
            this.validateFormula();
            this.runningNumber++;
            this.clearStyles();
        }

        removeSelected() {
            this.currentMeasure.formula.splice(this.index, 1);
            this.editFormula.splice(this.index, 1);
            this.runningNumber++;
            this.validateFormula();
            this.clearStyles();
        }


        rightParenthesis() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, ')');
                this.editFormula.splice(this.index + 1, 0, {account: ')', id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push(')');
                this.editFormula.push({account: ')', id: this.runningNumber});
            }
            this.rightParenthesisAdded();
        }

        leftParenthesis() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, '(');
                this.editFormula.splice(this.index + 1, 0, {account: '(', id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push('(');
                this.editFormula.push({account: '(', id: this.runningNumber});
            }
            this.leftParenthesisAdded();
        }

        divide() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, '/');
                this.editFormula.splice(this.index + 1, 0, {account: '/', id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push('/');
                this.editFormula.push({account: '/', id: this.runningNumber});
            }

            this.operatorAdded();
        }

        multiply() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, '*');
                this.editFormula.splice(this.index + 1, 0, {account: '*', id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push('*');
                this.editFormula.push({account: '*', id: this.runningNumber});
            }

            this.operatorAdded();
        }

        minus() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, '-');
                this.editFormula.splice(this.index + 1, 0, {account: '-', id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push('-');
                this.editFormula.push({account: '-', id: this.runningNumber});
            }

            this.operatorAdded();
        }

        plus() {
            if (this.indexSelected) {
                this.currentMeasure.formula.splice(this.index + 1, 0, '+');
                this.editFormula.splice(this.index + 1, 0, {account: '+', id: this.runningNumber});
                this.indexSelected = false;
            } else {
                this.currentMeasure.formula.push('+');
                this.editFormula.push({account: '+', id: this.runningNumber});
            }

            this.operatorAdded();
        }

        addFormulaItemToIndex(index) {
            this.indexSelected = true;
            this.index = index;
            this.editFormula[index].selectedStyle={ 'border':'4px solid #ff8f9e' };
        }

        outsideClick(event) {
            if (event) {
                if (event.relatedTarget) {
                    if (event.relatedTarget.tagName != "SELECT" && event.relatedTarget.tagName != 'INPUT' && event.relatedTarget.tagName != 'BUTTON') {
                        this.indexSelected = false;
                        this.clearStyles();
                    }
                }
            }
        }

        validateFormula() {
            var latest = null;
            if (!this.currentMeasure.formula[0]) {
                this.validFormula = false;
                return;
            } else if (this.getItemType(this.currentMeasure.formula[0]) == 'OPERATOR' || this.getItemType(this.currentMeasure.formula[0]) == 'CLOSING_PARENTHESIS') {
                this.validFormula = false;
                return;
            } else if (this.currentMeasure.formula.length == 1 && this.getItemType(this.currentMeasure.formula[0]) == formulaItemTypes.COMPANY_ACCOUNT) {
                this.validFormula = true;
            }
            var openParenthesisCount = 0;
            var closingParenthesisCount = 0;
            for (let i = 0; i < this.currentMeasure.formula.length; i++) {
                var current = this.currentMeasure.formula[i];
                if (latest) {
                    if (this.getItemType(current) == 'OPERATOR' && this.getItemType(latest) == 'OPERATOR') {
                        this.validFormula = false;
                        return;
                    } else if (this.getItemType(current) == formulaItemTypes.COMPANY_ACCOUNT && this.getItemType(latest) == formulaItemTypes.COMPANY_ACCOUNT) {
                        this.validFormula = false;
                        return;
                    } else if (this.getItemType(latest) == formulaItemTypes.COMPANY_ACCOUNT && this.getItemType(current) != 'OPERATOR' && this.getItemType(current) != 'CLOSING_PARENTHESIS') {
                        this.validFormula = false;
                        return;
                    } else if (this.getItemType(latest) == 'CLOSING_PARENTHESIS' && this.getItemType(current) != 'OPERATOR') {
                        this.validFormula = false;
                        return;
                    } else {
                        this.validFormula = true;
                    }

                }
                if (this.getItemType(current) == 'CLOSING_PARENTHESIS') {
                    closingParenthesisCount++;
                }
                if (this.getItemType(current) == 'OPEN_PARENTHESIS') {
                    openParenthesisCount++;
                }
                if (this.getItemType(this.currentMeasure.formula[this.currentMeasure.formula.length - 1]) == 'OPERATOR') {
                    this.validFormula = false;
                    return;
                } else if (this.getItemType(this.currentMeasure.formula[this.currentMeasure.formula.length - 1]) == 'OPEN_PARENTHESIS') {
                    this.validFormula = false;
                    return;
                } else {
                    this.validFormula = true;
                }
                latest = current;
            }
            if (closingParenthesisCount != openParenthesisCount) {
                this.validFormula = false;
            }
        }

        getItemType(item) {
            if (item.name && item.formula) {
                return formulaItemTypes.COMPANY_ACCOUNT;
            } else if (item.id && item.code) {
                return formulaItemTypes.COMPANY_ACCOUNT;
            } else if (item.id) {
                return formulaItemTypes.COMPANY_ACCOUNT;
            } else if (!isNaN(item)) {
                return formulaItemTypes.COMPANY_ACCOUNT;
            } else if (item.key) {
                return formulaItemTypes.COMPANY_ACCOUNT;
            } else if (item == '(') {
                return 'OPEN_PARENTHESIS';
            } else if (item == ')') {
                return 'CLOSING_PARENTHESIS';
            } else {
                return 'OPERATOR';
            }
        }

        formulaToEditFormula(formula) {
            for (let i = 0; i < formula.length; i++) {
                var current = formula[i];
                this.editFormula.push({account: current, id: this.runningNumber})
                this.runningNumber++;
            }
        }
        clearStyles(){
            for (let i = 0; i < this.editFormula.length; i++) {
                this.editFormula[i].selectedStyle={};
            }
        }


    }
};
