'use strict';

angular.module('ecoeasyApp')
    .controller('AccountMappingController', function ($scope, Principal, $state, $http, $q, $translate, AccountMappingService, AccountMapping, LinkingTable, LinkingTableRule) {
        Principal.identity().then(function (account) {
            $scope.demo = account.demo;
        });

        $scope.accountMappings = [];
        $scope.accountNumberToName = {};
        $scope.externalAccountToName = {};
        $scope.linkingTables = LinkingTable.query();

        $scope.loadAll = function () {
            var mappings;

            $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                $q.all([
                    AccountMappingService.getByCompany($scope.$root.getSelectedCompany().id)
                        .then(function (response) {
                            mappings = response.data;
                        }),
                    $http.get("/api/accountingModels/accountToNameMap/" + $scope.$root.getSelectedCompany().id)
                        .then(function (response) {
                            $scope.accountNumberToName = response.data;
                        }),
                    $http.get("/api/externalAccountingModels/byCompany/" + $scope.$root.getSelectedCompany().id)
                        .then(function (response) {
                            $scope.externalAccountToName = response.data;
                        })
                ])
                    .then(function () {
                        mappings.forEach(function (mapping) {
                            if ($scope.accountNumberToName[mapping.destAccount]) {
                                mapping.destName = $scope.accountNumberToName[mapping.destAccount];
                            }
                            else {
                                mapping.destName = "";
                            }
                            if ($scope.externalAccountToName[mapping.sourceAccount]) {
                                mapping.srcName = $scope.externalAccountToName[mapping.sourceAccount];
                            }
                            else {
                                mapping.srcName = "";
                            }
                        });
                        $scope.accountMappings = mappings;
                    });
            });

        };

        $scope.loadAll();

        $scope.$root.$on('ecoeasyApp:accountMappingUpdate', function (event, data) {
        });

        $scope.filters = [
            {
                id: 0,
                desc: $translate.instant('ecoeasyApp.accountMapping.filters.all')
            },
            {
                id: 1,
                desc: $translate.instant('ecoeasyApp.accountMapping.filters.unmapped')
            },
            {
                id: 2,
                desc: $translate.instant('ecoeasyApp.accountMapping.filters.automatically_mapped')
            },
            {
                id: 3,
                desc: $translate.instant('ecoeasyApp.accountMapping.filters.manually_mapped')
            },
            {
                id: 4,
                desc: $translate.instant('ecoeasyApp.accountMapping.filters.unconfirmed')
            }
        ];

        $scope.currentFilter = $scope.filters[1];

        $scope.mappingFilter = function (mapping) {
            switch ($scope.currentFilter.id) {
                case 0:
                    return true;
                case 1:
                    return mapping.mappingStatus === "NOT_RESOLVED";
                case 2:
                    return mapping.mappingStatus === "AUTO_RESOLVED";
                case 3:
                    return mapping.mappingStatus === "RESOLVED";
                case 4:
                    return mapping.mappingStatus === "AUTO_SUGGEST";
                default:
                    return false;
            }
        }

        $scope.isSuggestion = function (mapping) {
            return mapping.mappingStatus === "AUTO_SUGGEST";
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('ecoeasyApp:accountMappingUpdate', result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.acceptSuggestion = function (mapping, linkingTable) {
            $scope.isSaving = true;
            $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                mapping.mappingStatus = "AUTO_RESOLVED";
                mapping.companyId = company.id;
                AccountMapping.update(mapping, onSaveSuccess, onSaveError);
            });
            if (linkingTable) {
                var tableRule = {
                    destAccount: mapping.destAccount,
                    destAccountName: $scope.accountNumberToName[mapping.destAccount],
                    sourceAccount: mapping.sourceAccount,
                    sourceAccountName: $scope.externalAccountToName[mapping.sourceAccount],
                    linkingTable: linkingTable
                };
                LinkingTableRule.save(tableRule);
            }
        }

        $scope.refresh = function () {
            $scope.reset();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.accountMapping = {
                sourceAccount: null,
                destAccount: null,
                formula: null,
                id: null
            };
        };
    });
