export class ChartRow {
    constructor(id, nameFi, nameEn, nameSv, level, accountRanges, ordinal, movable, cashFlowType, topLevel, globalId) {
        this.id = id;
        this.name = nameFi;
        this.nameFi = nameFi;
        this.nameEn = nameEn;
        this.nameSv = nameSv;
        this.level = level;
        this.indent = 2 * level;
        this.editing = false;
        this.setAccountRanges(accountRanges);
        this.ordinal = ordinal;
        this.topLevel = topLevel;
        this.movable = movable;
        this.cashFlowType = cashFlowType;
        this.globalId =globalId;

    }

    setAccountRanges(accountRanges) {
        this.accountRanges = accountRanges;
        this.accountRangesString = ChartRow.toAccountRangeString(accountRanges);
    }

    static toAccountRangeString(accountRanges) {
        let str = '';
        if (accountRanges) {
            accountRanges
                .sort((a, b) => {
                    return a.min - b.min
                })
                .forEach((item, index) => {
                    if (index > 0) {
                        str += ', ';
                    }
                    if (item.min === item.max) {
                        str += item.min;
                    } else {
                        str += item.min + '–' + item.max;
                    }
                });
        }
        return str;
    }
}
