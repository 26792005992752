import i18next from "i18next";

export class CompanyChartRow {
    constructor(id, nameFi, nameEn, nameSv, level, ordinal, companyAccounts, movable) {
        this.id = id;
        this.nameFi = nameFi;
        this.nameEn = nameEn;
        this.nameSv = nameSv;
        this.level = level;
        this.indent = 3 * level;
        this.editing = false;
        this.ordinal = ordinal;
        this.companyAccounts = companyAccounts;
        this.isAccount = false;
        this.movable = movable;
        if(i18next.language == "en" && nameEn != null && nameEn != undefined && nameEn != "") {
            this.name = nameEn
        } else {
            this.name = nameFi;
        }
    }

}
