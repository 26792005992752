(function () {
    'use strict';
    angular
        .module('ecoeasyApp')
        .factory('UserGroup', UserGroup);

    UserGroup.$inject = ['$resource'];

    function UserGroup($resource) {
        var resourceUrl = 'api/user-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();

angular.module('ecoeasyApp')
    .service('UserGroupService', function ($http, $q, $rootScope, $log) {
        return ({
            getAllForOrganisation: getAllForOrganisation
        });

        function getAllForOrganisation(orgId) {

            return $http({
                method: "get",
                url: "/api/user-groups/organisation/" + orgId
            }).then(function (response) {
                return response.data;
            });
        }
    });
