'use strict';

angular.module('ecoeasyApp')
    .controller('PriceCatalogController', function ($scope, $state, PriceCatalog, Organisation) {

        $scope.organisation = null;
        $scope.organisations = Organisation.query();
        $scope.priceCatalog = [];
        $scope.loadAll = function () {
            PriceCatalog.CommonPriceCatalog.query({}, function (result, headers) {
                $scope.priceCatalog = result;
            });
        };
        $scope.loadAll();

        $scope.pickOrganisation = function () {
            if ($scope.organisation == null) {
                $scope.loadAll();
            } else {
                PriceCatalog.OrganisationPriceCatalog.query({'organisationId': $scope.organisation.id}, function (result, headers) {
                    $scope.priceCatalog = result;
                });
            }
        };

        var onSaveSuccess = function (result) {
            $scope.priceCatalog = result;
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            PriceCatalog.Save.save($scope.priceCatalog, onSaveSuccess, onSaveError);
        };


    });
