export default class ValueRowAbsolute {

    constructor(ordinal, id, name, level, values, augend) {
        this.ordinal = ordinal;
        this.id = id;
        this.name = name;
        this.level = level;
        this.values = angular.copy(values);
        this.total = 0;
        this.augend = augend;
    }

    changeValue(index, newValue) {
        this.values[index] = newValue;
    }


}