'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('merged-financials', {
            parent: 'site',
            url: '/merged-financials/:companyids',
            params: {
                companyids: null,
            },
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                permissions: ['BALANCE_SHEET', 'INCOME_STATEMENT'],
                pageTitle: 'global.title'
            },

            views: {
                'content@': {
                    template: require('./merged-financials.html'),
                    controller: 'MergedFinancialsController'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('financials');
                    $translatePartialLoader.addPart('variablecodes');
                    $translatePartialLoader.addPart('company-balance-sheet');
                    return $translate.refresh();
                }],
                companyids: ['$stateParams', function ($stateParams) {
                    return $stateParams.companyids;
                }]
            }
        });
    });
