'use strict';

angular.module('ecoeasyApp')
    .factory('Organisation', function ($resource, DateUtils) {
        return $resource('api/organisations/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    });

angular.module('ecoeasyApp')
        .service('OrganisationService', function ($http, $q, $rootScope, $log){
            return({
                getById: getById
            });
            
            function getById(id){
                return $http({
                    method: "get",
                    url: "/api/organisations/" + id
                }).then(function(response){
                    return response.data;
                });
            }
});
