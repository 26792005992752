'use strict';

angular.module('ecoeasyApp')
    .controller('IndustryDetailController', function ($scope, $rootScope, $stateParams, entity, Industry, IndustryCollectionDefinition, Indicator) {
        $scope.industry = entity;
        $scope.load = function (id) {
            Industry.get({id: id}, function (result) {
                $scope.industry = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:industryUpdate', function (event, result) {
            $scope.industry = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
