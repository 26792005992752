angular.module('ecoeasyApp')
    .service('CustomRowImportService', function ($http, $q, $rootScope, $log) {

        function sendRow(row, onSuccess, onError) {
            return $q.when().then(function () {
                $http.post("/api/customrowimport/import", row).then(onSuccess, onError);
            });
        }

        return ({
            sendRow: sendRow,
        });
    });
