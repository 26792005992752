import angular from "angular";
import PerformanceMeasuresComponent from "./performance-measures.component";
import uiBootstrap from "angular-ui-bootstrap";
import PerformanceMeasureFormulaComponent from "./performance-measure-formula/performance-measure-formula.component";
import PerformanceMeasureFormulaItemComponent
    from "./performance-measure-formula/performance-measure-formula-item/performance-measure-formula-item.component";
import {PerformanceMeasureEditComponent} from "./performance-measure-edit/performance-measure-edit.component";
import PerformanceMeasureFormulaEditComponent from "./performance-measure-formula/performance-measure-formula-edit.component";
import PerformanceMeasureFormulaItemEditComponent from  "./performance-measure-formula/performance-measure-formula-item/performance-measure-formula-item-edit.component";


export const PerformanceMeasuresModule =
    angular.module('performance-measures-module', [uiBootstrap])
        .component('pmFormulaItem', PerformanceMeasureFormulaItemComponent)
        .component('pmFormula', PerformanceMeasureFormulaComponent)
        .component('performanceMeasureEdit', PerformanceMeasureEditComponent)
        .component('performanceMeasures', PerformanceMeasuresComponent)
        .component('pmFormulaEdit', PerformanceMeasureFormulaEditComponent)
        .component('pmFormulaItemEdit', PerformanceMeasureFormulaItemEditComponent)

        .name;
