'use strict';

angular.module('ecoeasyApp')
    .controller('user-managementDeleteController', function ($scope, $uibModalInstance, entity, UserService) {

        $scope.user = entity;

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (userId) {
            UserService.deleteById(userId)
                .then(function () {
                    $uibModalInstance.close(true);
                });
        };

    });
