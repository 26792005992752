'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('budget', {
                parent: 'site',
                url: '/budget',
                params:{sub: null},
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['BUDGET'],
                    license: 'PRO',
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./budget.html'),
                        controller: 'BudgetController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('variablecodes');
                        $translatePartialLoader.addPart('survivor');
                        $translatePartialLoader.addPart('budget');
                        $translatePartialLoader.addPart('investmentBudgetData');
                        $translatePartialLoader.addPart('fundingBudgetData');
                        $translatePartialLoader.addPart('variablecodes');
                        $translatePartialLoader.addPart('devaluationBudgetData');
                        $translatePartialLoader.addPart('budgetVariableCodes');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('budget.new', {
                parent: 'budget',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    privileges: ['BUDGET']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', '$q', 'BudgetService', function ($stateParams, $state, $uibModal, $rootScope, $q, BudgetService) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./budget-dialog.html'),
                        controller: 'BudgetDialogController',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    targetPeriod: null,
                                    lastModified: null,
                                    master: null,
                                    description: null,
                                    id: null,
                                    parentBudgetId: null,
                                    includeBalanceBudget: false
                                };
                            },
                            hasDataForEstimates: function () {
                                return $q.when($rootScope.getSelectedCompany()).then(function (company) {
                                    return BudgetService.hasDataForEstimates(company.id);
                                })
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('budget');
                    })
                }]
            })
            .state('budget.edit', {
                parent: 'budget',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    privileges: ['BUDGET']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./budget-dialog.html'),
                        controller: 'BudgetDialogController',
                        size: 'md',
                        resolve: {
                            entity: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('budget.delete', {
                parent: 'budget',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    privileges: ['BUDGET']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./budget-delete-dialog.html'),
                        controller: 'BudgetDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('budget.sub', {
                parent: 'budget',
                url: '/{id}/sub',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION'],
                    privileges: ['BUDGET']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',

                        template: require('./budget-subbudget.html'),
                        controller: 'SubBudgetDialogController',
                        size: 'md',
                        resolve: {
                            entity: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })

            .state('investmentBudget', {
                parent: 'budget',
                url: '/investmentBudget/new/{targetBudgetId}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./investment-budget-dialog.html'),
                        controller: 'InvestmentBudgetDialogController',
                        size: 'lg',
                        resolve: {
                            investmentBudgetEntity: function () {
                                return {
                                    accountCategoryValueId: null,
                                    position: null,
                                    value: null,
                                    vatValue: null,
                                    devaluationTime: null,
                                    devaluationDelay: null,
                                    fundingBudgetData: null,
                                    id: null,
                                    budgetId: null,
                                    companyVariable: null,
                                    remnantValue: null
                                };
                            },
                            fundingBudgetEntity: function () {
                                return {
                                    budgetId: null,
                                    position: null,
                                    name: null,
                                    initialCashEffect: null,
                                    loanAmount: null,
                                    paymentTerm: null,
                                    interestPercentage: null,
                                    paymentFreeMonths: null,
                                    id: null
                                }
                            },

                            targetBudget: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.targetBudgetId}).$promise;
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('budget');
                    })
                }]
            })
            .state('updateInvestmentBudget', {
                parent: 'budget',
                url: '/investmentBudget/update/{investmentBudgetId}/{budgetId}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./investment-budget-dialog.html'),
                        controller: 'InvestmentBudgetDialogController',
                        size: 'lg',
                        resolve: {
                            investmentBudgetEntity: ['InvestmentBudgetData', function (InvestmentBudgetData) {
                                return InvestmentBudgetData.get({id: $stateParams.investmentBudgetId}).$promise;
                            }],
                            fundingBudgetEntity: function () {
                                return {
                                    budgetId: null,
                                    position: null,
                                    name: null,
                                    initialCashEffect: null,
                                    loanAmount: null,
                                    paymentTerm: null,
                                    interestPercentage: null,
                                    paymentFreeMonths: null,
                                    id: null
                                }
                            },
                            targetBudget: function () {
                                return null;
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('budget');
                    })
                }]
            })
            .state('deleteInvestmentBudget', {
                parent: 'budget',
                url: 'investmentBudget/{id}/delete',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./investment-budget-delete-dialog.html'),
                        controller: 'InvestmentBudgetDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['InvestmentBudgetData', function (InvestmentBudgetData) {
                                return InvestmentBudgetData.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('fundingBudget', {
                parent: 'budget',
                url: '/fundingBudget/new/{targetBudgetId}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./funding-budget-dialog.html'),
                        controller: 'FundingBudgetDialogController',
                        size: 'lg',
                        resolve: {
                            fundingBudgetEntity: function () {
                                return {
                                    budgetId: null,
                                    position: null,
                                    name: null,
                                    initialCashEffect: null,
                                    loanAmount: null,
                                    paymentTerm: null,
                                    interestPercentage: null,
                                    paymentFreeMonths: null,
                                    compayVariable: null,
                                    id: null
                                };
                            },
                            targetBudget: ['Budget', function (Budget) {
                                return Budget.get({id: $stateParams.targetBudgetId})
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('budget');
                    })
                }]
            })
            .state('updateFundingBudget', {
                parent: 'budget',
                url: '/fundingBudget/update/{fundingBudgetId}',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./funding-budget-dialog.html'),
                        controller: 'FundingBudgetDialogController',
                        size: 'lg',
                        resolve: {
                            fundingBudgetEntity: ['FundingBudgetData', function (FundingBudgetData) {
                                return FundingBudgetData.get({id: $stateParams.fundingBudgetId}).$promise;
                            }],
                            targetBudget: function () {
                                return null;
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('budget');
                    })
                }]
            })
            .state('deleteFundingBudget', {
                parent: 'budget',
                url: 'fundingBudgetData/{id}/delete',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./funding-budget-delete-dialog.html'),
                        controller: 'FundingBudgetDataDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['FundingBudgetData', function (FundingBudgetData) {
                                return FundingBudgetData.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('budget', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
