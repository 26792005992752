import {FiscalYearOption} from "./fiscal-year-option";
import {convertBudget, convertOperatingBudget} from "./budget-converter";

function convertJsonBudgets(data, incomeStatement) {
    let budgets = [];
    data.forEach((budget) => {
        let convertedBudget = convertBudget(budget);
        let operatingBudget = convertOperatingBudget(budget, incomeStatement);
        convertedBudget.data = operatingBudget.data;
        budgets.push(convertedBudget);
    });
    return budgets;
}

export class Budget2ComponentService {

    constructor($log, $q, SelectedCompanyService, BudgetRestClientService, CompanyAccountChartService, BasicChartOfAccountsService) {
        "ngInject";
        this.$log = $log;
        this.$q = $q;
        this.selectedCompanyService = SelectedCompanyService;
        this.budgetRestClient = BudgetRestClientService;
        this.companyAccountChartService = CompanyAccountChartService;
        this.basicChartOfAccountsService = BasicChartOfAccountsService;
        this.accountChartId = this.selectedCompanyService.getBasicChartOfAccountsId();
    }

    getSelectablePositions() {
        return this.budgetRestClient.getFiscalYears(this.selectedCompanyService.getSelectedCompanyId()).then((result) => {
            this.$log.debug('get result', result);
            let fiscalYears = [];
            result.forEach((item) => {
                let endDate = new Date(item.endDate);
                fiscalYears.push(new FiscalYearOption(item.id, new Date(item.startDate), endDate));
            });
            this.$log.debug('fiscalYears ', fiscalYears);
            return fiscalYears;
        });
    }

    getSelectableBudgets(currentPosition) {
        return this._getBudgets(currentPosition.id);
    }

    getSelectablePrimaryBudget(currentPosition) {
        let incomeStatement = null;

        //UPDATE BasicChartOfAccountsId if company is changed
        if (this.accountChartId !== this.selectedCompanyService.getBasicChartOfAccountsId()) {
            this.accountChartId = this.selectedCompanyService.getBasicChartOfAccountsId();
        }

        if (this.accountChartId !== 1) {
            return this.$q.all([
                this.basicChartOfAccountsService.getBasicChartOfAccounts().then(res => {
                    return res.filter(it => it.id === this.accountChartId);
                }),
                this.budgetRestClient.getBudgets(this.selectedCompanyService.getSelectedCompanyId(), currentPosition.id, true)
            ]).then(success => {
                incomeStatement = success[0][0].incomeStatement;
                return convertJsonBudgets(success[1], incomeStatement)[0];
            });
        } else {
            return this.budgetRestClient.getBudgets(this.selectedCompanyService.getSelectedCompanyId(), currentPosition.id, true).then(res => {
                return convertJsonBudgets(res, incomeStatement)[0];
            });
        }
    }

    getSelectableNonPrimaryBudgets(currentPosition) {
        let incomeStatement = null;
        if (this.accountChartId !== 1) {
            return this.$q.all([
                this.basicChartOfAccountsService.getBasicChartOfAccounts().then(res => {
                    return res.filter(it => it.id === this.accountChartId);
                }),
                this.budgetRestClient.getBudgets(this.selectedCompanyService.getSelectedCompanyId(), currentPosition.id, false)
            ]).then(success => {
                incomeStatement = success[0][0].incomeStatement;
                return convertJsonBudgets(success[1], incomeStatement);
            });
        } else {
            return this.budgetRestClient.getBudgets(this.selectedCompanyService.getSelectedCompanyId(), currentPosition.id, false).then((data) => {
                return convertJsonBudgets(data, null);
            });
        }
    }

    _getBudget(budgetId) {
        let incomeStatement = null;
        if (this.accountChartId !== 1) {
            return this.$q.all([
                this.basicChartOfAccountsService.getBasicChartOfAccounts().then(res => {
                    return res.filter(it => it.id === this.accountChartId);
                }),
                this.budgetRestClient.getOperatingBudget(this.selectedCompanyService.getSelectedCompanyId(), budgetId)
            ]).then(success => {
                incomeStatement = success[0][0].incomeStatement;
                return convertJsonBudgets([success[1]], incomeStatement)[0];
            });
        } else {
            return this.budgetRestClient.getOperatingBudget(this.selectedCompanyService.getSelectedCompanyId(), budgetId).then((budget) => {
                return convertJsonBudgets([budget], null)[0];
            });
        }
    }

    getCashBudgets(companyId, fiscalYearId) {
        return this.budgetRestClient.getCashBudgets(companyId, fiscalYearId).then((response) => response);
    }

    getLinkedCashBudgets(companyId, cashBudgetId) {
        return this.budgetRestClient.getLinkedCashBudgets(companyId, cashBudgetId).then((response) => response);
    }

    _getBudgets(fiscalYearId) {
        let incomeStatement = null;
        if (this.accountChartId !== 1) {
            return this.$q.all([
                this.basicChartOfAccountsService.getBasicChartOfAccounts().then(res => {
                    return res.filter(it => it.id === this.accountChartId);
                }),
                this.budgetRestClient.getBudgets(this.selectedCompanyService.getSelectedCompanyId(), fiscalYearId)
            ]).then(success => {
                incomeStatement = success[0][0].incomeStatement;
                return convertJsonBudgets(success[1], incomeStatement);
            });
        } else {
            return this.budgetRestClient.getBudgets(this.selectedCompanyService.getSelectedCompanyId(), fiscalYearId).then(res => {
                return convertJsonBudgets(res, incomeStatement);
            });
        }
    }

    getBudgetsWithoutValues(fiscalYearId) {
        return this.budgetRestClient.getBudgetsWithoutValues(this.selectedCompanyService.getSelectedCompanyId(), fiscalYearId).then((data) => {
            return data;
        })
    }

    createBudget(budget) {
        return this.budgetRestClient.postBudget(this.selectedCompanyService.getSelectedCompanyId(), {
            name: budget.name,
            fiscalYearId: budget.fiscalYearId,
            budgetId: -1,
            createBudgetFactor: budget.createBudgetFactor,
            createBudgetLevel: budget.createBudgetLevel,
            cashBudget: budget.cashBudget,
            cashBudgetId: budget.cashBudgetId,
            cashBudgetName: budget.cashBudgetName
        }).then(() => {
            return this.getBudgetsWithoutValues(budget.fiscalYearId).then((budgets) => {
                return budgets[budgets.length - 1];
            })
        });
    }

    saveBudgetValues(budget, values) {
        return this.budgetRestClient.putBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id, {values: values})
            .then(() => this._getBudget(budget.id));
    }

    deleteBudget(budget) {
        return this.budgetRestClient.deleteBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id);
    }

    setAsPrimary(budget, position) {
        let data = {
            primary: true,
            fiscalYearId: position.id
        };
        return this.budgetRestClient.putBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id, data).then(() => {
            return this._getBudgets(position.id).then((budgets) => budgets.find(value => value.id === budget.id));
        });
    }

    copyBudget(budget, data) {
        return this.budgetRestClient.postBudget(this.selectedCompanyService.getSelectedCompanyId(), {
            name: data.name,
            fiscalYearId: data.fiscalYearId,
            budgetId: budget.id,
            createBudgetFactor: data.createBudgetFactor,
            createBudgetLevel: data.createBudgetLevel,
            cashBudget: data.cashBudget,
            cashBudgetId: data.cashBudgetId
        }).then(() => {
            return this._getBudgets(data.fiscalYearId).then((budgets) => {
                return budgets[budgets.length - 1];
            })
        });
    }

    getCostPools() {
        return this.budgetRestClient.getCostPools(this.selectedCompanyService.getSelectedCompanyId());
    }

    createSubBudgets(budget, subBudgets) {
        return this.budgetRestClient.putBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id, {subBudgets: subBudgets}).then(() => {
            return this._getBudgets(budget.fiscalYear.id).then((budgets) => {
                return budgets.find((value) => value.id == budget.id);
            })
        });
    }

    getActualMonths(budget) {
        return this.budgetRestClient.getLastActualDate(this.selectedCompanyService.getSelectedCompanyId())
            .then((lastActual) => {
                return budget.yearMonths.filter(m => m.year < lastActual.year || (m.year == lastActual.year && m.month <= lastActual.month))
            });
    }

    updateActuals(budget, yearMonth) {
        return this.budgetRestClient.putBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id, {updateActual: yearMonth}).then(() => {
            return this._getBudgets(budget.fiscalYear.id).then((budgets) => {
                return budgets.find((value) => value.id == budget.id);
            })
        });
    }

    getSelectableSubBudgets(budget) {
        let incomeStatement = null;
        if (this.accountChartId !== 1) {
            return this.$q((resolve, reject) => {
                return this.$q.all([
                    this.basicChartOfAccountsService.getBasicChartOfAccounts().then(res => {
                        return res.filter(it => it.id === this.accountChartId);
                    }),
                    this.budgetRestClient.getOperatingBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id)
                ]).then(success => {
                    incomeStatement = success[0][0].incomeStatement;
                    let operatingBudget = convertJsonBudgets([success[1]], incomeStatement);
                    let selectableSubBudgets = [];
                    selectableSubBudgets.push({
                        id: 0,
                        name: "Ylätaso",
                        data: operatingBudget.data,
                        readonly: true
                    });
                    operatingBudget.subBudgets.forEach((sub) => {
                        selectableSubBudgets.push({
                            id: sub.subBudgetId,
                            name: sub.name,
                            data: sub.data,
                            readonly: false
                        });
                    });
                    resolve(selectableSubBudgets);
                });
            });
        } else {
            return this.$q((resolve, reject) => {
                this.budgetRestClient.getOperatingBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id).then(got => {
                    let operatingBudget = convertOperatingBudget(got, null);
                    this.$log.debug('Budget2ComponentService.getSelectableSubBudgets', got, operatingBudget);
                    let selectableSubBudgets = [];
                    selectableSubBudgets.push({
                        id: 0,
                        name: "Ylätaso",
                        data: operatingBudget.data,
                        readonly: true
                    });
                    operatingBudget.subBudgets.forEach((sub) => {
                        selectableSubBudgets.push({
                            id: sub.subBudgetId,
                            name: sub.name,
                            data: sub.data,
                            readonly: false
                        });
                    });
                    resolve(selectableSubBudgets);
                });
            });
        }
    }

    getOperatingBudgetData(budget) {
        let incomeStatement = null;
        if (this.accountChartId !== 1) {
            return this.$q((resolve, reject) => {
                return this.$q.all([
                    this.basicChartOfAccountsService.getBasicChartOfAccounts().then(res => {
                        return res.filter(it => it.id === this.accountChartId);
                    }),
                    this.budgetRestClient.getOperatingBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id)
                ]).then(success => {
                    incomeStatement = success[0][0].incomeStatement;
                    let operatingBudget = convertOperatingBudget(success[1], incomeStatement);
                    resolve(operatingBudget.data);
                });
            });
        } else {
            return this.$q((resolve, reject) => {
                this.budgetRestClient.getOperatingBudget(this.selectedCompanyService.getSelectedCompanyId(), budget.id).then(got => {
                    let operatingBudget = convertOperatingBudget(got, null);
                    this.$log.debug('Budget2ComponentService.getOperatingBudgetData: success', got, operatingBudget);
                    resolve(operatingBudget.data);
                });
            });
        }
    }

    getIncomeStatementChart(companyId) {
        return this.companyAccountChartService.getCompanyAccountChart(companyId).then((response) => {
            let tuloslaskelma = null;
            response.groups.forEach(function (jsonGroup) {
                if (!tuloslaskelma && jsonGroup.nameFi.replace(/\s+/g, '') === 'TULOSLASKELMA') {
                    tuloslaskelma = jsonGroup;
                }
            });
            return tuloslaskelma;
        });
    }

    getCompanyFiscalYears(companyId) {
        return this.budgetRestClient.getFiscalYears(companyId).then(res => {
            let result = [];
            res.forEach(fiscalYear => {
                result.push({startDate: new Date(fiscalYear.startDate).toISOString(), endDate: new Date(fiscalYear.endDate).toISOString()});
            })
            return result;
        });
    }

}
