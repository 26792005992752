'use strict';

angular.module('ecoeasyApp')
    .controller('AccountMappingDetailController', function ($scope, $rootScope, $stateParams, entity, AccountMapping, Company) {
        $scope.accountMapping = entity;
        $scope.load = function (id) {
            AccountMapping.get({id: id}, function (result) {
                $scope.accountMapping = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:accountMappingUpdate', function (event, result) {
            $scope.accountMapping = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
