import {BudgetTableRowType} from "./budget-table-data-row";

export class ChangedValuesContainer {

    constructor($log) {
        this.$log = $log;
        this.reset();
    }

    changeRow(row, subBudgetId) {
        if (BudgetTableRowType.SUM != row.type) {
            if (this.valuesChanged.has(subBudgetId)) {
                this.valuesChanged.get(subBudgetId).set(row.id, row.values);
            } else {
                const map = new Map();
                map.set(row.id, row.values);
                this.valuesChanged.set(subBudgetId, map)
            }
        }
        this.$log.debug('ChangedValuesContainer.changeRow', row, subBudgetId, this.valuesChanged);
    }

    getValuesToSave(currentBudget) {
        let values = [];
        this.valuesChanged.forEach((rowMap, subBudgetId) => {
            rowMap.forEach((valueRow, rowId) => {
                valueRow.forEach((value, index) => {
                    values.push({
                        groupId: rowId,
                        year: currentBudget.yearMonths[index].year,
                        month: currentBudget.yearMonths[index].month,
                        value: value,
                        costPoolId: subBudgetId
                    });
                });
            });
        });
        return values;
    }

    reset() {
        this.valuesChanged = new Map();
    }

    hasValues() {
        return this.valuesChanged.size > 0;
    }

}
