export class BasicChartOfAccountsService {

    constructor($resource, $log, BasicChartOfAccountsResource, BasicChartOfAccountsGroupsResource, BasicChartOfAccountsMeasuresResource) {
        "ngInject";
        this.BasicChartOfAccounts = BasicChartOfAccountsResource;
        this.BasicChartOfAccountsGroups = BasicChartOfAccountsGroupsResource;
        this.BasicChartOfAccountsMeasures = BasicChartOfAccountsMeasuresResource;
        this.$log = $log;
    }

    getBasicChartOfAccounts() {
        return this.BasicChartOfAccounts.query();
    }

    updateBasicChartOfAccountsCategory(updateData) {
        return this.BasicChartOfAccountsGroups
            .update(updateData.chartId, updateData.categoryId, updateData.payload);
    }

    getBasicChartMeasures(chartId) {
        return this.BasicChartOfAccountsMeasures
            .query(chartId);
    }

    addBasicChartMeasure(chartId, measure) {
        return this.BasicChartOfAccountsMeasures.save(chartId, measure);
    }

    updateBasicChartMeasure(chartId, measure) {
        return this.BasicChartOfAccountsMeasures.update(chartId, measure);
    }

    removeBasicChartMeasure(chartId, measure) {
        return this.BasicChartOfAccountsMeasures.remove(chartId, measure);
    }

}