import template from "./performance-measure-formula.html";
import "./performance-measure-formula.css";

class PerformanceMeasureFormulaComponent {

    constructor($log) {
        "ngInject";
        this.$log = $log;
        this.isOperator = false;
        this.isMeasure = false;
        this.isAccount = false;
    }

    $onInit() {
        this.$log.debug('$onInit()', this.formula);
    }
}

export default {
    bindings: {
        formula: '<'
    },
    template: template,
    controller: PerformanceMeasureFormulaComponent
}