import React, {useEffect, useState} from 'react';
import {bool, number} from 'prop-types';

import Money from "../../../shared/money";

const NumberLabel = props => {
    const { value, isThousands, showDecimals } = props;
    const [trueValue, setTrueValue] = useState( value || 0);
    useEffect(() => {
        if (Number.isFinite(value)) {
            setTrueValue(value)
        }
    });
    const [thousands, setThousands] = useState( false);
    useEffect(() => {
        if (isThousands !== thousands) {
            setThousands(isThousands);
        }
    });
    const [labelValue, setLabelValue] = useState(trueValue);
    useEffect(() => {
        if (thousands) {
            setLabelValue(Math.round(trueValue / 1000));
        } else {
            setLabelValue(trueValue);
        }
    });

    return (
        <Money value={labelValue} decimals={showDecimals} />
    );
};

NumberLabel.propTypes = {
    value: number,
    isThousands: bool,
    showDecimals: bool
};

export default NumberLabel;