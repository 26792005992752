'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('recalculate', {
                parent: 'site',
                url: '/recalculate',
                data: {
                    authorities: ['ROLE_USER_ORGANISATION', 'ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    pageTitle: 'global.menu.org.recalculate'
                },
                views: {
                    'content@': {
                        template: require('./recalculate.html'),
                        controller: 'RecalculateController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('recalculate');
                        return $translate.refresh();
                    }]
                }
            })
    });
