import {BudgetTableComponent} from './budget-table.component';
import {NumberInputComponent} from "./number-input/number-input.component";
import {NumberLabelComponent} from "./number-label/number-label.component";

export const BudgetTableModule = angular
    .module('BudgetTableModule', [])
    .component('numberLabel', NumberLabelComponent)
    .component('numberInput', NumberInputComponent)
    .component('budgetTable', BudgetTableComponent)
    .name;

