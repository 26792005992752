'use strict';

angular.module('ecoeasyApp')
    .controller('FundingBudgetDataController', function ($scope, $state, FundingBudgetData, ParseLinks) {

        $scope.fundingBudgetDatas = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function () {
            FundingBudgetData.query({
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.fundingBudgetDatas = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.fundingBudgetData = {
                budgetId: null,
                position: null,
                name: null,
                initialCashEffect: null,
                loanAmount: null,
                paymentTerm: null,
                interestPercentage: null,
                paymentFreeMonths: null,
                id: null
            };
        };
    });
