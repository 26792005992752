import template from './indicator-edit.html';

export const IndicatorEditComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class IndicatorEditComponent {
        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.indicator = null;
            this.measures = [];
            this.isSaving = false;
        }

        $onInit() {
            this.$log.debug('IndicatorEditComponent.$init: resolve', this.resolve);
            this.measures = this.resolve.measures.filter(measure => !measure.helper).map(measure => {

                measure.optionText = measure.name;
                if (measure.change) {
                    measure.optionText = measure.optionText + " [muutos]";
                }
                return measure;
            });
            this.indicator = angular.copy(this.resolve.indicator);
        }

        save() {
            this.$log.debug('IndicatorEditComponent.save');
            this.close({$value: this.indicator});
        }

        clear() {
            this.$log.debug('IndicatorEditComponent.clear');
            this.dismiss({$value: 'dismissed'});
        }

    }
};
