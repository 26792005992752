'use strict';

angular.module('ecoeasyApp').controller('SubBudgetDialogController',
    ['$scope', '$uibModalInstance', '$q', 'entity', 'BudgetService',
        function ($scope, $uibModalInstance, $q, entity, BudgetService) {

            $scope.newsb = {};
            $scope.subBudgets = [];
            window.ts = $scope;

            $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                BudgetService.getPossibleSubBudgets(company.id).then(function (subs) {
                    if (subs) {
                        $scope.subBudgets = subs;
                    }
                });
            });

            $scope.addSubBudget = function () {
                var bud = {};
                bud.name = $scope.newsb.name;
                bud.percentage = $scope.newsb.percentage;
                bud.selected = true;
                if ($scope.newsb.prevYear) {
                    bud.prevYear = true;
                }
                $scope.subBudgets.push(bud);
                $scope.newsb = {};
            }

            $scope.load = function (id) {
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:budgetUpdate', result);
                $scope.$emit('ecoeasyApp:navUpdate');
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                var buds = [];
                $scope.subBudgets.forEach(function (sub) {
                    if (sub.selected) {
                        sub.percentage = sub.percentage / 100;
                        buds.push(sub);
                    }
                });

                $scope.isSaving = true;
                BudgetService.createSubBudgets(entity.id, buds, onSaveSuccess, onSaveError);
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
