'use strict';

import 'jquery-ui/ui/widgets/sortable';

angular.module('ecoeasyApp')
    .controller('ReportingController',
        ['$scope', '$http', '$q', 'Principal', '$uibModal', '$rootScope', '$translate', '$filter', '$window',
            function ($scope, $http, $q, Principal, $uibModal, $rootScope, $translate, $filter, $window) {

                Principal.identity().then(function (account) {
                    $scope.account = account;
                    $scope.isAuthenticated = Principal.isAuthenticated;
                    $scope.showUpdateButton = Principal.hasAnyAuthority(["ROLE_ADMIN_ORGANISATION"]) || Principal.hasAnyPermission(["REPORTING"]);
                    $scope.demo = account.demo;
                });

                $scope.showAll = true;
                $scope.disableUpdateButton = false;
                $scope.searchType = '';
                $scope.searchAllways = 'yes';

                $scope.pages;
                $scope.filtered;

                window.ts = $scope;
                $scope.selectedPeriod;
                $scope.selectedMonth;
                $scope.reportForAllCostAccounts = [{id: 0, name: "Kaikki"}];
                $scope.selectAllCostAccounts = true;
                $scope.costAccounts = [];
                $scope.selectedCostAccounts = [];
                $scope.budgetPage;
                $scope.budget = 'yes';
                $scope.html = true;
                $scope.periods;
                $scope.selectedPeriodMonths;
                $scope.saveSelection = false;
                $scope.$translate = $translate;
                $scope.areaSelected = false;
                $scope.allAreas = [];
                $scope.selectedArea = {};
                $scope.hasArea = false;
                $scope.totalsSelected = false;
                $scope.noArea = {name: $translate.instant("reporting.noArea"),
                                 id: null};
                var availablePositions;
                var company;
                var currentActualizedPosition = -1;

                $q.when($scope.$root.getSelectedCompany()).then(function (comp) {
                    if (comp != null) {
                        company = comp;
                        if (company.actualizedPosition && company.actualizedPosition != null) {
                            currentActualizedPosition = company.actualizedPosition;
                        }
                        init();
                    } else {
                        $q.when($scope.$root.getDefaultCompany()).then(function (comp) {
                            if (comp != null) {
                                company = comp;
                                $scope.$root.setSelectedCompany(comp);
                                init();
                            }
                        });
                    }
                });

                function init() {
                    $http.get("/api/reports/pageset/" + company.id).then(function (response) {
                        var pgs = response.data;
                        $scope.initPages(pgs);
                        $scope.pages = pgs;

                        $scope.filtered = $filter('filter')($scope.pages, {
                            type: $scope.searchType,
                            allways: $scope.searchAllways
                        });
                    });
                    $http.get("/api/accountCategoryValueAreas/company/"+company.id).then(function (response) {
                        $scope.hasArea = true;
                        $scope.allAreas.push($scope.noArea);
                        for(var i = 0; i<response.data.length;i++) {
                            $scope.allAreas.push(response.data[i]);
                        }
                        $scope.selectedArea.item = $scope.noArea;
                    }).catch(function (){
                        $scope.hasArea = false;
                        $scope.allAreas.push($scope.noArea);
                        $scope.selectedArea.item = $scope.noArea;
                    });
                    $http.get("/api/companys/" + company.id + "/costaccounts").then(function (response) {
                        $scope.costAccountCategories = response.data;
                        if ($scope.costAccountCategories.length > 0) {
                            $scope.hasManyCostAccounts = true;
                        } else {
                            $scope.hasManyCostAccounts = false;
                        }
                    });
                    $http.get("/api/reports/" + company.id + "/availablePositions").then(function (response) {
                        availablePositions = response.data;
                        var years = [];
                        var maxYear = 0;
                        for (var key in availablePositions) {
                            if (availablePositions.hasOwnProperty(key)) {
                                years.push(key);
                                if (key > maxYear) {
                                    maxYear = key;
                                }
                            }
                        }

                        $scope.periods = years;

                        if (currentActualizedPosition > 0) {
                            var year = 0;
                            var month = 0;
                            if (currentActualizedPosition < 100000) {
                                month = currentActualizedPosition % 10;
                                year = Math.round((currentActualizedPosition - month) / 10);
                            }
                            else {
                                month = currentActualizedPosition % 100;
                                year = Math.round((currentActualizedPosition - month) / 100);
                            }
                            for (var i = 0; i < years.length; i++) {
                                if (years[i] == year) {
                                    $scope.selectedPeriod = years[i];
                                }
                            }
                            $scope.updateMonths(year, month);
                        }
                        else {
                            $scope.selectedPeriod = maxYear;
                            $scope.updateMonths(maxYear);
                        }
                        $scope.disableUpdateButton = !(company.status === "OK");
                    });

                }

                $scope.initPages = function (pgs) {
                    for (var i = 0; i < pgs.length; i++) {
                        if (pgs[i].pageType === "TWO_CHARTS_PORTRAIT" || pgs[i].pageType === "COMPARISON_TABLE" || pgs[i].pageType === "PREDICTION_SHORT_STATEMENT" ||
                            pgs[i].pageType === "COST_COMPOSITION" || pgs[i].pageType === "MONTHLY_TABLE_EMPLOYEE_EXPENSES" || pgs[i].pageType === "BUDGET_SHORT_STATEMENT" || pgs[i].pageType === "EARLY_SHORT_STATEMENT" ||
                            pgs[i].pageType === "BUDGET_SHORT" || pgs[i].pageType === "BUDGET_COMPARISON" || pgs[i].pageType === "BUDGET_COMPARISON_CASHFLOW" || pgs[i].pageType === "INCOMESTATEMENT_BUDGET_COMPARISON" ||
                            pgs[i].pageType === "MONTHLY_TABLE_GRAPH_EMPLOYEE_EXPENCES" || pgs[i].pageType === "LONG_INCOME_STATEMENT" || (pgs[i].pageType === "DYNAMIC" && pgs[i].pageBaseType !== "PIECHART")) {
                            pgs[i].type = 'notSelected';
                            pgs[i].allways = 'yes';
                        } else if (pgs[i].pageType === "COST_ACCOUNT_COMPARISON" || pgs[i].pageType === "COST_ACCOUNT_INCOME" || pgs[i].pageType === "COST_ACCOUNT_COMMISSIONED" || (pgs[i].pageType === "DYNAMIC" && pgs[i].pageBaseType === "PIECHART")) {
                            pgs[i].type = 'notSelected';
                            pgs[i].allways = 'no';
                        }
                        // else if (pgs[i].pageType === "BUDGET_SHORT_STATEMENT") {
                        //     pgs[i].allways = 'yes';
                        //     pgs[i].type = 'selected';
                        // }
                        else {
                            pgs[i].type = 'selected';
                            pgs[i].allways = 'yes';
                        }
                        pgs[i].pageNumber = i + 1;
                        if(pgs[i].pageTitle != null) {
                            pgs[i].desc = pgs[i].pageTitle;
                        } else {
                            pgs[i].desc = $translate.instant(pgs[i].descI18NCode);
                        }
                    }

                }

                $scope.addPage = function (pg) {
                    var exists = false;
                    for(var i = 0; i < $scope.pages.length; i++){
                        if(reportPageEquals(pg, $scope.pages[i])){
                            exists = true;
                            break;
                        }
                    }
                    if(exists === true){
                       alert($translate.instant("reporting.exists"));
                    }else {
                        var p = angular.copy(pg);
                        $scope.initPages([p]);
                        p.pageNumber = $scope.pages.length + 1;
                        $scope.pages.push(p);
                        $scope.filtered.push(p);
                    }

                    $scope.filtered = $filter('filter')($scope.pages, { type: $scope.searchType, allways: $scope.searchAllways });
                };

                $scope.updateMonths = function (year, preSelectedMonth) {
                    $scope.selectedPeriodMonths = availablePositions[year];

                    if (preSelectedMonth) {
                        for (var i = 0; i < availablePositions[year].length; i++) {
                            if (availablePositions[year][i] == preSelectedMonth) {
                                $scope.selectedMonth = availablePositions[year][i];
                            }
                        }
                    }
                    else {
                        var max = 0;
                        for (var i = 0; i < availablePositions[year].length; i++) {
                            var month = availablePositions[year][i];
                            if (month > max) {
                                max = month;
                            }
                        }
                        $scope.selectedMonth = max;
                    }
                    // setValidBudget(year, $scope.selectedMonth);
                };

                $scope.selectAccountCategories = function () {
                    $uibModal.open({
                        template: require('./reporting-cost-account-select.html'),
                        controller: 'CostAccountSelectController',
                        controllerAs: 'vm',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            entity: function () {
                                return $scope.costAccountCategories;
                            },
                            cid: function () {
                                return company.id;
                            },
                            area: function () {
                                return $scope.selectedArea.item;
                            }
                        },
                    }).result.then(function (selection) {
                        if(selection.area == null) {
                            $scope.selectedArea.item = null;
                            if (selection.selectAll === true) {
                                $scope.selectAllCostAccounts = true;
                                $scope.searchType = '';
                                $scope.searchAllways = 'yes';
                            } else {
                                $scope.selectAllCostAccounts = false;
                                $scope.searchType = 'notSelected';
                                $scope.searchAllways = '';
                                $scope.selectedCostAccounts = selection.selectedCostAccounts;
                            }

                            $scope.filtered = $filter('filter')($scope.pages, {
                                type: $scope.searchType,
                                allways: $scope.searchAllways
                            });
                        } else {
                            $http.get("/api/accountCategoryValueAreas/company/"+company.id).then(function (response) {
                                $scope.hasArea = true;
                                $scope.allAreas = response.data;
                                $scope.allAreas.unshift($scope.noArea);
                                for(var i = 0; i<$scope.allAreas.length;i++) {
                                    if($scope.allAreas[i].id === selection.area){
                                        $scope.selectedArea.item = $scope.allAreas[i];
                                    }
                                }
                                $scope.selectAllCostAccounts = false;
                                $scope.onAreaSelectedChange();
                            });
                        }
                    });
                };

                $scope.createAccountCategoryValueArea = function () {
                    $uibModal.open({
                        template: require('./reporting-cost-account-area.html'),
                        controller: 'CostAccountAreaController',
                        controllerAs: 'vm',
                        size: 'lg',
                        backdrop: 'static',
                        resolve: {
                            entity: function () {
                                return $scope.costAccountCategories;
                            },
                            cid: function () {
                                return company.id;
                            },
                            area: function () {
                                return $scope.selectedArea.item;
                            }
                        },
                    }).result.then(function (selection) {
                        getAllAreas(selection);

                    });
                };

                function getAllAreas(selection){
                    $http.get("/api/accountCategoryValueAreas/company/"+company.id).then(function (response) {
                        $scope.hasArea = true;
                        $scope.allAreas = response.data;
                        $scope.allAreas.unshift($scope.noArea);
                        if(selection) {
                            for (var i = 0; i < $scope.allAreas.length; i++) {
                                if ($scope.allAreas[i].id === selection.id) {
                                    $scope.selectedArea.item = $scope.allAreas[i];
                                }
                            }
                            $scope.selectAllCostAccounts = false;
                        } else{
                            $scope.selectedArea.item = $scope.noArea;
                            $scope.onAreaSelectedChange();
                        }
                    });
                }

                $scope.onAreaSelectedChange = function(){
                    var ars = $scope.selectedArea.item;
                     if(ars.id != null){
                        var area = ars;
                        var sca = [];
                        for(var i = 0; i<area.accountCategoryValues.length; i++){
                            sca.push({
                                id: area.accountCategoryValues[i].id,
                                name: area.accountCategoryValues[i].name,
                                code: area.accountCategoryValues[i].code
                            });
                        }
                        $scope.selectedCostAccounts = sca;
                        $scope.selectAllCostAccounts = false;
                        $scope.searchType = 'notSelected';
                        $scope.searchAllways = '';
                        $scope.filtered = $filter('filter')($scope.pages, { type: $scope.searchType, allways: $scope.searchAllways });
                        $scope.areaSelected = true;
                    }else{
                        $scope.selectAllCostAccounts = true;
                        $scope.searchType = '';
                        $scope.searchAllways = 'yes';
                        $scope.areaSelected = false;
                        $scope.selectedCostAccounts =[];
                        $scope.filtered = $filter('filter')($scope.pages, { type: $scope.searchType, allways: $scope.searchAllways });
                    }
                };

                $scope.onTotalsSelectedChange = function(totalsSelected){
                    $scope.totalsSelected = totalsSelected;
                };

                $scope.generateReport = function(twoDim, estimates) {
                    $scope.filtered = $filter('filter')($scope.pages, { type: $scope.searchType, allways: $scope.searchAllways });

                    if ($scope.selectAllCostAccounts === true) {
                        var costAccountIds = $scope.reportForAllCostAccounts;
                    } else {
                        var costAccountIds = $scope.selectedCostAccounts;
                    }
                    if($scope.selectedArea.item){
                        var areaName = $scope.selectedArea.item.name
                    } else{
                        var areaName="";
                    }
                    var dto = {
                        companyId: company.id,
                        costAccountIds: costAccountIds,
                        year: $scope.selectedPeriod,
                        month: $scope.selectedMonth,
                        timestamp: company.lastAccountingDataUpdate,
                        estimates: estimates,
                        saveSelection: $scope.saveSelection,
                        onlyTotals: $scope.totalsSelected,
                        pages: $scope.filtered,
                        companyName: company.name,
                        costAccountAreaName: areaName,
                        twoDimensional: twoDim
                    };

                    if($scope.selectedCostAccounts.length ===1) {
                        dto.onlyTotals=true;
                    }
                    console.log("DTO", dto);

                    // if(!$scope.html) {
                        var form = document.createElement("form");
                        form.action = "/api/reports/ecoeasy";
                        form.method = "POST";
                        form.target = "_blank";
                        form.style.display = "none";

                        var input = document.createElement("input");
                        input.type = "text";
                        input.name = "dto";
                        input.value = angular.toJson(dto);

                        var submit = document.createElement("input");
                        submit.type = "submit";
                        submit.id = "genSubmit";

                        form.appendChild(input);
                        form.appendChild(submit);
                        document.body.appendChild(form);

                        $("#genSubmit").click();

                        document.body.removeChild(form);
                        $scope.saveSelection = false;
                    // }else{
                    //     var url = "build/resources/main/pdf/report.html";
                    //     $http({
                    //         method: 'POST',
                    //         url: "/api/reports/html",
                    //         data: angular.toJson(dto)
                    //     }).then(function(){
                    //        $window.open(url, '_blank');
                    //     });
                    //
                    // }
                };

                $scope.sortableOptions = {
                    stop: function (e, ui) {
                        for (var i = 0; i < $scope.pages.length; i++) {
                            $scope.pages[i].pageNumber = i + 1;
                        }

                        for (var i = 0; i < $scope.filtered.length; i++) {
                            $scope.filtered[i].pageNumber = i + 1;
                        }
                    }
                };

                $scope.selectedId = -1;
                $scope.editDialog = function (id) {
                    $scope.selectedId = id;
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./reporting-dialog.html'),
                        controller: 'ReportingDialogController',
                        size: 'lg',
                        scope: $scope
                    });
                };

                $scope.deleteArea = function(){
                    $http.delete("/api/accountCategoryValueAreas/"+$scope.selectedArea.item.id).then(function(){
                        getAllAreas(null);
                        });
                };

                $scope.deletePage = function (page) {
                    for(var i = 0; i < $scope.filtered.length; i++){
                        if (reportPageEquals($scope.filtered[i], page)) {
                            $scope.filtered.splice(i, 1);
                            break;
                        }
                    }
                    for (var i = 0; i < $scope.pages.length; i++) {
                        if (reportPageEquals($scope.pages[i], page)) {
                            $scope.pages.splice(i, 1);
                            break;
                        }
                    }
                    for (var i = 0; i < $scope.pages.length; i++) {
                        $scope.pages[i].pageNumber = i + 1;
                    }

                    for(var i = 0; i < $scope.filtered.length; i++){
                        $scope.filtered[i].pageNumber = i + 1;
                    }
                };

                $scope.newDialog = function () {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./reporting-dialog.html'),
                        controller: 'ReportingDialogController',
                        size: 'md',
                        scope: $scope,
                        resolve: {
                            mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('reporting');
                                $translatePartialLoader.addPart('variablecodes');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $scope.filtered = $filter('filter')($scope.pages, { type: $scope.searchType, allways: $scope.searchAllways });
                    });
                };

                $scope.updateCompany = function () {
                    if (company.status === "OK") {
                        company['status'] = "UPDATE";
                        $http.put("/api/companys/status", company);
                        $scope.disableUpdateButton = true;
                    }

                };
                $scope.updateActualizedPosition = function () {

                    $http.get("/api/companys/actualizedposition", {
                        params: {id: company.id, year: $scope.selectedPeriod, month: $scope.selectedMonth}
                    })
                        .then(function (response) {
                            company.actualizedPosition = response.data.actualizedPosition;
                            currentActualizedPosition = response.data.actualizedPosition;
                            $rootScope.$broadcast('actualizedPositionChanged');
                        });
                };

                $scope.isSelectedPosActPos = function () {
                    return $scope.selectedPeriod + '' + $scope.selectedMonth == currentActualizedPosition;
                };

                function reportPageEquals(pg1, pg2) {
                    return pg1.pageTitle === pg2.pageTitle && pg1.pageType === pg2.pageType && pg1.pageBaseType === pg2.pageBaseType && reportVariablesEquals(pg1, pg2);
                };

                function reportVariablesEquals(pg1, pg2) {
                    if (pg1.pageVariables.length === pg2.pageVariables.length) {
                        if(pg1.pageVariables.length !== 0) {
                            for (var i = 0; i < pg1.pageVariables.length; i++) {
                                if(pg1.pageVariables[i].companyVariableId !== pg2.pageVariables[i].companyVariableId){
                                    return false;
                                }
                            }
                            return true;
                        } else{
                            return true;
                        }
                    } else{
                        return false;
                    }

                };
            }
        ]);
