'use strict';

angular.module('ecoeasyApp')
    .factory('CompanyDataVersion', function ($resource, DateUtils) {
        return $resource('api/companyDataVersions/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.accountingDate = DateUtils.convertLocaleDateFromServer(data.accountingDate);
                    data.insertionDate = DateUtils.convertDateTimeFromServer(data.insertionDate);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.accountingDate = DateUtils.convertLocaleDateToServer(data.accountingDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.accountingDate = DateUtils.convertLocaleDateToServer(data.accountingDate);
                    return angular.toJson(data);
                }
            }
        });
    })
    .service('CompanyDataVersionService', function ($http) {
        
        function deleteByCompany(companyId) {
            return $http.delete("/api/companyDataVersions/company/"+companyId).then(function(response){
                return response;
            });
        }
        
        return {
            deleteByCompany: deleteByCompany
        };
        
    });
