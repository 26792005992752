'use strict';

angular.module('ecoeasyApp')
    .controller('OrganisationBillingController', function ($scope, $http, $state, Company, ParseLinks, Principal, OrganisationBilling) {

        window.vvm = $scope;

        $scope.companies = null;
        $scope.users = null;
        $scope.companyUsers = null;


        $scope.loadOrganisationCompanies = function () {
            OrganisationBilling.OrganisationCompanies.query(onSuccess, onError);

            function onSuccess(data, headers) {
                $scope.companies = data;
            }

            function onError(error) {
            }
        };

        $scope.loadOrganisationUsers = function () {
            OrganisationBilling.OrganisationUsers.query(onSuccess, onError);

            function onSuccess(data, headers) {
                $scope.users = data;
            }

            function onError(error) {
            }
        };

        $scope.loadOrganisationCompanyUsers = function () {
            OrganisationBilling.OrganisationCompanyUsers.query(onSuccess, onError);

            function onSuccess(data, headers) {
                $scope.companyUsers = data;
            }

            function onError(error) {
            }
        };

        $scope.loadOrganisationCompanies();
        $scope.loadOrganisationUsers();
        $scope.loadOrganisationCompanyUsers();
    });
