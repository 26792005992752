'use strict';

angular.module('ecoeasyApp')
    .controller('ExternalAccountingModelDeleteController', function ($scope, $uibModalInstance, entity, ExternalAccountingModel) {

        $scope.externalAccountingModel = entity;
        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            ExternalAccountingModel.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    });
