import angular from 'angular';
import {BasicChartOfAccountsComponent} from './basic-chart-of-accounts.component';
import {BasicChartOfAccountsService} from "./basic-chart-of-accounts.service";
import ngResource from 'angular-resource';
import {ChartService} from './chart.service';
import {ChartTableComponent} from "./chart-table/chart-table.component";
import {BasicChartOfAccountsResource} from "./basic-chart-of-accounts-resource.service";
import {BasicChartOfAccountsGroupsResource} from "./basic-chart-of-accounts-groups-resource.service";
import {BasicChartOfAccountsMeasuresResource} from "./basic-chart-of-accounts-measures-resource.service";
import {IndicatorListComponent} from "./indicators/indicator-list.component";
import {IndicatorEditComponent} from "./indicators/indicator-edit.component";

export const BasicChartOfAccountsModule = angular
    .module(
        'basic-chart-of-accounts',
        [ngResource]
    )
    .config(function ($stateProvider) {
        $stateProvider.state({
            parent: 'site',
            name: 'basicChartOfAccounts',
            url: '/basic-chart-of-accounts',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.title'
            },
            views: {
                'content@': {
                    template: '<basic-chart-of-accounts>basicChartOfAccounts</basic-chart-of-accounts>'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('basicChartOfAccounts');
                    $translatePartialLoader.addPart('performance-measures');
                    $translatePartialLoader.addPart('indicator');
                    return $translate.refresh();
                }]
            }
        });
    })
    .service('BasicChartOfAccountsService', BasicChartOfAccountsService)
    .service('ChartService', ChartService)
    .service('BasicChartOfAccountsResource', BasicChartOfAccountsResource)
    .service('BasicChartOfAccountsGroupsResource', BasicChartOfAccountsGroupsResource)
    .service('BasicChartOfAccountsMeasuresResource', BasicChartOfAccountsMeasuresResource)
    .component('chartTable', ChartTableComponent)
    .component('basicChartOfAccounts', BasicChartOfAccountsComponent)
    .component('indicatorList', IndicatorListComponent)
    .component('indicatorEdit', IndicatorEditComponent)
    .name;

