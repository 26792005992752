'use strict';

angular.module('ecoeasyApp').controller('DevaluationBudgetDataDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DevaluationBudgetData', 'CompanyVariable',
        function ($scope, $stateParams, $uibModalInstance, entity, DevaluationBudgetData, CompanyVariable) {

            $scope.devaluationBudgetData = entity;
            $scope.companyvariables = CompanyVariable.query();
            $scope.load = function (id) {
                DevaluationBudgetData.get({id: id}, function (result) {
                    $scope.devaluationBudgetData = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:devaluationBudgetDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.devaluationBudgetData.id != null) {
                    DevaluationBudgetData.update($scope.devaluationBudgetData, onSaveSuccess, onSaveError);
                } else {
                    DevaluationBudgetData.save($scope.devaluationBudgetData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
