angular.module('ecoeasyApp')
    .service('ManualImportService', function ($http, $q, $rootScope, $log) {

        function getAvailableSources() {
            return $q.when($rootScope.getSelectedCompany()).then(function (company) {
                return $http.get("/api/manualimport/" + company.id + "/availablesystems").then(function (response) {
                    return response.data;
                });
            });
        }

        function sendFile(sourceSystem, file, onSuccess, onError) {
            return $q.when($rootScope.getSelectedCompany()).then(function (company) {
                var data = {
                    sourceSystemId: sourceSystem.id
                }
                var r = new FileReader();
                r.onloadend = function (event) {
                    var fileData = event.target.result;
                    var uInt8Array = new Uint8Array(fileData);
                    var byteArray = [];
                    for (var i = 0; i < uInt8Array.length; ++i) {
                        byteArray.push(uInt8Array[i]);
                    }
                    data.sourceFile = byteArray;

                    $http.post("/api/manualimport/" + company.id + "/import", data).then(onSuccess, onError);
                }
                r.readAsArrayBuffer(new Blob([file]));
            });
        }

        return ({
            getAvailableSources: getAvailableSources,
            sendFile: sendFile
        });
    });
