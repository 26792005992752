'use strict';

angular.module('ecoeasyApp')
    .controller('CompanyIndicatorDetailController', function ($scope, $rootScope, $stateParams, entity, Indicator, CompanyVariable) {
        $scope.indicator = entity;
        $scope.load = function (id) {
            Indicator.get({id: id}, function (result) {
                $scope.indicator = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:indicatorUpdate', function (event, result) {
            $scope.indicator = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
