import React, {useEffect, useState} from 'react';
import {number} from 'prop-types';
import DepreciationTable from "./depreciation-table";
import {getBasicChartOfAccountsData, getDepreciations} from "../../../service/react-service";
import {useTranslation} from "react-i18next";
import {firstCharToUpper, getInvestmentAccountOptions} from "../budget-utils";

const DepreciationWrapper = ({companyId, budgetId, accountChartId}) => {
    const [depreciationId, setDepreciationId] = useState(null);
    const [investments, setInvestments] = useState([]);
    const [balanceSheetAccounts, setBSAccounts] = useState([]);
    const [accountOptions, setAccountOptions] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const {t, i18n} = useTranslation('depreciation');

    const localNameKey = `name${firstCharToUpper(i18n.language)}`;

    useEffect(() => {
        setLoadingData(true);
        getBasicChartOfAccountsData().then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                const liikekirjuri = res.data.find(item => item.id === accountChartId);
                liikekirjuri && getInvestmentAccountOptions(liikekirjuri.balanceSheet, setAccountOptions, localNameKey);
            }
        }).catch(err => console.log(err));
        getDepreciations(companyId, budgetId).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
            } else {
                if (res.data.investmentDepreciations.length > 0) setInvestments(res.data.investmentDepreciations);
                if (res.data.balanceSheetDepreciations.length > 0) setBSAccounts(res.data.balanceSheetDepreciations);
                if (res.data.id) setDepreciationId(res.data.id);
            }
        }).catch(err => console.log(err));
        setLoadingData(false);
    }, [budgetId]);

    return (
        <>
            {(investments || balanceSheetAccounts) &&
            <>
                <h3>{t('main.title')}</h3>
                <DepreciationTable
                    companyId={companyId}
                    budgetId={budgetId}
                    investments={investments}
                    balanceSheetData={balanceSheetAccounts}
                    options={accountOptions}
                    depreciationId={depreciationId}
                />
            </>
            }
        </>
    );
}

DepreciationWrapper.propTypes = {
    companyId: number.isRequired,
    budgetId: number.isRequired,
    accountChartId: number.isRequired
};

export default DepreciationWrapper;