'use strict';

angular.module('ecoeasyApp')
    .controller('UserManagementDetailController', function ($scope, $stateParams, UserService, sourceState) {
        $scope.user = {};
        $scope.state = setState();

        $scope.load = function (userId) {
            $scope.user = UserService.getById(userId)
                .then(function (result) {
                    $scope.user = result;
                });
        };
        $scope.load($stateParams.userId);

        function setState() {
            if (sourceState === "ORG") {
                return "org-user-management";
            } else if (sourceState === "ADMIN") {
                return "user-management";
            } else {
                return "company-user-management";
            }
        }
    });
