'use strict';

angular.module('ecoeasyApp')
    .controller('SourceSystemDetailController', function ($scope, $rootScope, $stateParams, entity, SourceSystem, Company, AgentConfiguration) {
        $scope.sourceSystem = entity;
        $scope.load = function (id) {
            SourceSystem.get({id: id}, function (result) {
                $scope.sourceSystem = result;
            });
        };
        var unsubscribe = $rootScope.$on('ecoeasyApp:sourceSystemUpdate', function (event, result) {
            $scope.sourceSystem = result;
        });
        $scope.$on('$destroy', unsubscribe);

    });
