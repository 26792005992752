import React, {useState} from 'react';
import {Alert, Button, Modal} from 'react-bootstrap';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {getFundingData, editFunding} from '../../../service/react-service';
import MultipleMonthCheckboxInput from '../../shared/inputs/multiple-month-checkbox-input';
import RadioButtonInput from '../../shared/inputs/radio-button-input';
import SingleLineInput from '../../shared/inputs/single-line-input';
import SingleMonthPicker from '../../shared/inputs/single-month-picker';
import MultipleLineInput from '../../shared/inputs/multiple-line-input';
import {getFirstAmorizationDate} from "../budget-utils";
import {getFreePeriodInMonths} from "../../../utils/util";

const EditFundingModal = ({
                              showModal,
                              setShowModal,
                              setFundingData,
                              companyId,
                              budgetId,
                              funding,
                              setFunding,
                              actualDate
                          }) => {
    const {t} = useTranslation(['fundingModal', 'fundingForm', 'fundingOptionalValues']);
    const [errorMessage, setErrorMessage] = useState();

    const handleCloseModal = () => {
        setFunding();
        setShowModal(false);
    };

    const handleEditFunding = (values) => {
        const funding = {
            id: values.funding_id,
            name: values.funding_name,
            year: values.withdrawal_date.getFullYear(),
            month: (values.withdrawal_date.getMonth() + 1),
            amount: parseFloat(values.funding_amount),
            capitalCategory: values.capital_category,
            termInMonths: parseInt(values.loan_time),
            freePeriodInMonths: getFreePeriodInMonths(values.withdrawal_date, values.first_amorization_date),
            annualPaymentMonths: [...new Set(values.amorizations_per_year.sort((a, b) => {
                return a - b
            }))],
            annualInterestRate: parseFloat(values.yearly_interest),
            repaymentMethod: values.amorization_type,
            description: values.funding_description !== undefined ? values.funding_description : '',
        };

        editFunding(companyId, budgetId, funding).then((res, rej) => {
            if (rej !== undefined) {
                console.log(rej);
                setErrorMessage(t('fundingModal:errorMessage.general'));
            } else {
                getFundingData(companyId, budgetId).then((res, rej) => {
                    if (rej !== undefined) {
                        console.log(rej);
                    } else {
                        setFundingData(res.data);
                        setShowModal(false);
                    }
                });
            }
        });
    };

    const capitalCategoryOptions = [
        {
            value: 'loan',
            translation: 'fundingOptionalValues:capitalCategory.loan',
            key: 'loan-lable-key'
        },
        {
            value: 'capital',
            translation: 'fundingOptionalValues:capitalCategory.capital',
            key: 'capital-label-key',
        },
    ];

    if (funding !== undefined) {
        const withdrawDate = new Date(funding.year, (funding.month - 1));
        const firstAmorizationDate = getFirstAmorizationDate(funding.year, funding.month - 1, funding.freePeriodInMonths);

        const actualizedDate = new Date(actualDate.year, actualDate.month);

        return (
            <Formik
                enableReinitialize
                initialValues={{
                    funding_id: funding.id,
                    funding_name: funding.name,
                    funding_description: funding.description,
                    capital_category: funding.capitalCategory,
                    withdrawal_date: withdrawDate,
                    funding_amount: funding.amount, //add language specific
                    loan_time: funding.termInMonths,
                    first_amorization_date: firstAmorizationDate,
                    amorizations_per_year: funding.annualPaymentMonths,
                    yearly_interest: funding.annualInterestRate, //add language specific
                    amorization_type: funding.repaymentMethod,
                }}
                validationSchema={Yup.object({
                    funding_id: Yup.string(),
                    funding_name: Yup.string()
                        .required(t('fundingForm:inputValidation.required.fundingName'))
                        .max(32, t('fundingForm:inputValidation.maxLength.fundingName')),
                    funding_description: Yup.string()
                        .max(128, t('fundingForm:inputValidation.maxLength.description')),
                    capital_category: Yup.string()
                        .required(t('fundingForm:inputValidation.required.capitalCategory')),
                    withdrawal_date: Yup.date()
                        .min(actualizedDate, t('fundingForm:inputValidation.earlierThanCheck.withdrawalDate'))
                        .required(t('fundingForm:inputValidation.required.withdrawalDate'))
                        .typeError(t('fundingForm:inputValidation.required.withdrawalDate')),
                    funding_amount: Yup.number()
                        .required(t('fundingForm:inputValidation.required.fundingAmount'))
                        .positive(t('fundingForm:inputValidation.positiveAmountCheck.fundingAmount')),
                    loan_time: Yup.number()
                        .required(t('fundingForm:inputValidation.required.loanTime'))
                        .positive(t('fundingForm:inputValidation.positiveAmountCheck.loanTime'))
                        .integer(t('fundingForm:inputValidation.integerCheck.loanTime')),
                    first_amorization_date: Yup.date()
                        .required(t('fundingForm:inputValidation.required.firstAmorizationDate'))
                        .typeError(t('fundingForm:inputValidation.required.firstAmorizationDate'))
                        .min(Yup.ref('withdrawal_date'), t('fundingForm:inputValidation.earlierThanCheck.firstAmorizationDate')),
                    amorizations_per_year: Yup.array()
                        .min(1, t('fundingForm:inputValidation.required.amorizationsPerYear'))
                        .test({
                            name: 'max',
                            exclusive: false,
                            params: {},
                            message: t('fundingForm:inputValidation.maxLength.amortizationsPerYear'),
                            test: function (value) {
                                const testValue = [...new Set(value)]
                                return testValue.length <= parseFloat(this.parent.loan_time)
                            }
                        }),
                    yearly_interest: Yup.number()
                        .required(t('fundingForm:inputValidation.required.yearlyInterest'))
                        .positive(t('fundingForm:inputValidation.positiveAmountCheck.yearlyInterest'))
                })}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setTimeout(() => {
                        handleEditFunding(values);
                        setSubmitting(false);
                        if (errorMessage === undefined) {
                            resetForm({values: ''});
                        }
                    }, 400);
                }}
            >
                {({values}) => (
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('fundingModal:title.editFunding')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {errorMessage !== undefined
                                    ? <Alert bsStyle='danger'>(errorMessage)</Alert>
                                    : <div></div>}
                                <SingleLineInput
                                    label={t('fundingForm:label.fundingName')}
                                    id={'fundingName-edit'}
                                    name='funding_name'
                                    type='text'
                                    placeholder={t('fundingForm:placeholder.fundingName')}
                                />
                                <MultipleLineInput
                                    label={t('fundingForm:label.description')}
                                    id={'description-edit'}
                                    name='funding_description'
                                />
                                <RadioButtonInput
                                    label={'fundingForm:label.capitalCategory'}
                                    options={capitalCategoryOptions}
                                    id={'capitalCategory-edit'}
                                    name='capital_category'
                                />
                                <SingleMonthPicker
                                    label={t('fundingForm:label.withdrawalDate')}
                                    id={'withdrawalDate-edit'}
                                    name='withdrawal_date'
                                />
                                <SingleLineInput
                                    label={t('fundingForm:label.fundingAmount')}
                                    id={'fundingAmount-edit'}
                                    name='funding_amount'
                                    type='number'
                                    placeholder='0,00'
                                />
                                <SingleLineInput
                                    label={t('fundingForm:label.loanTime')}
                                    id={'loanTime-edit'}
                                    name='loan_time'
                                    type='number'
                                    placeholder='0'
                                />
                                <SingleMonthPicker
                                    label={t('fundingForm:label.firstAmorizationDate')}
                                    id={'firstAmorizationDate-edit'}
                                    name='first_amorization_date'
                                />
                                <MultipleMonthCheckboxInput
                                    label={t('fundingForm:label.amorizationMonths')}
                                    id={'amorizationPerYear-edit'}
                                    name='amorizations_per_year'
                                />
                                <SingleLineInput
                                    label={t('fundingForm:label.yearlyInterest')}
                                    id={'yearlyInterest-edit'}
                                    name='yearly_interest'
                                    type='number'
                                    placeholder='0,00'
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={handleCloseModal}>
                                    <span className='glyphicon glyphicon-ban-circle'/> {t('fundingModal:button.cancel')}
                                </Button>
                                <Button bsStyle="primary"
                                        type='submit'>{t('fundingModal:button.saveEditedFunding')}</Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        );
    } else {
        return (
            <div/>
        );
    }
}

export default EditFundingModal;