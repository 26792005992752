import template from './number-input.html';

export const NumberInputComponent = {
    bindings: {
        row: '<',
        columnIndex: '<',
        value: '<',
        isThousands: '<',
        onValueChange: '&'
    },
    template: template,
    controller: class NumberInputComponent {

        constructor($log) {
            "ngInject";
            this.$log = $log;
            this.trueValue = 0;
            this.inputValue = this.trueValue;
            this.thousands = false;
            this.numberPattern = /^([+-]|([+-]?[1-9]\d*|0))$/;
        }

        $onInit() {
            //this.$log.debug('NumberInputComponent.$onInit');
        }

        $onChanges(changesObj) {
            //this.$log.debug('NumberInputComponent.$onChanges: changesObj', changesObj);
            if (changesObj.value) {
                this.trueValue = changesObj.value.currentValue;
                this._changeInputValue((this.thousands) ? Math.round(this.trueValue / 1000) : this.trueValue);
            }
            if (changesObj.isThousands) {
                this._toggleThousands(changesObj.isThousands.currentValue);
            }
        }

        _changeTrueValue(newValue) {
            this.trueValue = newValue;
            this.onValueChange({row: this.row, index: this.columnIndex, newValue: this.trueValue});
        }

        _changeInputValue(newValue) {
            this.inputValue = newValue;
        }

        _toggleThousands(newValue) {
            this.thousands = newValue;
            this._changeInputValue((this.thousands) ? Math.round(this.trueValue / 1000) : this.trueValue);
        }

        userChangedTheValue() {
            //this.$log.debug('NumberInputComponent.userChangedTheValue: this.inputValue, this.trueValue', this.inputValue, this.trueValue);
            if (this.inputValue) {
                const parsedInt = parseInt(this.inputValue, 10);
                if (parsedInt) {
                    this._changeTrueValue((this.thousands) ? 1000*parsedInt : parsedInt);
                }
            } else {
                const zeroValue = 0;
                this._changeInputValue(zeroValue);
                if (this.trueValue != zeroValue) {
                    this._changeTrueValue(zeroValue);
                }
            }
        }

        verifyItsNumber() {
            //this.$log.debug('NumberInputComponent.verifyItsNumber: this.inputValue, this.trueValue', this.inputValue, this.trueValue);
            const zeroValue = 0;
            if (this.inputValue) {
                const parsedInt = parseInt(this.inputValue, 10);
                if (parsedInt) {
                    // it is number, all ok
                } else {
                    this._changeInputValue(zeroValue);
                    this._changeTrueValue(zeroValue);
                }
            } else {
                this._changeInputValue(zeroValue);
                this._changeTrueValue(zeroValue);
            }
        }

    }

}