'use strict';

angular.module('ecoeasyApp')
        .factory('FiscalYear', function ($resource, DateUtils) {
            return $resource('api/fiscalYears/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocaleDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateFromServer(data.endDate);
                        return data;
                    }
                },
                'update': {
                    method: 'PUT',
                    transformRequest: function (data) {
                        data.startDate = DateUtils.convertLocaleDateToServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateToServer(data.endDate);
                        return angular.toJson(data);
                    }
                },
                'save': {
                    method: 'POST',
                    transformRequest: function (data) {
                        data.startDate = DateUtils.convertLocaleDateToServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateToServer(data.endDate);
                        return angular.toJson(data);
                    }
                },
                'current': {
                    method: 'GET',
                    url: 'api/fiscalYears/company/:companyId/current',
                    isArray: false,
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocaleDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateFromServer(data.endDate);
                        return data;
                    }
                },
                'position': {
                    method: 'GET',
                    url: 'api/fiscalYears/company/:companyId/position/:position',
                    isArray: false,
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocaleDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateFromServer(data.endDate);
                        return data;
                    }
                },
                'all': {
                    method: 'GET',
                    url: 'api/fiscalYears/company/:companyId', 
                    isArray: true
                },
                'saveAndRecalculate': {
                    method: 'POST',
                    url: 'api/fiscalYears/saveAndRecalculate',
                    transformRequest: function (data) {
                        data.startDate = DateUtils.convertLocaleDateToServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateToServer(data.endDate);
                        return angular.toJson(data);
                    }
                },
                'updateAndRecalculate': {
                    method: 'PUT',
                    url: 'api/fiscalYears/saveAndRecalculate',
                    transformRequest: function (data) {
                        data.startDate = DateUtils.convertLocaleDateToServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateToServer(data.endDate);
                        return angular.toJson(data);
                    }
                },
                'updateOrUpdateAndRecalculate': {
                    method: 'PUT',
                    url: 'api/fiscalYears/saveOrSaveAndRecalculate',
                    transformRequest: function (data) {
                        data.startDate = DateUtils.convertLocaleDateToServer(data.startDate);
                        data.endDate = DateUtils.convertLocaleDateToServer(data.endDate);
                        return angular.toJson(data);
                    }
                }
            });
        });
