import angular from 'angular';
import {companyIncomeStatementComponent} from "./company-income-statement.component";
import {CompanyIncomeStatementService} from "./company-income-statement.service";
import {IncomeStatementRestClientService} from "./income-statement-rest-client.service";

export const CompanyIncomeStatementModule =
    angular.module("company-income-statement-module", [])
        .service("IncomeStatementRestClientService", IncomeStatementRestClientService)
        .service("CompanyIncomeStatementService", CompanyIncomeStatementService)
        .component("companyIncomeStatement", companyIncomeStatementComponent)
        .name;