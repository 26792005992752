'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('organisationCompanyIndicator', {
                parent: 'entity',
                url: '/organisationCompanyIndicators',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.organisationCompanyIndicator.home.title'
                },
                views: {
                    'content@': {
                        template: require('./organisationCompanyIndicators.html'),
                        controller: 'OrganisationCompanyIndicatorController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('organisationCompanyIndicator');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('organisationCompanyIndicator.detail', {
                parent: 'entity',
                url: '/organisationCompanyIndicator/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.organisationCompanyIndicator.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./organisationCompanyIndicator-detail.html'),
                        controller: 'OrganisationCompanyIndicatorDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('organisationCompanyIndicator');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OrganisationCompanyIndicator', function ($stateParams, OrganisationCompanyIndicator) {
                        return OrganisationCompanyIndicator.get({id: $stateParams.id});
                    }]
                }
            })
            .state('organisationCompanyIndicator.new', {
                parent: 'organisationCompanyIndicator',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./organisationCompanyIndicator-dialog.html'),
                        controller: 'OrganisationCompanyIndicatorDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('organisationCompanyIndicator', null, {reload: true});
                    }, function () {
                        $state.go('organisationCompanyIndicator');
                    })
                }]
            })
            .state('organisationCompanyIndicator.edit', {
                parent: 'organisationCompanyIndicator',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./organisationCompanyIndicator-dialog.html'),
                        controller: 'OrganisationCompanyIndicatorDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['OrganisationCompanyIndicator', function (OrganisationCompanyIndicator) {
                                return OrganisationCompanyIndicator.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('organisationCompanyIndicator', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('organisationCompanyIndicator.delete', {
                parent: 'organisationCompanyIndicator',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./organisationCompanyIndicator-delete-dialog.html'),
                        controller: 'OrganisationCompanyIndicatorDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OrganisationCompanyIndicator', function (OrganisationCompanyIndicator) {
                                return OrganisationCompanyIndicator.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('organisationCompanyIndicator', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
