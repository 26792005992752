import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ControlLabel, FormGroup, Glyphicon} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import fi from 'date-fns/locale/fi';
import en from 'date-fns/locale/en-GB';
import sv from 'date-fns/locale/sv'
import './single-month-picker.css';

const SingleMonthPicker = ({id, label, setValue, ...props}) => {
    const {t, i18n} = useTranslation('inputs');

    if (i18n.language === 'fi') {
        registerLocale('fi', fi);
    } else if (i18n.language === 'en') {
        registerLocale('en', en);
    } else {
        registerLocale('sv', sv);
    }

    const errorStyle = {
        fontSize: '12px',
        color: 'red',
        paddingTop: '0.5rem',
    };

    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(props);

    return (
        <FormGroup
            controlId={id}
        >
            <ControlLabel>{label}</ControlLabel>
            <br/>
            <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || new Date()}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
                dateFormat='MM/yyyy'
                showMonthYearPicker
                placeholderText={t('singleMonthPicker.dateFormat')}
                locale={i18n.language}
            />
            {meta.touched && meta.error ? (
                <div className="errormessage" style={errorStyle}><Glyphicon glyph="remove"/> {meta.error}</div>
            ) : null}
        </FormGroup>
    );
}

export default SingleMonthPicker;