'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('org-user-management', {
                parent: 'site',
                url: '/org-user-management',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_ORGANISATION'],
                    pageTitle: 'user-management.home.title'
                },
                views: {
                    'content@': {
                        template: require('../admin/user-management/user-management.html'),
                        controller: 'OrganisationUserManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        $translatePartialLoader.addPart('company');
                        return $translate.refresh();
                    }],
                    sourceState: function () {
                        return "ORG";
                    }
                }
            })

            .state('org-user-management-detail', {
                parent: 'org-user-management',
                url: '/user/:userId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_ORGANISATION'],
                    pageTitle: 'user-management.detail.title'
                },
                views: {
                    'content@': {
                        template: require('../admin/user-management/user-management-detail.html'),
                        controller: 'UserManagementDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    sourceState: function () {
                        return "ORG";
                    }
                }
            })

            .state('org-user-management.new', {
                parent: 'org-user-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_ORGANISATION'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('../admin/user-management/user-management-dialog.html'),
                        controller: 'UserManagementDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null, login: null, firstName: null, lastName: null, email: null,
                                    activated: true, langKey: null, createdBy: null, createdDate: null,
                                    lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                    resetKey: null, authorities: []
                                };
                            },
                            companyId: [function () {
                                return -1;
                            }],
                            sourceState: function () {
                                return "ORG";
                            },
                            executionMethod: function () {
                                return "ADD";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('org-user-management', null, {reload: true});
                    }, function () {
                        $state.go('org-user-management');
                    })
                }]
            })
            .state('org-user-management.edit', {
                parent: 'org-user-management',
                url: '/{user_id}/{organisation_id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_ORGANISATION'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('../admin/user-management/user-management-dialog.html'),
                        controller: 'UserManagementDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['UserService', function (UserService) {
                                return UserService.getById($stateParams.user_id);
                            }],
                            organisation_id: [function () {
                                return $stateParams.orgId;
                            }],
                            companyId: [function () {
                                return -1;
                            }],
                            sourceState: function () {
                                return "ORG";
                            },
                            executionMethod: function () {
                                return "EDIT";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('org-user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('org-user-management.delete', {
                parent: 'org-user-management',
                url: '/{userId}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    permissions: ['USER_ORGANISATION'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('../admin/user-management/user-management-delete-dialog.html'),
                        controller: 'user-managementDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['UserService', function (UserService) {
                                return UserService.getById($stateParams.userId);
                            }],
                            sourceState: function () {
                                return "ORG";
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('org-user-management', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })

        /*
            .state('org-user-management-permission-dialog', {
                parent: 'org-user-management',
                url: '/{login}/permission',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    pageTitle: 'user-management.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/permission-management/permission-management-dialog.html',
                        controller: 'PermissionManagementDialogController'                                   
                    }
                },
                params: {
                     type: "User"
                },
                
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }],
                    
                    targetEntity: function($stateParams, User){
                        return User.get({login : $stateParams.login}).$promise;
                    },
                    permissions: function(Principal, Permission){
                        return Permission.query().$promise; 
                    },
                    sourceState: function(){
                        return "ORG";
                    }
                    
                }
            })  */
        ;

    });
