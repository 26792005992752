import angular from 'angular';
import {CompanyAccountChartComponent} from './company-account-chart.component';
import {CompanyAccountChartEditDialogController} from "./company-account-chart-edit-dialog.controller";
import {CompanyAccountChartService} from "./company-account-chart.service";
import ngResource from 'angular-resource';
import {CompanyChartService} from './company-chart.service';

export const CompanyAccountChartModule = angular
    .module(
        'company-account-chart',
        [ngResource]
    )
    .config(function ($stateProvider) {
        $stateProvider
            .state({
                parent: 'site',
                name: 'companyAccountChart',
                url: '/company-account-chart',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: '<company-account-chart>companyAccountChart</company-account-chart>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('companyAccountChart');
                        $translatePartialLoader.addPart('performance-measures');
                        return $translate.refresh();
                    }]
                }
            });
    })
    .service('CompanyAccountChartService', CompanyAccountChartService)
    .service('CompanyChartService', CompanyChartService)
    .component('companyAccountChart', CompanyAccountChartComponent)
    .controller('CompanyAccountChartEditDialogController', CompanyAccountChartEditDialogController)
    .name;

