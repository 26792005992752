'use strict';

angular.module('ecoeasyApp').controller('AccountMappingDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', '$http', '$q', 'entity', 'AccountMapping', 'Company', 'LinkingTable', 'LinkingTableRule',
        function ($scope, $stateParams, $uibModalInstance, $http, $q, entity, AccountMapping, Company, LinkingTable, LinkingTableRule) {

            $scope.accountMapping = entity;
            $scope.accountToNameMap = {};
            $scope.linkingTables = LinkingTable.query();
            $scope.selectedTable;
            window.tts = $scope;
            $scope.selectedDestAccount = "";

            $scope.loadNames = function () {
                $http.get("/api/accountingModels/accountToNameMap/" + $scope.$root.getSelectedCompany().id)
                    .then(function (response) {
                        $scope.accountToNameMap = response.data;
                    });
                if ($scope.accountMapping.$promise) {
                    $scope.accountMapping.$promise.then(function () {
                        $http.get("/api/externalAccountingModels/byaccount/" + $scope.$root.getSelectedCompany().id + "/" + $scope.accountMapping.sourceAccount)
                            .then(function (response) {
                                $scope.accountMapping.srcName = response.data.name;
                            })
                        $scope.selectedDestAccount = "" + $scope.accountMapping.destAccount;
                    })
                }
            }
            $scope.loadNames();

            $scope.load = function (id) {
                AccountMapping.get({id: id}, function (result) {
                    $scope.accountMapping = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:accountMappingUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;

                $q.when($scope.$root.getSelectedCompany()).then(function (company) {
                    $scope.accountMapping.companyId = company.id;

                    if ($scope.selectedDestAccount !== "") {
                        var account = parseInt($scope.selectedDestAccount);
                        $scope.accountMapping.destAccount = account;
                    }
                    else {
                        $scope.accountMapping.destAccount = null;
                        $scope.accountMapping.mappingStatus = "NOT_RESOLVED";
                    }

                    if ($scope.accountMapping.destAccount && $scope.accountMapping.destAccount > 0) {
                        $scope.accountMapping.mappingStatus = "RESOLVED";
                    }
                    if ($scope.accountMapping.id != null) {
                        AccountMapping.update($scope.accountMapping, onSaveSuccess, onSaveError);
                    } else {
                        AccountMapping.save($scope.accountMapping, onSaveSuccess, onSaveError);
                    }
                    if ($scope.selectedTable) {
                        var tableRule = {
                            sourceAccount: $scope.accountMapping.sourceAccount,
                            sourceAccountName: $scope.accountMapping.srcName,
                            destAccount: $scope.accountMapping.destAccount,
                            destAccountName: $scope.accountToNameMap[$scope.accountMapping.destAccount],
                            linkingTable: {
                                id: $scope.selectedTable.id
                            }
                        }
                        $scope.selectedTable.linkingTableRules.push(tableRule);
                        LinkingTable.update($scope.selectedTable);
                    }
                });

            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
