'use strict';

angular.module('ecoeasyApp')
    .directive('convertToNumber', function() {
        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function(val) {
                    return '' + val;
                });
            }
        };
    })
    .controller('CustomerDialogController', ['$scope', '$uibModalInstance', '$http', '$q', 'companyId', 'orc', 'Country', 'Principal', 'LinkingTable', 'IndustryService', 'FEATURE_COMPANY_ACCOUNT_CHART', 'BasicChartOfAccountsService', 'FEATURE_KIINTEISTOKIRJURI', 'FEATURE_YHDISTYSKIRJURI',
        function ($scope, $uibModalInstance, $http, $q, companyId, orc, Country, Principal, LinkingTable, IndustryService, FEATURE_COMPANY_ACCOUNT_CHART, BasicChartOfAccountsService, FEATURE_KIINTEISTOKIRJURI, FEATURE_YHDISTYSKIRJURI) {
            Principal.identity().then(function (account) {
                $scope.account = account;
                $scope.orc = orc;
                $scope.customer;
                $scope.posAccountants;
                $scope.posSourceSystems;
                $scope.linkingTables = LinkingTable.query();
                $scope.industries = [];
                $scope.getData = false;
                $scope.hasYTJError = false;

                IndustryService.getActualIndustries().then(function (data) {
                    $scope.industries = data;
                });

                if (companyId !== null) {
                    $http.get("/api/customer/" + companyId + "/" + $scope.orc).then(function (response) {
                        $scope.customer = response.data;
                        $q.when($scope.linkingTables.$promise).then(function (tables) {
                            if ($scope.customer.company.linkingTable && $scope.customer.company.linkingTable != null) {
                                var id = $scope.customer.company.linkingTable.id;
                                for (var i = 0; i < $scope.linkingTables.length; i++) {
                                    if (id === $scope.linkingTables[i].id) {
                                        $scope.customer.company.linkingTable = $scope.linkingTables[i];
                                    }
                                }
                            }
                        });
                    });
                }
                else {
                    $scope.customer = {
                        licenseStatus: "BASIC",
                        company: {
                            linkingTable: null
                        }
                    };
                }
                $http.get("api/accountans/" + $scope.orc).then(function (response) {
                    response.data.sort(function(a, b) {
                        return a.lastName.localeCompare(b.lastName) ||
                            a.firstName.localeCompare(b.firstName)
                    });
                    $scope.posAccountants = response.data;
                });
                $http.get("api/sourcesystemdtos/").then(function (response) {
                    $scope.posSourceSystems = response.data;
                });
            });

            $scope.FEATURE_COMPANY_ACCOUNT_CHART = FEATURE_COMPANY_ACCOUNT_CHART;

            $scope.availableBasicChartOptions = [];

            if ($scope.FEATURE_COMPANY_ACCOUNT_CHART) {
                BasicChartOfAccountsService.getBasicChartOfAccounts().then((data)=>{
                    let options = data;
                    if (!FEATURE_KIINTEISTOKIRJURI) {
                        options = options.filter(it => it.name !== 'Kiinteistökirjuri');
                    }
                    if (!FEATURE_YHDISTYSKIRJURI) {
                        options = options.filter(it => it.name !== 'Yhdistyskirjuri');
                    }
                    $scope.availableBasicChartOptions = options;
                });
            }

            $scope.countrys = Country.query();

            $scope.isSsSelected = function () {
                if ($scope.customer !== null && $scope.customer !== "unknown") {
                    if ($scope.customer.sourceSystem !== null && $scope.customer.sourceSystem !== "unknown") {
                        return true;
                    }
                }
                return false;
            };
            $scope.isFivaldi = function () {
                if ($scope.customer !== null && $scope.customer !== "unknown") {
                    if ($scope.customer.sourceSystem &&
                        $scope.customer.sourceSystem !== null &&
                        $scope.customer.sourceSystem !== "unknown") {
                        if ($scope.customer.sourceSystem.name !== null && $scope.customer.sourceSystem.name !== "unknown" && $scope.customer.sourceSystem.name == "Fivaldi") {
                            return true;
                        }
                    }
                }
                return false;
            };
            $scope.isBriox = function () {
                if ($scope.customer !== null && $scope.customer !== "unknown") {
                    if ($scope.customer.sourceSystem &&
                        $scope.customer.sourceSystem !== null &&
                        $scope.customer.sourceSystem !== "unknown") {
                        if ($scope.customer.sourceSystem.name !== null && $scope.customer.sourceSystem.name !== "unknown" && $scope.customer.sourceSystem.name == "Briox") {
                            return true;
                        }
                    }
                }
                return false;
            };

            $scope.showAccountingFileInput = function () {
                var show = false;
                if ($scope.customer && $scope.customer.sourceSystem) {
                    show = $scope.customer.sourceSystem === "Nova"
                        || $scope.customer.sourceSystem === "Heeros";
                }
                return show || $scope.isFivaldi() || $scope.customer.sourceSystem === 'Fennoa' || $scope.customer.sourceSystem === 'Asteri';
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:companyUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.isNewCompany = function () {
                if ($scope.customer !== null && $scope.customer.company == null){
                    return true;
                } else if ($scope.customer !== null && $scope.customer.company !== null && $scope.customer.company.id == null){
                    return true;
                } else {
                    return false;
                }
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.customer !== null) {
                    if ($scope.customer.organisation == null) {
                        $scope.customer.organisation = $scope.orc;
                    }
                    if($scope.isBriox() && $scope.isNewCompany()) {
                        $scope.customer.sourceSystem.updateAuthenticationToken = true;
                    }

                    if (document.getElementById('field_file').value !== '') {
                        var f = document.getElementById('field_file').files[0],
                            r = new FileReader();
                        r.onloadend = function (e) {
                            var data = e.target.result;
                            var uInt8Array = new Uint8Array(data);
                            var byteArray = [];
                            for (var i = 0; i < uInt8Array.length; ++i) {
                                byteArray.push(uInt8Array[i]);
                            }
                            $scope.customer.byteArray = byteArray;

                            $http.put("/api/customer", $scope.customer);
                        }
                        r.readAsArrayBuffer(f);
                    } else {
                        $http.put("/api/customer", $scope.customer);
                    }


                }
                $scope.isSaving = false;
                $uibModalInstance.close();
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.fillDataFromYtj = function (id) {
                $scope.hasYTJError = false;
                $scope.getData = true;
                if (id !== '' && id !== 'undefined' && id !== undefined && id !== null) {
                    $http.get("https://avoindata.prh.fi/bis/v1/" + id).then(function (response) {
                        var bulk = response.data;
                        $scope.customer.company.name = bulk.results[0].name;
                        $scope.customer.company.streetAddr = bulk.results[0].addresses[bulk.results[0].addresses.length - 1].street;
                        $scope.customer.company.postCode = bulk.results[0].addresses[bulk.results[0].addresses.length - 1].postCode;
                        $scope.customer.company.city = bulk.results[0].addresses[bulk.results[0].addresses.length - 1].city;
                        for (var i = 0; i < bulk.results[0].contactDetails.length; i++) {
                            if (bulk.results[0].contactDetails[i].type === "Puhelin") {
                                $scope.customer.company.tel = bulk.results[0].contactDetails[i].value;
                            }
                        }
                        for (var i = 0; i < bulk.results[0].companyForms.length; i++) {
                            if (bulk.results[0].companyForms[i].language === "FI") {
                                if (bulk.results[0].companyForms[i].type === "OY") {
                                    $scope.customer.company.companyType = "OY";
                                } else if (bulk.results[0].companyForms[i].type === "OK") {
                                    $scope.customer.company.companyType = "OSUUSKUNTA";
                                }
                            }
                        }
                        $http.get("/api/industrys/code/" + bulk.results[0].businessLines[bulk.results[0].businessLines.length - 1].code).then(function (response) {
                            $scope.customer.company.industry = response.data;
                            $scope.getData = false;
                        });

                    }, function (data) {
                        $scope.hasYTJError = true;
                        $scope.getData = false;
                    });
                } else {
                    $scope.hasYTJError = true;
                    $scope.getData = false;
                }
            };


            $scope.$watch('customer.sourceSystem', function () {
                $scope.isSsSelected = $scope.customer && ($scope.customer.sourceSystem &&
                    $scope.customer.sourceSystem !== null &&
                    $scope.customer.sourceSystem !== "unknown");
                $scope.isFivaldi = $scope.customer && ($scope.customer.sourceSystem && $scope.customer.sourceSystem !== null
                    && $scope.customer.sourceSystem !== "unknown"
                    && $scope.customer.sourceSystem.name
                    && $scope.customer.sourceSystem.name === "Fivaldi");
            });

        }]);
