import {IncomeStatementRow} from "./income-statement-row";

function getRowName(row, locale) {
    if (locale === 'en') {
        if (row.nameEn) {
            return row.nameEn;
        } else {
            return row.nameFi;
        }
    } else if (locale === 'sv') {
        if (row.nameSv) {
            return row.nameSv;
        } else {
            return row.nameFi;
        }
    } else {
        return row.nameFi;
    }
}

export class AccountIncomeStatementRow extends IncomeStatementRow {

    constructor(account, prevAccount, locale) {
        super(account.id, account.code + " " + getRowName(account, locale), account.value, account.percentageValue, account.cumulativeValue, account.percentageCumulativeValue,
            prevAccount.value, prevAccount.percentageValue, prevAccount.cumulativeValue, prevAccount.percentageCumulativeValue, 10, false);
    }
}
