'use strict';

angular.module('ecoeasyApp').controller('FundingBudgetDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'fundingBudgetEntity', 'FundingBudgetData', 'InvestmentBudgetData', 'targetBudget', 'Budget',
        function ($scope, $stateParams, $uibModalInstance, fundingBudgetEntity, FundingBudgetData, InvestmentBudgetData, targetBudget, Budget) {

            $scope.fundingBudgetData = fundingBudgetEntity;

            if (fundingBudgetEntity != null && fundingBudgetEntity.id != null) {
                $scope.targetBudget = Budget.get({id: fundingBudgetEntity.budgetId});
            } else {
                $scope.targetBudget = targetBudget;
                $scope.fundingBudgetData.budgetId = targetBudget.id;
            }

//        $scope.investmentbudgetdatas = InvestmentBudgetData.query();
//        $scope.load = function(id) {
//            FundingBudgetData.get({id : id}, function(result) {
//                $scope.fundingBudgetData = result;
//            });
//        };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:fundingBudgetDataUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.fundingBudgetData.id != null) {
                    $scope.fundingBudgetData.budgetId = $scope.targetBudget.id;
                    FundingBudgetData.update($scope.fundingBudgetData, onSaveSuccess, onSaveError);
                } else {
                    $scope.fundingBudgetData.budgetId = $scope.targetBudget.id;
                    FundingBudgetData.save($scope.fundingBudgetData, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
