'use strict';

angular.module('ecoeasyApp')
    .factory('PriceCatalog', function ($resource) {

        var resourceUrl = 'api/licenseProductPriceCatalog';

        return {
            CommonPriceCatalog: $resource(resourceUrl, {}, {'query': {method: 'GET', isArray: false}}),
            OrganisationPriceCatalog: $resource(resourceUrl + '/:organisationId', {}, {
                'query': {
                    method: 'GET',
                    isArray: false
                }
            }),
            Save: $resource(resourceUrl, {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'update': {method: 'PUT'}
            })
        };
    });
