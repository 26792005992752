'use strict';

import {SurvivorChartDialogMeasureComponent} from "./survivor.chart-dialog.measure.component";

import {SurvivorService} from "./survivor.service";

angular.module('ecoeasyApp')
    .service('SurvivorService', SurvivorService)
    .component('survivorChartDialogMeasureComponent', SurvivorChartDialogMeasureComponent)
    .config(function ($stateProvider) {
        $stateProvider
            .state('survivor', {
                parent: 'site',
                url: '/survivor/:companyid',
                params: {
                    companyid: null,
                },
                data: {
                    authorities: ['ROLE_USER', 'ROLE_ADMIN_ORGANISATION', 'ROLE_USER_ORGANISATION'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./survivor.html'),
                        controller: 'SurvivorController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('variablecodes');
                        $translatePartialLoader.addPart('survivor');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    company_id: ['$stateParams', function ($stateParams) {
                        return $stateParams.companyid;
                    }]
                }
            });
    });
