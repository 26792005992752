import template from './budget.html';
import "./budget.css";
import {BudgetTableRowType} from "./budget-table-data-row";
import {ChangedValuesContainer} from "./changed-values-container";
import {FiscalYearOption} from "./fiscal-year-option";

export const BudgetComponent = {
    template: template,
    controller: class Budget2Component {
        constructor($log, $uibModal, Budget2ComponentService, RootScopeWrapperService, SelectedCompanyService, FEATURE_SECOND_PHASE_TABS) {
            "ngInject";
            this.$log = $log;
            this.$uibModal = $uibModal;
            this.currentBudget = null;
            this.currentLinkedCashBudget = null;
            this.currentPosition = null;
            this.currentDataData = null;
            this.currentDataReadonly = null;
            this.selectablePositions = [];
            this.selectableBudgets = [];
            this.selectableCashBudgets = [];
            this.linkedCashBudgets = [];
            this.selectableSubBudgets = null;
            this.currentSubBudget = null;
            this.loadingNonPrimaryBudgets = false;
            this.changedValuesContainer = new ChangedValuesContainer($log);
            this._myService = Budget2ComponentService;
            this.deregisterOnStateChangeStartListener = RootScopeWrapperService.onStateChangeStart((event) => {
                if (this._confirmationCheck()) event.preventDefault();
            })
            this.companyId = null;
            this.selectedCompanyService = SelectedCompanyService;
            this.companyIncomeStatementChart = null;
            this.accountChartId = null;
            this.featureSecondPhaseTabs = FEATURE_SECOND_PHASE_TABS;
            this.activeTab = 0;
            this.selectedCompanyFiscalYears = null;
            this.actualOrFirstDate = null;
        }

        $onInit() {
            this.loadingNonPrimaryBudgets = true;
            this._myService.getSelectablePositions().then((positions) => {
                this.selectablePositions = positions;
                if (this.selectablePositions.length > 0) {
                    this.selectPosition(this.selectablePositions[0]);
                    this.setCashBudgets(this.companyId, this.currentPosition.id);
                }
            });
            this.companyId = this.selectedCompanyService.getSelectedCompanyId();
            if (this.companyId) {
                this._myService.getIncomeStatementChart(this.companyId).then(chart => {
                    this.companyIncomeStatementChart = chart;
                });
                this._myService.getCompanyFiscalYears(this.companyId).then(fiscalYears => {
                    this.selectedCompanyFiscalYears = fiscalYears;
                });
            }
            this.accountChartId = this.selectedCompanyService.getBasicChartOfAccountsId();
        }

        $onDestroy() {
            this.deregisterOnStateChangeStartListener();
        }

        onActiveTabChange(tabIndex) {
            tabIndex.$observe('index', function (newVal, oldVal) {
                console.log('new value ', newVal, ' old value: ', oldVal)

            })
        }

        createBudget() {
            if (this._confirmationCheck()) return;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'budgetDialog',
                resolve: {
                    fiscalYearId: () => this.currentPosition.id,
                    copyBudget: () => false,
                    positions: () => this.selectablePositions,
                    selectableCashBudgets: () => this.selectableCashBudgets
                },
            }).result.then((budget) => {
                this._myService.createBudget(budget).then((created) => {
                    this._myService._getBudget(created.id).then((result) => {
                        this.selectBudget(result);
                        this.currentPosition = this.selectablePositions.find((value) => value.id == this.currentBudget.fiscalYear.id);
                        this._myService.getSelectableBudgets(this.currentPosition).then((budgets) => {
                            this.selectableBudgets = budgets;
                            this.setLinkedCashBudgets(this.companyId, this.currentBudget.id);
                            this.setCashBudgets(this.companyId, this.currentPosition.id);
                            if (budget.isPrimaryBudget) {
                                this.setPrimary();
                            }
                        });
                    })
                });
            });
        };

        budgetValueChanged(row, index) {
            this.$log.debug("Budget2Component.budgetValueChanged(row, index)", row, index);
            if (BudgetTableRowType.SUM !== row.type) {
                if (this.currentSubBudget) {
                    this.changedValuesContainer.changeRow(row, this.currentSubBudget.id);
                    this.currentSubBudget.data.rows.find(value => value.id == row.id).values[index] = row.values[index];
                    let sum = 0
                    this.selectableSubBudgets.filter(sub => sub.id > 0).forEach((subBudget) => {
                        sum += subBudget.data.rows.find(value => value.id == row.id).values[index]
                    });
                    const mainRow = this.selectableSubBudgets.find(sub => sub.id == 0).data.rows.find(value => value.id == row.id);
                    mainRow.values[index] = sum;
                    this.changedValuesContainer.changeRow(mainRow, 0);
                } else {
                    this.changedValuesContainer.changeRow(row, 0);
                }
            }
        }

        saveBudget() {
            let values = this.changedValuesContainer.getValuesToSave(this.currentBudget);
            this._myService.saveBudgetValues(this.currentBudget, values).then((savedBudget) => {
                this.changedValuesContainer.reset();
                this.selectableBudgets[this.selectableBudgets.indexOf(this.currentBudget)] = savedBudget;
            });
        }

        deleteBudget() {
            this._myService.deleteBudget(this.currentBudget)
                .then(() => {
                    this.selectableBudgets.splice(this.selectableBudgets.indexOf(this.currentBudget), 1);
                    this.linkedCashBudgets = this.linkedCashBudgets.filter(cashBudget => cashBudget.id !== this.currentBudget.id);
                    this.selectableCashBudgets = this.selectableCashBudgets.filter(cashBudget => cashBudget.id !== this.currentBudget.id);
                    this.currentBudget = null;
                    if (this.selectableBudgets.length > 0) {
                        this.selectBudget(this.selectableBudgets[0]);
                    }
                });
        }

        setPrimary() {
            if (this._confirmationCheck()) return;
            this._myService.setAsPrimary(this.currentBudget, this.currentPosition).then((primaryBudget) => {
                this.selectBudget(primaryBudget);
                this._myService.getSelectableBudgets(this.currentPosition).then((budgets) => {
                    this.selectableBudgets = budgets;
                });
            });
        }

        copyBudget() {
            if (this._confirmationCheck()) return;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'budgetDialog',
                resolve: {
                    fiscalYearId: () => this.currentPosition.id,
                    copyBudget: () => true,
                    positions: () => this.selectablePositions
                },
            }).result.then((budget) => {
                this._myService.copyBudget(this.currentBudget, budget).then((created) => {
                    this.selectBudget(created);
                    this.currentPosition = this.selectablePositions.find((value) => value.id == this.currentBudget.fiscalYear.id);
                    this._myService.getSelectableBudgets(this.currentPosition).then((budgets) => {
                        this.selectableBudgets = budgets;
                    });
                    if (budget.isPrimaryBudget) {
                        this.setPrimary();
                    }
                });
            });
        };

        _confirmationCheck() {
            return this.changedValuesContainer.hasValues() && !window.confirm("Budjetissa on tallentamattomia muutoksia, jotka katoavat ilman tallentamista. Haluatko silti jatkaa?");
        }

        selectBudget(budget) {
            this.$log.debug("Budget2Component.selectBudget", budget, this.currentBudget);
            if (budget === this.currentBudget) return;
            if (this._confirmationCheck()) return;
            this.currentBudget = budget;
            this.currentSubBudget = null;
            this.selectableSubBudgets = null;
            this.changedValuesContainer.reset();
            if (this.currentBudget) {
                this.setLinkedCashBudgets(this.companyId, this.currentBudget.id);
                if (this.currentBudget.hasSubBudgets) {
                    this._myService.getSelectableSubBudgets(this.currentBudget).then(result => {
                        this.selectableSubBudgets = result;
                        this.selectSubBudget(this.selectableSubBudgets[0]);
                    });
                } else {
                    this.selectableSubBudgets = null;
                    if (this.currentBudget.data) {
                        this.currentDataData = this.currentBudget.data;
                        this.currentDataReadonly = false;
                        this.budgetChanged(this.currentBudget.id);
                    } else {
                        this._myService.getOperatingBudgetData(this.currentBudget).then(result => {
                            this.currentBudget.data = result;
                            this.currentDataData = result;
                            this.currentDataReadonly = false;
                        });
                    }
                }
                this.actualOrFirstDate = this.currentBudget.actualDate ? this.currentBudget.actualDate : this.currentBudget.yearMonths[0];
            }
        }

        setCashBudgets(companyId, fiscalYearId) {
            this._myService.getCashBudgets(companyId, fiscalYearId).then(result => {
                this.selectableCashBudgets = result;
            });
        }

        setLinkedCashBudgets(companyId, budgetId) {
            this._myService.getLinkedCashBudgets(companyId, budgetId).then(result => {
                this.linkedCashBudgets = result;
            });
        }

        selectPosition(position) {
            if (this.currentPosition === position) return;
            if (this._confirmationCheck()) return;

            this.loadingNonPrimaryBudgets = true;
            this.currentPosition = position;
            this.changedValuesContainer.reset();
            this.selectBudget(null);
            this.selectableBudgets = [];
            this._myService.getSelectablePrimaryBudget(this.currentPosition)
                .then((primary) => {
                    this.selectableBudgets = [primary];
                    this.selectBudget(primary);
                    this.setCashBudgets(this.companyId, this.currentPosition.id);
                })
                .then(() => {
                    this._myService.getSelectableNonPrimaryBudgets(this.currentPosition).then((budgets) => {
                        this.loadingNonPrimaryBudgets = false;
                        if (budgets && budgets.length > 0) {
                            this.selectableBudgets = this.selectableBudgets.concat(budgets);
                            if (!this.currentBudget && this.selectableBudgets.length > 0) {
                                this.selectBudget(this.selectableBudgets[0]);
                            }
                            this.setLinkedCashBudgets(this.companyId, this.currentBudget.id);
                        }
                    });
                });
        };

        selectPositionForLinked(position, budgetId) {
            this.loadingNonPrimaryBudgets = true;
            let endDate = new Date(position.endDate);
            this.currentPosition = new FiscalYearOption(position.id, new Date(position.startDate), endDate);
            this.changedValuesContainer.reset();
            this.selectBudget(null);
            this.selectableBudgets = [];
            this._myService._getBudget(budgetId)
                .then((selected) => {
                    this.selectableBudgets = [selected];
                    this.selectBudget(selected);
                    this.setCashBudgets(this.companyId, this.currentPosition.id);
                })
                .then(() => {
                    this._myService.getSelectableNonPrimaryBudgets(this.currentPosition).then((budgets) => {
                        this.loadingNonPrimaryBudgets = false;
                        if (budgets && budgets.length > 0) {
                            this.selectableBudgets = this.selectableBudgets.concat(budgets);
                            if (!this.currentBudget && this.selectableBudgets.length > 0) {
                                this.selectBudget(this.selectableBudgets[0]);
                            }
                            this.setLinkedCashBudgets(this.companyId, this.currentBudget.id);
                        }
                    });
                })
        };

        selectLinkedCashBudget(cashBudget) {
            if (cashBudget.id === this.currentBudget.id) return;
            if (this._confirmationCheck()) return;
            if (cashBudget.fiscalYear.id !== this.currentPosition.id) {
                this.selectPositionForLinked(cashBudget.fiscalYear, cashBudget.id);
            } else {
                if (this.loadingNonPrimaryBudgets) {
                    this.waitLoading().then(result => {
                        this.selectBudgetWithId(cashBudget.id);
                    })
                } else {
                    this.selectBudgetWithId(cashBudget.id);
                }
            }
        }

        async waitLoading () {
            const timer = ms => new Promise(res => setTimeout(res, ms))
            for (var i = 0; i < 90; i++) {
                await timer(1000);
                if (!this.loadingNonPrimaryBudgets) {
                    return true;
                }
            }
            return true;
        }

        selectBudgetWithId(budgetId) {
            this.$log.debug("Budget2Component.selectBudgetWithId", budgetId, this.currentBudget);
            var selectedBudget = this.selectableBudgets.filter(budget => budget.id === budgetId);
            if(selectedBudget.length === 0) {
                this.currentBudget = null;
            } else {
                this.currentBudget = selectedBudget[0];
            }
            this.currentSubBudget = null;
            this.selectableSubBudgets = null;
            this.changedValuesContainer.reset();
            if (this.currentBudget) {
                this.setLinkedCashBudgets(this.companyId, this.currentBudget.id);
                if (this.currentBudget.hasSubBudgets) {
                    this._myService.getSelectableSubBudgets(this.currentBudget).then(result => {
                        this.selectableSubBudgets = result;
                        this.selectSubBudget(this.selectableSubBudgets[0]);
                    });
                } else {
                    this.selectableSubBudgets = null;
                    if (this.currentBudget.data) {
                        this.currentDataData = this.currentBudget.data;
                        this.currentDataReadonly = false;
                        this.budgetChanged(this.currentBudget.id);
                    } else {
                        this._myService.getOperatingBudgetData(this.currentBudget).then(result => {
                            this.currentBudget.data = result;
                            this.currentDataData = result;
                            this.currentDataReadonly = false;
                        });
                    }
                }
                this.actualOrFirstDate = this.currentBudget.actualDate ? this.currentBudget.actualDate : this.currentBudget.yearMonths[0];
            }
        }

        selectSubBudget(subBudget) {
            if (subBudget === this.currentSubBudget) return;
            this.currentSubBudget = subBudget;
            this.currentDataData = subBudget.data;
            this.currentDataReadonly = subBudget.readonly;
        }

        createSubBudgets() {
            if (this._confirmationCheck()) return;
            const ctrl = this;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'subBudgetsEdit',
                resolve: {
                    costPools: function () {
                        return ctrl._myService.getCostPools();
                    }
                }
            }).result.then((subBudgets) => {
                this.$log.debug("Budget2Component.createSubBudgets", subBudgets);
                this._myService.createSubBudgets(this.currentBudget, subBudgets)
                    .then((budget) => {
                        this.selectBudget(budget);
                        this._myService.getSelectableBudgets(this.currentPosition).then((budgets) => {
                            this.selectableBudgets = budgets;
                        });
                    });
            });
        }

        updateActual() {
            if (this._confirmationCheck()) return;
            const ctrl = this;
            this.$uibModal.open({
                size: 'lg',
                backdrop: 'static',
                component: 'budgetMonthDialog',
                resolve: {
                    possibleMonths: function () {
                        return ctrl._myService.getActualMonths(ctrl.currentBudget);
                    }
                }
            }).result.then((monthSelected) => {
                this.$log.debug("Budget2Component.updateActual", monthSelected);
                ctrl._myService.updateActuals(ctrl.currentBudget, monthSelected)
                    .then((savedBudget) => {
                        this.selectableBudgets[this.selectableBudgets.indexOf(this.currentBudget)] = savedBudget;
                        this.selectBudget(savedBudget);
                        if (savedBudget.isCashBudget){
                            this.$uibModal.open({
                                size: 'lg',
                                backdrop: 'static',
                                component: 'budgetUpdateDialog',
                                resolve: {}
                            });
                        }


                    });
            });
        }

        tabSelected($event) {
            this.$log.debug("Budget2Component.tabSelected", $event);
        }

        budgetTabSelected($event) {
            this.$log.debug("Budget2Component.tabSelected", $event);
            //this.budgetChanged(this.currentBudget.id)
        }

        tabDeselected($event, $selectedIndex) {
            this.activeTab = $selectedIndex;
            this.$log.debug("Budget2Component.tabDeselected", $event, $selectedIndex);
        }

        budgetChanged(budgetId) {
            this.$log.debug("Budget2Component.budgetChanged", budgetId);
            if (this.currentSubBudget == null) {
                this._myService.getOperatingBudgetData(this.currentBudget).then(result => {
                    this.currentDataData = result;
                    //this.currentDataReadonly = false;
                });
            } else {
                this._myService.getSelectableSubBudgets(this.currentBudget).then(result => {
                    this.selectableSubBudgets = result;
                    this.selectSubBudget(this.selectableSubBudgets.find(sub => sub.id == this.currentSubBudget.id));
                });
            }
        }
    }
};
