export class BudgetRestClientService {

    constructor(HttpClientService) {
        "ngInject";
        this.httpClient = HttpClientService;
    }

    getCostPools(companyId) {
        return this.httpClient.get("/api/companies/" + companyId + "/cost-pools").then((response) => { return response.data; });
    }

    getLastActualDate(companyId) {
        return this.httpClient.get("/api/companies/" + companyId + "/last-actual-date").then((response)=>{ return response.data; });
    }

    getFiscalYears(companyId) {
        return this.httpClient.get("/api/fiscalYears/company/" + companyId + "/sorted/budget").then((response)=>{ return response.data; });
    }

    postBudget(companyId, budgetBody) {
        return this.httpClient.post("/api/companies/" + companyId + "/budgets", budgetBody);
    }

    deleteBudget(companyId, budgetId){
        return this.httpClient.delete("/api/companies/" + companyId + "/budgets/"+ budgetId);
    }

    getBudgets(companyId, fiscalYearId, primary) {
        const queryParam = (primary === undefined) ? '' : '?primary=' + !!primary;
        return this.httpClient.get("/api/companies/" + companyId + "/budgets/fiscal-years/"+fiscalYearId+queryParam).then((response)=>{ return response.data; });
    }

    getBudgetsWithoutValues(companyId, fiscalYearId){
        return this.httpClient.get("/api/companies/"+ companyId + "/budgets/fiscal-years/"+fiscalYearId+"/no-values").then((response)=>{ return response.data; });
    }

    getOperatingBudget(companyId, budgetId) {
        return this.httpClient.get("/api/companies/" + companyId + "/budgets/" + budgetId + "/operating-budget").then((response)=>{ return response.data; });
    }

    getCashBudgets(companyId, fiscalYearId) {
        return this.httpClient.get("/api/companies/" + companyId + "/budgets/cash-budgets/fiscal-years/" + fiscalYearId).then((response) => { return response.data; });
    }

    getLinkedCashBudgets(companyId, cashBudgetId) {
        return this.httpClient.get("/api/companies/" + companyId + "/budgets/cash-budgets/" + cashBudgetId).then((response) => { return response.data; });
    }

    postCashBudget(companyId, budgetId, cashBudgetId) {
        return this.httpClient.post("/api/companies/" + companyId + "/budgets/" + budgetId + "/cashBudget/" + cashBudgetId);
    }

    putBudget(companyId, budgetId, data) {
        return this.httpClient.put("/api/companies/" + companyId + "/budgets/"+budgetId, data);
    }

    deleteBudgetRow(companyId, budgetId, budgetGroupId){
        return this.httpClient.delete("/api/companies/"+ companyId + "/budgets/"+budgetId+ "/groups/"+ budgetGroupId)
    }

}
