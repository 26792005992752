import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import {useTranslation} from "react-i18next";
import {findAccountKey} from "../budget-utils";
import Money from "../../shared/money";

const BalanceSheetDepreciationTable = ({
                                           balanceSheetData,
                                           options,
                                           isAllCollapsed,
                                           setDepreciations
                                       }) => {
    const {t} = useTranslation('depreciation');
    const [collapsed, setCollapsed] = useState(false);
    const allCollapsed = collapsed || isAllCollapsed;

    const depreciation = (amount, interest) => {
        return (amount * interest) / 100;
    }

    const createParentGroup = account => {
        const parentName = findAccountKey(options, 'id', account.bcoaCategoryId);
        const match = groupsAndAccounts.find(item => item.id === account.bcoaCategoryId);
        if (match) {
            return match.accounts.push(account);
        } else {
            return groupsAndAccounts.push({id: account.bcoaCategoryId, categoryName: parentName, accounts: [account]});
        }
    }

    let groupsAndAccounts = [];
    if (balanceSheetData.length > 0) balanceSheetData.map(data => createParentGroup(data));

    const createGroupLevel = (group, isCollapsed) => {
        const [groupCollapsed, setGroupCollapsed] = useState(false);
        const isAccountCollapsed = isCollapsed || groupCollapsed;

        return (
            <React.Fragment key={group.id}>
                <tr hidden={isCollapsed} onClick={() => setGroupCollapsed(!groupCollapsed)}>
                    <td style={{paddingLeft: '1em', fontWeight: 'bold'}}>
                        {group.categoryName}:
                        {groupCollapsed ?
                            <Glyphicon glyph='chevron-down' style={{float: 'right'}}/>
                            : <Glyphicon glyph='chevron-up' style={{float: 'right'}}/>
                        }
                    </td>
                    <td colSpan='4'/>
                </tr>
                {group.accounts.map(account => {
                        return createAccountLevel(account, isAccountCollapsed)
                    }
                )}
            </React.Fragment>
        );
    };

    const createAccountLevel = (account, isCollapsed) => {
        const initialDepreciation = depreciation(account.cumulativeValue, account.depreciationPercentage);

        const [percentage, setPercentage] = useState(account.depreciationPercentage || 0);
        const [depreciationValue, setDepreciationValue] = useState(initialDepreciation);

        const accountData = {
            id: account.id,
            depreciationAmount: depreciationValue,
            depreciationPercentage: percentage
        };

        useEffect(() => {
            setDepreciationValue(depreciation(account.cumulativeValue, percentage))
            setDepreciations(accountData);
        }, [percentage, depreciationValue]);

        const calculateRemainingSum = (value, _depreciation) => {
            return value - _depreciation;
        }

        return (
            <tr key={account.id} hidden={isCollapsed}>
                <td style={{paddingLeft: '3em'}}>{account.code} {account.name}</td>
                <td align='right'><Money value={account.cumulativeValue} decimals={true}/> €</td>
                <td align='right'><Money value={depreciationValue} decimals={true}/> €</td>
                <td align='right'>
                    <input type='number'
                           style={{width: '50px', marginRight: '0.4rem'}}
                           min={0.00}
                           step={0.1}
                           value={percentage}
                           onInput={e => setPercentage(parseFloat(e.target.value))}
                    /> %
                </td>
                <td align='right'>
                    <Money value={calculateRemainingSum(account.cumulativeValue, depreciationValue)} decimals={true}/> €
                </td>
            </tr>
        );
    }

    return (
        <>
            <tr style={{background: '#EBEBEB'}} onClick={() => setCollapsed(!collapsed)}>
                <td style={{fontWeight: 'bold'}}>
                    {t('table.subHeaders.balanceAccount')}:
                    {allCollapsed ?
                        <Glyphicon glyph='chevron-down' style={{float: 'right'}}/>
                        : <Glyphicon glyph='chevron-up' style={{float: 'right'}}/>
                    }
                </td>
                <td colSpan='4'/>
            </tr>
            {groupsAndAccounts.length > 0 && groupsAndAccounts.map(group => createGroupLevel(group, allCollapsed))}
        </>
    );
}

BalanceSheetDepreciationTable.propTypes = {
    balanceSheetData: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    isAllCollapsed: PropTypes.bool.isRequired,
    setDepreciations: PropTypes.func.isRequired
};

export default BalanceSheetDepreciationTable;