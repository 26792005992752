(function () {
    'use strict';

    angular
        .module('ecoeasyApp')
        .controller('PermissionManagementDeleteController', PermissionManagementDeleteController);

    PermissionManagementDeleteController.$inject = ['$uibModalInstance', 'permissionMapping', 'permissionMappingService'];

    function PermissionManagementDeleteController($uibModalInstance, permissionMapping, permissionMappingService) {
        var vm = this;

        vm.permissionMapping = permissionMapping;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            permissionMappingService.deletePermissionMapping(id)
                .then(function () {
                    $uibModalInstance.close(true);
                });
        }
    }

})();
