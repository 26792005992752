import React, {useEffect, useState} from 'react';
import {array, number} from 'prop-types';
import {useTranslation} from "react-i18next";
import Table from "react-bootstrap/lib/Table";
import Button from 'react-bootstrap/lib/Button';
import BalanceSheetDepreciationTable from "./balancesheet-depreciation-table";
import InvestmentsDepreciationTable from "./investments-depreciation-table";
import Money from "../../shared/money";
import '../depreciation/depreciation-table.css';
import {postDepreciations, updateDepreciations} from "../../../service/react-service";

const DepreciationTable = ({
                               companyId,
                               budgetId,
                               investments,
                               balanceSheetData,
                               options,
                               depreciationId}) => {
    const {t} = useTranslation('depreciation');
    const [allCollapsed, setAllCollapsed] = useState(false);
    const [totalDepreciation, setTotalDepreciation] = useState(0);
    const [investmentDepreciations, setInvestmentDepreciations] = useState(investments);
    const [bsDepreciations, setBSDepreciations] = useState([]);

    const totalRemaining = () => {
        let total = 0;
        balanceSheetData.forEach(bs => total += bs.cumulativeValue);
        investments.forEach(bs => total += bs.value);
        return parseFloat(total.toFixed(2));
    }

    const totalDepr = () => {
        let total = 0;
        bsDepreciations.forEach(bs => total += bs.depreciationAmount);
        investmentDepreciations.forEach(bs => total += bs.depreciationAmount);
        return parseFloat(total.toFixed(2));
    }

    const addBSDepreciation = data => {
        if (bsDepreciations.find(el => el.id === data.id)) {
            setBSDepreciations(
                bsDepreciations.map(item => {
                    return item.id === data.id ? data : item;
                })
            );
        } else {
            setBSDepreciations(prev => [...prev, data]);
        }
    }

    const updateTotalAmount = () => {
        setTotalDepreciation(0);
        investmentDepreciations.map(val => setTotalDepreciation(prev => prev + val.depreciationAmount));
        bsDepreciations.map(val => setTotalDepreciation(prev => prev + val.depreciationAmount));
    }

    useEffect(() => {
        updateTotalAmount();
    }, [investmentDepreciations, bsDepreciations]);

    const handleSubmit = (bsDepreciations, depreciationId) => {
        const balanceSheet = bsDepreciations.map(bs => {
            return {
                id: bs.id,
                depreciationPercentage: bs.depreciationPercentage
            }
        });

        //Only balancesheet depreciations are added to total value; investments are handled in backend
        let totalDepreciationFromBS = 0;
        bsDepreciations.forEach(bs => totalDepreciationFromBS += bs.depreciationAmount);

        const data = {
            totalDepreciationAmount: parseFloat(Math.round(totalDepreciationFromBS).toFixed(2)),
            balanceSheetDepreciationsList: balanceSheet
        };

        if (depreciationId == null) {
            postDepreciations(companyId, budgetId, data);
        } else {
            updateDepreciations(companyId, budgetId, data, depreciationId);
        }
    }

    return (
        <>
            <>
                <Table striped bordered className='DepreciationTable'>
                    <thead>
                    <tr>
                        <th style={{width: '300px'}}>
                            <Button
                                style={{color: '#FFF'}}
                                className='btn btn-primary btn-sm btn-block'
                                onClick={() => setAllCollapsed(!allCollapsed)}
                            >
                                {allCollapsed ? t('shared:buttonCaptions.showRows') : t('shared:buttonCaptions.hideRows')}
                            </Button>
                        </th>
                        <th>{t('table.headers.amountLeft')}</th>
                        <th>{t('table.headers.amountToDepreciate')}</th>
                        <th>{t('table.headers.depreciatePercentage')}</th>
                        <th>{t('table.headers.total')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {investments.length > 0 &&
                    <InvestmentsDepreciationTable investments={investments}
                                                  options={options}
                                                  totalDepreciation={totalDepreciation}
                                                  updateTotal={setTotalDepreciation}
                                                  isAllCollapsed={allCollapsed}
                                                  setDepreciations={setInvestmentDepreciations}
                    />}
                    {balanceSheetData.length > 0 &&
                    <BalanceSheetDepreciationTable balanceSheetData={balanceSheetData}
                                                   options={options}
                                                   totalDepreciation={totalDepreciation}
                                                   updateTotal={setTotalDepreciation}
                                                   isAllCollapsed={allCollapsed}
                                                   setDepreciations={addBSDepreciation}
                    />}
                    {(balanceSheetData.length > 0 || investments.length > 0) &&
                    <tr style={{background: '#EBEBEB'}}>
                        <td>
                            <label>{t('table.totalDepreciation')}</label>
                        </td>
                        <td align='right'>
                            <Money value={totalRemaining()} decimals={true}/> €
                        </td>
                        <td align='right'>
                            <Money value={totalDepr()} decimals={true}/> €
                        </td>
                        <td />
                        <td align='right'><Money value={parseFloat((totalRemaining() - totalDepr()).toFixed(2))} decimals={true}/> €</td>
                    </tr>}
                    </tbody>
                </Table>
            </>
            {(balanceSheetData.length > 0 || investments.length > 0) &&
            <Button style={{color: '#FFF', marginTop: '1em'}}
                    className='btn btn-primary btn-sm'
                    onClick={() => handleSubmit(bsDepreciations, depreciationId)}
            >
                {t('table.save')}
            </Button>
            }
        </>
    );
}

DepreciationTable.propTypes = {
    companyId: number.isRequired,
    budgetId: number.isRequired,
    investments: array.isRequired,
    balanceSheetData: array.isRequired,
    options: array.isRequired
};

export default DepreciationTable;