'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('bigbrother', {
                parent: 'site',
                url: '/controlroom',
                data: {
                    authorities: ['ROLE_USER_ORGANISATION', 'ROLE_ADMIN', 'ROLE_ADMIN_ORGANISATION'],
                    pageTitle: 'global.title'
                },
                views: {
                    'content@': {
                        template: require('./bigbrother.html'),
                        controller: 'BigBrotherController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('userGroup');
                        $translatePartialLoader.addPart('bigbrother');
                        return $translate.refresh();
                    }],

                    filters: function () {
                        return [];
                    },

                    orgUsers: function () {
                        return [];
                    },
                    companies: function () { // mock data.

                        return [];
                    }
                }
            });
    });
