import React from 'react';
import {
    ControlLabel,
    FormGroup,
    Glyphicon
} from 'react-bootstrap';
import {Field, useField} from 'formik';

const MultipleMonthCheckboxInput = ({label, value, ...props}) => {
    const [field, meta, helpers] = useField(props);

    const errorStyle = {
        fontSize: '12px',
        color: 'red',
        paddingTop: '0',
    };

    const labelStyle = {
        marginRight: '0.8rem',
        marginBottom: '0'
    };

    const monthsCollection = [
        {value: '1', label: '01', key: 'checkbox-january-label-key'},
        {value: '2', label: '02', key: 'checkbox-february-label-key'},
        {value: '3', label: '03', key: 'checkbox-march-label-key'},
        {value: '4', label: '04', key: 'checkbox-april-label-key'},
        {value: '5', label: '05', key: 'checkbox-may-label-key'},
        {value: '6', label: '06', key: 'checkbox-june-label-key'},
        {value: '7', label: '07', key: 'checkbox-july-label-key'},
        {value: '8', label: '08', key: 'checkbox-august-label-key'},
        {value: '9', label: '09', key: 'checkbox-september-label-key'},
        {value: '10', label: '10', key: 'checkbox-october-label-key'},
        {value: '11', label: '11', key: 'checkbox-november-label-key'},
        {value: '12', label: '12', key: 'checkbox-december-label-key'},
    ];

    const handleOnChange = (event) => {
        if (field.value.length > 0) {
            if (field.value.includes(parseInt(event.target.value))) {
                helpers.setValue(field.value.filter((element) => element !== parseInt(event.target.value)));
            } else {
                helpers.setValue(field.value.concat([parseInt(event.target.value)]));
            }
        } else {
            helpers.setValue(field.value.concat([parseInt(event.target.value)]));
        }

    };

    return (
        <FormGroup
            controlId={props.id}
        >
            <ControlLabel htmlFor={props.id || props.name}>{label}</ControlLabel>
            <br/>
            {monthsCollection.map((month) => (
                <label key={month.key} style={labelStyle}>
                    <Field
                        type="checkbox"
                        name={props.name}
                        value={month.value}
                        style={{marginRight: '5px'}}
                        checked={field.value.includes(parseInt(month.value))}
                        onChange={handleOnChange}
                    />
                    {month.label}
                </label>
            ))}
            {meta.touched && meta.error ? (
                <div className="errormessage" style={errorStyle}><Glyphicon glyph="remove"/>{meta.error}</div>
            ) : null}
        </FormGroup>
    );
}

export default MultipleMonthCheckboxInput;
