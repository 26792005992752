'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('fiscalYear', {
                parent: 'entity',
                url: '/fiscalYears/{companyId}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT'],
                    pageTitle: 'ecoeasyApp.fiscalYear.home.title'
                },
                views: {
                    'content@': {
                        template: require('./fiscalYears.html'),
                        controller: 'FiscalYearController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fiscalYear');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('fiscalYear.detail', {
                parent: 'entity',
                url: '/fiscalYear/{companyId}/{id}',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT'],
                    pageTitle: 'ecoeasyApp.fiscalYear.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./fiscalYear-detail.html'),
                        controller: 'FiscalYearDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fiscalYear');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'FiscalYear', function ($stateParams, FiscalYear) {
                        return FiscalYear.get({id: $stateParams.id});
                    }]
                }
            })
            .state('fiscalYear.new', {
                parent: 'fiscalYear',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./fiscalYear-dialog.html'),
                        controller: 'FiscalYearDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    startPosition: 0,
                                    endPosition: 0,
                                    length: 0,
                                    startDate: null,
                                    endDate: null,
                                    id: null,
                                    company: {
                                        id: $stateParams.companyId
                                    }
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('fiscalYear', null, {reload: true});
                    }, function () {
                        $state.go('fiscalYear');
                    })
                }]
            })
            .state('fiscalYear.edit', {
                parent: 'fiscalYear',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./fiscalYear-dialog.html'),
                        controller: 'FiscalYearDialogController',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('fiscalYear');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            entity: ['FiscalYear', function (FiscalYear) {
                                return FiscalYear.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('fiscalYear', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('fiscalYear.delete', {
                parent: 'fiscalYear',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN_ORGANISATION', 'ROLE_ADMIN'],
                    permissions: ['COMPANY_MANAGEMENT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        template: require('./fiscalYear-delete-dialog.html'),
                        controller: 'FiscalYearDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['FiscalYear', function (FiscalYear) {
                                return FiscalYear.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('fiscalYear', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
