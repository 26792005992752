'use strict';

angular.module('ecoeasyApp').controller('CompanyDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Company', 'Industry', 'AccountingModel', 'Country',
        function ($scope, $stateParams, $uibModalInstance, entity, Company, Industry, AccountingModel, Country) {

            $scope.company = entity;
            $scope.industrys = Industry.query();
            $scope.accountingmodels = AccountingModel.query();
            $scope.countrys = Country.query();
            $scope.load = function (id) {
                Company.get({id: id}, function (result) {
                    $scope.company = result;
                });
            };

            var onSaveSuccess = function (result) {
                $scope.$emit('ecoeasyApp:companyUpdate', result);
                $uibModalInstance.close(result);
                $scope.isSaving = false;
            };

            var onSaveError = function (result) {
                $scope.isSaving = false;
            };

            $scope.save = function () {
                $scope.isSaving = true;
                if ($scope.company.id != null) {
                    Company.update($scope.company, onSaveSuccess, onSaveError);
                } else {
                    Company.save($scope.company, onSaveSuccess, onSaveError);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
            $scope.datePickerForLastModelUpdate = {};

            $scope.datePickerForLastModelUpdate.status = {
                opened: false
            };

            $scope.datePickerForLastModelUpdateOpen = function ($event) {
                $scope.datePickerForLastModelUpdate.status.opened = true;
            };
            $scope.datePickerForLastAccountingDataUpdate = {};

            $scope.datePickerForLastAccountingDataUpdate.status = {
                opened: false
            };

            $scope.datePickerForLastAccountingDataUpdateOpen = function ($event) {
                $scope.datePickerForLastAccountingDataUpdate.status.opened = true;
            };
        }]);
