'use strict';
//TODO tarkistetaan roolit ja oikeudet tassa direktiivissa!
angular.module('ecoeasyApp') 
    .directive('hasOrgLvlPermissionFor', ['$rootScope', 'Principal', function ($rootScope, Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        if(!element.hasClass('hidden-no-company')) {
                            element.removeClass('hidden');
                        }
                        element.removeClass('hidden-no-permission');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                        element.addClass('hidden-no-permission');
                    },
                    defineVisibility = function (reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }
                        
                        var authoroties = [];
                        var permissions = [];
                        for(var i = 0; i < privileges.length; i++){
                            if(privileges[i].includes("ROLE_")){
                                authoroties.push(privileges[i]);
                            }else{
                                permissions.push(privileges[i]);
                            }
                        }
                        if(authoroties.length > 0 && permissions.length > 0){
                            result = Principal.hasAnyAuthority(authoroties);
                            if(result){
                               setVisible(); 
                            } else {
                               result = Principal.hasOrgLvlPermissionfor(permissions);
                               if(result){
                                    setVisible();
                               } else {
                                    setHidden();
                               }
                            }
                        } else if(authoroties.length > 0 && permissions.length == 0){
                            result = Principal.hasAnyAuthority(authoroties);
                            if(result){
                                setVisible();
                            } else {
                                setHidden();
                            }
                        } else if (permissions.length > 0 && authoroties.length == 0){
                            result = Principal.hasOrgLvlPermissionfor(permissions);
                            if(result){
                                setVisible();
                            } else {
                                setHidden();
                            }
                        } else {
                            setVisible();
                        }
                    },
                    privileges = attrs.hasOrgLvlPermissionFor.replace(/\s+/g, '').split(',');
                   
                if (privileges.length > 0) {
                    defineVisibility(true);
                }
                /*
                $rootScope.$on('navUpdate', function(event,data){
                        defineVisibility(true); 
                });
                */
                
                
            }
        };
    }]);

