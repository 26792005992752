export function numberWithSeparator(x) {
    var number = x.toFixed(2);
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
}

export class IncomeStatementRow {

    constructor(id,
                name,
                currentMonthValue,
                currentMonthValuePercentage,
                currentCumulativeMonthValue,
                currentCumulativeMonthValuePercentage,
                lastYearMonthValue,
                lastYearMonthValuePercentage,
                lastYearCumulativeMonthValue,
                lastYearCumulativeMonthValuePercentage,
                indent,
                header) {
        this.id = id;
        this.name = name;
        this.currentMonthValue = currentMonthValue;
        this.currentMonthValuePercentage = currentMonthValuePercentage;
        this.currentCumulativeMonthValue = currentCumulativeMonthValue;
        this.currentCumulativeMonthValuePercentage = currentCumulativeMonthValuePercentage;
        this.lastYearMonthValue = lastYearMonthValue;
        this.lastYearMonthValuePercentage = lastYearMonthValuePercentage;
        this.lastYearCumulativeMonthValue = lastYearCumulativeMonthValue;
        this.lastYearCumulativeMonthValuePercentage = lastYearCumulativeMonthValuePercentage;
        this.indent = indent;
        this.header = header;
    }

}
