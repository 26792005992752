'use strict';

angular.module('ecoeasyApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('permission', {
                parent: 'entity',
                url: '/permissions',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.permission.home.title'
                },
                views: {
                    'content@': {
                        template: require('./permissions.html'),
                        controller: 'PermissionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('permission');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('permission.detail', {
                parent: 'entity',
                url: '/permission/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ecoeasyApp.permission.detail.title'
                },
                views: {
                    'content@': {
                        template: require('./permission-detail.html'),
                        controller: 'PermissionDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('permission');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Permission', function ($stateParams, Permission) {
                        return Permission.get({id: $stateParams.id});
                    }]
                }
            })
            .state('permission.new', {
                parent: 'permission',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./permission-dialog.html'),
                        controller: 'PermissionDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    code: null,
                                    i18NCode: null,
                                    i18NDescCode: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        $state.go('permission', null, {reload: true});
                    }, function () {
                        $state.go('permission');
                    })
                }]
            })
            .state('permission.edit', {
                parent: 'permission',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./permission-dialog.html'),
                        controller: 'PermissionDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['Permission', function (Permission) {
                                return Permission.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('permission', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            })
            .state('permission.delete', {
                parent: 'permission',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        backdrop: 'static',
                        template: require('./permission-delete-dialog.html'),
                        controller: 'PermissionDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['Permission', function (Permission) {
                                return Permission.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('permission', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    })
                }]
            });
    });
