import template from "./budget-update-dialog.html";

export const BudgetUpdateDialogComponent = {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: template,
    controller: class BudgetUpdateDialogComponent {
        constructor($translate) {
            "ngInject";
            this.$translate = $translate;
        }

        $onInit() {}

        clear() {
            this.dismiss({$value: 'dismissed'});
        }

    }
}