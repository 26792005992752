'use strict';

angular.module('ecoeasyApp')
    .directive('activeMenu', function($translate, $locale, tmhDynamicLocale) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var language = attrs.activeMenu;

                scope.$watch(function() {
                    return $translate.use();
                }, function(selectedLanguage) {
                    if (language === selectedLanguage) {
                        tmhDynamicLocale.set(language);
                        element.addClass('active');
                    } else {
                        element.removeClass('active');
                    }
                });
            }
        };
    })
    .directive('activeLink', function(location) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var clazz = attrs.activeLink;
                var path = attrs.href;
                path = path.substring(1); //hack because path does bot return including hashbang
                scope.location = location;
                scope.$watch('location.path()', function(newPath) {
                    if (path === newPath) {
                        element.addClass(clazz);
                    } else {
                        element.removeClass(clazz);
                    }
                });
            }
        };
    })
    .directive('hasCompanySelected', ['$location',  'Principal', function (location, Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var setVisible = function () {
                        if(!element.hasClass("hidden-no-permission")) {
                            element.removeClass('hidden');
                        }
                        element.removeClass('hidden-no-company');
                    },
                    setHidden = function () {
                        element.addClass('hidden');
                        element.addClass('hidden-no-company');
                    },
                    defineVisibility = function (reset) {
                        if (reset) {
                            setVisible();
                        }
                        if(scope.getSelectedCompanyId() > 0) {
                            setVisible();
                        }else {
                            if (Principal.isIdentityResolved()) {
                                Principal.identity().then(function (account) {
                                    if (account != null && account.sourceType == "COMPANY") {
                                        setVisible();
                                    } else {
                                        setHidden();
                                    }
                                });
                            }
                        }
                    };

                scope.$location = location;
                scope.$root.$on("navUpdate", function() {
                    defineVisibility(true);
                });
                scope.$root.$watch('selectedCompanyId', function(newValue){
                    defineVisibility(true);
                });
            }
        };
    }]);
    // .directive('hasAuthenticated',  ['$location', 'Principal', function(location, Principal) {
    //     return {
    //         restrict: 'A',
    //         link: function (scope, element, attrs) {
    //             var setVisible = function () {
    //                     element.removeClass('hidden');
    //                 },
    //                 setHidden = function () {
    //                     element.addClass('hidden');
    //                 },
    //                 defineVisibility = function (reset) {
    //                     if (reset) {
    //                         setVisible();
    //                     }
    //                     Principal.identity().then(function (account) {
    //                             Principal.authenticate(account);
    //                             if (Principal.isAuthenticated() > 0) {
    //                                 setVisible();
    //                             }
    //                             else {
    //                                 setHidden();
    //                             }
    //                         });
    //
    //                 };
    //             scope.$location = location;
    //             scope.$watch('$location.path()', function(newValue) {
    //                 defineVisibility(true);
    //             });
    //             scope.$watch('isAuthenticated', function(newValue){
    //                 defineVisibility(true);
    //             });
    //         }
    //     };
    // }]);
    // .directive('hasCompanySelected', ['$location', function (location) {
    //     return {
    //         restrict: 'A',
    //         link: function (scope, element, attrs) {
    //             var setVisible = function () {
    //                     if(!element.hasClass("hidden-no-permission")) {
    //                         element.removeClass('hidden');
    //                     }
    //                     element.removeClass('hidden-no-company');
    //                 },
    //                 setHidden = function () {
    //                     element.addClass('hidden');
    //                     element.addClass('hidden-no-company');
    //                 },
    //                 defineVisibility = function (reset) {
    //                     if (reset) {
    //                         setVisible();
    //                     }
    //                     if(scope.getSelectedCompanyId() > 0) {
    //                         setVisible();
    //                     }
    //                     else {
    //                         setHidden();
    //
    //
    //                     }
    //                 };
    //                 scope.$location = location;
    //                 scope.$watch('$location.path()', function(newValue) {
    //                     defineVisibility(true);
    //                 });
    //                 scope.$watch('selectedCompanyId', function(newValue){
    //                 	defineVisibility(true);
    //                 });
    //         }
    //     };
    // }]);
