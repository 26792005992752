import angular from "angular";
import {moneyFilterFactory} from "./money-format.filter";
import {percentageComponent} from "./percentage.component";
import {integerFilterFactory} from "./integer-format.filter";
import {HttpClientService} from "./http-client.service";

import {reactToAngularComponent} from "../../react-app/utils/react-to-angular";
import Money from "../../react-app/components/shared/money";

export const SharedModule =
    angular.module('shared', [])
        .service('HttpClientService', HttpClientService)
        .filter('money', moneyFilterFactory)
        .filter('integer', integerFilterFactory)
        .component('percentage', percentageComponent)
        .component('money', reactToAngularComponent(Money))
        .name;
