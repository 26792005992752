
export class DynamicReportVariablesService {

    constructor($http, $q, FEATURE_COMPANY_ACCOUNT_CHART, SelectedCompanyService, CompanyAccountChartService, CompanyChartService, BasicChartOfAccountsService) {
        "ngInject";
        this.$http = $http;
        this.$q = $q;
        this.featureCompanyAccountChartEnabled = FEATURE_COMPANY_ACCOUNT_CHART;
        this.selectedCompanyService = SelectedCompanyService;
        this.companyAccountChartService = CompanyAccountChartService;
        this.companyChartService = CompanyChartService;
        this.basicChartOfAccountsService = BasicChartOfAccountsService;
    }

    getVariables() {
        if (this.featureCompanyAccountChartEnabled && this.selectedCompanyService.isBasicChartSelected()) {
            // companyChart, id, accountnmbr, formula, code
            return this._fetchNew(this.selectedCompanyService.getSelectedCompanyId(), this.selectedCompanyService.getBasicChartOfAccountsId());
        } else {
            return this._loadOldFromTheServer(this.selectedCompanyService.getSelectedCompanyId());
        }
    }

    _fetchNew(companyId, basicChartId) {
        return this.$q.all([
                this.basicChartOfAccountsService.getBasicChartMeasures(basicChartId),
                this.companyAccountChartService.queryMeasures(companyId),
                this.companyAccountChartService.getCompanyAccountChart(companyId)
            ]).then( results => {
                let basicChartMeasureVariables = results[0].map(measure => { return {companyChart: true, id:measure.id, accountnmbr: null, formula: null, code: measure.name }});
                let companyChartMeasureVariables = results[1].map(measure => { return {companyChart: true, id:measure.id, accountnmbr: null, formula: null, code: measure.name }});
                let chart = this.companyChartService.fromJsonToCharts(results[2]);
                let balanceSheetVariables = chart.balanceSheet.rows.map(row => { return {companyChart: true, id:row.id, accountnmbr: row.code, formula: null, code: row.nameFi }});
                let incomeStatementVariables = chart.incomeStatement.rows.map(row => { return {companyChart: true, id:row.id, accountnmbr: row.code, formula: null, code: row.nameFi }});
                return balanceSheetVariables.concat(incomeStatementVariables).concat(basicChartMeasureVariables).concat(companyChartMeasureVariables);
            });
    }

    _loadOldFromTheServer(companyId) {
        return this.$http.get("/api/accountingModels/variablesForCompany/" + companyId).then(function (response) {
            return response.data;
        });
    }
}