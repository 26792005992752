'use strict';

angular.module('ecoeasyApp')
    .controller('PermissionController', function ($scope, $state, Permission) {

        $scope.permissions = [];
        $scope.loadAll = function () {
            Permission.query(function (result) {
                $scope.permissions = result;
            });
        };
        $scope.loadAll();


        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.permission = {
                code: null,
                i18NCode: null,
                i18NDescCode: null,
                id: null
            };
        };
    });
