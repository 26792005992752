import {BudgetTableComponent} from './budget-table.component';
import {NumberInputComponent} from "./number-input/number-input.component";
import {AddRowsDialogComponent} from "./add-rows-dialog/add-rows-dialog.component";
import {EditRowDialogComponent} from "./edit-row-dialog/edit-row-dialog.component";
import {MemoDialogComponent} from './memo-dialog/memo-dialog.component';
import {DeleteRowDialogComponent} from "./delete-row-dialog/delete-row-dialog.component";
import {RowCalculationDialogComponent} from "../row-calculation-dialog/row-calculation-dialog.component";

import {reactToAngularComponent} from "../../../react-app/utils/react-to-angular";
import NumberLabel from "../../../react-app/components/budget/budget-table/number-label/number-label";
import {AllocateValuesDialogComponent} from "./allocate-values-dialog/allocate-values-dialog.component";
import {OperatingBudgetService} from "./operating-budget.service";

export const Budget2TableModule = angular
    .module('Budget2TableModule', [])
    .service('OperatingBudgetService', OperatingBudgetService)
    .component('b2NumberInput', NumberInputComponent)
    .component('b2BudgetTable', BudgetTableComponent)
    .component('addRowsDialog', AddRowsDialogComponent)
    .component('editRowDialog', EditRowDialogComponent)
    .component('memoDialog', MemoDialogComponent)
    .component('deleteRowDialog', DeleteRowDialogComponent)
    .component('b2NumberLabel', reactToAngularComponent(NumberLabel))
    .component('rowCalculationDialog', RowCalculationDialogComponent)
    .component('allocateValuesDialogComponent', AllocateValuesDialogComponent)
    .name;

